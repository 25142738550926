"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TIMINGS = void 0;
exports.TIMINGS = {
    '5 сек': {
        'Национальные каналы': { timingMarkup: 1.3, duration: 5 },
        Москва: { timingMarkup: 1.3, duration: 5 },
        'Санкт-Петербург': { timingMarkup: 1.1, duration: 5 },
        Екатеринбург: { timingMarkup: 1.1, duration: 5 },
        Новосибирск: { timingMarkup: 1.1, duration: 5 },
        'Нижний Новгород': { timingMarkup: 1.1, duration: 5 },
        Остальные: { timingMarkup: 1, duration: 5 }
    },
    '10 сек': {
        'Национальные каналы': { timingMarkup: 1.15, duration: 10 },
        Москва: { timingMarkup: 1.15, duration: 10 },
        'Санкт-Петербург': { timingMarkup: 1.05, duration: 10 },
        Екатеринбург: { timingMarkup: 1.05, duration: 10 },
        Новосибирск: { timingMarkup: 1.05, duration: 10 },
        'Нижний Новгород': { timingMarkup: 1.05, duration: 10 },
        Остальные: { timingMarkup: 1, duration: 10 }
    },
    '15 сек': {
        'Национальные каналы': { timingMarkup: 1.05, duration: 15 },
        Москва: { timingMarkup: 1.05, duration: 15 },
        'Санкт-Петербург': { timingMarkup: 1.03, duration: 15 },
        Екатеринбург: { timingMarkup: 1.03, duration: 15 },
        Новосибирск: { timingMarkup: 1.03, duration: 15 },
        'Нижний Новгород': { timingMarkup: 1.03, duration: 15 },
        Остальные: { timingMarkup: 1, duration: 15 }
    },
    '20 сек': {
        'Национальные каналы': { timingMarkup: 1, duration: 20 },
        Москва: { timingMarkup: 1, duration: 20 },
        'Санкт-Петербург': { timingMarkup: 1, duration: 20 },
        Екатеринбург: { timingMarkup: 1, duration: 20 },
        Новосибирск: { timingMarkup: 1, duration: 20 },
        'Нижний Новгород': { timingMarkup: 1, duration: 20 },
        Остальные: { timingMarkup: 1, duration: 20 }
    },
    '25 сек': {
        'Национальные каналы': { timingMarkup: 0.98, duration: 25 },
        Москва: { timingMarkup: 0.98, duration: 25 },
        'Санкт-Петербург': { timingMarkup: 0.99, duration: 25 },
        Екатеринбург: { timingMarkup: 0.99, duration: 25 },
        Новосибирск: { timingMarkup: 0.99, duration: 25 },
        'Нижний Новгород': { timingMarkup: 0.99, duration: 25 },
        Остальные: { timingMarkup: 1, duration: 25 }
    },
    '30 сек': {
        'Национальные каналы': { timingMarkup: 0.96, duration: 30 },
        Москва: { timingMarkup: 0.96, duration: 30 },
        'Санкт-Петербург': { timingMarkup: 0.98, duration: 30 },
        Екатеринбург: { timingMarkup: 0.98, duration: 30 },
        Новосибирск: { timingMarkup: 0.98, duration: 30 },
        'Нижний Новгород': { timingMarkup: 0.98, duration: 30 },
        Остальные: { timingMarkup: 1, duration: 30 }
    },
    Порекомендуйте: {
        'Национальные каналы': { timingMarkup: 1, duration: 20 },
        Москва: { timingMarkup: 1, duration: 20 },
        'Санкт-Петербург': { timingMarkup: 1, duration: 20 },
        Екатеринбург: { timingMarkup: 1, duration: 20 },
        Новосибирск: { timingMarkup: 1, duration: 20 },
        'Нижний Новгород': { timingMarkup: 1, duration: 20 },
        Остальные: { timingMarkup: 1, duration: 20 }
    }
};
