"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdvertisingCampaignStep = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const x_date_pickers_1 = require("@mui/x-date-pickers");
const date_fns_1 = require("date-fns");
const react_1 = require("react");
const FormContext_1 = require("../FormContext");
const Navigation_1 = require("../Navigation");
const styles_1 = require("../styles");
const COMMERCIAL_DURATIONS = ['5 сек', '10 сек', '15 сек', '20 сек', '25 сек', '30 сек', 'Порекомендуйте'];
const daysOffset = (0, date_fns_1.add)(new Date(), {
    days: 14
});
const checkIsValidPeriod = (period) => (0, date_fns_1.isAfter)(period, daysOffset) || (0, date_fns_1.isSameDay)(period, daysOffset);
function AdvertisingCampaignStep() {
    const { hasCommercial, changeHasCommercialHandler, periodFrom, changePeriodFromHandler, periodTo, changePeriodToHandler, commercialDuration, changeCommercialDurationHandler, price } = (0, react_1.useContext)(FormContext_1.FormContext);
    const [isOpenToPeriod, toggleOpenToPeriod] = (0, react_1.useState)(false);
    const toggleOpenToPeriodHandler = () => {
        toggleOpenToPeriod(!isOpenToPeriod);
    };
    const formattedPeriodTo = (0, date_fns_1.parse)(periodTo, 'yyyy-MM-dd', new Date());
    const formattedPeriodFrom = (0, date_fns_1.parse)(periodFrom, 'yyyy-MM-dd', new Date());
    const isValidPeriodFrom = checkIsValidPeriod(formattedPeriodFrom);
    const isValidPeriodTo = checkIsValidPeriod(formattedPeriodTo) && (0, date_fns_1.isAfter)(formattedPeriodTo, formattedPeriodFrom);
    const isValid = periodFrom && periodTo && isValidPeriodFrom && isValidPeriodTo;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(styles_1.Field, { children: (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(material_1.Checkbox, { checked: hasCommercial, onChange: changeHasCommercialHandler }), label: "\u0415\u0441\u0442\u044C \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u044B\u0439 \u0440\u043E\u043B\u0438\u043A" }) }), (0, jsx_runtime_1.jsxs)(styles_1.Field, { children: [(0, jsx_runtime_1.jsx)("div", { children: "\u041F\u0435\u0440\u0438\u043E\u0434 \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u043E\u0439 \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u0438" }), (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ direction: "row", spacing: 3, mt: 1 }, { children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(styles_1.LabelText, { children: "\u041E\u0442" }), (0, jsx_runtime_1.jsx)(x_date_pickers_1.DatePicker, { openTo: "day", views: ['year', 'month', 'day'], value: periodFrom, inputFormat: "dd.MM.yyyy", onChange: value => {
                                            changePeriodFromHandler(value);
                                            toggleOpenToPeriodHandler();
                                        }, toolbarTitle: "\u0414\u0430\u0442\u044B \u043D\u0430\u0447\u0430\u043B\u0430 \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u043E\u0439 \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u0438", minDate: daysOffset, closeOnSelect: true, renderInput: params => ((0, jsx_runtime_1.jsx)(material_1.TextField, Object.assign({}, params, { inputProps: Object.assign(Object.assign({}, params.inputProps), { placeholder: '01.01.2023' }) }))) })] }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(styles_1.LabelText, { children: "\u0414\u043E" }), (0, jsx_runtime_1.jsx)(x_date_pickers_1.DatePicker, { openTo: "day", views: ['year', 'month', 'day'], value: periodTo, inputFormat: "dd.MM.yyyy", onChange: value => {
                                            changePeriodToHandler(value);
                                            toggleOpenToPeriodHandler();
                                        }, toolbarTitle: "\u0414\u0430\u0442\u044B \u043A\u043E\u043D\u0446\u0430 \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u043E\u0439 \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u0438", minDate: daysOffset, closeOnSelect: true, open: isOpenToPeriod, onOpen: toggleOpenToPeriodHandler, onClose: toggleOpenToPeriodHandler, renderInput: params => ((0, jsx_runtime_1.jsx)(material_1.TextField, Object.assign({}, params, { inputProps: Object.assign(Object.assign({}, params.inputProps), { placeholder: '01.01.2023' }) }))) })] })] }))] }), (0, jsx_runtime_1.jsxs)(styles_1.Field, { children: [(0, jsx_runtime_1.jsx)("div", { children: "\u0414\u043B\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u043E\u0433\u043E \u0440\u043E\u043B\u0438\u043A\u0430" }), (0, jsx_runtime_1.jsx)(material_1.RadioGroup, Object.assign({ row: true, value: commercialDuration, onChange: changeCommercialDurationHandler }, { children: COMMERCIAL_DURATIONS.map(_commercialDuration => ((0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { value: _commercialDuration, control: (0, jsx_runtime_1.jsx)(material_1.Radio, {}), label: _commercialDuration }, _commercialDuration))) }))] }), (0, jsx_runtime_1.jsx)(Navigation_1.Navigation, { stepData: { hasCommercial, periodFrom, periodTo, commercialDuration, price }, isValid: isValid })] }));
}
exports.AdvertisingCampaignStep = AdvertisingCampaignStep;
