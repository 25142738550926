"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACTIVITIES_SECTOR = exports.ACTIVITIES = void 0;
exports.ACTIVITIES = [
    'Руководитель',
    'Специалист',
    'Служащий',
    'Рабочий',
    'Школьник',
    'Студент (ВУЗ)',
    'Учащийся (Колледж)',
    'Пенсионер',
    'Инвалид',
    'Безработный',
    'Домохозяйка / Молодая мать',
    'Индивидуальный предприниматель',
    'Владелец / совладелец предприятия'
];
exports.ACTIVITIES_SECTOR = [
    'Торговля, обслуживание, рестораны',
    'Транспорт, складское хозяйство, связь',
    'Финансы, страхование',
    'Энергетика',
    'Наука, образование, культура',
    'Мелиорация, лесничество',
    'Строительство, монтаж',
    'Производство, промышленность',
    'Право, юриспруденция',
    'ЖКХ',
    'Государственное и местное управление',
    'Здравоохранение',
    'Спорт',
    'Армия, полиция',
    'Не работает'
];
