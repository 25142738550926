"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INTERESTS = void 0;
exports.INTERESTS = [
    'Товары для детей',
    'Ожидание ребенка',
    'Дополнительное образование',
    'Путешествия',
    'Домашние животные',
    'Доставка еды',
    'Рестораны и кафе',
    'Досуг',
    'Кэшбэк, скидки, купоны',
    'Кредиты',
    'Инвестиции',
    'Страхование',
    'Недвижимость',
    'Ремонт',
    'Мебель',
    'Товары для дома',
    'Товары для сада',
    'Техника и электроника',
    'Покупка/продажа автомобиля',
    'Ремонт автомобиля',
    'Косметика, парфюмерия',
    'Одежда и обувь',
    'Медицинские услуги',
    'Лекарства',
    'Спортивные трансляции',
    'Занятия спортом',
    'Продукты питания',
    'Напитки'
];
