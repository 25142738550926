"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.differenceInDaysWithLast = void 0;
const date_fns_1 = require("date-fns");
const differenceInDaysWithLast = (periodTo, periodFrom) => {
    const parsedPeriodTo = (0, date_fns_1.parse)(periodTo, 'yyyy-MM-dd', new Date());
    const parsedPeriodFrom = (0, date_fns_1.parse)(periodFrom, 'yyyy-MM-dd', new Date());
    return (0, date_fns_1.differenceInDays)(parsedPeriodTo, parsedPeriodFrom) + 1;
};
exports.differenceInDaysWithLast = differenceInDaysWithLast;
