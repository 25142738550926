"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CitiesStep = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Search_1 = require("@mui/icons-material/Search");
const material_1 = require("@mui/material");
const react_1 = require("react");
const FormContext_1 = require("../FormContext");
const Navigation_1 = require("../Navigation");
const styles_1 = require("../styles");
function CitiesStep() {
    const [cityInputText, changeCityInputText] = (0, react_1.useState)('');
    const { selectedCities, toggleSelectedCities, availableCities } = (0, react_1.useContext)(FormContext_1.FormContext);
    const handleChangeCityInputText = (e) => {
        changeCityInputText(e.target.value);
    };
    const filteredCities = availableCities.filter(city => city.toLocaleLowerCase().includes(cityInputText.toLocaleLowerCase()));
    const isValid = selectedCities.length > 0;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(styles_1.Field, { children: (0, jsx_runtime_1.jsx)(material_1.TextField, { required: true, value: cityInputText, onChange: handleChangeCityInputText, fullWidth: true, placeholder: "\u041F\u043E\u0438\u0441\u043A...", size: "small", InputProps: {
                        startAdornment: ((0, jsx_runtime_1.jsx)(material_1.InputAdornment, Object.assign({ position: "start" }, { children: (0, jsx_runtime_1.jsx)(Search_1.default, {}) })))
                    } }) }), (0, jsx_runtime_1.jsx)(styles_1.ScrollList, { children: (0, jsx_runtime_1.jsx)(material_1.FormGroup, { children: filteredCities.map(city => ((0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(material_1.Checkbox, { onChange: toggleSelectedCities(city), checked: selectedCities.includes(city) }), label: city }, city))) }) }), (0, jsx_runtime_1.jsx)(Navigation_1.Navigation, { stepData: { selectedCities }, isValid: isValid })] }));
}
exports.CitiesStep = CitiesStep;
