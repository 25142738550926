"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SliderWrapper = exports.Field = exports.Placeholder = exports.ScrollList = exports.LabelText = void 0;
const styled_components_1 = require("styled-components");
exports.LabelText = styled_components_1.default.div.withConfig({ displayName: "LabelText", componentId: "-i7omd" }) `
  margin-bottom: 8px;
  font-size: 14px;
`;
exports.ScrollList = styled_components_1.default.div.withConfig({ displayName: "ScrollList", componentId: "-ornjim" }) `
  height: 316px;
  overflow: auto;
`;
exports.Placeholder = styled_components_1.default.div.withConfig({ displayName: "Placeholder", componentId: "-r5d97y" }) `
  color: #9e9e9e;
  overflow: hidden;
  text-overflow: ellipsis;
`;
exports.Field = styled_components_1.default.div.withConfig({ displayName: "Field", componentId: "-3m9r17" }) `
  margin-bottom: 20px;
`;
exports.SliderWrapper = styled_components_1.default.div.withConfig({ displayName: "SliderWrapper", componentId: "-15eb2dh" }) `
  margin-top: 50px;
  padding: 0px 25px;
`;
