"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TargetAudienceFourthStep = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const interests_1 = require("@common/dictionaries/interests");
const FormContext_1 = require("../FormContext");
const Navigation_1 = require("../Navigation");
const styles_1 = require("../styles");
const OTHER = ['Наличие интернета', 'Наличие дачи', 'Наличие SmartTV в телевизоре', 'Наличие спутниковой тарелки'];
function TargetAudienceFourthStep() {
    const { interests, changeInterestsHandler, others, toggleSelectedOthers } = (0, react_1.useContext)(FormContext_1.FormContext);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(styles_1.Field, { children: [(0, jsx_runtime_1.jsx)(styles_1.LabelText, { children: "\u0418\u043D\u0442\u0435\u0440\u0435\u0441\u044B" }), (0, jsx_runtime_1.jsx)(material_1.Select, Object.assign({ required: true, value: interests, onChange: changeInterestsHandler, fullWidth: true, multiple: true, displayEmpty: true, renderValue: interests.length > 0 ? selected => selected.join(', ') : () => (0, jsx_runtime_1.jsx)(styles_1.Placeholder, { children: "\u0418\u043D\u0442\u0435\u0440\u0435\u0441\u044B" }) }, { children: interests_1.INTERESTS.map(interest => ((0, jsx_runtime_1.jsxs)(material_1.MenuItem, Object.assign({ value: interest }, { children: [(0, jsx_runtime_1.jsx)(material_1.Checkbox, { checked: interests.indexOf(interest) > -1 }), (0, jsx_runtime_1.jsx)("div", { children: interest })] }), interest))) }))] }), (0, jsx_runtime_1.jsxs)(styles_1.Field, { children: [(0, jsx_runtime_1.jsx)("div", { children: "\u0414\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u043E" }), OTHER.map(other => ((0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(material_1.Checkbox, { onChange: toggleSelectedOthers(other), checked: others.includes(other) }), label: other }, other)))] }), (0, jsx_runtime_1.jsx)(Navigation_1.Navigation, { stepData: { interests, others }, isValid: true })] }));
}
exports.TargetAudienceFourthStep = TargetAudienceFourthStep;
