"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Navigation = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const FormContext_1 = require("./FormContext");
function Navigation({ stepData, isValid }) {
    const { nextStepHandler, prevStepHandler, stepInfo } = (0, react_1.useContext)(FormContext_1.FormContext);
    const stepName = stepInfo.name;
    const data = {
        action: stepName,
        metadata: stepData,
        url: window.location.href
    };
    const clickNextStep = () => nextStepHandler(data);
    const clickPrevStep = () => prevStepHandler(data);
    const buy = () => {
        data.metadata.purchaseType = 'купить';
        nextStepHandler(data);
    };
    const buyInCredit = () => {
        data.metadata.purchaseType = 'купить в кредит';
        nextStepHandler(data);
    };
    if (stepName === 'Бренд') {
        return ((0, jsx_runtime_1.jsx)(material_1.Grid, Object.assign({ item: true, justifyContent: "end", ml: "auto" }, { children: (0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ variant: "contained", onClick: clickNextStep, disabled: !isValid, disableElevation: true }, { children: "\u0420\u0430\u0441\u0441\u0447\u0438\u0442\u0430\u0442\u044C \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u044E" })) })));
    }
    if (stepName === 'Результаты') {
        return ((0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ direction: "row", justifyContent: "end", spacing: 2, mt: 4 }, { children: [(0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ variant: "outlined", onClick: clickPrevStep, disableElevation: true, sx: { mr: 'auto' } }, { children: "\u041D\u0430\u0437\u0430\u0434" })), (0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ variant: "contained", color: "secondary", onClick: buyInCredit, disableElevation: true }, { children: "\u041A\u0443\u043F\u0438\u0442\u044C \u0432 \u043A\u0440\u0435\u0434\u0438\u0442" })), (0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ variant: "contained", onClick: buy, disableElevation: true }, { children: "\u041A\u0443\u043F\u0438\u0442\u044C" }))] })));
    }
    return ((0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ direction: "row", justifyContent: "end", spacing: 2, mt: 4 }, { children: [(0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ variant: "outlined", onClick: clickPrevStep, disableElevation: true }, { children: "\u041D\u0430\u0437\u0430\u0434" })), (0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ variant: "contained", disabled: !isValid, onClick: clickNextStep, disableElevation: true }, { children: "\u0414\u0430\u043B\u0435\u0435" }))] })));
}
exports.Navigation = Navigation;
