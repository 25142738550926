"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Main = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = require("styled-components");
const mediaQueries_1 = require("@common/mediaQueries");
const Advantages_1 = require("./components/Advantages");
const Banner_1 = require("./components/Banner");
const Footer_1 = require("./components/Footer");
const Form_1 = require("./components/Form");
const FormContext_1 = require("./components/Form/FormContext");
const Header_1 = require("./components/Header");
const WorkSteps_1 = require("./components/WorkSteps");
const Section = styled_components_1.default.section.withConfig({ displayName: "Section", componentId: "-1p5i3ax" }) `
  margin-bottom: 40px;

  @media ${mediaQueries_1.devices.tablet} {
    margin-bottom: 80px;
  }
`;
function Main() {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(Header_1.Header, {}), (0, jsx_runtime_1.jsx)(Banner_1.Banner, {}), (0, jsx_runtime_1.jsx)(Section, { children: (0, jsx_runtime_1.jsx)(FormContext_1.FormProvider, { children: (0, jsx_runtime_1.jsx)(Form_1.Form, {}) }) }), (0, jsx_runtime_1.jsx)(Section, { children: (0, jsx_runtime_1.jsx)(Advantages_1.Advantages, {}) }), (0, jsx_runtime_1.jsx)(Section, { children: (0, jsx_runtime_1.jsx)(material_1.Grid, Object.assign({ container: true, justifyContent: "center" }, { children: (0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ variant: "contained", href: "#form", disableElevation: true }, { children: "\u0420\u0430\u0441\u0441\u0447\u0438\u0442\u0430\u0442\u044C \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u044E" })) })) }), (0, jsx_runtime_1.jsx)(Section, { children: (0, jsx_runtime_1.jsx)(WorkSteps_1.WorkSteps, {}) }), (0, jsx_runtime_1.jsx)(Footer_1.Footer, {})] }));
}
exports.Main = Main;
