"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormStepper = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const styled_components_1 = require("styled-components");
const FormContext_1 = require("./FormContext");
const StepperStyled = (0, styled_components_1.default)(material_1.Stepper).withConfig({ displayName: "StepperStyled", componentId: "-usbqgg" }) `
  padding: 8px 16px;
  background-color: #f8f8f8;
  border-radius: 300px;
  margin-bottom: 32px;
  overflow: auto;
`;
function FormStepper() {
    const { steps, activeStep } = (0, react_1.useContext)(FormContext_1.FormContext);
    return ((0, jsx_runtime_1.jsx)(StepperStyled, Object.assign({ activeStep: activeStep }, { children: steps.map(step => step.visible ? ((0, jsx_runtime_1.jsx)(material_1.Step, Object.assign({ active: step.stepsGroup.includes(activeStep) }, { children: (0, jsx_runtime_1.jsx)(material_1.StepLabel, Object.assign({ icon: step.stepperIndex }, { children: step.stepperName })) }), step.name)) : ((0, jsx_runtime_1.jsx)(react_1.Fragment, {}, step.name))) })));
}
exports.FormStepper = FormStepper;
