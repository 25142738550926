"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRODUCT_CATEGORIES = void 0;
exports.PRODUCT_CATEGORIES = [
    'Алкогольные напитки',
    'Аудио, видео, кино, фототехника',
    'Аудио, видеопродукция, игры',
    'Безалкогольные напитки',
    'Бытовая техника',
    'Бытовая химия',
    'Галантерея и текстиль',
    'Государственные организации',
    'Компьютерная техника и ПО',
    'Кондитерские изделия',
    'Кухонные и хозяйственные принадлежности',
    'Лек. препараты и биоактивные добавки',
    'Массовые мероприятия',
    'Мебель и предметы интерьера',
    'Медицинское оборудование и материалы',
    'Общественно-спортивные организации',
    'Одежда и обувь',
    'Оргтехника и канцелярские товары',
    'Парфюмерия',
    'Продукты питания',
    'Промышленное оборудование',
    'Промышленные материалы',
    'Растения, товары для сада и огорода',
    'Реклама торговой марки',
    'Российский союз автостраховщиков',
    'Спортивные товары',
    'Средства массовой информации',
    'Средства связи и оборудование',
    'Строительные, отд. материалы, сантехника',
    'Строительство и ремонт',
    'Товары для детей',
    'Товары для животных',
    'Товары для красоты и здоровья',
    'Товары для организации праздников',
    'Товары повседневного спроса',
    'Транспорт и сопутствующие товары',
    'Часы, ювелирные изделия',
    'Услуги бытовые и сервис',
    'Услуги в области интернета',
    'Услуги в области рекламы и маркетинга',
    'Услуги в области торговли',
    'Услуги в системе образования и трудоустройство',
    'Услуги индустрии развлечения',
    'Услуги медицинские',
    'Услуги общественного питания',
    'Услуги по операциями с недвижимостью',
    'Услуги по туризму, спорту и отдыху',
    'Услуги производственного характера',
    'Услуги связи',
    'Услуги страховые',
    'Услуги транспортные',
    'Услуги финансовые'
];
