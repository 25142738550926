"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLocalStorage = void 0;
const react_1 = require("react");
const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = (0, react_1.useState)(() => {
        let currentValue;
        try {
            currentValue = JSON.parse(localStorage.getItem(key) || String(defaultValue));
        }
        catch (error) {
            currentValue = defaultValue;
        }
        if (key === 'periodFrom' || key === 'periodTo') {
            const date = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
            return date;
        }
        return currentValue;
    });
    (0, react_1.useEffect)(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);
    return [value, setValue];
};
exports.useLocalStorage = useLocalStorage;
