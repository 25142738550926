"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TargetAudienceThirdStep = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const activities_1 = require("@common/dictionaries/activities");
const FormContext_1 = require("../FormContext");
const Navigation_1 = require("../Navigation");
const styles_1 = require("../styles");
const EMPLOYMENTS = ['Полный рабочий день', 'Неполный рабочий день', 'Не работает'];
const COMPANY_TYPES = ['Государственная', 'Негосударственная'];
function TargetAudienceThirdStep() {
    const { employment, changeEmploymentHandler, activities, changeActivitiesHandler, activitiesSector, changeActivitiesSectorHandler, companyType, changeCompanyTypeHandler } = (0, react_1.useContext)(FormContext_1.FormContext);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(styles_1.Field, { children: [(0, jsx_runtime_1.jsx)("div", { children: "\u0417\u0430\u043D\u044F\u0442\u043E\u0441\u0442\u044C" }), (0, jsx_runtime_1.jsx)(material_1.RadioGroup, Object.assign({ row: true, value: employment, onChange: changeEmploymentHandler }, { children: EMPLOYMENTS.map(_employment => ((0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { value: _employment, control: (0, jsx_runtime_1.jsx)(material_1.Radio, {}), label: _employment }, _employment))) }))] }), (0, jsx_runtime_1.jsxs)(styles_1.Field, { children: [(0, jsx_runtime_1.jsx)(styles_1.LabelText, { children: "\u0420\u043E\u0434 \u0434\u0435\u044F\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u0438" }), (0, jsx_runtime_1.jsx)(material_1.Select, Object.assign({ required: true, value: activities, onChange: changeActivitiesHandler, fullWidth: true, multiple: true, displayEmpty: true, renderValue: activities.length > 0 ? selected => selected.join(', ') : () => (0, jsx_runtime_1.jsx)(styles_1.Placeholder, { children: "\u0420\u043E\u0434 \u0434\u0435\u044F\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u0438" }) }, { children: activities_1.ACTIVITIES.map(activity => ((0, jsx_runtime_1.jsxs)(material_1.MenuItem, Object.assign({ value: activity }, { children: [(0, jsx_runtime_1.jsx)(material_1.Checkbox, { checked: activities.indexOf(activity) > -1 }), (0, jsx_runtime_1.jsx)("div", { children: activity })] }), activity))) }))] }), (0, jsx_runtime_1.jsxs)(styles_1.Field, { children: [(0, jsx_runtime_1.jsx)(styles_1.LabelText, { children: "\u041E\u0442\u0440\u0430\u0441\u043B\u044C \u0434\u0435\u044F\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u0438" }), (0, jsx_runtime_1.jsx)(material_1.Select, Object.assign({ required: true, value: activitiesSector, onChange: changeActivitiesSectorHandler, fullWidth: true, multiple: true, displayEmpty: true, renderValue: activitiesSector.length > 0
                            ? selected => selected.join(', ')
                            : () => (0, jsx_runtime_1.jsx)(styles_1.Placeholder, { children: "\u041E\u0442\u0440\u0430\u0441\u043B\u044C \u0434\u0435\u044F\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u0438" }) }, { children: activities_1.ACTIVITIES_SECTOR.map(activitySector => ((0, jsx_runtime_1.jsxs)(material_1.MenuItem, Object.assign({ value: activitySector }, { children: [(0, jsx_runtime_1.jsx)(material_1.Checkbox, { checked: activitiesSector.indexOf(activitySector) > -1 }), (0, jsx_runtime_1.jsx)("div", { children: activitySector })] }), activitySector))) }))] }), (0, jsx_runtime_1.jsxs)(styles_1.Field, { children: [(0, jsx_runtime_1.jsx)("div", { children: "\u0422\u0438\u043F \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u044F" }), (0, jsx_runtime_1.jsx)(material_1.RadioGroup, Object.assign({ row: true, value: companyType, onChange: changeCompanyTypeHandler }, { children: COMPANY_TYPES.map(_companyType => ((0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { value: _companyType, control: (0, jsx_runtime_1.jsx)(material_1.Radio, {}), label: _companyType }, _companyType))) }))] }), (0, jsx_runtime_1.jsx)(Navigation_1.Navigation, { stepData: { employment, activities, activitiesSector, companyType }, isValid: true })] }));
}
exports.TargetAudienceThirdStep = TargetAudienceThirdStep;
