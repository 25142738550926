"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChannelsStep = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const styled_components_1 = require("styled-components");
const channels_1 = require("@common/dictionaries/channels");
const FormContext_1 = require("../FormContext");
const Navigation_1 = require("../Navigation");
const styles_1 = require("../styles");
const CityChannelList = styled_components_1.default.div.withConfig({ displayName: "CityChannelList", componentId: "-17009wz" }) `
  margin-bottom: 24px;
`;
const StickyCityName = styled_components_1.default.div.withConfig({ displayName: "StickyCityName", componentId: "-1j6jdd" }) `
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  padding-right: 11px;
`;
const ChannelBlock = styled_components_1.default.div.withConfig({ displayName: "ChannelBlock", componentId: "-1918w04" }) `
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  margin-bottom: 8px;
  padding: 0px 11px;
`;
function ChannelsStep() {
    const { selectedCities, selectedChannels, changeSelectedChannelsHandler, isChannelChecked, isAllChannelsByCityChecked, changeAllSelectedChannelsByCityHandler } = (0, react_1.useContext)(FormContext_1.FormContext);
    const isValid = Object.values(selectedChannels).some((channels) => channels.length > 0);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(styles_1.ScrollList, { children: selectedCities.map((city) => ((0, jsx_runtime_1.jsxs)(CityChannelList, { children: [(0, jsx_runtime_1.jsxs)(StickyCityName, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "h4" }, { children: city })), (0, jsx_runtime_1.jsx)(material_1.Checkbox, { checked: isAllChannelsByCityChecked(city), onChange: changeAllSelectedChannelsByCityHandler(city) })] }), channels_1.CHANNELS[city].map((channel) => ((0, jsx_runtime_1.jsxs)(ChannelBlock, { children: [channel.channelName, (0, jsx_runtime_1.jsx)(material_1.Checkbox, { checked: isChannelChecked(city, channel.channelName), onChange: changeSelectedChannelsHandler(city, channel.channelName) })] }, channel.channelName)))] }, city))) }), (0, jsx_runtime_1.jsx)(Navigation_1.Navigation, { stepData: { selectedChannels }, isValid: isValid })] }));
}
exports.ChannelsStep = ChannelsStep;
