"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormProvider = exports.FormContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const date_fns_1 = require("date-fns");
const react_1 = require("react");
const logger_1 = require("@api/logger");
const dates_1 = require("@common/dates");
const channels_1 = require("@common/dictionaries/channels");
const cities_1 = require("@common/dictionaries/cities");
const useLocalStorage_1 = require("@hooks/useLocalStorage");
const calculatePrice_1 = require("./calculatePrice");
const checkIsOverLimit_1 = require("./checkIsOverLimit");
const steps_1 = require("./steps");
const FormContext = (0, react_1.createContext)(null);
exports.FormContext = FormContext;
function FormProvider({ children }) {
    const [steps, changeSteps] = (0, react_1.useState)(steps_1.STEPS);
    const [activeStep, setActiveStep] = (0, useLocalStorage_1.useLocalStorage)('activeStep', 0);
    const [brand, changeBrand] = (0, useLocalStorage_1.useLocalStorage)('brand', '');
    const [placementType, changePlacementType] = (0, useLocalStorage_1.useLocalStorage)('placementType', '');
    const [productCategory, changeProductCategory] = (0, useLocalStorage_1.useLocalStorage)('productCategory', '');
    const [selectedCities, changeSelectedCities] = (0, useLocalStorage_1.useLocalStorage)('selectedCities', []);
    const [selectedChannels, changeSelectedChannels] = (0, useLocalStorage_1.useLocalStorage)('selectedChannels', {});
    const [gender, changeGender] = (0, useLocalStorage_1.useLocalStorage)('gender', 'Любой');
    const [age, changeAge] = (0, useLocalStorage_1.useLocalStorage)('age', [25, 45]);
    const [incomes, changeIncomes] = (0, useLocalStorage_1.useLocalStorage)('incomes', []);
    const [maritalStatus, changeMaritalStatus] = (0, useLocalStorage_1.useLocalStorage)('maritalStatus', 'Любое');
    const [hasChildren, changeHasChildren] = (0, useLocalStorage_1.useLocalStorage)('hasChildren', false);
    const [childrenAge, changeChildrenAge] = (0, useLocalStorage_1.useLocalStorage)('childrenAge', [null, null]);
    const [educations, changeEducations] = (0, useLocalStorage_1.useLocalStorage)('educations', []);
    const [employment, changeEmployment] = (0, useLocalStorage_1.useLocalStorage)('employment', '');
    const [activities, changeActivities] = (0, useLocalStorage_1.useLocalStorage)('activities', []);
    const [activitiesSector, changeActivitiesSector] = (0, useLocalStorage_1.useLocalStorage)('activitiesSector', []);
    const [companyType, changeCompanyType] = (0, useLocalStorage_1.useLocalStorage)('companyType', '');
    const [interests, changeInterests] = (0, useLocalStorage_1.useLocalStorage)('interests', []);
    const [others, changeOthers] = (0, useLocalStorage_1.useLocalStorage)('others', []);
    const [periodFrom, changePeriodFrom] = (0, useLocalStorage_1.useLocalStorage)('periodFrom', null);
    const [periodTo, changePeriodTo] = (0, useLocalStorage_1.useLocalStorage)('periodTo', null);
    const [hasCommercial, changeHasCommercial] = (0, useLocalStorage_1.useLocalStorage)('hasCommercial', false);
    const [commercialDuration, changeCommercialDuration] = (0, useLocalStorage_1.useLocalStorage)('commercialDuration', '20 сек');
    const [email, changeEmail] = (0, useLocalStorage_1.useLocalStorage)('email', '');
    const [spotCount, changeSpotCount] = (0, useLocalStorage_1.useLocalStorage)('spotCount', 3);
    const [price, changePrice] = (0, useLocalStorage_1.useLocalStorage)('price', '');
    const [budgetWarning, changeBudgetWarning] = (0, useLocalStorage_1.useLocalStorage)('budgetWarning', false);
    const changePriceLoggerTimer = (0, react_1.useRef)(null);
    const availableCities = cities_1.CITIES.filter(city => city !== 'Национальные каналы');
    const stepInfo = steps[activeStep];
    (0, react_1.useEffect)(() => {
        if (placementType === 'Национальное') {
            const updatedSteps = steps_1.STEPS.filter(step => step.name !== 'Медиа (города)');
            changeSteps(updatedSteps);
        }
        if (placementType === 'Региональное') {
            changeSteps(steps_1.STEPS);
        }
        if (placementType === 'Национальное + Региональное') {
            changeSteps(steps_1.STEPS);
        }
    }, [placementType]);
    const nextStepHandler = (stepData) => {
        if (activeStep === steps.length - 1)
            return;
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        logger_1.LoggerApi.logger(stepData);
    };
    const prevStepHandler = (stepData) => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
        logger_1.LoggerApi.logger(stepData);
    };
    const stepToChannelsHandler = (stepData) => {
        const index = steps.findIndex(step => step.name === 'Медиа (каналы)');
        setActiveStep(index);
        logger_1.LoggerApi.logger(stepData);
    };
    const stepToAdvertisingCampaignHandler = (stepData) => {
        const index = steps.findIndex(step => step.name === 'Контент');
        setActiveStep(index);
        logger_1.LoggerApi.logger(stepData);
    };
    const changeBrandHandler = (e) => {
        changeBrand(e.target.value);
    };
    const changePlacementTypeHandler = (e) => {
        changePlacementType(e.target.value);
        if (e.target.value === 'Национальное' || e.target.value === 'Национальное + Региональное') {
            changeSelectedCities(['Национальные каналы']);
            changeSelectedChannels({
                'Национальные каналы': channels_1.CHANNELS['Национальные каналы'].map(channel => channel.channelName)
            });
        }
        if (e.target.value === 'Региональное') {
            changeSelectedCities([]);
            changeSelectedChannels({});
        }
    };
    const changeProductCategoryHandler = (e) => {
        changeProductCategory(e.target.value);
    };
    const toggleSelectedCities = (city) => () => {
        if (selectedCities.includes(city)) {
            const updatedCities = selectedCities.filter(selectedCity => selectedCity !== city);
            changeSelectedCities(updatedCities);
            const _a = selectedChannels, _b = city, _ = _a[_b], updatedSelectedChannels = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
            changeSelectedChannels(updatedSelectedChannels);
        }
        else {
            const updatedCities = [...selectedCities, city];
            changeSelectedCities(updatedCities);
            changeSelectedChannels(prevState => (Object.assign(Object.assign({}, prevState), { [city]: channels_1.CHANNELS[city].map(channel => channel.channelName) })));
        }
    };
    const changeSelectedChannelsHandler = (city, channelName) => () => {
        var _a;
        if ((_a = selectedChannels[city]) === null || _a === void 0 ? void 0 : _a.includes(channelName)) {
            const updatedChannels = selectedChannels[city].filter(channel => channel !== channelName);
            changeSelectedChannels(prevState => (Object.assign(Object.assign({}, prevState), { [city]: updatedChannels })));
        }
        else {
            let updatedChannels = [];
            if (selectedChannels[city]) {
                updatedChannels = [...selectedChannels[city], channelName];
            }
            else {
                updatedChannels = [channelName];
            }
            changeSelectedChannels(prevState => (Object.assign(Object.assign({}, prevState), { [city]: updatedChannels })));
        }
    };
    const isChannelChecked = (city, channelName) => { var _a; return Boolean((_a = selectedChannels === null || selectedChannels === void 0 ? void 0 : selectedChannels[city]) === null || _a === void 0 ? void 0 : _a.includes(channelName)); };
    const isAllChannelsByCityChecked = (city) => { var _a; return ((_a = selectedChannels === null || selectedChannels === void 0 ? void 0 : selectedChannels[city]) === null || _a === void 0 ? void 0 : _a.length) === channels_1.CHANNELS[city].length; };
    const changeAllSelectedChannelsByCityHandler = (city) => () => {
        if (isAllChannelsByCityChecked(city)) {
            const _a = selectedChannels, _b = city, _ = _a[_b], otherSelectedChannels = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
            changeSelectedChannels(otherSelectedChannels);
        }
        else {
            const updatedChannels = channels_1.CHANNELS[city].map(channel => channel.channelName);
            changeSelectedChannels(prevState => (Object.assign(Object.assign({}, prevState), { [city]: updatedChannels })));
        }
    };
    const changeGenderHandler = (event) => {
        changeGender(event.target.value);
    };
    const changeAgeHandler = (event, newAge) => {
        changeAge(newAge);
    };
    const toggleSelectedIncomes = (income) => () => {
        if (incomes.includes(income)) {
            const updatedIncomes = incomes.filter(_income => _income !== income);
            changeIncomes(updatedIncomes);
        }
        else {
            const updatedIncomes = [...incomes, income];
            changeIncomes(updatedIncomes);
        }
    };
    const changeMaritalStatusHandler = (event) => {
        changeMaritalStatus(event.target.value);
    };
    const changeChildrenAgeHandler = (event, newAge) => {
        changeChildrenAge(newAge);
    };
    const changeHasChildrenHandler = () => {
        changeHasChildren(!hasChildren);
    };
    const toggleSelectedEducations = (education) => () => {
        if (educations.includes(education)) {
            const updatedEducations = educations.filter(_education => _education !== education);
            changeEducations(updatedEducations);
        }
        else {
            const updatedEducations = [...educations, education];
            changeEducations(updatedEducations);
        }
    };
    const changeEmploymentHandler = (event) => {
        changeEmployment(event.target.value);
    };
    const changeActivitiesHandler = (event) => {
        const { value } = event.target;
        changeActivities(typeof value === 'string' ? value.split(',') : value);
    };
    const changeActivitiesSectorHandler = (event) => {
        const { value } = event.target;
        changeActivitiesSector(typeof value === 'string' ? value.split(',') : value);
    };
    const changeCompanyTypeHandler = (event) => {
        changeCompanyType(event.target.value);
    };
    const changeInterestsHandler = (event) => {
        const { value } = event.target;
        changeInterests(typeof value === 'string' ? value.split(',') : value);
    };
    const toggleSelectedOthers = (other) => () => {
        if (others.includes(other)) {
            const updatedOthers = others.filter(_other => _other !== other);
            changeOthers(updatedOthers);
        }
        else {
            const updatedOthers = [...others, other];
            changeOthers(updatedOthers);
        }
    };
    const changePeriodFromHandler = (value) => {
        const date = (0, date_fns_1.format)(value, 'yyyy-MM-dd');
        changePeriodFrom(date);
    };
    const changePeriodToHandler = (value) => {
        const date = (0, date_fns_1.format)(value, 'yyyy-MM-dd');
        changePeriodTo(date);
    };
    const changeHasCommercialHandler = () => {
        changeHasCommercial(!hasCommercial);
    };
    const changeCommercialDurationHandler = (event) => {
        changeCommercialDuration(event.target.value);
    };
    const changeEmailHandler = (event) => {
        changeEmail(event.target.value);
    };
    const changePriceHandler = (newSpotCount) => {
        const { totalPrice, regionsBudget } = (0, calculatePrice_1.calculatePrice)(periodFrom, periodTo, newSpotCount, commercialDuration, selectedChannels);
        const isOverLimit = (0, checkIsOverLimit_1.checkIsOverLimit)(regionsBudget);
        if (isOverLimit) {
            changeBudgetWarning(true);
        }
        else {
            changeBudgetWarning(false);
        }
        changePrice(totalPrice);
    };
    const changeSpotCountHandler = (event, newSpotCount) => {
        if (newSpotCount !== spotCount) {
            changeSpotCount(newSpotCount);
            changePriceHandler(newSpotCount);
        }
    };
    const totalSpotCountPerDay = Object.values(selectedChannels).flat().length * spotCount;
    const periodDays = (0, dates_1.differenceInDaysWithLast)(periodTo, periodFrom);
    const totalSpotCount = totalSpotCountPerDay * periodDays;
    (0, react_1.useEffect)(() => {
        if (stepInfo.name === 'Контент' && periodFrom && periodTo) {
            changePriceHandler(spotCount);
        }
    }, [stepInfo, periodFrom, periodTo]);
    (0, react_1.useEffect)(() => {
        if (changePriceLoggerTimer.current) {
            clearTimeout(changePriceLoggerTimer.current);
        }
        changePriceLoggerTimer.current = setTimeout(() => {
            const data = {
                action: 'Изменение количества выходов',
                metadata: { price, spotCount },
                url: window.location.href
            };
            logger_1.LoggerApi.logger(data);
        }, 500);
        return () => {
            clearTimeout(changePriceLoggerTimer.current);
        };
    }, [price]);
    const value = (0, react_1.useMemo)(() => ({
        steps,
        activeStep,
        stepInfo,
        nextStepHandler,
        prevStepHandler,
        stepToChannelsHandler,
        stepToAdvertisingCampaignHandler,
        brand,
        changeBrandHandler,
        placementType,
        changePlacementTypeHandler,
        productCategory,
        changeProductCategoryHandler,
        availableCities,
        selectedCities,
        toggleSelectedCities,
        selectedChannels,
        changeSelectedChannelsHandler,
        isChannelChecked,
        isAllChannelsByCityChecked,
        changeAllSelectedChannelsByCityHandler,
        gender,
        changeGenderHandler,
        age,
        changeAgeHandler,
        incomes,
        toggleSelectedIncomes,
        maritalStatus,
        changeMaritalStatusHandler,
        hasChildren,
        changeHasChildrenHandler,
        childrenAge,
        changeChildrenAgeHandler,
        educations,
        toggleSelectedEducations,
        activities,
        changeActivitiesHandler,
        activitiesSector,
        changeActivitiesSectorHandler,
        employment,
        changeEmploymentHandler,
        companyType,
        changeCompanyTypeHandler,
        interests,
        changeInterestsHandler,
        others,
        toggleSelectedOthers,
        periodFrom,
        changePeriodFromHandler,
        periodTo,
        changePeriodToHandler,
        hasCommercial,
        changeHasCommercialHandler,
        commercialDuration,
        changeCommercialDurationHandler,
        email,
        changeEmailHandler,
        price,
        spotCount,
        changeSpotCountHandler,
        budgetWarning,
        totalSpotCountPerDay,
        totalSpotCount
    }), [
        steps,
        activeStep,
        brand,
        placementType,
        productCategory,
        availableCities,
        selectedCities,
        selectedChannels,
        gender,
        age,
        incomes,
        maritalStatus,
        hasChildren,
        childrenAge,
        educations,
        activities,
        activitiesSector,
        employment,
        companyType,
        interests,
        others,
        periodFrom,
        periodTo,
        hasCommercial,
        commercialDuration,
        email,
        price,
        spotCount,
        budgetWarning,
        totalSpotCountPerDay,
        totalSpotCount
    ]);
    return (0, jsx_runtime_1.jsx)(FormContext.Provider, Object.assign({ value: value }, { children: children }));
}
exports.FormProvider = FormProvider;
