"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Banner = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = require("styled-components");
const mediaQueries_1 = require("@common/mediaQueries");
const Wrapper = styled_components_1.default.div.withConfig({ displayName: "Wrapper", componentId: "-2ohupi" }) `
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  padding: 40px 0px;

  /* @media ${mediaQueries_1.devices.tablet} {
    min-height: 600px;
    text-align: left;

    & > .BannerContainer {
      width: 470px;
      min-width: 355px;
      margin: 0;
    }
  } */
`;
const Description = styled_components_1.default.p.withConfig({ displayName: "Description", componentId: "-pbju0y" }) `
  letter-spacing: 0.15px;
  line-height: 175%;
  margin-bottom: 0px;
`;
function Banner() {
    return ((0, jsx_runtime_1.jsx)(Wrapper, { children: (0, jsx_runtime_1.jsxs)(material_1.Container, Object.assign({ className: "BannerContainer" }, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "h1" }, { children: "\u0420\u0435\u043A\u043B\u0430\u043C\u0430 \u043D\u0430 \u0422\u0412 \u0434\u043B\u044F \u043C\u0430\u043B\u043E\u0433\u043E \u0438 \u0441\u0440\u0435\u0434\u043D\u0435\u0433\u043E \u0431\u0438\u0437\u043D\u0435\u0441\u0430" })), (0, jsx_runtime_1.jsx)(Description, { children: "\u0421\u043E\u0437\u0434\u0430\u0439\u0442\u0435 \u0438 \u0437\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u0435 \u0440\u0435\u043A\u043B\u0430\u043C\u0443 \u0441\u0432\u043E\u0435\u0433\u043E \u0431\u0438\u0437\u043D\u0435\u0441\u0430" })] })) }));
}
exports.Banner = Banner;
