"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorBoundary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const GlobalError_1 = require("./GlobalError");
class ErrorBoundary extends react_1.Component {
    constructor() {
        super(...arguments);
        this.state = { error: false };
    }
    static getDerivedStateFromError(error) {
        console.log('React ErrorBoundary: ', error);
        return { error: true };
    }
    render() {
        const { error } = this.state;
        const { children, type = 'global' } = this.props;
        if (!error)
            return children;
        if (type === 'global')
            return (0, jsx_runtime_1.jsx)(GlobalError_1.GlobalError, {});
        return (0, jsx_runtime_1.jsx)(GlobalError_1.GlobalError, {});
    }
}
exports.ErrorBoundary = ErrorBoundary;
