"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
const styles_1 = require("@mui/material/styles");
const PRIMARY_COLOR = '#00A35C';
exports.theme = (0, styles_1.createTheme)({
    palette: {
        primary: {
            main: PRIMARY_COLOR
        },
        secondary: {
            main: '#007642'
        }
    },
    typography: {
        h1: {
            fontSize: 34,
            fontWeight: 500
        },
        h2: {
            fontSize: 34,
            fontWeight: 500
        },
        h3: {
            fontSize: 20,
            fontWeight: 500,
            color: '#1c1c1c'
        },
        h4: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '175%',
            marginBottom: '4px'
        },
        button: {
            textTransform: 'none'
        }
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '13px'
                }
            }
        }
    }
});
