"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculatePrice = void 0;
const date_fns_1 = require("date-fns");
const dates_1 = require("@common/dates");
const avgTVRBA_1 = require("@common/dictionaries/avgTVRBA");
const channels_1 = require("@common/dictionaries/channels");
const timings_1 = require("@common/dictionaries/timings");
const VAT = 1.2;
const getDates = (periodFrom, periodTo) => {
    const parsedPeriodFrom = (0, date_fns_1.parse)(periodFrom, 'yyyy-MM-dd', new Date());
    const dates = [];
    let currentDate = parsedPeriodFrom;
    const dayDifference = (0, dates_1.differenceInDaysWithLast)(periodTo, periodFrom);
    for (let i = 0; i < dayDifference; i += 1) {
        dates.push({ month: (0, date_fns_1.getMonth)(currentDate) + 1 });
        currentDate = (0, date_fns_1.addDays)(currentDate, 1);
    }
    return dates;
};
const calculatePrice = (periodFrom, periodTo, spotCount, commercialDuration, selectedChannels) => {
    const periodInterval = getDates(periodFrom, periodTo);
    let totalPrice = 0;
    const regionsBudget = {};
    periodInterval.forEach(period => {
        Object.entries(selectedChannels).forEach(([cityName, channels]) => {
            regionsBudget[cityName] = 0;
            channels.forEach(channelName => {
                const channelInfo = channels_1.CHANNELS[cityName].find(channel => channel.channelName === channelName);
                const { timingMarkup, duration } = timings_1.TIMINGS[commercialDuration][cityName] || timings_1.TIMINGS[commercialDuration]['Остальные'];
                const cpp = channelInfo[`cpp${period.month}`] * timingMarkup;
                if (channelInfo.priceType === 'grp') {
                    const avgTVRBAValue = avgTVRBA_1.AVG_TVR_BA.find(el => el.cityName === cityName && el.channelName === channelName).value;
                    const grp = spotCount * ((avgTVRBAValue / 20) * duration);
                    const price = grp * cpp;
                    totalPrice += price;
                    regionsBudget[cityName] += price;
                }
                if (channelInfo.priceType === 'min') {
                    const grp = spotCount * (duration / 60);
                    const price = grp * cpp;
                    totalPrice += price;
                    regionsBudget[cityName] += price;
                }
            });
        });
    });
    return {
        totalPrice: Math.round(totalPrice * VAT),
        regionsBudget
    };
};
exports.calculatePrice = calculatePrice;
