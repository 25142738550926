"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Email_1 = require("@mui/icons-material/Email");
const Telegram_1 = require("@mui/icons-material/Telegram");
const material_1 = require("@mui/material");
const styled_components_1 = require("styled-components");
const mediaQueries_1 = require("@common/mediaQueries");
const logo_svg_1 = require("./logo.svg");
const Logo = styled_components_1.default.img.withConfig({ displayName: "Logo", componentId: "-1tdip3w" }) `
  width: 190px;
  height: 28px;

  @media ${mediaQueries_1.devices.tablet} {
    width: 244px;
    height: 35px;
  }
`;
const ContainerStyled = (0, styled_components_1.default)(material_1.Container).withConfig({ displayName: "ContainerStyled", componentId: "-1jrpbfh" }) `
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonsList = styled_components_1.default.div.withConfig({ displayName: "ButtonsList", componentId: "-1e7abjh" }) `
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    font-size: 2rem;
  }
`;
const CallToFeedback = styled_components_1.default.span.withConfig({ displayName: "CallToFeedback", componentId: "-1bqyeqt" }) `
  display: none;

  @media ${mediaQueries_1.devices.tablet} {
    display: block;
  }
`;
const ButtonStyled = (0, styled_components_1.default)(material_1.Button).withConfig({ displayName: "ButtonStyled", componentId: "-1c15l6v" }) `
  display: none;

  @media ${mediaQueries_1.devices.mobileXL} {
    display: block;
  }
`;
function Header() {
    return ((0, jsx_runtime_1.jsx)("header", { children: (0, jsx_runtime_1.jsxs)(ContainerStyled, { children: [(0, jsx_runtime_1.jsx)(Logo, { src: logo_svg_1.default, alt: "Tele-portal logo" }), (0, jsx_runtime_1.jsxs)(ButtonsList, { children: [(0, jsx_runtime_1.jsx)(CallToFeedback, { children: "\u0421\u0432\u044F\u0436\u0438\u0442\u0435\u0441\u044C \u0441 \u043D\u0430\u043C\u0438:" }), (0, jsx_runtime_1.jsx)(material_1.IconButton, Object.assign({ color: "primary", href: "tg://resolve?domain=tele_p0rtal" }, { children: (0, jsx_runtime_1.jsx)(Telegram_1.default, {}) })), (0, jsx_runtime_1.jsx)(material_1.IconButton, Object.assign({ color: "primary", href: "mailto:info@tele-portal.ru" }, { children: (0, jsx_runtime_1.jsx)(Email_1.default, {}) })), (0, jsx_runtime_1.jsx)(ButtonStyled, Object.assign({ variant: "outlined", href: "#form" }, { children: "\u0420\u0430\u0441\u0441\u0447\u0438\u0442\u0430\u0442\u044C \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u044E" }))] })] }) }));
}
exports.Header = Header;
