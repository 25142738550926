"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STEPS = void 0;
const AdvertisingCampaignStep_1 = require("./AdvertisingCampaignStep");
const BrandStep_1 = require("./BrandStep");
const ChannelsStep_1 = require("./ChannelsStep");
const CitiesStep_1 = require("./CitiesStep");
const ResultsStep_1 = require("./ResultsStep");
const TargetAudienceFirstStep_1 = require("./TargetAudienceFirstStep");
const TargetAudienceFourthStep_1 = require("./TargetAudienceFourthStep");
const TargetAudienceSecondStep_1 = require("./TargetAudienceSecondStep");
const TargetAudienceThirdStep_1 = require("./TargetAudienceThirdStep");
const ThanksStep_1 = require("./ThanksStep");
exports.STEPS = [
    {
        stepperName: 'Бренд',
        stepperIndex: 1,
        name: 'Бренд',
        title: 'Укажите бренд, товарную категорию, тип размещения и email',
        component: BrandStep_1.BrandStep,
        visible: true,
        stepsGroup: [0]
    },
    { name: 'Медиа (города)', title: 'Выберите города', component: CitiesStep_1.CitiesStep, visible: false },
    {
        stepperName: 'Медиа',
        stepperIndex: 2,
        name: 'Медиа (каналы)',
        title: 'Выберите каналы для регионов',
        component: ChannelsStep_1.ChannelsStep,
        visible: true,
        stepsGroup: [1, 2]
    },
    {
        name: 'Целевая аудитория (пол, возраст, доход)',
        title: 'Укажите целевую аудиторию',
        component: TargetAudienceFirstStep_1.TargetAudienceFirstStep,
        visible: false
    },
    {
        name: 'Целевая аудитория (дети, интересы, образование)',
        title: 'Укажите целевую аудиторию',
        component: TargetAudienceSecondStep_1.TargetAudienceSecondStep,
        visible: false
    },
    {
        name: 'Целевая аудитория (деятельность)',
        title: 'Укажите целевую аудиторию',
        component: TargetAudienceThirdStep_1.TargetAudienceThirdStep,
        visible: false
    },
    {
        stepperName: 'Аудитория',
        stepperIndex: 3,
        name: 'Целевая аудитория (интересы, дополнительно)',
        title: 'Укажите целевую аудиторию',
        component: TargetAudienceFourthStep_1.TargetAudienceFourthStep,
        visible: true,
        stepsGroup: [3, 4, 5, 6]
    },
    {
        stepperName: 'Контент',
        stepperIndex: 4,
        name: 'Контент',
        title: 'Выберите параметры рекламной кампании',
        component: AdvertisingCampaignStep_1.AdvertisingCampaignStep,
        visible: true,
        stepsGroup: [7]
    },
    {
        stepperName: 'Результаты',
        stepperIndex: 5,
        name: 'Результаты',
        title: 'Результаты',
        component: ResultsStep_1.ResultsStep,
        visible: true,
        stepsGroup: [8]
    },
    {
        name: 'Финал',
        component: ThanksStep_1.ThanksStep,
        visible: false
    }
];
