"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = require("styled-components");
const FooterStyled = styled_components_1.default.footer.withConfig({ displayName: "FooterStyled", componentId: "-15vhndy" }) `
  background-color: #222222;
  color: #fff;
  padding: 30px 0px;
`;
const ContainerStyled = (0, styled_components_1.default)(material_1.Container).withConfig({ displayName: "ContainerStyled", componentId: "-1o2efra" }) `
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px 30px;
  justify-content: center;
`;
const Info = styled_components_1.default.div.withConfig({ displayName: "Info", componentId: "-s0x3tq" }) `
  font-size: 14px;
`;
function Footer() {
    return ((0, jsx_runtime_1.jsx)(FooterStyled, { children: (0, jsx_runtime_1.jsxs)(ContainerStyled, { children: [(0, jsx_runtime_1.jsx)(Info, { children: "\u0418\u041D\u041D: 502004626928" }), (0, jsx_runtime_1.jsxs)(Info, { children: ["Email:", (0, jsx_runtime_1.jsx)(material_1.Link, Object.assign({ color: "#fff", href: "mailto:info@tele-portal.ru" }, { children: "info@tele-portal.ru" }))] }), (0, jsx_runtime_1.jsxs)(Info, { children: ["Telegram:", (0, jsx_runtime_1.jsx)(material_1.Link, Object.assign({ color: "#fff", href: "tg://resolve?domain=tele_p0rtal" }, { children: "@TELE_P0RTAL" }))] })] }) }));
}
exports.Footer = Footer;
