"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Form = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const styled_components_1 = require("styled-components");
const mediaQueries_1 = require("@common/mediaQueries");
const FormContext_1 = require("./FormContext");
const FormStepper_1 = require("./FormStepper");
const ContainerStyled = (0, styled_components_1.default)(material_1.Container).withConfig({ displayName: "ContainerStyled", componentId: "-42mbfc" }) `
  @media ${mediaQueries_1.devices.mobileS} {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #f8f8f8;
    border-radius: 40px;
    min-height: 720px;
    align-items: center;
    gap: 20px;
  }
`;
const TitleBlock = styled_components_1.default.div.withConfig({ displayName: "TitleBlock", componentId: "-1auhjr4" }) `
  text-align: center;
`;
const Description = styled_components_1.default.p.withConfig({ displayName: "Description", componentId: "-1gmtoc5" }) `
  letter-spacing: 0.15px;
  line-height: 175%;
  margin-bottom: 0px;
`;
const FormStyled = (0, styled_components_1.default)(material_1.Box).withConfig({ displayName: "FormStyled", componentId: "-1m9e4rb" }) `
  @media ${mediaQueries_1.devices.mobileS} {
    padding: 10px;
    min-height: 530px;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }

  @media ${mediaQueries_1.devices.tablet} {
    padding: 48px;
  }
`;
function Form() {
    const { steps, activeStep } = (0, react_1.useContext)(FormContext_1.FormContext);
    const CurrentStep = steps[activeStep].component;
    return ((0, jsx_runtime_1.jsxs)(ContainerStyled, Object.assign({ id: "form" }, { children: [activeStep === 0 && ((0, jsx_runtime_1.jsxs)(TitleBlock, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "h2" }, { children: "\u0417\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u0435 \u0444\u043E\u0440\u043C\u0443 \u0438 \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u0435 \u043E\u0446\u0435\u043D\u043A\u0443 \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u0438 \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u043E\u0439 \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u0438" })), (0, jsx_runtime_1.jsx)(Description, { children: "\u0420\u0430\u0441\u0441\u043A\u0430\u0436\u0438\u0442\u0435 \u043E \u0441\u0432\u043E\u0435\u043C \u0431\u0438\u0437\u043D\u0435\u0441\u0435, \u0432\u0430\u0448\u0435\u0439 \u0446\u0435\u043B\u0435\u0432\u043E\u0439 \u0430\u0443\u0434\u0438\u0442\u043E\u0440\u0438\u0438 \u0438 \u0431\u0430\u0437\u043E\u0432\u044B\u0445 \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u0430\u0445 \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u043E\u0439 \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u0438: \u044D\u0442\u043E \u043F\u043E\u043C\u043E\u0436\u0435\u0442 \u043D\u0430\u043C \u0441\u0444\u043E\u0440\u043C\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u043E \u044D\u0444\u0444\u0435\u043A\u0442\u0438\u0432\u043D\u043E\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435" })] })), (0, jsx_runtime_1.jsxs)(FormStyled, Object.assign({ component: "form" }, { children: [(0, jsx_runtime_1.jsx)(FormStepper_1.FormStepper, {}), (0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "h3", mb: "32px" }, { children: steps[activeStep].title })), (0, jsx_runtime_1.jsx)(CurrentStep, {})] }))] })));
}
exports.Form = Form;
