"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkIsOverLimit = void 0;
const MONTH_DAYS = 30;
const REGION_PRICE_LIMIT = 20000000 / MONTH_DAYS;
const NATIONAL_PRICE_LIMIT = 80000000 / MONTH_DAYS;
const checkIsOverLimit = (regionsBudget) => {
    const isOverLimit = Object.entries(regionsBudget).some(([region, budget]) => {
        if (region === 'Национальные каналы') {
            return budget > NATIONAL_PRICE_LIMIT;
        }
        return budget > REGION_PRICE_LIMIT;
    });
    return isOverLimit;
};
exports.checkIsOverLimit = checkIsOverLimit;
