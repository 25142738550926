"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateFillPercent = exports.steps = exports.ONE_STEP_HEIGHT = void 0;
const brief_svg_1 = require("./img/brief.svg");
const mediaPlan_svg_1 = require("./img/mediaPlan.svg");
const realization_svg_1 = require("./img/realization.svg");
const report_svg_1 = require("./img/report.svg");
const video_svg_1 = require("./img/video.svg");
exports.ONE_STEP_HEIGHT = 380;
exports.steps = [
    {
        index: 1,
        title: 'Бриф',
        titleDescription: 'Быстрая форма с параметрами кампании',
        points: ['Период', 'Региональность', 'Целевая аудитория', 'Наличие видео ролика', 'Длительность ТВ-ролика'],
        img: brief_svg_1.default
    },
    {
        index: 2,
        title: 'Видеоролик',
        points: ['Определить эффективную длительность', 'Определить сообщение', 'Подобрать специалиста'],
        img: video_svg_1.default
    },
    {
        index: 3,
        title: 'Медиаплан',
        points: [
            'Распределение объемов между телеканалами',
            'Расчет стоимости рекламной кампании',
            'Оценка эффективности рекламной кампании',
            'Оформление заказа'
        ],
        img: mediaPlan_svg_1.default
    },
    {
        index: 4,
        title: 'Реализация',
        points: [
            'Автоматизированная реализация утвержденного медиаплана',
            'Выход рекламной кампании в эфир',
            'Ежедневный мониторинг и контроль'
        ],
        img: realization_svg_1.default
    },
    {
        index: 5,
        title: 'Отчетность',
        points: ['Отчет о набранных контактах', 'Графики подтвержденных выходов', 'Акт', 'Счет-фактура'],
        img: report_svg_1.default
    }
];
const calculateFillPercent = (scrollPosition, index) => {
    if (scrollPosition > exports.ONE_STEP_HEIGHT * index)
        return 100;
    if (scrollPosition < 0)
        return 0;
    const stepsOffset = exports.ONE_STEP_HEIGHT * (index - 1);
    const scrollPositionThisStep = scrollPosition - stepsOffset;
    const percent = (scrollPositionThisStep * 100) / exports.ONE_STEP_HEIGHT;
    return percent;
};
exports.calculateFillPercent = calculateFillPercent;
