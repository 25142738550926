"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHANNELS = void 0;
exports.CHANNELS = {
    'Национальные каналы': [
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 163221.29,
            cpp2: 275435.93,
            cpp3: 277986.27,
            cpp4: 285637.26,
            cpp5: 247382.27,
            cpp6: 255033.27,
            cpp7: 201476.28,
            cpp8: 242281.61,
            cpp9: 372348.58,
            cpp10: 357046.58,
            cpp11: 349395.58,
            cpp12: 283086.93
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 55050.92,
            cpp2: 92898.42,
            cpp3: 93758.59,
            cpp4: 96339.11,
            cpp5: 83436.55,
            cpp6: 86017.06,
            cpp7: 67953.48,
            cpp8: 81716.21,
            cpp9: 125584.91,
            cpp10: 120423.88,
            cpp11: 117843.37,
            cpp12: 95478.94
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 48742.69,
            cpp2: 82253.29,
            cpp3: 83014.89,
            cpp4: 85299.71,
            cpp5: 73875.64,
            cpp6: 76160.45,
            cpp7: 60166.76,
            cpp8: 72352.43,
            cpp9: 111194.26,
            cpp10: 106624.64,
            cpp11: 104339.82,
            cpp12: 84538.1
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 134073.53,
            cpp2: 226249.09,
            cpp3: 228343.98,
            cpp4: 234628.68,
            cpp5: 203205.2,
            cpp6: 209489.89,
            cpp7: 165497.02,
            cpp8: 199015.4,
            cpp9: 305855.25,
            cpp10: 293285.85,
            cpp11: 287001.16,
            cpp12: 232533.78
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 133210.72,
            cpp2: 224793.09,
            cpp3: 226874.51,
            cpp4: 233118.76,
            cpp5: 201897.5,
            cpp6: 208141.75,
            cpp7: 164431.99,
            cpp8: 197734.67,
            cpp9: 303886.96,
            cpp10: 291398.46,
            cpp11: 285154.2,
            cpp12: 231037.35
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 54287.78,
            cpp2: 91610.63,
            cpp3: 92458.87,
            cpp4: 95003.61,
            cpp5: 82279.91,
            cpp6: 84824.65,
            cpp7: 67011.48,
            cpp8: 80583.42,
            cpp9: 123843.99,
            cpp10: 118754.51,
            cpp11: 116209.77,
            cpp12: 94155.37
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 69088.33,
            cpp2: 116586.55,
            cpp3: 117666.06,
            cpp4: 120904.57,
            cpp5: 104712,
            cpp6: 107950.51,
            cpp7: 85280.9,
            cpp8: 102552.99,
            cpp9: 157607.75,
            cpp10: 151130.72,
            cpp11: 147892.2,
            cpp12: 119825.07
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 45791.11,
            cpp2: 77272.5,
            cpp3: 77987.99,
            cpp4: 80134.44,
            cpp5: 69402.15,
            cpp6: 71548.61,
            cpp7: 56523.4,
            cpp8: 67971.18,
            cpp9: 104460.97,
            cpp10: 100168.05,
            cpp11: 98021.6,
            cpp12: 79418.96
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 102629.81,
            cpp2: 173187.8,
            cpp3: 174791.39,
            cpp4: 179602.16,
            cpp5: 155548.3,
            cpp6: 160359.07,
            cpp7: 126683.67,
            cpp8: 152341.12,
            cpp9: 234124.24,
            cpp10: 224502.7,
            cpp11: 219691.93,
            cpp12: 177998.57
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 109584.16,
            cpp2: 184923.26,
            cpp3: 186635.52,
            cpp4: 191772.27,
            cpp5: 166088.49,
            cpp6: 171225.24,
            cpp7: 135267.94,
            cpp8: 162663.98,
            cpp9: 249988.86,
            cpp10: 239715.34,
            cpp11: 234578.58,
            cpp12: 190060.02
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Че',
            cpp1: 75289.43,
            cpp2: 127050.92,
            cpp3: 128227.32,
            cpp4: 131756.51,
            cpp5: 114110.55,
            cpp6: 117639.74,
            cpp7: 92935.4,
            cpp8: 111757.75,
            cpp9: 171754.02,
            cpp10: 164695.64,
            cpp11: 161166.45,
            cpp12: 130580.11
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Ю',
            cpp1: 61333.64,
            cpp2: 103500.52,
            cpp3: 104458.85,
            cpp4: 107333.87,
            cpp5: 92958.8,
            cpp6: 95833.81,
            cpp7: 75708.71,
            cpp8: 91042.12,
            cpp9: 139917.36,
            cpp10: 134167.33,
            cpp11: 131292.32,
            cpp12: 106375.53
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 33487.45,
            cpp2: 56510.08,
            cpp3: 57033.32,
            cpp4: 58603.05,
            cpp5: 50754.42,
            cpp6: 52324.15,
            cpp7: 41336.08,
            cpp8: 49707.94,
            cpp9: 76393.26,
            cpp10: 73253.81,
            cpp11: 71684.08,
            cpp12: 58079.8
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Карусель',
            cpp1: 51461.98,
            cpp2: 86842.1,
            cpp3: 87646.19,
            cpp4: 90058.47,
            cpp5: 77997.07,
            cpp6: 80409.35,
            cpp7: 63523.39,
            cpp8: 76388.88,
            cpp9: 117397.65,
            cpp10: 112573.09,
            cpp11: 110160.81,
            cpp12: 89254.38
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 24681.17,
            cpp2: 41649.48,
            cpp3: 42035.12,
            cpp4: 43192.05,
            cpp5: 37407.4,
            cpp6: 38564.33,
            cpp7: 30465.82,
            cpp8: 36636.12,
            cpp9: 56303.93,
            cpp10: 53990.07,
            cpp11: 52833.14,
            cpp12: 42806.41
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 39418.15,
            cpp2: 66518.13,
            cpp3: 67134.04,
            cpp4: 68981.77,
            cpp5: 59743.14,
            cpp6: 61590.86,
            cpp7: 48656.78,
            cpp8: 58511.32,
            cpp9: 89922.66,
            cpp10: 86227.21,
            cpp11: 84379.49,
            cpp12: 68365.86
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 61734.16,
            cpp2: 104176.39,
            cpp3: 105140.99,
            cpp4: 108034.78,
            cpp5: 93565.83,
            cpp6: 96459.62,
            cpp7: 76203.1,
            cpp8: 91636.64,
            cpp9: 140831.05,
            cpp10: 135043.47,
            cpp11: 132149.68,
            cpp12: 107070.18
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Disney',
            cpp1: 65720.94,
            cpp2: 110904.09,
            cpp3: 111930.97,
            cpp4: 115011.64,
            cpp5: 99608.3,
            cpp6: 102688.97,
            cpp7: 81124.28,
            cpp8: 97554.52,
            cpp9: 149925.89,
            cpp10: 143764.55,
            cpp11: 140683.89,
            cpp12: 113984.75
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'СТС Love',
            cpp1: 56391.56,
            cpp2: 95160.76,
            cpp3: 96041.88,
            cpp4: 98685.24,
            cpp5: 85468.46,
            cpp6: 88111.82,
            cpp7: 69608.34,
            cpp8: 83706.23,
            cpp9: 128643.25,
            cpp10: 123356.55,
            cpp11: 120713.19,
            cpp12: 97804.12
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'ТНТ 4',
            cpp1: 73096.03,
            cpp2: 123349.54,
            cpp3: 124491.67,
            cpp4: 127918.04,
            cpp5: 110786.16,
            cpp6: 114212.54,
            cpp7: 90227.91,
            cpp8: 108501.91,
            cpp9: 166750.31,
            cpp10: 159897.56,
            cpp11: 156471.18,
            cpp12: 126775.92
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Муз-ТВ',
            cpp1: 73271.38,
            cpp2: 123645.45,
            cpp3: 124790.31,
            cpp4: 128224.91,
            cpp5: 111051.93,
            cpp6: 114486.52,
            cpp7: 90444.35,
            cpp8: 108762.2,
            cpp9: 167150.32,
            cpp10: 160281.13,
            cpp11: 156846.54,
            cpp12: 127080.04
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: '2х2',
            cpp1: 41278.3,
            cpp2: 69657.14,
            cpp3: 70302.11,
            cpp4: 72237.03,
            cpp5: 62562.43,
            cpp6: 64497.35,
            cpp7: 50952.91,
            cpp8: 61272.48,
            cpp9: 94166.13,
            cpp10: 90296.29,
            cpp11: 88361.37,
            cpp12: 71592.06
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Суббота',
            cpp1: 59684.75,
            cpp2: 100718.02,
            cpp3: 101650.6,
            cpp4: 104448.32,
            cpp5: 90459.7,
            cpp6: 93257.43,
            cpp7: 73673.37,
            cpp8: 88594.56,
            cpp9: 136155.84,
            cpp10: 130560.4,
            cpp11: 127762.68,
            cpp12: 103515.74
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'МИР',
            cpp1: 31302.16,
            cpp2: 52822.39,
            cpp3: 53311.49,
            cpp4: 54778.77,
            cpp5: 47442.33,
            cpp6: 48909.62,
            cpp7: 38638.6,
            cpp8: 46464.14,
            cpp9: 71408.04,
            cpp10: 68473.47,
            cpp11: 67006.18,
            cpp12: 54289.68
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'СПАС',
            cpp1: 19168.8,
            cpp2: 32347.35,
            cpp3: 32646.86,
            cpp4: 33545.4,
            cpp5: 29052.71,
            cpp6: 29951.25,
            cpp7: 23661.49,
            cpp8: 28453.69,
            cpp9: 43728.82,
            cpp10: 41931.75,
            cpp11: 41033.21,
            cpp12: 33245.89
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Кабельно-спутниковые каналы (пакет "Единый")',
            cpp1: 83821.42,
            cpp2: 128956.03,
            cpp3: 141851.63,
            cpp4: 148299.43,
            cpp5: 128956.03,
            cpp6: 128956.03,
            cpp7: 103164.82,
            cpp8: 113481.3,
            cpp9: 185696.68,
            cpp10: 177959.32,
            cpp11: 177959.32,
            cpp12: 141851.63
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Кабельно-спутниковые каналы (пакет "Женский")',
            cpp1: 73600.51,
            cpp2: 113231.56,
            cpp3: 124554.72,
            cpp4: 130216.3,
            cpp5: 113231.56,
            cpp6: 113231.56,
            cpp7: 90585.25,
            cpp8: 99643.77,
            cpp9: 163053.45,
            cpp10: 156259.55,
            cpp11: 156259.55,
            cpp12: 124554.72
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Кабельно-спутниковые каналы (пакет "Мужской")',
            cpp1: 73800.92,
            cpp2: 113539.88,
            cpp3: 124893.87,
            cpp4: 130570.87,
            cpp5: 113539.88,
            cpp6: 113539.88,
            cpp7: 90831.91,
            cpp8: 99915.1,
            cpp9: 163497.43,
            cpp10: 156685.04,
            cpp11: 156685.04,
            cpp12: 124893.87
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Кабельно-спутниковые каналы (пакет "Детский")',
            cpp1: 24637.16,
            cpp2: 37903.32,
            cpp3: 41693.66,
            cpp4: 43588.82,
            cpp5: 37903.32,
            cpp6: 37903.32,
            cpp7: 30322.66,
            cpp8: 33354.92,
            cpp9: 54580.79,
            cpp10: 52306.59,
            cpp11: 52306.59,
            cpp12: 41693.66
        },
        {
            cityName: 'Национальные каналы',
            priceType: 'grp',
            channelName: 'Дом кино',
            cpp1: 54832.13,
            cpp2: 84357.13,
            cpp3: 92792.84,
            cpp4: 97010.7,
            cpp5: 84357.13,
            cpp6: 84357.13,
            cpp7: 67485.7,
            cpp8: 74234.27,
            cpp9: 121474.26,
            cpp10: 116412.84,
            cpp11: 116412.84,
            cpp12: 92792.84
        }
    ],
    Москва: [
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 42486.75,
            cpp2: 63730.13,
            cpp3: 75059.93,
            cpp4: 81432.94,
            cpp5: 63730.13,
            cpp6: 58773.34,
            cpp7: 46027.32,
            cpp8: 56649,
            cpp9: 106216.88,
            cpp10: 107633.11,
            cpp11: 107633.11,
            cpp12: 84973.51
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 14225.09,
            cpp2: 21337.64,
            cpp3: 25130.99,
            cpp4: 27264.76,
            cpp5: 21337.64,
            cpp6: 19678.04,
            cpp7: 15410.52,
            cpp8: 18966.79,
            cpp9: 35562.73,
            cpp10: 36036.9,
            cpp11: 36036.9,
            cpp12: 28450.18
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 17083.37,
            cpp2: 25625.06,
            cpp3: 30180.63,
            cpp4: 32743.13,
            cpp5: 25625.06,
            cpp6: 23632,
            cpp7: 18506.99,
            cpp8: 22777.83,
            cpp9: 42708.44,
            cpp10: 43277.88,
            cpp11: 43277.88,
            cpp12: 34166.75
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 55483.2,
            cpp2: 83224.79,
            cpp3: 98020.31,
            cpp4: 106342.79,
            cpp5: 83224.79,
            cpp6: 76751.75,
            cpp7: 60106.8,
            cpp8: 73977.59,
            cpp9: 138707.99,
            cpp10: 140557.43,
            cpp11: 140557.43,
            cpp12: 110966.39
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 56585.67,
            cpp2: 84878.5,
            cpp3: 99968.01,
            cpp4: 108455.86,
            cpp5: 84878.5,
            cpp6: 78276.84,
            cpp7: 61301.14,
            cpp8: 75447.56,
            cpp9: 141464.17,
            cpp10: 143350.36,
            cpp11: 143350.36,
            cpp12: 113171.33
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 16325.48,
            cpp2: 24488.21,
            cpp3: 28841.67,
            cpp4: 31290.5,
            cpp5: 24488.21,
            cpp6: 22583.57,
            cpp7: 17685.93,
            cpp8: 21767.3,
            cpp9: 40813.69,
            cpp10: 41357.87,
            cpp11: 41357.87,
            cpp12: 32650.95
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 21576.09,
            cpp2: 32364.13,
            cpp3: 38117.76,
            cpp4: 41354.17,
            cpp5: 32364.13,
            cpp6: 29846.92,
            cpp7: 23374.1,
            cpp8: 28768.12,
            cpp9: 53940.22,
            cpp10: 54659.43,
            cpp11: 54659.43,
            cpp12: 43152.18
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 13341.28,
            cpp2: 20011.93,
            cpp3: 23569.6,
            cpp4: 25570.79,
            cpp5: 20011.93,
            cpp6: 18455.44,
            cpp7: 14453.06,
            cpp8: 17788.38,
            cpp9: 33353.21,
            cpp10: 33797.92,
            cpp11: 33797.92,
            cpp12: 26682.57
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 34644.76,
            cpp2: 51967.14,
            cpp3: 61205.75,
            cpp4: 66402.46,
            cpp5: 51967.14,
            cpp6: 47925.25,
            cpp7: 37531.83,
            cpp8: 46193.02,
            cpp9: 86611.91,
            cpp10: 87766.73,
            cpp11: 87766.73,
            cpp12: 69289.52
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 47724.67,
            cpp2: 71587.01,
            cpp3: 84313.59,
            cpp4: 91472.29,
            cpp5: 71587.01,
            cpp6: 66019.13,
            cpp7: 51701.73,
            cpp8: 63632.9,
            cpp9: 119311.69,
            cpp10: 120902.51,
            cpp11: 120902.51,
            cpp12: 95449.35
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Че',
            cpp1: 16259.61,
            cpp2: 24389.41,
            cpp3: 28725.3,
            cpp4: 31164.24,
            cpp5: 24389.41,
            cpp6: 22492.45,
            cpp7: 17614.57,
            cpp8: 21679.47,
            cpp9: 40649.01,
            cpp10: 41191,
            cpp11: 41191,
            cpp12: 32519.21
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Ю',
            cpp1: 24876.87,
            cpp2: 37315.31,
            cpp3: 43949.14,
            cpp4: 47680.67,
            cpp5: 37315.31,
            cpp6: 34413.01,
            cpp7: 26949.95,
            cpp8: 33169.16,
            cpp9: 62192.18,
            cpp10: 63021.41,
            cpp11: 63021.41,
            cpp12: 49753.75
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 9161.75,
            cpp2: 13742.63,
            cpp3: 16185.76,
            cpp4: 17560.02,
            cpp5: 13742.63,
            cpp6: 12673.76,
            cpp7: 9925.23,
            cpp8: 12215.67,
            cpp9: 22904.38,
            cpp10: 23209.77,
            cpp11: 23209.77,
            cpp12: 18323.5
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 8303.46,
            cpp2: 12455.19,
            cpp3: 14669.44,
            cpp4: 15914.96,
            cpp5: 12455.19,
            cpp6: 11486.45,
            cpp7: 8995.41,
            cpp8: 11071.28,
            cpp9: 20758.64,
            cpp10: 21035.42,
            cpp11: 21035.42,
            cpp12: 16606.91
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 14789.51,
            cpp2: 22184.26,
            cpp3: 26128.13,
            cpp4: 28346.56,
            cpp5: 22184.26,
            cpp6: 20458.82,
            cpp7: 16021.97,
            cpp8: 19719.34,
            cpp9: 36973.77,
            cpp10: 37466.76,
            cpp11: 37466.76,
            cpp12: 29579.02
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 9923.2,
            cpp2: 14884.79,
            cpp3: 17530.98,
            cpp4: 19019.46,
            cpp5: 14884.79,
            cpp6: 13727.09,
            cpp7: 10750.13,
            cpp8: 13230.93,
            cpp9: 24807.99,
            cpp10: 25138.76,
            cpp11: 25138.76,
            cpp12: 19846.39
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Disney',
            cpp1: 18898.25,
            cpp2: 28347.38,
            cpp3: 33386.91,
            cpp4: 36221.65,
            cpp5: 28347.38,
            cpp6: 26142.58,
            cpp7: 20473.11,
            cpp8: 25197.67,
            cpp9: 47245.63,
            cpp10: 47875.57,
            cpp11: 47875.57,
            cpp12: 37796.51
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Москва 24',
            cpp1: 17900.43,
            cpp2: 26850.64,
            cpp3: 31624.09,
            cpp4: 34309.16,
            cpp5: 26850.64,
            cpp6: 24762.26,
            cpp7: 19392.13,
            cpp8: 23867.24,
            cpp9: 44751.07,
            cpp10: 45347.75,
            cpp11: 45347.75,
            cpp12: 35800.86
        },
        {
            cityName: 'Москва',
            priceType: 'grp',
            channelName: 'Карусель',
            cpp1: 8219.63,
            cpp2: 12329.44,
            cpp3: 14521.35,
            cpp4: 15754.29,
            cpp5: 12329.44,
            cpp6: 11370.49,
            cpp7: 8904.6,
            cpp8: 10959.51,
            cpp9: 20549.07,
            cpp10: 20823.06,
            cpp11: 20823.06,
            cpp12: 16439.26
        },
        {
            cityName: 'Москва',
            priceType: 'min',
            channelName: 'СТС Love',
            cpp1: 3286.78,
            cpp2: 4930.17,
            cpp3: 5806.64,
            cpp4: 6299.66,
            cpp5: 4930.17,
            cpp6: 4546.71,
            cpp7: 3560.68,
            cpp8: 4382.37,
            cpp9: 8216.94,
            cpp10: 8326.5,
            cpp11: 8326.5,
            cpp12: 6573.55
        },
        {
            cityName: 'Москва',
            priceType: 'min',
            channelName: 'ТНТ 4',
            cpp1: 3632.02,
            cpp2: 5448.02,
            cpp3: 6416.56,
            cpp4: 6961.36,
            cpp5: 5448.02,
            cpp6: 5024.29,
            cpp7: 3934.68,
            cpp8: 4842.69,
            cpp9: 9080.04,
            cpp10: 9201.1,
            cpp11: 9201.1,
            cpp12: 7264.03
        },
        {
            cityName: 'Москва',
            priceType: 'min',
            channelName: 'Муз-ТВ',
            cpp1: 0,
            cpp2: 0,
            cpp3: 0,
            cpp4: 0,
            cpp5: 0,
            cpp6: 0,
            cpp7: 0,
            cpp8: 0,
            cpp9: 19667.96,
            cpp10: 19930.2,
            cpp11: 19930.2,
            cpp12: 15734.37
        },
        {
            cityName: 'Москва',
            priceType: 'min',
            channelName: 'Суббота',
            cpp1: 5847.57,
            cpp2: 8771.36,
            cpp3: 10330.71,
            cpp4: 11207.85,
            cpp5: 8771.36,
            cpp6: 8089.14,
            cpp7: 6334.87,
            cpp8: 7796.76,
            cpp9: 14618.93,
            cpp10: 14813.85,
            cpp11: 14813.85,
            cpp12: 11695.15
        },
        {
            cityName: 'Москва',
            priceType: 'min',
            channelName: 'RU.TV',
            cpp1: 2878,
            cpp2: 4317,
            cpp3: 5084.47,
            cpp4: 5516.17,
            cpp5: 4317,
            cpp6: 3981.24,
            cpp7: 3117.84,
            cpp8: 3837.34,
            cpp9: 7195.01,
            cpp10: 7290.94,
            cpp11: 7290.94,
            cpp12: 5756.01
        },
        {
            cityName: 'Москва',
            priceType: 'min',
            channelName: 'TV 1000',
            cpp1: 9774.19,
            cpp2: 14661.29,
            cpp3: 17267.74,
            cpp4: 18733.87,
            cpp5: 14661.29,
            cpp6: 13520.97,
            cpp7: 10588.71,
            cpp8: 13032.26,
            cpp9: 24435.48,
            cpp10: 24761.29,
            cpp11: 24761.29,
            cpp12: 19548.39
        },
        {
            cityName: 'Москва',
            priceType: 'min',
            channelName: 'TV 1000 РУССКОЕ КИНО',
            cpp1: 6789,
            cpp2: 10183.5,
            cpp3: 11993.89,
            cpp4: 13012.24,
            cpp5: 10183.5,
            cpp6: 9391.45,
            cpp7: 7354.75,
            cpp8: 9052,
            cpp9: 16972.49,
            cpp10: 17198.79,
            cpp11: 17198.79,
            cpp12: 13577.99
        },
        {
            cityName: 'Москва',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 5382.18,
            cpp2: 8073.27,
            cpp3: 9508.52,
            cpp4: 10315.84,
            cpp5: 8073.27,
            cpp6: 7445.35,
            cpp7: 5830.69,
            cpp8: 7176.24,
            cpp9: 13455.45,
            cpp10: 13634.85,
            cpp11: 13634.85,
            cpp12: 10764.36
        },
        {
            cityName: 'Москва',
            priceType: 'min',
            channelName: 'РБК',
            cpp1: 12896.27,
            cpp2: 19344.4,
            cpp3: 22783.41,
            cpp4: 24717.85,
            cpp5: 19344.4,
            cpp6: 17839.84,
            cpp7: 13970.96,
            cpp8: 17195.02,
            cpp9: 32240.67,
            cpp10: 32670.55,
            cpp11: 32670.55,
            cpp12: 25792.54
        },
        {
            cityName: 'Москва',
            priceType: 'min',
            channelName: 'Доверие',
            cpp1: 2685.85,
            cpp2: 4028.78,
            cpp3: 4745,
            cpp4: 5147.88,
            cpp5: 4028.78,
            cpp6: 3715.43,
            cpp7: 2909.67,
            cpp8: 3581.13,
            cpp9: 6714.63,
            cpp10: 6804.16,
            cpp11: 6804.16,
            cpp12: 5371.7
        }
    ],
    'Санкт-Петербург': [
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 17848.29,
            cpp2: 29206.3,
            cpp3: 34398.53,
            cpp4: 35696.59,
            cpp5: 29206.3,
            cpp6: 25961.15,
            cpp7: 19470.87,
            cpp8: 24338.58,
            cpp9: 47054.59,
            cpp10: 49326.19,
            cpp11: 49326.19,
            cpp12: 38941.73
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 5930.14,
            cpp2: 9703.87,
            cpp3: 11429,
            cpp4: 11860.28,
            cpp5: 9703.87,
            cpp6: 8625.66,
            cpp7: 6469.25,
            cpp8: 8086.56,
            cpp9: 15634.01,
            cpp10: 16388.75,
            cpp11: 16388.75,
            cpp12: 12938.49
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 7636.33,
            cpp2: 12495.81,
            cpp3: 14717.29,
            cpp4: 15272.66,
            cpp5: 12495.81,
            cpp6: 11107.39,
            cpp7: 8330.54,
            cpp8: 10413.18,
            cpp9: 20132.14,
            cpp10: 21104.04,
            cpp11: 21104.04,
            cpp12: 16661.08
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 25104.6,
            cpp2: 41080.25,
            cpp3: 48383.41,
            cpp4: 50209.2,
            cpp5: 41080.25,
            cpp6: 36515.78,
            cpp7: 27386.83,
            cpp8: 34233.54,
            cpp9: 66184.85,
            cpp10: 69379.98,
            cpp11: 69379.98,
            cpp12: 54773.67
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 23001.62,
            cpp2: 37639.02,
            cpp3: 44330.4,
            cpp4: 46003.24,
            cpp5: 37639.02,
            cpp6: 33456.91,
            cpp7: 25092.68,
            cpp8: 31365.85,
            cpp9: 60640.64,
            cpp10: 63568.12,
            cpp11: 63568.12,
            cpp12: 50185.36
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 6158.36,
            cpp2: 10077.32,
            cpp3: 11868.85,
            cpp4: 12316.73,
            cpp5: 10077.32,
            cpp6: 8957.62,
            cpp7: 6718.22,
            cpp8: 8397.77,
            cpp9: 16235.69,
            cpp10: 17019.48,
            cpp11: 17019.48,
            cpp12: 13436.43
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 7344.53,
            cpp2: 12018.32,
            cpp3: 14154.91,
            cpp4: 14689.06,
            cpp5: 12018.32,
            cpp6: 10682.95,
            cpp7: 8012.22,
            cpp8: 10015.27,
            cpp9: 19362.85,
            cpp10: 20297.61,
            cpp11: 20297.61,
            cpp12: 16024.43
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 6195.35,
            cpp2: 10137.84,
            cpp3: 11940.13,
            cpp4: 12390.7,
            cpp5: 10137.84,
            cpp6: 9011.42,
            cpp7: 6758.56,
            cpp8: 8448.2,
            cpp9: 16333.19,
            cpp10: 17121.69,
            cpp11: 17121.69,
            cpp12: 13517.12
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 11425.36,
            cpp2: 18696.04,
            cpp3: 22019.78,
            cpp4: 22850.71,
            cpp5: 18696.04,
            cpp6: 16618.7,
            cpp7: 12464.02,
            cpp8: 15580.03,
            cpp9: 30121.39,
            cpp10: 31575.53,
            cpp11: 31575.53,
            cpp12: 24928.05
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 18058.79,
            cpp2: 29550.74,
            cpp3: 34804.21,
            cpp4: 36117.57,
            cpp5: 29550.74,
            cpp6: 26267.33,
            cpp7: 19700.5,
            cpp8: 24625.62,
            cpp9: 47609.53,
            cpp10: 49907.92,
            cpp11: 49907.92,
            cpp12: 39400.99
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'Че',
            cpp1: 10118.34,
            cpp2: 16557.28,
            cpp3: 19500.8,
            cpp4: 20236.68,
            cpp5: 16557.28,
            cpp6: 14717.58,
            cpp7: 11038.19,
            cpp8: 13797.74,
            cpp9: 26675.62,
            cpp10: 27963.41,
            cpp11: 27963.41,
            cpp12: 22076.38
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 2440.06,
            cpp2: 3992.83,
            cpp3: 4702.67,
            cpp4: 4880.13,
            cpp5: 3992.83,
            cpp6: 3549.18,
            cpp7: 2661.89,
            cpp8: 3327.36,
            cpp9: 6432.9,
            cpp10: 6743.45,
            cpp11: 6743.45,
            cpp12: 5323.78
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 2779.2,
            cpp2: 4547.78,
            cpp3: 5356.27,
            cpp4: 5558.4,
            cpp5: 4547.78,
            cpp6: 4042.47,
            cpp7: 3031.85,
            cpp8: 3789.82,
            cpp9: 7326.98,
            cpp10: 7680.69,
            cpp11: 7680.69,
            cpp12: 6063.71
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 3649.19,
            cpp2: 5971.4,
            cpp3: 7032.99,
            cpp4: 7298.38,
            cpp5: 5971.4,
            cpp6: 5307.91,
            cpp7: 3980.94,
            cpp8: 4976.17,
            cpp9: 9620.6,
            cpp10: 10085.04,
            cpp11: 10085.04,
            cpp12: 7961.87
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 3383.81,
            cpp2: 5537.15,
            cpp3: 6521.53,
            cpp4: 6767.63,
            cpp5: 5537.15,
            cpp6: 4921.91,
            cpp7: 3691.43,
            cpp8: 4614.29,
            cpp9: 8920.96,
            cpp10: 9351.63,
            cpp11: 9351.63,
            cpp12: 7382.87
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'Телеканал 78',
            cpp1: 3945.3,
            cpp2: 6455.94,
            cpp3: 7603.66,
            cpp4: 7890.59,
            cpp5: 6455.94,
            cpp6: 5738.61,
            cpp7: 4303.96,
            cpp8: 5379.95,
            cpp9: 10401.24,
            cpp10: 10903.36,
            cpp11: 10903.36,
            cpp12: 8607.92
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'grp',
            channelName: 'ТК Санкт-Петербург',
            cpp1: 2133.91,
            cpp2: 3491.86,
            cpp3: 4112.63,
            cpp4: 4267.83,
            cpp5: 3491.86,
            cpp6: 3103.87,
            cpp7: 2327.91,
            cpp8: 2909.88,
            cpp9: 5625.77,
            cpp10: 5897.36,
            cpp11: 5897.36,
            cpp12: 4655.81
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'min',
            channelName: 'СТС Love',
            cpp1: 2006.52,
            cpp2: 3283.39,
            cpp3: 3867.11,
            cpp4: 4013.04,
            cpp5: 3283.39,
            cpp6: 2918.57,
            cpp7: 2188.93,
            cpp8: 2736.16,
            cpp9: 5289.91,
            cpp10: 5545.29,
            cpp11: 5545.29,
            cpp12: 4377.86
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'min',
            channelName: 'ТНТ 4',
            cpp1: 2090.17,
            cpp2: 3420.28,
            cpp3: 4028.33,
            cpp4: 4180.34,
            cpp5: 3420.28,
            cpp6: 3040.25,
            cpp7: 2280.19,
            cpp8: 2850.23,
            cpp9: 5510.45,
            cpp10: 5776.47,
            cpp11: 5776.47,
            cpp12: 4560.37
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'min',
            channelName: 'Муз-ТВ',
            cpp1: 0,
            cpp2: 0,
            cpp3: 0,
            cpp4: 0,
            cpp5: 0,
            cpp6: 0,
            cpp7: 0,
            cpp8: 0,
            cpp9: 6883.63,
            cpp10: 7215.95,
            cpp11: 7215.95,
            cpp12: 5696.8
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'min',
            channelName: 'Суббота',
            cpp1: 2029.19,
            cpp2: 3320.49,
            cpp3: 3910.8,
            cpp4: 4058.38,
            cpp5: 3320.49,
            cpp6: 2951.55,
            cpp7: 2213.66,
            cpp8: 2767.08,
            cpp9: 5349.68,
            cpp10: 5607.94,
            cpp11: 5607.94,
            cpp12: 4427.32
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'min',
            channelName: 'RU.TV',
            cpp1: 1685.12,
            cpp2: 2757.47,
            cpp3: 3247.69,
            cpp4: 3370.24,
            cpp5: 2757.47,
            cpp6: 2451.09,
            cpp7: 1838.31,
            cpp8: 2297.89,
            cpp9: 4442.59,
            cpp10: 4657.06,
            cpp11: 4657.06,
            cpp12: 3676.63
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'min',
            channelName: 'TV 1000',
            cpp1: 2017.38,
            cpp2: 3301.17,
            cpp3: 3888.04,
            cpp4: 4034.76,
            cpp5: 3301.17,
            cpp6: 2934.37,
            cpp7: 2200.78,
            cpp8: 2750.97,
            cpp9: 5318.55,
            cpp10: 5575.31,
            cpp11: 5575.31,
            cpp12: 4401.56
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'min',
            channelName: 'TV 1000 РУССКОЕ КИНО',
            cpp1: 1661.54,
            cpp2: 2718.88,
            cpp3: 3202.23,
            cpp4: 3323.07,
            cpp5: 2718.88,
            cpp6: 2416.78,
            cpp7: 1812.58,
            cpp8: 2265.73,
            cpp9: 4380.41,
            cpp10: 4591.88,
            cpp11: 4591.88,
            cpp12: 3625.17
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 1922.74,
            cpp2: 3146.3,
            cpp3: 3705.64,
            cpp4: 3845.47,
            cpp5: 3146.3,
            cpp6: 2796.71,
            cpp7: 2097.53,
            cpp8: 2621.91,
            cpp9: 5069.03,
            cpp10: 5313.75,
            cpp11: 5313.75,
            cpp12: 4195.06
        },
        {
            cityName: 'Санкт-Петербург',
            priceType: 'min',
            channelName: 'РБК',
            cpp1: 3753.14,
            cpp2: 6141.5,
            cpp3: 7233.33,
            cpp4: 7506.28,
            cpp5: 6141.5,
            cpp6: 5459.11,
            cpp7: 4094.34,
            cpp8: 5117.92,
            cpp9: 9894.64,
            cpp10: 10372.32,
            cpp11: 10372.32,
            cpp12: 8188.67
        }
    ],
    Барнаул: [
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 530.96,
            cpp2: 867.91,
            cpp3: 1123.18,
            cpp4: 1143.6,
            cpp5: 949.59,
            cpp6: 827.07,
            cpp7: 663.69,
            cpp8: 765.8,
            cpp9: 1480.55,
            cpp10: 1552.02,
            cpp11: 1552.02,
            cpp12: 1225.28
        },
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 500.64,
            cpp2: 818.35,
            cpp3: 1059.04,
            cpp4: 1078.3,
            cpp5: 895.37,
            cpp6: 779.84,
            cpp7: 625.8,
            cpp8: 722.08,
            cpp9: 1396.01,
            cpp10: 1463.41,
            cpp11: 1463.41,
            cpp12: 1155.32
        },
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 381.75,
            cpp2: 624.01,
            cpp3: 807.54,
            cpp4: 822.22,
            cpp5: 682.74,
            cpp6: 594.64,
            cpp7: 477.18,
            cpp8: 550.6,
            cpp9: 1064.49,
            cpp10: 1115.87,
            cpp11: 1115.87,
            cpp12: 880.95
        },
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 619.25,
            cpp2: 1012.24,
            cpp3: 1309.96,
            cpp4: 1333.78,
            cpp5: 1107.51,
            cpp6: 964.61,
            cpp7: 774.07,
            cpp8: 893.16,
            cpp9: 1726.77,
            cpp10: 1810.13,
            cpp11: 1810.13,
            cpp12: 1429.05
        },
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 623.97,
            cpp2: 1019.95,
            cpp3: 1319.94,
            cpp4: 1343.94,
            cpp5: 1115.95,
            cpp6: 971.96,
            cpp7: 779.97,
            cpp8: 899.96,
            cpp9: 1739.92,
            cpp10: 1823.92,
            cpp11: 1823.92,
            cpp12: 1439.94
        },
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 193.91,
            cpp2: 316.97,
            cpp3: 410.2,
            cpp4: 417.66,
            cpp5: 346.81,
            cpp6: 302.06,
            cpp7: 242.39,
            cpp8: 279.68,
            cpp9: 540.72,
            cpp10: 566.83,
            cpp11: 566.83,
            cpp12: 447.49
        },
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 199.55,
            cpp2: 326.19,
            cpp3: 422.13,
            cpp4: 429.8,
            cpp5: 356.89,
            cpp6: 310.84,
            cpp7: 249.44,
            cpp8: 287.81,
            cpp9: 556.44,
            cpp10: 583.3,
            cpp11: 583.3,
            cpp12: 460.5
        },
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 362.56,
            cpp2: 592.65,
            cpp3: 766.96,
            cpp4: 780.9,
            cpp5: 648.43,
            cpp6: 564.76,
            cpp7: 453.2,
            cpp8: 522.93,
            cpp9: 1010.99,
            cpp10: 1059.8,
            cpp11: 1059.8,
            cpp12: 836.68
        },
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 294.34,
            cpp2: 481.14,
            cpp3: 622.65,
            cpp4: 633.97,
            cpp5: 526.42,
            cpp6: 458.5,
            cpp7: 367.93,
            cpp8: 424.53,
            cpp9: 820.76,
            cpp10: 860.39,
            cpp11: 860.39,
            cpp12: 679.25
        },
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 430.36,
            cpp2: 703.47,
            cpp3: 910.37,
            cpp4: 926.93,
            cpp5: 769.68,
            cpp6: 670.37,
            cpp7: 537.95,
            cpp8: 620.71,
            cpp9: 1200.04,
            cpp10: 1257.97,
            cpp11: 1257.97,
            cpp12: 993.14
        },
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 130.05,
            cpp2: 212.57,
            cpp3: 275.1,
            cpp4: 280.1,
            cpp5: 232.58,
            cpp6: 202.57,
            cpp7: 162.56,
            cpp8: 187.57,
            cpp9: 362.63,
            cpp10: 380.13,
            cpp11: 380.13,
            cpp12: 300.1
        },
        {
            cityName: 'Барнаул',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 294.73,
            cpp2: 481.76,
            cpp3: 623.46,
            cpp4: 634.79,
            cpp5: 527.11,
            cpp6: 459.09,
            cpp7: 368.41,
            cpp8: 425.08,
            cpp9: 821.83,
            cpp10: 861.51,
            cpp11: 861.51,
            cpp12: 680.14
        }
    ],
    Владивосток: [
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 625.11,
            cpp2: 1021.81,
            cpp3: 1322.34,
            cpp4: 1346.38,
            cpp5: 1117.98,
            cpp6: 973.72,
            cpp7: 781.38,
            cpp8: 901.6,
            cpp9: 1743.09,
            cpp10: 1827.24,
            cpp11: 1827.24,
            cpp12: 1442.55
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 518.92,
            cpp2: 848.24,
            cpp3: 1097.73,
            cpp4: 1117.68,
            cpp5: 928.08,
            cpp6: 808.33,
            cpp7: 648.66,
            cpp8: 748.45,
            cpp9: 1447,
            cpp10: 1516.86,
            cpp11: 1516.86,
            cpp12: 1197.52
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 450.87,
            cpp2: 737,
            cpp3: 953.76,
            cpp4: 971.11,
            cpp5: 806.36,
            cpp6: 702.32,
            cpp7: 563.59,
            cpp8: 650.29,
            cpp9: 1257.24,
            cpp10: 1317.93,
            cpp11: 1317.93,
            cpp12: 1040.47
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 982.55,
            cpp2: 1606.09,
            cpp3: 2078.46,
            cpp4: 2116.25,
            cpp5: 1757.25,
            cpp6: 1530.5,
            cpp7: 1228.18,
            cpp8: 1417.13,
            cpp9: 2739.79,
            cpp10: 2872.06,
            cpp11: 2872.06,
            cpp12: 2267.41
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1059.37,
            cpp2: 1731.66,
            cpp3: 2240.97,
            cpp4: 2281.71,
            cpp5: 1894.64,
            cpp6: 1650.17,
            cpp7: 1324.21,
            cpp8: 1527.93,
            cpp9: 2954,
            cpp10: 3096.61,
            cpp11: 3096.61,
            cpp12: 2444.69
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 302.25,
            cpp2: 494.07,
            cpp3: 639.38,
            cpp4: 651.01,
            cpp5: 540.57,
            cpp6: 470.82,
            cpp7: 377.82,
            cpp8: 435.94,
            cpp9: 842.83,
            cpp10: 883.51,
            cpp11: 883.51,
            cpp12: 697.51
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 435.34,
            cpp2: 711.61,
            cpp3: 920.91,
            cpp4: 937.66,
            cpp5: 778.59,
            cpp6: 678.13,
            cpp7: 544.17,
            cpp8: 627.89,
            cpp9: 1213.93,
            cpp10: 1272.53,
            cpp11: 1272.53,
            cpp12: 1004.63
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 443.19,
            cpp2: 724.44,
            cpp3: 937.52,
            cpp4: 954.56,
            cpp5: 792.63,
            cpp6: 690.35,
            cpp7: 553.99,
            cpp8: 639.22,
            cpp9: 1235.82,
            cpp10: 1295.48,
            cpp11: 1295.48,
            cpp12: 1022.75
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 512.63,
            cpp2: 837.96,
            cpp3: 1084.42,
            cpp4: 1104.13,
            cpp5: 916.82,
            cpp6: 798.52,
            cpp7: 640.79,
            cpp8: 739.37,
            cpp9: 1429.46,
            cpp10: 1498.47,
            cpp11: 1498.47,
            cpp12: 1183
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 657.74,
            cpp2: 1075.15,
            cpp3: 1391.37,
            cpp4: 1416.66,
            cpp5: 1176.34,
            cpp6: 1024.55,
            cpp7: 822.17,
            cpp8: 948.66,
            cpp9: 1834.07,
            cpp10: 1922.62,
            cpp11: 1922.62,
            cpp12: 1517.85
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 240.64,
            cpp2: 393.36,
            cpp3: 509.05,
            cpp4: 518.31,
            cpp5: 430.38,
            cpp6: 374.85,
            cpp7: 300.8,
            cpp8: 347.08,
            cpp9: 671.02,
            cpp10: 703.41,
            cpp11: 703.41,
            cpp12: 555.33
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 536.46,
            cpp2: 876.91,
            cpp3: 1134.83,
            cpp4: 1155.46,
            cpp5: 959.44,
            cpp6: 835.64,
            cpp7: 670.58,
            cpp8: 773.74,
            cpp9: 1495.91,
            cpp10: 1568.12,
            cpp11: 1568.12,
            cpp12: 1237.99
        },
        {
            cityName: 'Владивосток',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 351.74,
            cpp2: 574.96,
            cpp3: 744.06,
            cpp4: 757.59,
            cpp5: 629.07,
            cpp6: 547.9,
            cpp7: 439.67,
            cpp8: 507.32,
            cpp9: 980.81,
            cpp10: 1028.16,
            cpp11: 1028.16,
            cpp12: 811.71
        },
        {
            cityName: 'Владивосток',
            priceType: 'min',
            channelName: 'ТНТ 4',
            cpp1: 463.9,
            cpp2: 758.3,
            cpp3: 981.33,
            cpp4: 999.17,
            cpp5: 829.67,
            cpp6: 722.61,
            cpp7: 579.88,
            cpp8: 669.09,
            cpp9: 1293.57,
            cpp10: 1356.02,
            cpp11: 1356.02,
            cpp12: 1070.54
        }
    ],
    Волгоград: [
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 753.05,
            cpp2: 1230.94,
            cpp3: 1592.98,
            cpp4: 1621.94,
            cpp5: 1346.79,
            cpp6: 1173.01,
            cpp7: 941.31,
            cpp8: 1086.12,
            cpp9: 2099.84,
            cpp10: 2201.21,
            cpp11: 2201.21,
            cpp12: 1737.8
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 529.64,
            cpp2: 865.76,
            cpp3: 1120.4,
            cpp4: 1140.77,
            cpp5: 947.25,
            cpp6: 825.02,
            cpp7: 662.05,
            cpp8: 763.91,
            cpp9: 1476.89,
            cpp10: 1548.19,
            cpp11: 1548.19,
            cpp12: 1222.25
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 357.7,
            cpp2: 584.7,
            cpp3: 756.67,
            cpp4: 770.43,
            cpp5: 639.73,
            cpp6: 557.18,
            cpp7: 447.12,
            cpp8: 515.91,
            cpp9: 997.43,
            cpp10: 1045.58,
            cpp11: 1045.58,
            cpp12: 825.46
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 878.86,
            cpp2: 1436.6,
            cpp3: 1859.13,
            cpp4: 1892.93,
            cpp5: 1571.81,
            cpp6: 1369,
            cpp7: 1098.58,
            cpp8: 1267.59,
            cpp9: 2450.67,
            cpp10: 2568.98,
            cpp11: 2568.98,
            cpp12: 2028.14
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 847.92,
            cpp2: 1386.02,
            cpp3: 1793.67,
            cpp4: 1826.28,
            cpp5: 1516.46,
            cpp6: 1320.79,
            cpp7: 1059.89,
            cpp8: 1222.96,
            cpp9: 2364.38,
            cpp10: 2478.52,
            cpp11: 2478.52,
            cpp12: 1956.73
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 212.86,
            cpp2: 347.94,
            cpp3: 450.27,
            cpp4: 458.46,
            cpp5: 380.68,
            cpp6: 331.56,
            cpp7: 266.07,
            cpp8: 307,
            cpp9: 593.54,
            cpp10: 622.19,
            cpp11: 622.19,
            cpp12: 491.21
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 338.47,
            cpp2: 553.27,
            cpp3: 716,
            cpp4: 729.02,
            cpp5: 605.34,
            cpp6: 527.23,
            cpp7: 423.09,
            cpp8: 488.18,
            cpp9: 943.81,
            cpp10: 989.38,
            cpp11: 989.38,
            cpp12: 781.09
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 287.92,
            cpp2: 470.64,
            cpp3: 609.07,
            cpp4: 620.14,
            cpp5: 514.94,
            cpp6: 448.49,
            cpp7: 359.9,
            cpp8: 415.27,
            cpp9: 802.86,
            cpp10: 841.62,
            cpp11: 841.62,
            cpp12: 664.43
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 353.04,
            cpp2: 577.09,
            cpp3: 746.82,
            cpp4: 760.4,
            cpp5: 631.4,
            cpp6: 549.93,
            cpp7: 441.3,
            cpp8: 509.2,
            cpp9: 984.45,
            cpp10: 1031.97,
            cpp11: 1031.97,
            cpp12: 814.72
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 414.18,
            cpp2: 677.02,
            cpp3: 876.14,
            cpp4: 892.07,
            cpp5: 740.74,
            cpp6: 645.16,
            cpp7: 517.72,
            cpp8: 597.37,
            cpp9: 1154.92,
            cpp10: 1210.67,
            cpp11: 1210.67,
            cpp12: 955.79
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 171.46,
            cpp2: 280.27,
            cpp3: 362.7,
            cpp4: 369.29,
            cpp5: 306.64,
            cpp6: 267.08,
            cpp7: 214.32,
            cpp8: 247.29,
            cpp9: 478.1,
            cpp10: 501.18,
            cpp11: 501.18,
            cpp12: 395.67
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 152.46,
            cpp2: 249.21,
            cpp3: 322.5,
            cpp4: 328.37,
            cpp5: 272.66,
            cpp6: 237.48,
            cpp7: 190.57,
            cpp8: 219.89,
            cpp9: 425.12,
            cpp10: 445.64,
            cpp11: 445.64,
            cpp12: 351.82
        },
        {
            cityName: 'Волгоград',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 423.22,
            cpp2: 691.81,
            cpp3: 895.28,
            cpp4: 911.56,
            cpp5: 756.92,
            cpp6: 659.25,
            cpp7: 529.03,
            cpp8: 610.42,
            cpp9: 1180.14,
            cpp10: 1237.12,
            cpp11: 1237.12,
            cpp12: 976.67
        },
        {
            cityName: 'Волгоград',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 200.41,
            cpp2: 327.6,
            cpp3: 423.95,
            cpp4: 431.66,
            cpp5: 358.43,
            cpp6: 312.18,
            cpp7: 250.52,
            cpp8: 289.06,
            cpp9: 558.84,
            cpp10: 585.82,
            cpp11: 585.82,
            cpp12: 462.49
        }
    ],
    Воронеж: [
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 686.11,
            cpp2: 1121.53,
            cpp3: 1451.4,
            cpp4: 1477.78,
            cpp5: 1227.09,
            cpp6: 1068.76,
            cpp7: 857.64,
            cpp8: 989.59,
            cpp9: 1913.2,
            cpp10: 2005.56,
            cpp11: 2005.56,
            cpp12: 1583.34
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 558.49,
            cpp2: 912.91,
            cpp3: 1181.42,
            cpp4: 1202.9,
            cpp5: 998.84,
            cpp6: 869.95,
            cpp7: 698.11,
            cpp8: 805.51,
            cpp9: 1557.32,
            cpp10: 1632.51,
            cpp11: 1632.51,
            cpp12: 1288.82
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 570.9,
            cpp2: 933.2,
            cpp3: 1207.67,
            cpp4: 1229.63,
            cpp5: 1021.03,
            cpp6: 889.29,
            cpp7: 713.63,
            cpp8: 823.41,
            cpp9: 1591.93,
            cpp10: 1668.78,
            cpp11: 1668.78,
            cpp12: 1317.46
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 1029.98,
            cpp2: 1683.61,
            cpp3: 2178.79,
            cpp4: 2218.41,
            cpp5: 1842.07,
            cpp6: 1604.39,
            cpp7: 1287.47,
            cpp8: 1485.54,
            cpp9: 2872.05,
            cpp10: 3010.7,
            cpp11: 3010.7,
            cpp12: 2376.87
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1004.76,
            cpp2: 1642.4,
            cpp3: 2125.45,
            cpp4: 2164.1,
            cpp5: 1796.97,
            cpp6: 1565.11,
            cpp7: 1255.95,
            cpp8: 1449.17,
            cpp9: 2801.73,
            cpp10: 2936.99,
            cpp11: 2936.99,
            cpp12: 2318.68
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 414.24,
            cpp2: 677.13,
            cpp3: 876.28,
            cpp4: 892.22,
            cpp5: 740.86,
            cpp6: 645.26,
            cpp7: 517.8,
            cpp8: 597.47,
            cpp9: 1155.1,
            cpp10: 1210.87,
            cpp11: 1210.87,
            cpp12: 955.95
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 406.14,
            cpp2: 663.89,
            cpp3: 859.15,
            cpp4: 874.77,
            cpp5: 726.37,
            cpp6: 632.65,
            cpp7: 507.68,
            cpp8: 585.79,
            cpp9: 1132.52,
            cpp10: 1187.19,
            cpp11: 1187.19,
            cpp12: 937.26
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 459.65,
            cpp2: 751.34,
            cpp3: 972.33,
            cpp4: 990.01,
            cpp5: 822.06,
            cpp6: 715.99,
            cpp7: 574.56,
            cpp8: 662.95,
            cpp9: 1281.7,
            cpp10: 1343.58,
            cpp11: 1343.58,
            cpp12: 1060.72
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 699.16,
            cpp2: 1142.86,
            cpp3: 1478.99,
            cpp4: 1505.88,
            cpp5: 1250.42,
            cpp6: 1089.08,
            cpp7: 873.95,
            cpp8: 1008.4,
            cpp9: 1949.58,
            cpp10: 2043.7,
            cpp11: 2043.7,
            cpp12: 1613.45
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 685.29,
            cpp2: 1120.18,
            cpp3: 1449.65,
            cpp4: 1476,
            cpp5: 1225.61,
            cpp6: 1067.47,
            cpp7: 856.61,
            cpp8: 988.4,
            cpp9: 1910.9,
            cpp10: 2003.15,
            cpp11: 2003.15,
            cpp12: 1581.43
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 264.77,
            cpp2: 432.8,
            cpp3: 560.09,
            cpp4: 570.28,
            cpp5: 473.53,
            cpp6: 412.43,
            cpp7: 330.96,
            cpp8: 381.88,
            cpp9: 738.3,
            cpp10: 773.95,
            cpp11: 773.95,
            cpp12: 611.01
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 209.4,
            cpp2: 342.29,
            cpp3: 442.96,
            cpp4: 451.02,
            cpp5: 374.5,
            cpp6: 326.18,
            cpp7: 261.75,
            cpp8: 302.02,
            cpp9: 583.91,
            cpp10: 612.09,
            cpp11: 612.09,
            cpp12: 483.23
        },
        {
            cityName: 'Воронеж',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 461.11,
            cpp2: 753.73,
            cpp3: 975.42,
            cpp4: 993.15,
            cpp5: 824.67,
            cpp6: 718.26,
            cpp7: 576.38,
            cpp8: 665.06,
            cpp9: 1285.78,
            cpp10: 1347.85,
            cpp11: 1347.85,
            cpp12: 1064.09
        }
    ],
    Екатеринбург: [
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 1930.9,
            cpp2: 3156.28,
            cpp3: 4084.6,
            cpp4: 4158.87,
            cpp5: 3453.34,
            cpp6: 3007.75,
            cpp7: 2413.63,
            cpp8: 2784.96,
            cpp9: 5384.25,
            cpp10: 5644.18,
            cpp11: 5644.18,
            cpp12: 4455.93
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 1447.07,
            cpp2: 2365.4,
            cpp3: 3061.11,
            cpp4: 3116.77,
            cpp5: 2588.03,
            cpp6: 2254.09,
            cpp7: 1808.84,
            cpp8: 2087.12,
            cpp9: 4035.1,
            cpp10: 4229.9,
            cpp11: 4229.9,
            cpp12: 3339.39
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 1489.64,
            cpp2: 2435,
            cpp3: 3151.17,
            cpp4: 3208.46,
            cpp5: 2664.17,
            cpp6: 2320.41,
            cpp7: 1862.06,
            cpp8: 2148.53,
            cpp9: 4153.82,
            cpp10: 4354.35,
            cpp11: 4354.35,
            cpp12: 3437.64
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 3354.15,
            cpp2: 5482.74,
            cpp3: 7095.31,
            cpp4: 7224.32,
            cpp5: 5998.76,
            cpp6: 5224.73,
            cpp7: 4192.68,
            cpp8: 4837.71,
            cpp9: 9352.91,
            cpp10: 9804.43,
            cpp11: 9804.43,
            cpp12: 7740.34
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 3564.1,
            cpp2: 5825.94,
            cpp3: 7539.45,
            cpp4: 7676.53,
            cpp5: 6374.26,
            cpp6: 5551.78,
            cpp7: 4455.13,
            cpp8: 5140.53,
            cpp9: 9938.36,
            cpp10: 10418.15,
            cpp11: 10418.15,
            cpp12: 8224.85
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 1260.6,
            cpp2: 2060.59,
            cpp3: 2666.65,
            cpp4: 2715.13,
            cpp5: 2254.53,
            cpp6: 1963.62,
            cpp7: 1575.75,
            cpp8: 1818.17,
            cpp9: 3515.13,
            cpp10: 3684.82,
            cpp11: 3684.82,
            cpp12: 2909.07
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 1391.31,
            cpp2: 2274.26,
            cpp3: 2943.17,
            cpp4: 2996.68,
            cpp5: 2488.31,
            cpp6: 2167.24,
            cpp7: 1739.14,
            cpp8: 2006.7,
            cpp9: 3879.63,
            cpp10: 4066.92,
            cpp11: 4066.92,
            cpp12: 3210.73
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 1116.31,
            cpp2: 1824.74,
            cpp3: 2361.43,
            cpp4: 2404.36,
            cpp5: 1996.48,
            cpp6: 1738.87,
            cpp7: 1395.39,
            cpp8: 1610.06,
            cpp9: 3112.79,
            cpp10: 3263.06,
            cpp11: 3263.06,
            cpp12: 2576.1
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 2316.28,
            cpp2: 3786.23,
            cpp3: 4899.82,
            cpp4: 4988.91,
            cpp5: 4142.58,
            cpp6: 3608.05,
            cpp7: 2895.35,
            cpp8: 3340.79,
            cpp9: 6458.86,
            cpp10: 6770.67,
            cpp11: 6770.67,
            cpp12: 5345.26
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 3170.3,
            cpp2: 5182.22,
            cpp3: 6706.4,
            cpp4: 6828.33,
            cpp5: 5669.96,
            cpp6: 4938.35,
            cpp7: 3962.87,
            cpp8: 4572.55,
            cpp9: 8840.25,
            cpp10: 9267.03,
            cpp11: 9267.03,
            cpp12: 7316.07
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 569.36,
            cpp2: 930.69,
            cpp3: 1204.42,
            cpp4: 1226.32,
            cpp5: 1018.28,
            cpp6: 886.89,
            cpp7: 711.7,
            cpp8: 821.2,
            cpp9: 1587.65,
            cpp10: 1664.29,
            cpp11: 1664.29,
            cpp12: 1313.92
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 503.1,
            cpp2: 822.37,
            cpp3: 1064.25,
            cpp4: 1083.6,
            cpp5: 899.77,
            cpp6: 783.67,
            cpp7: 628.87,
            cpp8: 725.62,
            cpp9: 1402.87,
            cpp10: 1470.6,
            cpp11: 1470.6,
            cpp12: 1161
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 1025.24,
            cpp2: 1675.87,
            cpp3: 2168.78,
            cpp4: 2208.21,
            cpp5: 1833.6,
            cpp6: 1597.01,
            cpp7: 1281.55,
            cpp8: 1478.71,
            cpp9: 2858.84,
            cpp10: 2996.86,
            cpp11: 2996.86,
            cpp12: 2365.94
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'min',
            channelName: 'ТНТ 4',
            cpp1: 0,
            cpp2: 0,
            cpp3: 0,
            cpp4: 1755.67,
            cpp5: 1457.83,
            cpp6: 1269.72,
            cpp7: 1018.91,
            cpp8: 1175.67,
            cpp9: 2272.96,
            cpp10: 2382.69,
            cpp11: 2382.69,
            cpp12: 1881.07
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 523.35,
            cpp2: 855.47,
            cpp3: 1107.08,
            cpp4: 1127.21,
            cpp5: 935.99,
            cpp6: 815.21,
            cpp7: 654.18,
            cpp8: 754.83,
            cpp9: 1459.33,
            cpp10: 1529.78,
            cpp11: 1529.78,
            cpp12: 1207.72
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'min',
            channelName: '4 канал',
            cpp1: 1084.19,
            cpp2: 1772.24,
            cpp3: 2293.49,
            cpp4: 2335.19,
            cpp5: 1939.04,
            cpp6: 1688.84,
            cpp7: 1355.24,
            cpp8: 1563.74,
            cpp9: 3023.23,
            cpp10: 3169.18,
            cpp11: 3169.18,
            cpp12: 2501.99
        },
        {
            cityName: 'Екатеринбург',
            priceType: 'min',
            channelName: 'ОТВ',
            cpp1: 1969.76,
            cpp2: 3219.8,
            cpp3: 4166.8,
            cpp4: 4242.56,
            cpp5: 3522.84,
            cpp6: 3068.28,
            cpp7: 2462.2,
            cpp8: 2841,
            cpp9: 5492.6,
            cpp10: 5757.76,
            cpp11: 5757.76,
            cpp12: 4545.6
        }
    ],
    Ижевск: [
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3995.01,
            cpp2: 6530.3,
            cpp3: 8450.98,
            cpp4: 8604.63,
            cpp5: 7144.92,
            cpp6: 6222.99,
            cpp7: 4993.76,
            cpp8: 5762.03,
            cpp9: 11139.93,
            cpp10: 11677.72,
            cpp11: 11677.72,
            cpp12: 9219.25
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 3328.04,
            cpp2: 5440.07,
            cpp3: 7040.09,
            cpp4: 7168.09,
            cpp5: 5952.08,
            cpp6: 5184.07,
            cpp7: 4160.05,
            cpp8: 4800.06,
            cpp9: 9280.12,
            cpp10: 9728.13,
            cpp11: 9728.13,
            cpp12: 7680.1
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 3303.98,
            cpp2: 5400.74,
            cpp3: 6989.2,
            cpp4: 7116.27,
            cpp5: 5909.05,
            cpp6: 5146.59,
            cpp7: 4129.98,
            cpp8: 4765.36,
            cpp9: 9213.03,
            cpp10: 9657.8,
            cpp11: 9657.8,
            cpp12: 7624.58
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2129.6,
            cpp2: 3481.08,
            cpp3: 4504.93,
            cpp4: 4586.84,
            cpp5: 3808.71,
            cpp6: 3317.27,
            cpp7: 2662,
            cpp8: 3071.54,
            cpp9: 5938.31,
            cpp10: 6224.99,
            cpp11: 6224.99,
            cpp12: 4914.47
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2372.48,
            cpp2: 3878.09,
            cpp3: 5018.71,
            cpp4: 5109.96,
            cpp5: 4243.09,
            cpp6: 3695.59,
            cpp7: 2965.6,
            cpp8: 3421.85,
            cpp9: 6615.57,
            cpp10: 6934.94,
            cpp11: 6934.94,
            cpp12: 5474.96
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 943.64,
            cpp2: 1542.49,
            cpp3: 1996.17,
            cpp4: 2032.46,
            cpp5: 1687.67,
            cpp6: 1469.9,
            cpp7: 1179.55,
            cpp8: 1361.02,
            cpp9: 2631.31,
            cpp10: 2758.34,
            cpp11: 2758.34,
            cpp12: 2177.63
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1153.72,
            cpp2: 1885.89,
            cpp3: 2440.57,
            cpp4: 2484.94,
            cpp5: 2063.39,
            cpp6: 1797.14,
            cpp7: 1442.15,
            cpp8: 1664.02,
            cpp9: 3217.11,
            cpp10: 3372.42,
            cpp11: 3372.42,
            cpp12: 2662.44
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 599.21,
            cpp2: 979.48,
            cpp3: 1267.56,
            cpp4: 1290.61,
            cpp5: 1071.66,
            cpp6: 933.39,
            cpp7: 749.01,
            cpp8: 864.25,
            cpp9: 1670.87,
            cpp10: 1751.54,
            cpp11: 1751.54,
            cpp12: 1382.79
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 675.53,
            cpp2: 1104.23,
            cpp3: 1429,
            cpp4: 1454.98,
            cpp5: 1208.15,
            cpp6: 1052.26,
            cpp7: 844.41,
            cpp8: 974.32,
            cpp9: 1883.68,
            cpp10: 1974.62,
            cpp11: 1974.62,
            cpp12: 1558.91
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 911.67,
            cpp2: 1490.23,
            cpp3: 1928.54,
            cpp4: 1963.6,
            cpp5: 1630.49,
            cpp6: 1420.11,
            cpp7: 1139.59,
            cpp8: 1314.91,
            cpp9: 2542.16,
            cpp10: 2664.89,
            cpp11: 2664.89,
            cpp12: 2103.86
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 417.67,
            cpp2: 682.74,
            cpp3: 883.54,
            cpp4: 899.61,
            cpp5: 747,
            cpp6: 650.61,
            cpp7: 522.09,
            cpp8: 602.42,
            cpp9: 1164.67,
            cpp10: 1220.9,
            cpp11: 1220.9,
            cpp12: 963.87
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'Россия 24',
            cpp1: 388.22,
            cpp2: 634.59,
            cpp3: 821.24,
            cpp4: 836.17,
            cpp5: 694.32,
            cpp6: 604.73,
            cpp7: 485.28,
            cpp8: 559.93,
            cpp9: 1082.54,
            cpp10: 1134.8,
            cpp11: 1134.8,
            cpp12: 895.9
        },
        {
            cityName: 'Ижевск',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 306.04,
            cpp2: 500.26,
            cpp3: 647.4,
            cpp4: 659.17,
            cpp5: 547.35,
            cpp6: 476.72,
            cpp7: 382.56,
            cpp8: 441.41,
            cpp9: 853.39,
            cpp10: 894.59,
            cpp11: 894.59,
            cpp12: 706.26
        }
    ],
    Иркутск: [
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 665.42,
            cpp2: 1087.7,
            cpp3: 1407.62,
            cpp4: 1433.21,
            cpp5: 1190.08,
            cpp6: 1036.52,
            cpp7: 831.77,
            cpp8: 959.74,
            cpp9: 1855.5,
            cpp10: 1945.07,
            cpp11: 1945.07,
            cpp12: 1535.58
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 603.23,
            cpp2: 986.05,
            cpp3: 1276.06,
            cpp4: 1299.26,
            cpp5: 1078.85,
            cpp6: 939.64,
            cpp7: 754.03,
            cpp8: 870.04,
            cpp9: 1682.08,
            cpp10: 1763.28,
            cpp11: 1763.28,
            cpp12: 1392.06
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 513.95,
            cpp2: 840.11,
            cpp3: 1087.2,
            cpp4: 1106.96,
            cpp5: 919.18,
            cpp6: 800.57,
            cpp7: 642.43,
            cpp8: 741.27,
            cpp9: 1433.12,
            cpp10: 1502.31,
            cpp11: 1502.31,
            cpp12: 1186.03
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 1059.44,
            cpp2: 1731.78,
            cpp3: 2241.13,
            cpp4: 2281.87,
            cpp5: 1894.77,
            cpp6: 1650.28,
            cpp7: 1324.3,
            cpp8: 1528.04,
            cpp9: 2954.21,
            cpp10: 3096.83,
            cpp11: 3096.83,
            cpp12: 2444.87
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1010.43,
            cpp2: 1651.67,
            cpp3: 2137.46,
            cpp4: 2176.32,
            cpp5: 1807.12,
            cpp6: 1573.95,
            cpp7: 1263.04,
            cpp8: 1457.36,
            cpp9: 2817.56,
            cpp10: 2953.58,
            cpp11: 2953.58,
            cpp12: 2331.77
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 255.05,
            cpp2: 416.9,
            cpp3: 539.52,
            cpp4: 549.33,
            cpp5: 456.14,
            cpp6: 397.28,
            cpp7: 318.81,
            cpp8: 367.85,
            cpp9: 711.18,
            cpp10: 745.52,
            cpp11: 745.52,
            cpp12: 588.57
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 298.14,
            cpp2: 487.35,
            cpp3: 630.69,
            cpp4: 642.16,
            cpp5: 533.22,
            cpp6: 464.42,
            cpp7: 372.68,
            cpp8: 430.02,
            cpp9: 831.36,
            cpp10: 871.5,
            cpp11: 871.5,
            cpp12: 688.03
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 519.6,
            cpp2: 849.35,
            cpp3: 1099.16,
            cpp4: 1119.15,
            cpp5: 929.29,
            cpp6: 809.38,
            cpp7: 649.5,
            cpp8: 749.43,
            cpp9: 1448.89,
            cpp10: 1518.84,
            cpp11: 1518.84,
            cpp12: 1199.09
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 575.21,
            cpp2: 940.25,
            cpp3: 1216.8,
            cpp4: 1238.92,
            cpp5: 1028.75,
            cpp6: 896,
            cpp7: 719.02,
            cpp8: 829.63,
            cpp9: 1603.96,
            cpp10: 1681.39,
            cpp11: 1681.39,
            cpp12: 1327.41
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 678.38,
            cpp2: 1108.89,
            cpp3: 1435.03,
            cpp4: 1461.12,
            cpp5: 1213.25,
            cpp6: 1056.7,
            cpp7: 847.97,
            cpp8: 978.43,
            cpp9: 1891.63,
            cpp10: 1982.95,
            cpp11: 1982.95,
            cpp12: 1565.49
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 183.69,
            cpp2: 300.26,
            cpp3: 388.57,
            cpp4: 395.63,
            cpp5: 328.52,
            cpp6: 286.13,
            cpp7: 229.61,
            cpp8: 264.93,
            cpp9: 512.2,
            cpp10: 536.93,
            cpp11: 536.93,
            cpp12: 423.89
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 281.28,
            cpp2: 459.79,
            cpp3: 595.02,
            cpp4: 605.84,
            cpp5: 503.06,
            cpp6: 438.15,
            cpp7: 351.6,
            cpp8: 405.7,
            cpp9: 784.35,
            cpp10: 822.21,
            cpp11: 822.21,
            cpp12: 649.12
        },
        {
            cityName: 'Иркутск',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 747.6,
            cpp2: 1222.05,
            cpp3: 1581.47,
            cpp4: 1610.23,
            cpp5: 1337.06,
            cpp6: 1164.54,
            cpp7: 934.51,
            cpp8: 1078.28,
            cpp9: 2084.67,
            cpp10: 2185.31,
            cpp11: 2185.31,
            cpp12: 1725.24
        }
    ],
    Казань: [
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 1547.51,
            cpp2: 2529.58,
            cpp3: 3273.58,
            cpp4: 3333.1,
            cpp5: 2767.66,
            cpp6: 2410.54,
            cpp7: 1934.39,
            cpp8: 2231.98,
            cpp9: 4315.17,
            cpp10: 4523.49,
            cpp11: 4523.49,
            cpp12: 3571.17
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 892.79,
            cpp2: 1459.36,
            cpp3: 1888.59,
            cpp4: 1922.93,
            cpp5: 1596.72,
            cpp6: 1390.69,
            cpp7: 1115.98,
            cpp8: 1287.67,
            cpp9: 2489.5,
            cpp10: 2609.69,
            cpp11: 2609.69,
            cpp12: 2060.28
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 998.34,
            cpp2: 1631.91,
            cpp3: 2111.88,
            cpp4: 2150.28,
            cpp5: 1785.5,
            cpp6: 1555.11,
            cpp7: 1247.93,
            cpp8: 1439.92,
            cpp9: 2783.84,
            cpp10: 2918.24,
            cpp11: 2918.24,
            cpp12: 2303.87
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 2621.47,
            cpp2: 4285.09,
            cpp3: 5545.41,
            cpp4: 5646.24,
            cpp5: 4688.39,
            cpp6: 4083.44,
            cpp7: 3276.83,
            cpp8: 3780.96,
            cpp9: 7309.86,
            cpp10: 7662.75,
            cpp11: 7662.75,
            cpp12: 6049.54
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 2538.49,
            cpp2: 4149.46,
            cpp3: 5369.89,
            cpp4: 5467.52,
            cpp5: 4539.99,
            cpp6: 3954.19,
            cpp7: 3173.11,
            cpp8: 3661.29,
            cpp9: 7078.49,
            cpp10: 7420.21,
            cpp11: 7420.21,
            cpp12: 5858.06
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 809.6,
            cpp2: 1323.39,
            cpp3: 1712.62,
            cpp4: 1743.76,
            cpp5: 1447.94,
            cpp6: 1261.11,
            cpp7: 1012,
            cpp8: 1167.69,
            cpp9: 2257.54,
            cpp10: 2366.52,
            cpp11: 2366.52,
            cpp12: 1868.31
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 813.02,
            cpp2: 1328.98,
            cpp3: 1719.86,
            cpp4: 1751.13,
            cpp5: 1454.06,
            cpp6: 1266.44,
            cpp7: 1016.28,
            cpp8: 1172.63,
            cpp9: 2267.09,
            cpp10: 2376.53,
            cpp11: 2376.53,
            cpp12: 1876.21
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 665.39,
            cpp2: 1087.66,
            cpp3: 1407.56,
            cpp4: 1433.15,
            cpp5: 1190.02,
            cpp6: 1036.47,
            cpp7: 831.74,
            cpp8: 959.7,
            cpp9: 1855.41,
            cpp10: 1944.99,
            cpp11: 1944.99,
            cpp12: 1535.51
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 1170.98,
            cpp2: 1914.11,
            cpp3: 2477.08,
            cpp4: 2522.12,
            cpp5: 2094.26,
            cpp6: 1824.03,
            cpp7: 1463.73,
            cpp8: 1688.92,
            cpp9: 3265.25,
            cpp10: 3422.88,
            cpp11: 3422.88,
            cpp12: 2702.27
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 1615.88,
            cpp2: 2641.34,
            cpp3: 3418.21,
            cpp4: 3480.35,
            cpp5: 2889.94,
            cpp6: 2517.04,
            cpp7: 2019.85,
            cpp8: 2330.59,
            cpp9: 4505.82,
            cpp10: 4723.34,
            cpp11: 4723.34,
            cpp12: 3728.95
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 370.04,
            cpp2: 604.87,
            cpp3: 782.77,
            cpp4: 797,
            cpp5: 661.8,
            cpp6: 576.4,
            cpp7: 462.55,
            cpp8: 533.71,
            cpp9: 1031.83,
            cpp10: 1081.64,
            cpp11: 1081.64,
            cpp12: 853.93
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 343.14,
            cpp2: 560.91,
            cpp3: 725.88,
            cpp4: 739.08,
            cpp5: 613.7,
            cpp6: 534.51,
            cpp7: 428.93,
            cpp8: 494.92,
            cpp9: 956.84,
            cpp10: 1003.04,
            cpp11: 1003.04,
            cpp12: 791.87
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 913.13,
            cpp2: 1492.62,
            cpp3: 1931.62,
            cpp4: 1966.74,
            cpp5: 1633.1,
            cpp6: 1422.38,
            cpp7: 1141.41,
            cpp8: 1317.02,
            cpp9: 2546.23,
            cpp10: 2669.15,
            cpp11: 2669.15,
            cpp12: 2107.23
        },
        {
            cityName: 'Казань',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 1304.12,
            cpp2: 2131.74,
            cpp3: 2758.72,
            cpp4: 2808.88,
            cpp5: 2332.37,
            cpp6: 2031.42,
            cpp7: 1630.15,
            cpp8: 1880.94,
            cpp9: 3636.49,
            cpp10: 3812.05,
            cpp11: 3812.05,
            cpp12: 3009.51
        },
        {
            cityName: 'Казань',
            priceType: 'min',
            channelName: 'ТНТ 4',
            cpp1: 284.22,
            cpp2: 464.59,
            cpp3: 601.24,
            cpp4: 612.17,
            cpp5: 508.32,
            cpp6: 442.73,
            cpp7: 355.28,
            cpp8: 409.93,
            cpp9: 792.54,
            cpp10: 830.8,
            cpp11: 830.8,
            cpp12: 655.89
        },
        {
            cityName: 'Казань',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 299.72,
            cpp2: 489.93,
            cpp3: 634.03,
            cpp4: 645.56,
            cpp5: 536.04,
            cpp6: 466.88,
            cpp7: 374.65,
            cpp8: 432.29,
            cpp9: 835.77,
            cpp10: 876.11,
            cpp11: 876.11,
            cpp12: 691.67
        }
    ],
    Кемерово: [
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 550.29,
            cpp2: 899.5,
            cpp3: 1164.06,
            cpp4: 1185.23,
            cpp5: 984.16,
            cpp6: 857.17,
            cpp7: 687.86,
            cpp8: 793.68,
            cpp9: 1534.45,
            cpp10: 1608.53,
            cpp11: 1608.53,
            cpp12: 1269.89
        },
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 361.17,
            cpp2: 590.37,
            cpp3: 764,
            cpp4: 777.9,
            cpp5: 645.93,
            cpp6: 562.59,
            cpp7: 451.46,
            cpp8: 520.91,
            cpp9: 1007.1,
            cpp10: 1055.72,
            cpp11: 1055.72,
            cpp12: 833.46
        },
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 463.92,
            cpp2: 758.33,
            cpp3: 981.37,
            cpp4: 999.21,
            cpp5: 829.7,
            cpp6: 722.65,
            cpp7: 579.9,
            cpp8: 669.12,
            cpp9: 1293.62,
            cpp10: 1356.08,
            cpp11: 1356.08,
            cpp12: 1070.59
        },
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 819.31,
            cpp2: 1339.26,
            cpp3: 1733.16,
            cpp4: 1764.67,
            cpp5: 1465.31,
            cpp6: 1276.24,
            cpp7: 1024.14,
            cpp8: 1181.7,
            cpp9: 2284.62,
            cpp10: 2394.91,
            cpp11: 2394.91,
            cpp12: 1890.72
        },
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 642.89,
            cpp2: 1050.88,
            cpp3: 1359.96,
            cpp4: 1384.69,
            cpp5: 1149.79,
            cpp6: 1001.43,
            cpp7: 803.62,
            cpp8: 927.25,
            cpp9: 1792.68,
            cpp10: 1879.22,
            cpp11: 1879.22,
            cpp12: 1483.6
        },
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 271,
            cpp2: 442.98,
            cpp3: 573.27,
            cpp4: 583.69,
            cpp5: 484.67,
            cpp6: 422.14,
            cpp7: 338.75,
            cpp8: 390.87,
            cpp9: 755.67,
            cpp10: 792.16,
            cpp11: 792.16,
            cpp12: 625.39
        },
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 287.14,
            cpp2: 469.37,
            cpp3: 607.42,
            cpp4: 618.46,
            cpp5: 513.54,
            cpp6: 447.28,
            cpp7: 358.93,
            cpp8: 414.15,
            cpp9: 800.69,
            cpp10: 839.34,
            cpp11: 839.34,
            cpp12: 662.64
        },
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 286.28,
            cpp2: 467.96,
            cpp3: 605.6,
            cpp4: 616.61,
            cpp5: 512,
            cpp6: 445.94,
            cpp7: 357.85,
            cpp8: 412.91,
            cpp9: 798.28,
            cpp10: 836.82,
            cpp11: 836.82,
            cpp12: 660.65
        },
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 300.7,
            cpp2: 491.53,
            cpp3: 636.1,
            cpp4: 647.67,
            cpp5: 537.8,
            cpp6: 468.4,
            cpp7: 375.88,
            cpp8: 433.71,
            cpp9: 838.5,
            cpp10: 878.98,
            cpp11: 878.98,
            cpp12: 693.93
        },
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 411.24,
            cpp2: 672.22,
            cpp3: 869.93,
            cpp4: 885.75,
            cpp5: 735.48,
            cpp6: 640.58,
            cpp7: 514.05,
            cpp8: 593.13,
            cpp9: 1146.72,
            cpp10: 1202.08,
            cpp11: 1202.08,
            cpp12: 949.01
        },
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 175.22,
            cpp2: 286.42,
            cpp3: 370.66,
            cpp4: 377.4,
            cpp5: 313.38,
            cpp6: 272.94,
            cpp7: 219.03,
            cpp8: 252.72,
            cpp9: 488.6,
            cpp10: 512.19,
            cpp11: 512.19,
            cpp12: 404.36
        },
        {
            cityName: 'Кемерово',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 476.12,
            cpp2: 778.28,
            cpp3: 1007.18,
            cpp4: 1025.5,
            cpp5: 851.53,
            cpp6: 741.65,
            cpp7: 595.15,
            cpp8: 686.72,
            cpp9: 1327.65,
            cpp10: 1391.74,
            cpp11: 1391.74,
            cpp12: 1098.75
        }
    ],
    Краснодар: [
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 1056.9,
            cpp2: 1727.63,
            cpp3: 2235.75,
            cpp4: 2276.4,
            cpp5: 1890.23,
            cpp6: 1646.33,
            cpp7: 1321.13,
            cpp8: 1524.38,
            cpp9: 2947.13,
            cpp10: 3089.4,
            cpp11: 3089.4,
            cpp12: 2439
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 722.53,
            cpp2: 1181.06,
            cpp3: 1528.43,
            cpp4: 1556.22,
            cpp5: 1292.22,
            cpp6: 1125.48,
            cpp7: 903.16,
            cpp8: 1042.11,
            cpp9: 2014.75,
            cpp10: 2112.01,
            cpp11: 2112.01,
            cpp12: 1667.38
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 663.19,
            cpp2: 1084.06,
            cpp3: 1402.9,
            cpp4: 1428.41,
            cpp5: 1186.09,
            cpp6: 1033.04,
            cpp7: 828.99,
            cpp8: 956.52,
            cpp9: 1849.28,
            cpp10: 1938.55,
            cpp11: 1938.55,
            cpp12: 1530.44
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 1574.34,
            cpp2: 2573.44,
            cpp3: 3330.34,
            cpp4: 3390.89,
            cpp5: 2815.65,
            cpp6: 2452.34,
            cpp7: 1967.93,
            cpp8: 2270.68,
            cpp9: 4389.99,
            cpp10: 4601.92,
            cpp11: 4601.92,
            cpp12: 3633.1
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1208.2,
            cpp2: 1974.94,
            cpp3: 2555.8,
            cpp4: 2602.27,
            cpp5: 2160.81,
            cpp6: 1882,
            cpp7: 1510.25,
            cpp8: 1742.59,
            cpp9: 3369.01,
            cpp10: 3531.65,
            cpp11: 3531.65,
            cpp12: 2788.15
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 490.21,
            cpp2: 801.31,
            cpp3: 1036.99,
            cpp4: 1055.84,
            cpp5: 876.73,
            cpp6: 763.6,
            cpp7: 612.77,
            cpp8: 707.04,
            cpp9: 1366.94,
            cpp10: 1432.93,
            cpp11: 1432.93,
            cpp12: 1131.26
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 491.49,
            cpp2: 803.4,
            cpp3: 1039.7,
            cpp4: 1058.6,
            cpp5: 879.02,
            cpp6: 765.6,
            cpp7: 614.37,
            cpp8: 708.89,
            cpp9: 1370.51,
            cpp10: 1436.68,
            cpp11: 1436.68,
            cpp12: 1134.22
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 513.05,
            cpp2: 838.64,
            cpp3: 1085.3,
            cpp4: 1105.03,
            cpp5: 917.57,
            cpp6: 799.17,
            cpp7: 641.31,
            cpp8: 739.98,
            cpp9: 1430.62,
            cpp10: 1499.68,
            cpp11: 1499.68,
            cpp12: 1183.96
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 665.89,
            cpp2: 1088.48,
            cpp3: 1408.62,
            cpp4: 1434.23,
            cpp5: 1190.92,
            cpp6: 1037.26,
            cpp7: 832.37,
            cpp8: 960.42,
            cpp9: 1856.82,
            cpp10: 1946.46,
            cpp11: 1946.46,
            cpp12: 1536.68
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 827.79,
            cpp2: 1353.11,
            cpp3: 1751.09,
            cpp4: 1782.93,
            cpp5: 1480.47,
            cpp6: 1289.44,
            cpp7: 1034.73,
            cpp8: 1193.92,
            cpp9: 2308.25,
            cpp10: 2419.69,
            cpp11: 2419.69,
            cpp12: 1910.28
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 252.43,
            cpp2: 412.63,
            cpp3: 533.99,
            cpp4: 543.7,
            cpp5: 451.47,
            cpp6: 393.21,
            cpp7: 315.54,
            cpp8: 364.09,
            cpp9: 703.9,
            cpp10: 737.88,
            cpp11: 737.88,
            cpp12: 582.54
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 283.88,
            cpp2: 464.03,
            cpp3: 600.51,
            cpp4: 611.43,
            cpp5: 507.7,
            cpp6: 442.19,
            cpp7: 354.85,
            cpp8: 409.44,
            cpp9: 791.58,
            cpp10: 829.79,
            cpp11: 829.79,
            cpp12: 655.1
        },
        {
            cityName: 'Краснодар',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 541.63,
            cpp2: 885.36,
            cpp3: 1145.76,
            cpp4: 1166.59,
            cpp5: 968.69,
            cpp6: 843.7,
            cpp7: 677.04,
            cpp8: 781.2,
            cpp9: 1510.32,
            cpp10: 1583.24,
            cpp11: 1583.24,
            cpp12: 1249.92
        },
        {
            cityName: 'Краснодар',
            priceType: 'min',
            channelName: 'ТНТ 4',
            cpp1: 319.87,
            cpp2: 522.86,
            cpp3: 676.64,
            cpp4: 688.95,
            cpp5: 572.07,
            cpp6: 498.26,
            cpp7: 399.84,
            cpp8: 461.35,
            cpp9: 891.94,
            cpp10: 935,
            cpp11: 935,
            cpp12: 738.16
        },
        {
            cityName: 'Краснодар',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 278.38,
            cpp2: 455.04,
            cpp3: 588.87,
            cpp4: 599.58,
            cpp5: 497.86,
            cpp6: 433.62,
            cpp7: 347.97,
            cpp8: 401.5,
            cpp9: 776.24,
            cpp10: 813.71,
            cpp11: 813.71,
            cpp12: 642.4
        }
    ],
    Красноярск: [
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 1412.87,
            cpp2: 2309.5,
            cpp3: 2988.76,
            cpp4: 3043.1,
            cpp5: 2526.86,
            cpp6: 2200.82,
            cpp7: 1766.09,
            cpp8: 2037.79,
            cpp9: 3939.73,
            cpp10: 4129.93,
            cpp11: 4129.93,
            cpp12: 3260.47
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 1045.67,
            cpp2: 1709.28,
            cpp3: 2212,
            cpp4: 2252.22,
            cpp5: 1870.15,
            cpp6: 1628.84,
            cpp7: 1307.09,
            cpp8: 1508.18,
            cpp9: 2915.82,
            cpp10: 3056.59,
            cpp11: 3056.59,
            cpp12: 2413.1
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 958.8,
            cpp2: 1567.26,
            cpp3: 2028.22,
            cpp4: 2065.1,
            cpp5: 1714.77,
            cpp6: 1493.51,
            cpp7: 1198.49,
            cpp8: 1382.88,
            cpp9: 2673.56,
            cpp10: 2802.63,
            cpp11: 2802.63,
            cpp12: 2212.61
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 1933.16,
            cpp2: 3159.97,
            cpp3: 4089.37,
            cpp4: 4163.72,
            cpp5: 3457.37,
            cpp6: 3011.26,
            cpp7: 2416.44,
            cpp8: 2788.21,
            cpp9: 5390.53,
            cpp10: 5650.76,
            cpp11: 5650.76,
            cpp12: 4461.13
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1794.44,
            cpp2: 2933.22,
            cpp3: 3795.93,
            cpp4: 3864.95,
            cpp5: 3209.29,
            cpp6: 2795.19,
            cpp7: 2243.05,
            cpp8: 2588.14,
            cpp9: 5003.73,
            cpp10: 5245.29,
            cpp11: 5245.29,
            cpp12: 4141.02
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 743.84,
            cpp2: 1215.89,
            cpp3: 1573.51,
            cpp4: 1602.12,
            cpp5: 1330.33,
            cpp6: 1158.68,
            cpp7: 929.8,
            cpp8: 1072.85,
            cpp9: 2074.17,
            cpp10: 2174.3,
            cpp11: 2174.3,
            cpp12: 1716.56
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 649.46,
            cpp2: 1061.62,
            cpp3: 1373.86,
            cpp4: 1398.84,
            cpp5: 1161.54,
            cpp6: 1011.66,
            cpp7: 811.83,
            cpp8: 936.72,
            cpp9: 1811,
            cpp10: 1898.43,
            cpp11: 1898.43,
            cpp12: 1498.76
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 945.86,
            cpp2: 1546.11,
            cpp3: 2000.85,
            cpp4: 2037.23,
            cpp5: 1691.63,
            cpp6: 1473.35,
            cpp7: 1182.32,
            cpp8: 1364.21,
            cpp9: 2637.48,
            cpp10: 2764.81,
            cpp11: 2764.81,
            cpp12: 2182.74
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 788.31,
            cpp2: 1288.58,
            cpp3: 1667.58,
            cpp4: 1697.9,
            cpp5: 1409.86,
            cpp6: 1227.94,
            cpp7: 985.39,
            cpp8: 1136.98,
            cpp9: 2198.17,
            cpp10: 2304.29,
            cpp11: 2304.29,
            cpp12: 1819.17
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 1288.39,
            cpp2: 2106.02,
            cpp3: 2725.44,
            cpp4: 2774.99,
            cpp5: 2304.24,
            cpp6: 2006.92,
            cpp7: 1610.49,
            cpp8: 1858.26,
            cpp9: 3592.63,
            cpp10: 3766.06,
            cpp11: 3766.06,
            cpp12: 2973.21
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 334.44,
            cpp2: 546.68,
            cpp3: 707.47,
            cpp4: 720.34,
            cpp5: 598.14,
            cpp6: 520.96,
            cpp7: 418.05,
            cpp8: 482.37,
            cpp9: 932.58,
            cpp10: 977.6,
            cpp11: 977.6,
            cpp12: 771.79
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 379.14,
            cpp2: 619.75,
            cpp3: 802.03,
            cpp4: 816.62,
            cpp5: 678.08,
            cpp6: 590.59,
            cpp7: 473.93,
            cpp8: 546.84,
            cpp9: 1057.23,
            cpp10: 1108.27,
            cpp11: 1108.27,
            cpp12: 874.95
        },
        {
            cityName: 'Красноярск',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 990.74,
            cpp2: 1619.48,
            cpp3: 2095.8,
            cpp4: 2133.9,
            cpp5: 1771.9,
            cpp6: 1543.27,
            cpp7: 1238.43,
            cpp8: 1428.95,
            cpp9: 2762.64,
            cpp10: 2896.01,
            cpp11: 2896.01,
            cpp12: 2286.33
        },
        {
            cityName: 'Красноярск',
            priceType: 'min',
            channelName: 'Суббота',
            cpp1: 1124.18,
            cpp2: 1837.61,
            cpp3: 2378.08,
            cpp4: 2421.32,
            cpp5: 2010.56,
            cpp6: 1751.13,
            cpp7: 1405.23,
            cpp8: 1621.42,
            cpp9: 3134.74,
            cpp10: 3286.07,
            cpp11: 3286.07,
            cpp12: 2594.27
        }
    ],
    'Нижний Новгород': [
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 1577.32,
            cpp2: 2578.32,
            cpp3: 3336.65,
            cpp4: 3397.32,
            cpp5: 2820.99,
            cpp6: 2456.99,
            cpp7: 1971.66,
            cpp8: 2274.99,
            cpp9: 4398.31,
            cpp10: 4610.64,
            cpp11: 4610.64,
            cpp12: 3639.98
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 1165,
            cpp2: 1904.33,
            cpp3: 2464.43,
            cpp4: 2509.24,
            cpp5: 2083.56,
            cpp6: 1814.72,
            cpp7: 1456.25,
            cpp8: 1680.29,
            cpp9: 3248.56,
            cpp10: 3405.39,
            cpp11: 3405.39,
            cpp12: 2688.47
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 1111.12,
            cpp2: 1816.26,
            cpp3: 2350.45,
            cpp4: 2393.19,
            cpp5: 1987.2,
            cpp6: 1730.79,
            cpp7: 1388.9,
            cpp8: 1602.58,
            cpp9: 3098.32,
            cpp10: 3247.9,
            cpp11: 3247.9,
            cpp12: 2564.13
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 3446.62,
            cpp2: 5633.9,
            cpp3: 7290.93,
            cpp4: 7423.5,
            cpp5: 6164.15,
            cpp6: 5368.78,
            cpp7: 4308.28,
            cpp8: 4971.09,
            cpp9: 9610.78,
            cpp10: 10074.75,
            cpp11: 10074.75,
            cpp12: 7953.75
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 3128.41,
            cpp2: 5113.74,
            cpp3: 6617.78,
            cpp4: 6738.11,
            cpp5: 5595.03,
            cpp6: 4873.09,
            cpp7: 3910.51,
            cpp8: 4512.12,
            cpp9: 8723.44,
            cpp10: 9144.57,
            cpp11: 9144.57,
            cpp12: 7219.4
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 908.87,
            cpp2: 1485.66,
            cpp3: 1922.62,
            cpp4: 1957.57,
            cpp5: 1625.49,
            cpp6: 1415.75,
            cpp7: 1136.09,
            cpp8: 1310.88,
            cpp9: 2534.36,
            cpp10: 2656.71,
            cpp11: 2656.71,
            cpp12: 2097.4
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 1354.85,
            cpp2: 2214.66,
            cpp3: 2866.03,
            cpp4: 2918.14,
            cpp5: 2423.1,
            cpp6: 2110.44,
            cpp7: 1693.56,
            cpp8: 1954.11,
            cpp9: 3777.94,
            cpp10: 3960.33,
            cpp11: 3960.33,
            cpp12: 3126.57
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 777.01,
            cpp2: 1270.11,
            cpp3: 1643.67,
            cpp4: 1673.55,
            cpp5: 1389.65,
            cpp6: 1210.34,
            cpp7: 971.26,
            cpp8: 1120.68,
            cpp9: 2166.65,
            cpp10: 2271.25,
            cpp11: 2271.25,
            cpp12: 1793.09
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 1652.81,
            cpp2: 2701.72,
            cpp3: 3496.34,
            cpp4: 3559.91,
            cpp5: 2955.99,
            cpp6: 2574.58,
            cpp7: 2066.02,
            cpp8: 2383.87,
            cpp9: 4608.81,
            cpp10: 4831.3,
            cpp11: 4831.3,
            cpp12: 3814.19
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 2531.72,
            cpp2: 4138.38,
            cpp3: 5355.56,
            cpp4: 5452.93,
            cpp5: 4527.88,
            cpp6: 3943.64,
            cpp7: 3164.65,
            cpp8: 3651.52,
            cpp9: 7059.6,
            cpp10: 7400.4,
            cpp11: 7400.4,
            cpp12: 5842.42
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 343.81,
            cpp2: 561.99,
            cpp3: 727.28,
            cpp4: 740.51,
            cpp5: 614.88,
            cpp6: 535.54,
            cpp7: 429.76,
            cpp8: 495.87,
            cpp9: 958.69,
            cpp10: 1004.97,
            cpp11: 1004.97,
            cpp12: 793.4
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 349.9,
            cpp2: 571.96,
            cpp3: 740.18,
            cpp4: 753.64,
            cpp5: 625.79,
            cpp6: 545.04,
            cpp7: 437.38,
            cpp8: 504.67,
            cpp9: 975.69,
            cpp10: 1022.8,
            cpp11: 1022.8,
            cpp12: 807.47
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 1814.53,
            cpp2: 2966.06,
            cpp3: 3838.43,
            cpp4: 3908.22,
            cpp5: 3245.22,
            cpp6: 2826.48,
            cpp7: 2268.16,
            cpp8: 2617.11,
            cpp9: 5059.75,
            cpp10: 5304.01,
            cpp11: 5304.01,
            cpp12: 4187.38
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 684.55,
            cpp2: 1118.98,
            cpp3: 1448.1,
            cpp4: 1474.43,
            cpp5: 1224.3,
            cpp6: 1066.33,
            cpp7: 855.69,
            cpp8: 987.34,
            cpp9: 1908.86,
            cpp10: 2001.01,
            cpp11: 2001.01,
            cpp12: 1579.74
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'min',
            channelName: 'ТНТ 4',
            cpp1: 427.64,
            cpp2: 699.02,
            cpp3: 904.62,
            cpp4: 921.06,
            cpp5: 764.81,
            cpp6: 666.13,
            cpp7: 534.55,
            cpp8: 616.78,
            cpp9: 1192.45,
            cpp10: 1250.02,
            cpp11: 1250.02,
            cpp12: 986.85
        },
        {
            cityName: 'Нижний Новгород',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 317.49,
            cpp2: 518.97,
            cpp3: 671.61,
            cpp4: 683.82,
            cpp5: 567.81,
            cpp6: 494.55,
            cpp7: 396.86,
            cpp8: 457.91,
            cpp9: 885.3,
            cpp10: 928.04,
            cpp11: 928.04,
            cpp12: 732.66
        }
    ],
    Новосибирск: [
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 1819.82,
            cpp2: 2974.7,
            cpp3: 3849.62,
            cpp4: 3919.61,
            cpp5: 3254.68,
            cpp6: 2834.72,
            cpp7: 2274.77,
            cpp8: 2624.74,
            cpp9: 5074.5,
            cpp10: 5319.47,
            cpp11: 5319.47,
            cpp12: 4199.58
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 1165.6,
            cpp2: 1905.3,
            cpp3: 2465.68,
            cpp4: 2510.51,
            cpp5: 2084.62,
            cpp6: 1815.64,
            cpp7: 1456.99,
            cpp8: 1681.15,
            cpp9: 3250.22,
            cpp10: 3407.12,
            cpp11: 3407.12,
            cpp12: 2689.84
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 1268.99,
            cpp2: 2074.31,
            cpp3: 2684.4,
            cpp4: 2733.21,
            cpp5: 2269.54,
            cpp6: 1976.69,
            cpp7: 1586.24,
            cpp8: 1830.27,
            cpp9: 3538.53,
            cpp10: 3709.35,
            cpp11: 3709.35,
            cpp12: 2928.44
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 2471.48,
            cpp2: 4039.92,
            cpp3: 5228.13,
            cpp4: 5323.19,
            cpp5: 4420.15,
            cpp6: 3849.81,
            cpp7: 3089.35,
            cpp8: 3564.64,
            cpp9: 6891.63,
            cpp10: 7224.33,
            cpp11: 7224.33,
            cpp12: 5703.42
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 2301.18,
            cpp2: 3761.54,
            cpp3: 4867.87,
            cpp4: 4956.38,
            cpp5: 4115.56,
            cpp6: 3584.52,
            cpp7: 2876.47,
            cpp8: 3319,
            cpp9: 6416.74,
            cpp10: 6726.51,
            cpp11: 6726.51,
            cpp12: 5310.4
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 866.63,
            cpp2: 1416.61,
            cpp3: 1833.25,
            cpp4: 1866.59,
            cpp5: 1549.93,
            cpp6: 1349.94,
            cpp7: 1083.29,
            cpp8: 1249.95,
            cpp9: 2416.56,
            cpp10: 2533.22,
            cpp11: 2533.22,
            cpp12: 1999.91
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 988.41,
            cpp2: 1615.68,
            cpp3: 2090.88,
            cpp4: 2128.89,
            cpp5: 1767.74,
            cpp6: 1539.65,
            cpp7: 1235.52,
            cpp8: 1425.6,
            cpp9: 2756.16,
            cpp10: 2889.21,
            cpp11: 2889.21,
            cpp12: 2280.96
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 1166.43,
            cpp2: 1906.66,
            cpp3: 2467.45,
            cpp4: 2512.31,
            cpp5: 2086.12,
            cpp6: 1816.94,
            cpp7: 1458.04,
            cpp8: 1682.35,
            cpp9: 3252.55,
            cpp10: 3409.56,
            cpp11: 3409.56,
            cpp12: 2691.76
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 1065.8,
            cpp2: 1742.18,
            cpp3: 2254.58,
            cpp4: 2295.57,
            cpp5: 1906.15,
            cpp6: 1660.19,
            cpp7: 1332.25,
            cpp8: 1537.21,
            cpp9: 2971.95,
            cpp10: 3115.42,
            cpp11: 3115.42,
            cpp12: 2459.54
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 2404.51,
            cpp2: 3930.44,
            cpp3: 5086.46,
            cpp4: 5178.94,
            cpp5: 4300.37,
            cpp6: 3745.48,
            cpp7: 3005.63,
            cpp8: 3468.04,
            cpp9: 6704.87,
            cpp10: 7028.56,
            cpp11: 7028.56,
            cpp12: 5548.86
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 503.44,
            cpp2: 822.93,
            cpp3: 1064.97,
            cpp4: 1084.34,
            cpp5: 900.39,
            cpp6: 784.21,
            cpp7: 629.3,
            cpp8: 726.12,
            cpp9: 1403.83,
            cpp10: 1471.6,
            cpp11: 1471.6,
            cpp12: 1161.79
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 391.21,
            cpp2: 639.47,
            cpp3: 827.55,
            cpp4: 842.6,
            cpp5: 699.66,
            cpp6: 609.38,
            cpp7: 489.01,
            cpp8: 564.24,
            cpp9: 1090.86,
            cpp10: 1143.52,
            cpp11: 1143.52,
            cpp12: 902.78
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 807.28,
            cpp2: 1319.59,
            cpp3: 1707.7,
            cpp4: 1738.75,
            cpp5: 1443.79,
            cpp6: 1257.49,
            cpp7: 1009.1,
            cpp8: 1164.34,
            cpp9: 2251.06,
            cpp10: 2359.74,
            cpp11: 2359.74,
            cpp12: 1862.95
        },
        {
            cityName: 'Новосибирск',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 896.06,
            cpp2: 1464.72,
            cpp3: 1895.52,
            cpp4: 1929.98,
            cpp5: 1602.57,
            cpp6: 1395.79,
            cpp7: 1120.08,
            cpp8: 1292.4,
            cpp9: 2498.64,
            cpp10: 2619.26,
            cpp11: 2619.26,
            cpp12: 2067.84
        },
        {
            cityName: 'Новосибирск',
            priceType: 'min',
            channelName: 'ТНТ 4',
            cpp1: 316.93,
            cpp2: 518.06,
            cpp3: 670.43,
            cpp4: 682.62,
            cpp5: 566.82,
            cpp6: 493.68,
            cpp7: 396.16,
            cpp8: 457.11,
            cpp9: 883.75,
            cpp10: 926.41,
            cpp11: 926.41,
            cpp12: 731.38
        },
        {
            cityName: 'Новосибирск',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 431.02,
            cpp2: 704.55,
            cpp3: 911.78,
            cpp4: 928.35,
            cpp5: 770.87,
            cpp6: 671.4,
            cpp7: 538.78,
            cpp8: 621.67,
            cpp9: 1201.89,
            cpp10: 1259.91,
            cpp11: 1259.91,
            cpp12: 994.67
        }
    ],
    Омск: [
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 826.68,
            cpp2: 1351.31,
            cpp3: 1748.75,
            cpp4: 1780.55,
            cpp5: 1478.49,
            cpp6: 1287.72,
            cpp7: 1033.35,
            cpp8: 1192.33,
            cpp9: 2305.17,
            cpp10: 2416.46,
            cpp11: 2416.46,
            cpp12: 1907.73
        },
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 472.07,
            cpp2: 771.65,
            cpp3: 998.61,
            cpp4: 1016.77,
            cpp5: 844.28,
            cpp6: 735.34,
            cpp7: 590.09,
            cpp8: 680.87,
            cpp9: 1316.35,
            cpp10: 1379.9,
            cpp11: 1379.9,
            cpp12: 1089.39
        },
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 493.54,
            cpp2: 806.74,
            cpp3: 1044.02,
            cpp4: 1063,
            cpp5: 882.67,
            cpp6: 768.78,
            cpp7: 616.92,
            cpp8: 711.83,
            cpp9: 1376.21,
            cpp10: 1442.64,
            cpp11: 1442.64,
            cpp12: 1138.93
        },
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 1328.1,
            cpp2: 2170.94,
            cpp3: 2809.45,
            cpp4: 2860.53,
            cpp5: 2375.26,
            cpp6: 2068.77,
            cpp7: 1660.13,
            cpp8: 1915.53,
            cpp9: 3703.36,
            cpp10: 3882.14,
            cpp11: 3882.14,
            cpp12: 3064.85
        },
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1408.38,
            cpp2: 2302.16,
            cpp3: 2979.26,
            cpp4: 3033.43,
            cpp5: 2518.83,
            cpp6: 2193.82,
            cpp7: 1760.47,
            cpp8: 2031.32,
            cpp9: 3927.21,
            cpp10: 4116.8,
            cpp11: 4116.8,
            cpp12: 3250.11
        },
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 415.23,
            cpp2: 678.73,
            cpp3: 878.36,
            cpp4: 894.33,
            cpp5: 742.61,
            cpp6: 646.79,
            cpp7: 519.03,
            cpp8: 598.88,
            cpp9: 1157.84,
            cpp10: 1213.74,
            cpp11: 1213.74,
            cpp12: 958.21
        },
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 591.64,
            cpp2: 967.1,
            cpp3: 1251.54,
            cpp4: 1274.3,
            cpp5: 1058.12,
            cpp6: 921.59,
            cpp7: 739.55,
            cpp8: 853.32,
            cpp9: 1649.76,
            cpp10: 1729.4,
            cpp11: 1729.4,
            cpp12: 1365.32
        },
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 616.98,
            cpp2: 1008.52,
            cpp3: 1305.14,
            cpp4: 1328.87,
            cpp5: 1103.44,
            cpp6: 961.06,
            cpp7: 771.22,
            cpp8: 889.87,
            cpp9: 1720.41,
            cpp10: 1803.47,
            cpp11: 1803.47,
            cpp12: 1423.79
        },
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 659.7,
            cpp2: 1078.35,
            cpp3: 1395.52,
            cpp4: 1420.89,
            cpp5: 1179.85,
            cpp6: 1027.61,
            cpp7: 824.62,
            cpp8: 951.49,
            cpp9: 1839.54,
            cpp10: 1928.35,
            cpp11: 1928.35,
            cpp12: 1522.38
        },
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 149.15,
            cpp2: 243.8,
            cpp3: 315.5,
            cpp4: 321.24,
            cpp5: 266.74,
            cpp6: 232.32,
            cpp7: 186.43,
            cpp8: 215.12,
            cpp9: 415.89,
            cpp10: 435.97,
            cpp11: 435.97,
            cpp12: 344.18
        },
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 195.11,
            cpp2: 318.94,
            cpp3: 412.74,
            cpp4: 420.25,
            cpp5: 348.95,
            cpp6: 303.93,
            cpp7: 243.89,
            cpp8: 0,
            cpp9: 0,
            cpp10: 0,
            cpp11: 0,
            cpp12: 0
        },
        {
            cityName: 'Омск',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 388.32,
            cpp2: 634.76,
            cpp3: 821.46,
            cpp4: 836.39,
            cpp5: 694.5,
            cpp6: 604.89,
            cpp7: 485.41,
            cpp8: 560.08,
            cpp9: 1082.83,
            cpp10: 1135.1,
            cpp11: 1135.1,
            cpp12: 896.13
        },
        {
            cityName: 'Омск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 749.26,
            cpp2: 1224.75,
            cpp3: 1584.97,
            cpp4: 1613.79,
            cpp5: 1340.02,
            cpp6: 1167.12,
            cpp7: 936.58,
            cpp8: 1080.66,
            cpp9: 2089.28,
            cpp10: 2190.15,
            cpp11: 2190.15,
            cpp12: 1729.06
        }
    ],
    Пермь: [
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 1098.12,
            cpp2: 1795,
            cpp3: 2322.95,
            cpp4: 2365.18,
            cpp5: 1963.95,
            cpp6: 1710.53,
            cpp7: 1372.65,
            cpp8: 1583.83,
            cpp9: 3062.07,
            cpp10: 3209.89,
            cpp11: 3209.89,
            cpp12: 2534.12
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 667.19,
            cpp2: 1090.6,
            cpp3: 1411.36,
            cpp4: 1437.02,
            cpp5: 1193.24,
            cpp6: 1039.28,
            cpp7: 833.99,
            cpp8: 962.29,
            cpp9: 1860.43,
            cpp10: 1950.25,
            cpp11: 1950.25,
            cpp12: 1539.67
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 770.26,
            cpp2: 1259.08,
            cpp3: 1629.4,
            cpp4: 1659.02,
            cpp5: 1377.58,
            cpp6: 1199.83,
            cpp7: 962.83,
            cpp8: 1110.95,
            cpp9: 2147.84,
            cpp10: 2251.53,
            cpp11: 2251.53,
            cpp12: 1777.53
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 1670.6,
            cpp2: 2730.79,
            cpp3: 3533.97,
            cpp4: 3598.22,
            cpp5: 2987.81,
            cpp6: 2602.29,
            cpp7: 2088.25,
            cpp8: 2409.52,
            cpp9: 4658.41,
            cpp10: 4883.3,
            cpp11: 4883.3,
            cpp12: 3855.24
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1740.74,
            cpp2: 2845.44,
            cpp3: 3682.34,
            cpp4: 3749.29,
            cpp5: 3113.25,
            cpp6: 2711.54,
            cpp7: 2175.93,
            cpp8: 2510.69,
            cpp9: 4853.99,
            cpp10: 5088.32,
            cpp11: 5088.32,
            cpp12: 4017.1
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 734.77,
            cpp2: 1201.06,
            cpp3: 1554.32,
            cpp4: 1582.58,
            cpp5: 1314.11,
            cpp6: 1144.54,
            cpp7: 918.46,
            cpp8: 1059.76,
            cpp9: 2048.87,
            cpp10: 2147.79,
            cpp11: 2147.79,
            cpp12: 1695.62
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 716.75,
            cpp2: 1171.61,
            cpp3: 1516.21,
            cpp4: 1543.77,
            cpp5: 1281.88,
            cpp6: 1116.48,
            cpp7: 895.94,
            cpp8: 1033.78,
            cpp9: 1998.63,
            cpp10: 2095.12,
            cpp11: 2095.12,
            cpp12: 1654.04
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 752.32,
            cpp2: 1229.75,
            cpp3: 1591.44,
            cpp4: 1620.38,
            cpp5: 1345.49,
            cpp6: 1171.88,
            cpp7: 940.4,
            cpp8: 1085.08,
            cpp9: 2097.81,
            cpp10: 2199.09,
            cpp11: 2199.09,
            cpp12: 1736.12
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 780.74,
            cpp2: 1276.22,
            cpp3: 1651.57,
            cpp4: 1681.6,
            cpp5: 1396.33,
            cpp6: 1216.16,
            cpp7: 975.93,
            cpp8: 1126.07,
            cpp9: 2177.08,
            cpp10: 2282.18,
            cpp11: 2282.18,
            cpp12: 1801.72
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 640.23,
            cpp2: 1046.53,
            cpp3: 1354.34,
            cpp4: 1378.96,
            cpp5: 1145.03,
            cpp6: 997.28,
            cpp7: 800.29,
            cpp8: 923.41,
            cpp9: 1785.26,
            cpp10: 1871.45,
            cpp11: 1871.45,
            cpp12: 1477.46
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 334.61,
            cpp2: 546.96,
            cpp3: 707.83,
            cpp4: 720.7,
            cpp5: 598.44,
            cpp6: 521.22,
            cpp7: 418.27,
            cpp8: 482.61,
            cpp9: 933.05,
            cpp10: 978.1,
            cpp11: 978.1,
            cpp12: 772.18
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 201.3,
            cpp2: 329.05,
            cpp3: 425.83,
            cpp4: 433.57,
            cpp5: 360.02,
            cpp6: 313.56,
            cpp7: 251.63,
            cpp8: 290.34,
            cpp9: 561.32,
            cpp10: 588.42,
            cpp11: 588.42,
            cpp12: 464.54
        },
        {
            cityName: 'Пермь',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 678.26,
            cpp2: 1108.69,
            cpp3: 1434.78,
            cpp4: 1460.87,
            cpp5: 1213.04,
            cpp6: 1056.52,
            cpp7: 847.82,
            cpp8: 978.26,
            cpp9: 1891.3,
            cpp10: 1982.6,
            cpp11: 1982.6,
            cpp12: 1565.21
        },
        {
            cityName: 'Пермь',
            priceType: 'min',
            channelName: 'ТНТ 4',
            cpp1: 183.19,
            cpp2: 299.45,
            cpp3: 387.53,
            cpp4: 394.57,
            cpp5: 327.64,
            cpp6: 285.36,
            cpp7: 228.99,
            cpp8: 264.22,
            cpp9: 510.83,
            cpp10: 535.49,
            cpp11: 535.49,
            cpp12: 422.76
        },
        {
            cityName: 'Пермь',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 344.84,
            cpp2: 563.67,
            cpp3: 729.46,
            cpp4: 742.72,
            cpp5: 616.73,
            cpp6: 537.15,
            cpp7: 431.05,
            cpp8: 497.36,
            cpp9: 961.56,
            cpp10: 1007.98,
            cpp11: 1007.98,
            cpp12: 795.78
        }
    ],
    'Ростов-на-Дону': [
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 1490.29,
            cpp2: 2436.05,
            cpp3: 3152.54,
            cpp4: 3209.86,
            cpp5: 2665.33,
            cpp6: 2321.42,
            cpp7: 1862.86,
            cpp8: 2149.46,
            cpp9: 4155.62,
            cpp10: 4356.24,
            cpp11: 4356.24,
            cpp12: 3439.14
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 865.12,
            cpp2: 1414.13,
            cpp3: 1830.05,
            cpp4: 1863.33,
            cpp5: 1547.23,
            cpp6: 1347.59,
            cpp7: 1081.4,
            cpp8: 1247.76,
            cpp9: 2412.34,
            cpp10: 2528.8,
            cpp11: 2528.8,
            cpp12: 1996.42
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 667.99,
            cpp2: 1091.9,
            cpp3: 1413.05,
            cpp4: 1438.74,
            cpp5: 1194.67,
            cpp6: 1040.52,
            cpp7: 834.98,
            cpp8: 963.44,
            cpp9: 1862.65,
            cpp10: 1952.58,
            cpp11: 1952.58,
            cpp12: 1541.51
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 1551.45,
            cpp2: 2536.02,
            cpp3: 3281.9,
            cpp4: 3341.58,
            cpp5: 2774.7,
            cpp6: 2416.68,
            cpp7: 1939.31,
            cpp8: 2237.66,
            cpp9: 4326.15,
            cpp10: 4535,
            cpp11: 4535,
            cpp12: 3580.26
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1491.95,
            cpp2: 2438.76,
            cpp3: 3156.04,
            cpp4: 3213.43,
            cpp5: 2668.29,
            cpp6: 2323.99,
            cpp7: 1864.93,
            cpp8: 2151.85,
            cpp9: 4160.24,
            cpp10: 4361.08,
            cpp11: 4361.08,
            cpp12: 3442.96
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 624.32,
            cpp2: 1020.52,
            cpp3: 1320.67,
            cpp4: 1344.68,
            cpp5: 1116.57,
            cpp6: 972.49,
            cpp7: 780.4,
            cpp8: 900.46,
            cpp9: 1740.88,
            cpp10: 1824.92,
            cpp11: 1824.92,
            cpp12: 1440.73
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 550.59,
            cpp2: 900.01,
            cpp3: 1164.72,
            cpp4: 1185.9,
            cpp5: 984.72,
            cpp6: 857.66,
            cpp7: 688.24,
            cpp8: 794.13,
            cpp9: 1535.31,
            cpp10: 1609.43,
            cpp11: 1609.43,
            cpp12: 1270.6
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 527.43,
            cpp2: 862.14,
            cpp3: 1115.71,
            cpp4: 1135.99,
            cpp5: 943.28,
            cpp6: 821.57,
            cpp7: 659.28,
            cpp8: 760.71,
            cpp9: 1470.71,
            cpp10: 1541.71,
            cpp11: 1541.71,
            cpp12: 1217.14
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 713.08,
            cpp2: 1165.62,
            cpp3: 1508.44,
            cpp4: 1535.87,
            cpp5: 1275.32,
            cpp6: 1110.76,
            cpp7: 891.35,
            cpp8: 1028.48,
            cpp9: 1988.4,
            cpp10: 2084.39,
            cpp11: 2084.39,
            cpp12: 1645.57
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 1156.12,
            cpp2: 1889.82,
            cpp3: 2445.65,
            cpp4: 2490.11,
            cpp5: 2067.68,
            cpp6: 1800.89,
            cpp7: 1445.16,
            cpp8: 1667.49,
            cpp9: 3223.81,
            cpp10: 3379.44,
            cpp11: 3379.44,
            cpp12: 2667.98
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 278.74,
            cpp2: 455.64,
            cpp3: 589.65,
            cpp4: 600.37,
            cpp5: 498.52,
            cpp6: 434.2,
            cpp7: 348.43,
            cpp8: 402.03,
            cpp9: 777.26,
            cpp10: 814.79,
            cpp11: 814.79,
            cpp12: 643.25
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 202.97,
            cpp2: 331.78,
            cpp3: 429.37,
            cpp4: 437.17,
            cpp5: 363.01,
            cpp6: 316.17,
            cpp7: 253.72,
            cpp8: 292.75,
            cpp9: 565.98,
            cpp10: 593.31,
            cpp11: 593.31,
            cpp12: 468.4
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 932.53,
            cpp2: 1524.32,
            cpp3: 1972.65,
            cpp4: 2008.52,
            cpp5: 1667.79,
            cpp6: 1452.59,
            cpp7: 1165.66,
            cpp8: 1344.99,
            cpp9: 2600.32,
            cpp10: 2725.85,
            cpp11: 2725.85,
            cpp12: 2151.99
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 565.89,
            cpp2: 925.01,
            cpp3: 1197.07,
            cpp4: 1218.84,
            cpp5: 1012.07,
            cpp6: 881.48,
            cpp7: 707.36,
            cpp8: 816.18,
            cpp9: 1577.96,
            cpp10: 1654.13,
            cpp11: 1654.13,
            cpp12: 1305.9
        },
        {
            cityName: 'Ростов-на-Дону',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 286.49,
            cpp2: 468.29,
            cpp3: 606.03,
            cpp4: 617.05,
            cpp5: 512.37,
            cpp6: 446.26,
            cpp7: 358.11,
            cpp8: 413.2,
            cpp9: 798.85,
            cpp10: 837.42,
            cpp11: 837.42,
            cpp12: 661.12
        }
    ],
    Самара: [
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 1683.28,
            cpp2: 2751.51,
            cpp3: 3560.78,
            cpp4: 3625.52,
            cpp5: 3010.48,
            cpp6: 2622.03,
            cpp7: 2104.1,
            cpp8: 2427.8,
            cpp9: 4693.75,
            cpp10: 4920.35,
            cpp11: 4920.35,
            cpp12: 3884.49
        },
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 1043.46,
            cpp2: 1705.66,
            cpp3: 2207.32,
            cpp4: 2247.45,
            cpp5: 1866.19,
            cpp6: 1625.39,
            cpp7: 1304.33,
            cpp8: 1504.99,
            cpp9: 2909.65,
            cpp10: 3050.12,
            cpp11: 3050.12,
            cpp12: 2407.99
        },
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 898.16,
            cpp2: 1468.15,
            cpp3: 1899.95,
            cpp4: 1934.5,
            cpp5: 1606.32,
            cpp6: 1399.06,
            cpp7: 1122.7,
            cpp8: 1295.42,
            cpp9: 2504.48,
            cpp10: 2625.39,
            cpp11: 2625.39,
            cpp12: 2072.68
        },
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 2908.58,
            cpp2: 4754.4,
            cpp3: 6152.76,
            cpp4: 6264.63,
            cpp5: 5201.88,
            cpp6: 4530.67,
            cpp7: 3635.72,
            cpp8: 4195.06,
            cpp9: 8110.45,
            cpp10: 8501.99,
            cpp11: 8501.99,
            cpp12: 6712.1
        },
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 2667.45,
            cpp2: 4360.25,
            cpp3: 5642.68,
            cpp4: 5745.27,
            cpp5: 4770.63,
            cpp6: 4155.06,
            cpp7: 3334.31,
            cpp8: 3847.28,
            cpp9: 7438.08,
            cpp10: 7797.16,
            cpp11: 7797.16,
            cpp12: 6155.65
        },
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 1035.54,
            cpp2: 1692.71,
            cpp3: 2190.57,
            cpp4: 2230.4,
            cpp5: 1852.03,
            cpp6: 1613.06,
            cpp7: 1294.43,
            cpp8: 1493.57,
            cpp9: 2887.57,
            cpp10: 3026.97,
            cpp11: 3026.97,
            cpp12: 2389.71
        },
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 1100.95,
            cpp2: 1799.62,
            cpp3: 2328.92,
            cpp4: 2371.27,
            cpp5: 1969,
            cpp6: 1714.94,
            cpp7: 1376.18,
            cpp8: 1587.9,
            cpp9: 3069.95,
            cpp10: 3218.15,
            cpp11: 3218.15,
            cpp12: 2540.64
        },
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 938.92,
            cpp2: 1534.78,
            cpp3: 1986.18,
            cpp4: 2022.29,
            cpp5: 1679.23,
            cpp6: 1462.55,
            cpp7: 1173.65,
            cpp8: 1354.21,
            cpp9: 2618.15,
            cpp10: 2744.54,
            cpp11: 2744.54,
            cpp12: 2166.74
        },
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 1143.31,
            cpp2: 1868.87,
            cpp3: 2418.54,
            cpp4: 2462.52,
            cpp5: 2044.77,
            cpp6: 1780.93,
            cpp7: 1429.14,
            cpp8: 1649.01,
            cpp9: 3188.08,
            cpp10: 3341.99,
            cpp11: 3341.99,
            cpp12: 2638.41
        },
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 449.26,
            cpp2: 734.37,
            cpp3: 950.36,
            cpp4: 967.64,
            cpp5: 803.49,
            cpp6: 699.81,
            cpp7: 561.58,
            cpp8: 647.97,
            cpp9: 1252.75,
            cpp10: 1313.23,
            cpp11: 1313.23,
            cpp12: 1036.76
        },
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 458.72,
            cpp2: 749.83,
            cpp3: 970.37,
            cpp4: 988.02,
            cpp5: 820.41,
            cpp6: 714.55,
            cpp7: 573.4,
            cpp8: 0,
            cpp9: 0,
            cpp10: 0,
            cpp11: 0,
            cpp12: 0
        },
        {
            cityName: 'Самара',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 819.57,
            cpp2: 1339.69,
            cpp3: 1733.71,
            cpp4: 1765.23,
            cpp5: 1465.78,
            cpp6: 1276.64,
            cpp7: 1024.47,
            cpp8: 1182.08,
            cpp9: 2285.35,
            cpp10: 2395.68,
            cpp11: 2395.68,
            cpp12: 1891.32
        },
        {
            cityName: 'Самара',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1514.12,
            cpp2: 2475,
            cpp3: 3202.95,
            cpp4: 3261.18,
            cpp5: 2707.95,
            cpp6: 2358.53,
            cpp7: 1892.65,
            cpp8: 2183.83,
            cpp9: 4222.07,
            cpp10: 4425.89,
            cpp11: 4425.89,
            cpp12: 3494.12
        }
    ],
    Саратов: [
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 548.17,
            cpp2: 896.06,
            cpp3: 1159.6,
            cpp4: 1180.68,
            cpp5: 980.39,
            cpp6: 853.89,
            cpp7: 685.22,
            cpp8: 790.64,
            cpp9: 1528.56,
            cpp10: 1602.36,
            cpp11: 1602.36,
            cpp12: 1265.02
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 401.26,
            cpp2: 655.9,
            cpp3: 848.81,
            cpp4: 864.25,
            cpp5: 717.63,
            cpp6: 625.03,
            cpp7: 501.57,
            cpp8: 578.74,
            cpp9: 1118.89,
            cpp10: 1172.9,
            cpp11: 1172.9,
            cpp12: 925.98
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 420.73,
            cpp2: 687.74,
            cpp3: 890.01,
            cpp4: 906.19,
            cpp5: 752.46,
            cpp6: 655.37,
            cpp7: 525.92,
            cpp8: 606.83,
            cpp9: 1173.2,
            cpp10: 1229.83,
            cpp11: 1229.83,
            cpp12: 970.92
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 880.68,
            cpp2: 1439.58,
            cpp3: 1862.98,
            cpp4: 1896.86,
            cpp5: 1575.07,
            cpp6: 1371.83,
            cpp7: 1100.85,
            cpp8: 1270.22,
            cpp9: 2455.75,
            cpp10: 2574.31,
            cpp11: 2574.31,
            cpp12: 2032.35
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 851.27,
            cpp2: 1391.49,
            cpp3: 1800.76,
            cpp4: 1833.5,
            cpp5: 1522.46,
            cpp6: 1326.01,
            cpp7: 1064.08,
            cpp8: 1227.79,
            cpp9: 2373.73,
            cpp10: 2488.32,
            cpp11: 2488.32,
            cpp12: 1964.46
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 323.98,
            cpp2: 529.58,
            cpp3: 685.34,
            cpp4: 697.8,
            cpp5: 579.42,
            cpp6: 504.66,
            cpp7: 404.97,
            cpp8: 467.28,
            cpp9: 903.4,
            cpp10: 947.01,
            cpp11: 947.01,
            cpp12: 747.64
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 502.84,
            cpp2: 821.96,
            cpp3: 1063.71,
            cpp4: 1083.05,
            cpp5: 899.32,
            cpp6: 783.28,
            cpp7: 628.55,
            cpp8: 725.25,
            cpp9: 1402.16,
            cpp10: 1469.85,
            cpp11: 1469.85,
            cpp12: 1160.41
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 308.17,
            cpp2: 503.74,
            cpp3: 651.9,
            cpp4: 663.75,
            cpp5: 551.15,
            cpp6: 480.03,
            cpp7: 385.21,
            cpp8: 444.47,
            cpp9: 859.32,
            cpp10: 900.8,
            cpp11: 900.8,
            cpp12: 711.16
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 490.45,
            cpp2: 801.7,
            cpp3: 1037.5,
            cpp4: 1056.36,
            cpp5: 877.16,
            cpp6: 763.97,
            cpp7: 613.07,
            cpp8: 707.38,
            cpp9: 1367.61,
            cpp10: 1433.63,
            cpp11: 1433.63,
            cpp12: 1131.81
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 548.75,
            cpp2: 897,
            cpp3: 1160.82,
            cpp4: 1181.93,
            cpp5: 981.42,
            cpp6: 854.79,
            cpp7: 685.94,
            cpp8: 791.47,
            cpp9: 1530.17,
            cpp10: 1604.04,
            cpp11: 1604.04,
            cpp12: 1266.35
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 188.96,
            cpp2: 308.88,
            cpp3: 399.73,
            cpp4: 406.99,
            cpp5: 337.95,
            cpp6: 294.34,
            cpp7: 236.2,
            cpp8: 272.54,
            cpp9: 526.91,
            cpp10: 552.35,
            cpp11: 552.35,
            cpp12: 436.06
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 500.05,
            cpp2: 817.39,
            cpp3: 1057.8,
            cpp4: 1077.03,
            cpp5: 894.32,
            cpp6: 778.93,
            cpp7: 625.06,
            cpp8: 721.23,
            cpp9: 1394.37,
            cpp10: 1461.69,
            cpp11: 1461.69,
            cpp12: 1153.96
        },
        {
            cityName: 'Саратов',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 361.44,
            cpp2: 590.82,
            cpp3: 764.58,
            cpp4: 778.49,
            cpp5: 646.42,
            cpp6: 563.01,
            cpp7: 451.8,
            cpp8: 521.31,
            cpp9: 1007.86,
            cpp10: 1056.52,
            cpp11: 1056.52,
            cpp12: 834.09
        },
        {
            cityName: 'Саратов',
            priceType: 'min',
            channelName: 'ТНТ 4',
            cpp1: 241.27,
            cpp2: 394.39,
            cpp3: 510.39,
            cpp4: 519.67,
            cpp5: 431.51,
            cpp6: 375.83,
            cpp7: 301.59,
            cpp8: 347.99,
            cpp9: 672.78,
            cpp10: 705.26,
            cpp11: 705.26,
            cpp12: 556.79
        },
        {
            cityName: 'Саратов',
            priceType: 'min',
            channelName: 'Суббота',
            cpp1: 368.17,
            cpp2: 601.81,
            cpp3: 778.82,
            cpp4: 792.98,
            cpp5: 658.46,
            cpp6: 573.49,
            cpp7: 460.21,
            cpp8: 531.01,
            cpp9: 1026.63,
            cpp10: 1076.19,
            cpp11: 1076.19,
            cpp12: 849.62
        },
        {
            cityName: 'Саратов',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 165.11,
            cpp2: 269.89,
            cpp3: 349.27,
            cpp4: 355.62,
            cpp5: 295.29,
            cpp6: 257.19,
            cpp7: 206.38,
            cpp8: 238.14,
            cpp9: 460.4,
            cpp10: 482.62,
            cpp11: 482.62,
            cpp12: 381.02
        }
    ],
    Ставрополь: [
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 562.83,
            cpp2: 920.02,
            cpp3: 1190.61,
            cpp4: 1212.26,
            cpp5: 1006.61,
            cpp6: 876.72,
            cpp7: 703.54,
            cpp8: 811.78,
            cpp9: 1569.44,
            cpp10: 1645.21,
            cpp11: 1645.21,
            cpp12: 1298.85
        },
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 372.48,
            cpp2: 608.87,
            cpp3: 787.95,
            cpp4: 802.27,
            cpp5: 666.17,
            cpp6: 580.21,
            cpp7: 465.6,
            cpp8: 537.24,
            cpp9: 1038.66,
            cpp10: 1088.8,
            cpp11: 1088.8,
            cpp12: 859.58
        },
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 443.94,
            cpp2: 725.67,
            cpp3: 939.1,
            cpp4: 956.17,
            cpp5: 793.96,
            cpp6: 691.52,
            cpp7: 554.92,
            cpp8: 640.29,
            cpp9: 1237.9,
            cpp10: 1297.66,
            cpp11: 1297.66,
            cpp12: 1024.47
        },
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 563.71,
            cpp2: 921.44,
            cpp3: 1192.46,
            cpp4: 1214.14,
            cpp5: 1008.17,
            cpp6: 878.08,
            cpp7: 704.63,
            cpp8: 813.04,
            cpp9: 1571.88,
            cpp10: 1647.76,
            cpp11: 1647.76,
            cpp12: 1300.86
        },
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 490.57,
            cpp2: 801.89,
            cpp3: 1037.74,
            cpp4: 1056.61,
            cpp5: 877.36,
            cpp6: 764.15,
            cpp7: 613.21,
            cpp8: 707.55,
            cpp9: 1367.93,
            cpp10: 1433.97,
            cpp11: 1433.97,
            cpp12: 1132.08
        },
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 233.05,
            cpp2: 380.95,
            cpp3: 492.99,
            cpp4: 501.96,
            cpp5: 416.8,
            cpp6: 363.02,
            cpp7: 291.31,
            cpp8: 336.13,
            cpp9: 649.85,
            cpp10: 681.23,
            cpp11: 681.23,
            cpp12: 537.81
        },
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 361.02,
            cpp2: 590.13,
            cpp3: 763.7,
            cpp4: 777.58,
            cpp5: 645.67,
            cpp6: 562.36,
            cpp7: 451.27,
            cpp8: 520.7,
            cpp9: 1006.69,
            cpp10: 1055.29,
            cpp11: 1055.29,
            cpp12: 833.12
        },
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 184.27,
            cpp2: 301.2,
            cpp3: 389.79,
            cpp4: 396.88,
            cpp5: 329.55,
            cpp6: 287.03,
            cpp7: 230.33,
            cpp8: 265.77,
            cpp9: 513.82,
            cpp10: 538.62,
            cpp11: 538.62,
            cpp12: 425.23
        },
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 420.07,
            cpp2: 686.65,
            cpp3: 888.61,
            cpp4: 904.77,
            cpp5: 751.28,
            cpp6: 654.34,
            cpp7: 525.09,
            cpp8: 605.87,
            cpp9: 1171.35,
            cpp10: 1227.9,
            cpp11: 1227.9,
            cpp12: 969.39
        },
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 416.75,
            cpp2: 681.22,
            cpp3: 881.58,
            cpp4: 897.61,
            cpp5: 745.34,
            cpp6: 649.17,
            cpp7: 520.94,
            cpp8: 601.08,
            cpp9: 1162.09,
            cpp10: 1218.19,
            cpp11: 1218.19,
            cpp12: 961.73
        },
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 426.13,
            cpp2: 696.55,
            cpp3: 901.42,
            cpp4: 917.81,
            cpp5: 762.11,
            cpp6: 663.77,
            cpp7: 532.66,
            cpp8: 614.61,
            cpp9: 1188.24,
            cpp10: 1245.6,
            cpp11: 1245.6,
            cpp12: 983.37
        },
        {
            cityName: 'Ставрополь',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 427.5,
            cpp2: 698.79,
            cpp3: 904.32,
            cpp4: 920.76,
            cpp5: 764.56,
            cpp6: 665.91,
            cpp7: 534.37,
            cpp8: 616.58,
            cpp9: 1192.05,
            cpp10: 1249.6,
            cpp11: 1249.6,
            cpp12: 986.53
        }
    ],
    Тверь: [
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 499.77,
            cpp2: 816.94,
            cpp3: 1057.21,
            cpp4: 1076.43,
            cpp5: 893.82,
            cpp6: 778.49,
            cpp7: 624.72,
            cpp8: 720.83,
            cpp9: 1393.6,
            cpp10: 1460.87,
            cpp11: 1460.87,
            cpp12: 1153.32
        },
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 351.87,
            cpp2: 575.18,
            cpp3: 744.35,
            cpp4: 757.88,
            cpp5: 629.31,
            cpp6: 548.11,
            cpp7: 439.84,
            cpp8: 507.51,
            cpp9: 981.18,
            cpp10: 1028.55,
            cpp11: 1028.55,
            cpp12: 812.01
        },
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 377.14,
            cpp2: 616.48,
            cpp3: 797.8,
            cpp4: 812.31,
            cpp5: 674.5,
            cpp6: 587.47,
            cpp7: 471.43,
            cpp8: 543.95,
            cpp9: 1051.65,
            cpp10: 1102.41,
            cpp11: 1102.41,
            cpp12: 870.33
        },
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 571.01,
            cpp2: 933.38,
            cpp3: 1207.9,
            cpp4: 1229.86,
            cpp5: 1021.23,
            cpp6: 889.46,
            cpp7: 713.76,
            cpp8: 823.57,
            cpp9: 1592.24,
            cpp10: 1669.1,
            cpp11: 1669.1,
            cpp12: 1317.71
        },
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 584.07,
            cpp2: 954.73,
            cpp3: 1235.54,
            cpp4: 1258,
            cpp5: 1044.59,
            cpp6: 909.8,
            cpp7: 730.09,
            cpp8: 842.41,
            cpp9: 1628.66,
            cpp10: 1707.29,
            cpp11: 1707.29,
            cpp12: 1347.86
        },
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 207.41,
            cpp2: 339.03,
            cpp3: 438.75,
            cpp4: 446.73,
            cpp5: 370.94,
            cpp6: 323.08,
            cpp7: 259.26,
            cpp8: 299.15,
            cpp9: 578.35,
            cpp10: 606.27,
            cpp11: 606.27,
            cpp12: 478.63
        },
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 202.88,
            cpp2: 331.63,
            cpp3: 429.17,
            cpp4: 436.98,
            cpp5: 362.85,
            cpp6: 316.03,
            cpp7: 253.6,
            cpp8: 292.62,
            cpp9: 565.73,
            cpp10: 593.04,
            cpp11: 593.04,
            cpp12: 468.19
        },
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 192.87,
            cpp2: 315.26,
            cpp3: 407.99,
            cpp4: 415.4,
            cpp5: 344.93,
            cpp6: 300.43,
            cpp7: 241.08,
            cpp8: 278.17,
            cpp9: 537.8,
            cpp10: 563.76,
            cpp11: 563.76,
            cpp12: 445.07
        },
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 459.06,
            cpp2: 750.39,
            cpp3: 971.09,
            cpp4: 988.75,
            cpp5: 821.02,
            cpp6: 715.08,
            cpp7: 573.83,
            cpp8: 662.11,
            cpp9: 1280.08,
            cpp10: 1341.88,
            cpp11: 1341.88,
            cpp12: 1059.38
        },
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 471.27,
            cpp2: 770.34,
            cpp3: 996.91,
            cpp4: 1015.03,
            cpp5: 842.84,
            cpp6: 734.09,
            cpp7: 589.08,
            cpp8: 679.71,
            cpp9: 1314.11,
            cpp10: 1377.55,
            cpp11: 1377.55,
            cpp12: 1087.54
        },
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 132.56,
            cpp2: 216.68,
            cpp3: 280.41,
            cpp4: 285.5,
            cpp5: 237.07,
            cpp6: 206.48,
            cpp7: 165.69,
            cpp8: 191.19,
            cpp9: 369.63,
            cpp10: 387.47,
            cpp11: 387.47,
            cpp12: 305.9
        },
        {
            cityName: 'Тверь',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 340.67,
            cpp2: 556.87,
            cpp3: 720.65,
            cpp4: 733.76,
            cpp5: 609.28,
            cpp6: 530.66,
            cpp7: 425.84,
            cpp8: 491.35,
            cpp9: 949.95,
            cpp10: 995.81,
            cpp11: 995.81,
            cpp12: 786.17
        }
    ],
    Томск: [
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 423.07,
            cpp2: 691.55,
            cpp3: 894.95,
            cpp4: 911.22,
            cpp5: 756.64,
            cpp6: 659.01,
            cpp7: 528.83,
            cpp8: 610.19,
            cpp9: 1179.71,
            cpp10: 1236.66,
            cpp11: 1236.66,
            cpp12: 976.31
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 429.05,
            cpp2: 701.33,
            cpp3: 907.61,
            cpp4: 924.11,
            cpp5: 767.34,
            cpp6: 668.33,
            cpp7: 536.31,
            cpp8: 618.82,
            cpp9: 1196.39,
            cpp10: 1254.15,
            cpp11: 1254.15,
            cpp12: 990.12
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 399.75,
            cpp2: 653.44,
            cpp3: 845.63,
            cpp4: 861,
            cpp5: 714.94,
            cpp6: 622.69,
            cpp7: 499.69,
            cpp8: 576.56,
            cpp9: 1114.69,
            cpp10: 1168.5,
            cpp11: 1168.5,
            cpp12: 922.5
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 1062.84,
            cpp2: 1737.34,
            cpp3: 2248.32,
            cpp4: 2289.2,
            cpp5: 1900.85,
            cpp6: 1655.58,
            cpp7: 1328.55,
            cpp8: 1532.95,
            cpp9: 2963.7,
            cpp10: 3106.77,
            cpp11: 3106.77,
            cpp12: 2452.72
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1032.72,
            cpp2: 1688.09,
            cpp3: 2184.59,
            cpp4: 2224.31,
            cpp5: 1846.97,
            cpp6: 1608.65,
            cpp7: 1290.9,
            cpp8: 1489.49,
            cpp9: 2879.69,
            cpp10: 3018.71,
            cpp11: 3018.71,
            cpp12: 2383.19
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 221.91,
            cpp2: 362.73,
            cpp3: 469.42,
            cpp4: 477.95,
            cpp5: 396.87,
            cpp6: 345.66,
            cpp7: 277.38,
            cpp8: 320.06,
            cpp9: 618.78,
            cpp10: 648.65,
            cpp11: 648.65,
            cpp12: 512.09
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 277.02,
            cpp2: 452.83,
            cpp3: 586.01,
            cpp4: 596.67,
            cpp5: 495.45,
            cpp6: 431.52,
            cpp7: 346.28,
            cpp8: 399.55,
            cpp9: 772.47,
            cpp10: 809.76,
            cpp11: 809.76,
            cpp12: 639.29
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 301.88,
            cpp2: 493.47,
            cpp3: 638.6,
            cpp4: 650.21,
            cpp5: 539.91,
            cpp6: 470.24,
            cpp7: 377.36,
            cpp8: 435.41,
            cpp9: 841.79,
            cpp10: 882.43,
            cpp11: 882.43,
            cpp12: 696.66
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 272.09,
            cpp2: 444.77,
            cpp3: 575.58,
            cpp4: 586.05,
            cpp5: 486.63,
            cpp6: 423.84,
            cpp7: 340.12,
            cpp8: 392.44,
            cpp9: 758.72,
            cpp10: 795.35,
            cpp11: 795.35,
            cpp12: 627.91
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 389.74,
            cpp2: 637.07,
            cpp3: 824.45,
            cpp4: 839.44,
            cpp5: 697.03,
            cpp6: 607.09,
            cpp7: 487.17,
            cpp8: 562.12,
            cpp9: 1086.77,
            cpp10: 1139.23,
            cpp11: 1139.23,
            cpp12: 899.4
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 125.16,
            cpp2: 204.59,
            cpp3: 264.77,
            cpp4: 269.58,
            cpp5: 223.85,
            cpp6: 194.96,
            cpp7: 156.45,
            cpp8: 180.52,
            cpp9: 349.01,
            cpp10: 365.86,
            cpp11: 365.86,
            cpp12: 288.84
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 141.17,
            cpp2: 230.77,
            cpp3: 298.64,
            cpp4: 304.07,
            cpp5: 252.48,
            cpp6: 219.91,
            cpp7: 176.47,
            cpp8: 0,
            cpp9: 0,
            cpp10: 0,
            cpp11: 0,
            cpp12: 0
        },
        {
            cityName: 'Томск',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 359.29,
            cpp2: 587.3,
            cpp3: 760.04,
            cpp4: 773.85,
            cpp5: 642.58,
            cpp6: 559.66,
            cpp7: 449.11,
            cpp8: 518.21,
            cpp9: 1001.87,
            cpp10: 1050.23,
            cpp11: 1050.23,
            cpp12: 829.13
        }
    ],
    Тула: [
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3820.4,
            cpp2: 6244.89,
            cpp3: 8081.62,
            cpp4: 8228.56,
            cpp5: 6832.65,
            cpp6: 5951.01,
            cpp7: 4775.51,
            cpp8: 5510.2,
            cpp9: 10653.05,
            cpp10: 11167.34,
            cpp11: 11167.34,
            cpp12: 8816.32
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 3268.81,
            cpp2: 5343.24,
            cpp3: 6914.79,
            cpp4: 7040.51,
            cpp5: 5846.14,
            cpp6: 5091.8,
            cpp7: 4086.01,
            cpp8: 4714.63,
            cpp9: 9114.94,
            cpp10: 9554.98,
            cpp11: 9554.98,
            cpp12: 7543.4
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2992.77,
            cpp2: 4892.03,
            cpp3: 6330.87,
            cpp4: 6445.97,
            cpp5: 5352.46,
            cpp6: 4661.82,
            cpp7: 3740.97,
            cpp8: 4316.5,
            cpp9: 8345.23,
            cpp10: 8748.1,
            cpp11: 8748.1,
            cpp12: 6906.4
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3250.5,
            cpp2: 5313.31,
            cpp3: 6876.05,
            cpp4: 7001.07,
            cpp5: 5813.39,
            cpp6: 5063.28,
            cpp7: 4063.12,
            cpp8: 4688.22,
            cpp9: 9063.89,
            cpp10: 9501.46,
            cpp11: 9501.46,
            cpp12: 7501.15
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 3138.69,
            cpp2: 5130.55,
            cpp3: 6639.54,
            cpp4: 6760.26,
            cpp5: 5613.43,
            cpp6: 4889.12,
            cpp7: 3923.36,
            cpp8: 4526.96,
            cpp9: 8752.12,
            cpp10: 9174.64,
            cpp11: 9174.64,
            cpp12: 7243.13
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1470.48,
            cpp2: 2403.66,
            cpp3: 3110.62,
            cpp4: 3167.18,
            cpp5: 2629.89,
            cpp6: 2290.55,
            cpp7: 1838.1,
            cpp8: 2120.88,
            cpp9: 4100.37,
            cpp10: 4298.32,
            cpp11: 4298.32,
            cpp12: 3393.41
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1304.61,
            cpp2: 2132.54,
            cpp3: 2759.76,
            cpp4: 2809.94,
            cpp5: 2333.25,
            cpp6: 2032.19,
            cpp7: 1630.77,
            cpp8: 1881.65,
            cpp9: 3637.86,
            cpp10: 3813.48,
            cpp11: 3813.48,
            cpp12: 3010.65
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 753.1,
            cpp2: 1231.03,
            cpp3: 1593.09,
            cpp4: 1622.06,
            cpp5: 1346.89,
            cpp6: 1173.1,
            cpp7: 941.37,
            cpp8: 1086.2,
            cpp9: 2099.99,
            cpp10: 2201.37,
            cpp11: 2201.37,
            cpp12: 1737.92
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1018.16,
            cpp2: 1664.3,
            cpp3: 2153.8,
            cpp4: 2192.96,
            cpp5: 1820.94,
            cpp6: 1585.98,
            cpp7: 1272.7,
            cpp8: 1468.5,
            cpp9: 2839.1,
            cpp10: 2976.16,
            cpp11: 2976.16,
            cpp12: 2349.6
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1114.35,
            cpp2: 1821.54,
            cpp3: 2357.29,
            cpp4: 2400.15,
            cpp5: 1992.98,
            cpp6: 1735.82,
            cpp7: 1392.94,
            cpp8: 1607.24,
            cpp9: 3107.33,
            cpp10: 3257.34,
            cpp11: 3257.34,
            cpp12: 2571.59
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 710.7,
            cpp2: 1161.72,
            cpp3: 1503.41,
            cpp4: 1530.74,
            cpp5: 1271.06,
            cpp6: 1107.05,
            cpp7: 888.38,
            cpp8: 1025.05,
            cpp9: 1981.76,
            cpp10: 2077.44,
            cpp11: 2077.44,
            cpp12: 1640.08
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'Звезда',
            cpp1: 414.05,
            cpp2: 676.81,
            cpp3: 875.88,
            cpp4: 891.8,
            cpp5: 740.51,
            cpp6: 644.96,
            cpp7: 517.56,
            cpp8: 597.19,
            cpp9: 1154.56,
            cpp10: 1210.3,
            cpp11: 1210.3,
            cpp12: 955.5
        },
        {
            cityName: 'Тула',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 360.08,
            cpp2: 588.6,
            cpp3: 761.71,
            cpp4: 775.56,
            cpp5: 643.99,
            cpp6: 560.9,
            cpp7: 450.1,
            cpp8: 519.35,
            cpp9: 1004.08,
            cpp10: 1052.55,
            cpp11: 1052.55,
            cpp12: 830.96
        }
    ],
    Тюмень: [
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 1504.85,
            cpp2: 2459.85,
            cpp3: 3183.34,
            cpp4: 3241.22,
            cpp5: 2691.37,
            cpp6: 2344.1,
            cpp7: 1881.06,
            cpp8: 2170.46,
            cpp9: 4196.22,
            cpp10: 4398.8,
            cpp11: 4398.8,
            cpp12: 3472.73
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 648.3,
            cpp2: 1059.72,
            cpp3: 1371.4,
            cpp4: 1396.34,
            cpp5: 1159.46,
            cpp6: 1009.85,
            cpp7: 810.38,
            cpp8: 935.05,
            cpp9: 1807.76,
            cpp10: 1895.03,
            cpp11: 1895.03,
            cpp12: 1496.08
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 702.3,
            cpp2: 1147.99,
            cpp3: 1485.63,
            cpp4: 1512.64,
            cpp5: 1256.03,
            cpp6: 1093.97,
            cpp7: 877.87,
            cpp8: 1012.93,
            cpp9: 1958.33,
            cpp10: 2052.87,
            cpp11: 2052.87,
            cpp12: 1620.69
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 1432.7,
            cpp2: 2341.91,
            cpp3: 3030.71,
            cpp4: 3085.81,
            cpp5: 2562.33,
            cpp6: 2231.7,
            cpp7: 1790.87,
            cpp8: 2066.39,
            cpp9: 3995.02,
            cpp10: 4187.89,
            cpp11: 4187.89,
            cpp12: 3306.23
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1267.51,
            cpp2: 2071.89,
            cpp3: 2681.27,
            cpp4: 2730.02,
            cpp5: 2266.89,
            cpp6: 1974.39,
            cpp7: 1584.39,
            cpp8: 1828.14,
            cpp9: 3534.4,
            cpp10: 3705.03,
            cpp11: 3705.03,
            cpp12: 2925.02
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 478.88,
            cpp2: 782.79,
            cpp3: 1013.02,
            cpp4: 1031.44,
            cpp5: 856.46,
            cpp6: 745.95,
            cpp7: 598.6,
            cpp8: 690.7,
            cpp9: 1335.34,
            cpp10: 1399.81,
            cpp11: 1399.81,
            cpp12: 1105.11
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 554.33,
            cpp2: 906.12,
            cpp3: 1172.63,
            cpp4: 1193.95,
            cpp5: 991.4,
            cpp6: 863.48,
            cpp7: 692.92,
            cpp8: 799.52,
            cpp9: 1545.74,
            cpp10: 1620.36,
            cpp11: 1620.36,
            cpp12: 1279.23
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 378.44,
            cpp2: 618.61,
            cpp3: 800.56,
            cpp4: 815.11,
            cpp5: 676.83,
            cpp6: 589.5,
            cpp7: 473.06,
            cpp8: 545.83,
            cpp9: 1055.28,
            cpp10: 1106.22,
            cpp11: 1106.22,
            cpp12: 873.33
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 725.69,
            cpp2: 1186.22,
            cpp3: 1535.11,
            cpp4: 1563.02,
            cpp5: 1297.87,
            cpp6: 1130.4,
            cpp7: 907.11,
            cpp8: 1046.67,
            cpp9: 2023.55,
            cpp10: 2121.24,
            cpp11: 2121.24,
            cpp12: 1674.67
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 1024.58,
            cpp2: 1674.79,
            cpp3: 2167.37,
            cpp4: 2206.78,
            cpp5: 1832.42,
            cpp6: 1595.98,
            cpp7: 1280.72,
            cpp8: 1477.76,
            cpp9: 2856.99,
            cpp10: 2994.92,
            cpp11: 2994.92,
            cpp12: 2364.41
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 325.32,
            cpp2: 531.78,
            cpp3: 688.18,
            cpp4: 700.69,
            cpp5: 581.83,
            cpp6: 506.75,
            cpp7: 406.65,
            cpp8: 469.21,
            cpp9: 907.15,
            cpp10: 950.94,
            cpp11: 950.94,
            cpp12: 750.74
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 313.48,
            cpp2: 512.42,
            cpp3: 663.13,
            cpp4: 675.19,
            cpp5: 560.65,
            cpp6: 488.31,
            cpp7: 391.85,
            cpp8: 452.14,
            cpp9: 874.13,
            cpp10: 916.33,
            cpp11: 916.33,
            cpp12: 723.42
        },
        {
            cityName: 'Тюмень',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 677.77,
            cpp2: 1107.89,
            cpp3: 1433.74,
            cpp4: 1459.81,
            cpp5: 1212.16,
            cpp6: 1055.75,
            cpp7: 847.21,
            cpp8: 977.55,
            cpp9: 1889.93,
            cpp10: 1981.17,
            cpp11: 1981.17,
            cpp12: 1564.08
        },
        {
            cityName: 'Тюмень',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 500.74,
            cpp2: 818.51,
            cpp3: 1059.25,
            cpp4: 1078.51,
            cpp5: 895.55,
            cpp6: 779.99,
            cpp7: 625.92,
            cpp8: 722.22,
            cpp9: 1396.29,
            cpp10: 1463.69,
            cpp11: 1463.69,
            cpp12: 1155.55
        }
    ],
    Уфа: [
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 1205.36,
            cpp2: 1970.3,
            cpp3: 2549.8,
            cpp4: 2596.16,
            cpp5: 2155.74,
            cpp6: 1877.58,
            cpp7: 1506.7,
            cpp8: 1738.5,
            cpp9: 3361.1,
            cpp10: 3523.36,
            cpp11: 3523.36,
            cpp12: 2781.6
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 650.24,
            cpp2: 1062.89,
            cpp3: 1375.5,
            cpp4: 1400.51,
            cpp5: 1162.92,
            cpp6: 1012.87,
            cpp7: 812.79,
            cpp8: 937.84,
            cpp9: 1813.16,
            cpp10: 1900.69,
            cpp11: 1900.69,
            cpp12: 1500.54
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 842.09,
            cpp2: 1376.49,
            cpp3: 1781.34,
            cpp4: 1813.73,
            cpp5: 1506.04,
            cpp6: 1311.71,
            cpp7: 1052.61,
            cpp8: 1214.55,
            cpp9: 2348.13,
            cpp10: 2461.49,
            cpp11: 2461.49,
            cpp12: 1943.28
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 2263.77,
            cpp2: 3700.39,
            cpp3: 4788.74,
            cpp4: 4875.8,
            cpp5: 4048.66,
            cpp6: 3526.25,
            cpp7: 2829.71,
            cpp8: 3265.05,
            cpp9: 6312.43,
            cpp10: 6617.16,
            cpp11: 6617.16,
            cpp12: 5224.08
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1898.48,
            cpp2: 3103.29,
            cpp3: 4016.02,
            cpp4: 4089.04,
            cpp5: 3395.36,
            cpp6: 2957.25,
            cpp7: 2373.1,
            cpp8: 2738.2,
            cpp9: 5293.85,
            cpp10: 5549.41,
            cpp11: 5549.41,
            cpp12: 4381.12
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 570.86,
            cpp2: 933.13,
            cpp3: 1207.58,
            cpp4: 1229.54,
            cpp5: 1020.95,
            cpp6: 889.22,
            cpp7: 713.57,
            cpp8: 823.35,
            cpp9: 1591.81,
            cpp10: 1668.66,
            cpp11: 1668.66,
            cpp12: 1317.36
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 681.95,
            cpp2: 1114.73,
            cpp3: 1442.6,
            cpp4: 1468.83,
            cpp5: 1219.65,
            cpp6: 1062.28,
            cpp7: 852.44,
            cpp8: 983.59,
            cpp9: 1901.6,
            cpp10: 1993.41,
            cpp11: 1993.41,
            cpp12: 1573.74
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 595.31,
            cpp2: 973.1,
            cpp3: 1259.3,
            cpp4: 1282.2,
            cpp5: 1064.68,
            cpp6: 927.3,
            cpp7: 744.13,
            cpp8: 858.61,
            cpp9: 1659.99,
            cpp10: 1740.13,
            cpp11: 1740.13,
            cpp12: 1373.78
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 817.06,
            cpp2: 1335.59,
            cpp3: 1728.41,
            cpp4: 1759.83,
            cpp5: 1461.29,
            cpp6: 1272.74,
            cpp7: 1021.33,
            cpp8: 1178.46,
            cpp9: 2278.35,
            cpp10: 2388.34,
            cpp11: 2388.34,
            cpp12: 1885.53
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 1127.13,
            cpp2: 1842.43,
            cpp3: 2384.32,
            cpp4: 2427.67,
            cpp5: 2015.84,
            cpp6: 1755.73,
            cpp7: 1408.92,
            cpp8: 1625.67,
            cpp9: 3142.97,
            cpp10: 3294.7,
            cpp11: 3294.7,
            cpp12: 2601.08
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 288.32,
            cpp2: 471.3,
            cpp3: 609.92,
            cpp4: 621,
            cpp5: 515.66,
            cpp6: 449.12,
            cpp7: 360.4,
            cpp8: 415.85,
            cpp9: 803.98,
            cpp10: 842.79,
            cpp11: 842.79,
            cpp12: 665.36
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 319.92,
            cpp2: 522.95,
            cpp3: 676.76,
            cpp4: 689.06,
            cpp5: 572.17,
            cpp6: 498.34,
            cpp7: 399.9,
            cpp8: 0,
            cpp9: 0,
            cpp10: 0,
            cpp11: 0,
            cpp12: 0
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 966.41,
            cpp2: 1579.71,
            cpp3: 2044.33,
            cpp4: 2081.5,
            cpp5: 1728.39,
            cpp6: 1505.37,
            cpp7: 1208.01,
            cpp8: 1393.86,
            cpp9: 2694.8,
            cpp10: 2824.9,
            cpp11: 2824.9,
            cpp12: 2230.18
        },
        {
            cityName: 'Уфа',
            priceType: 'grp',
            channelName: 'БСТ',
            cpp1: 3348.44,
            cpp2: 5473.41,
            cpp3: 7083.24,
            cpp4: 7212.03,
            cpp5: 5988.56,
            cpp6: 5215.84,
            cpp7: 4185.55,
            cpp8: 4829.48,
            cpp9: 9337,
            cpp10: 9787.75,
            cpp11: 9787.75,
            cpp12: 7727.17
        }
    ],
    Ульяновск: [
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3096.49,
            cpp2: 5061.56,
            cpp3: 6550.26,
            cpp4: 6669.35,
            cpp5: 5537.94,
            cpp6: 4823.37,
            cpp7: 3870.61,
            cpp8: 4466.08,
            cpp9: 8634.43,
            cpp10: 9051.26,
            cpp11: 9051.26,
            cpp12: 7145.74
        },
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 3074.61,
            cpp2: 5025.81,
            cpp3: 6503.99,
            cpp4: 6622.25,
            cpp5: 5498.83,
            cpp6: 4789.3,
            cpp7: 3843.27,
            cpp8: 4434.54,
            cpp9: 8573.44,
            cpp10: 8987.33,
            cpp11: 8987.33,
            cpp12: 7095.26
        },
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2805.66,
            cpp2: 4586.17,
            cpp3: 5935.04,
            cpp4: 6042.95,
            cpp5: 5017.81,
            cpp6: 4370.35,
            cpp7: 3507.07,
            cpp8: 4046.62,
            cpp9: 7823.46,
            cpp10: 8201.15,
            cpp11: 8201.15,
            cpp12: 6474.59
        },
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2812.98,
            cpp2: 4598.14,
            cpp3: 5950.54,
            cpp4: 6058.73,
            cpp5: 5030.91,
            cpp6: 4381.76,
            cpp7: 3516.23,
            cpp8: 4057.19,
            cpp9: 7843.89,
            cpp10: 8222.56,
            cpp11: 8222.56,
            cpp12: 6491.5
        },
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 3070.22,
            cpp2: 5018.63,
            cpp3: 6494.7,
            cpp4: 6612.79,
            cpp5: 5490.98,
            cpp6: 4782.46,
            cpp7: 3837.78,
            cpp8: 4428.21,
            cpp9: 8561.2,
            cpp10: 8974.5,
            cpp11: 8974.5,
            cpp12: 7085.13
        },
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1327.5,
            cpp2: 2169.95,
            cpp3: 2808.18,
            cpp4: 2859.23,
            cpp5: 2374.19,
            cpp6: 2067.84,
            cpp7: 1659.38,
            cpp8: 1914.67,
            cpp9: 3701.69,
            cpp10: 3880.39,
            cpp11: 3880.39,
            cpp12: 3063.47
        },
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1352.21,
            cpp2: 2210.34,
            cpp3: 2860.44,
            cpp4: 2912.45,
            cpp5: 2418.37,
            cpp6: 2106.32,
            cpp7: 1690.26,
            cpp8: 1950.3,
            cpp9: 3770.58,
            cpp10: 3952.61,
            cpp11: 3952.61,
            cpp12: 3120.48
        },
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1912.12,
            cpp2: 3125.59,
            cpp3: 4044.88,
            cpp4: 4118.42,
            cpp5: 3419.76,
            cpp6: 2978.5,
            cpp7: 2390.16,
            cpp8: 2757.87,
            cpp9: 5331.88,
            cpp10: 5589.29,
            cpp11: 5589.29,
            cpp12: 4412.59
        },
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 989.82,
            cpp2: 1617.97,
            cpp3: 2093.84,
            cpp4: 2131.91,
            cpp5: 1770.25,
            cpp6: 1541.83,
            cpp7: 1237.27,
            cpp8: 1427.62,
            cpp9: 2760.07,
            cpp10: 2893.31,
            cpp11: 2893.31,
            cpp12: 2284.19
        },
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1045.13,
            cpp2: 1708.39,
            cpp3: 2210.85,
            cpp4: 2251.05,
            cpp5: 1869.18,
            cpp6: 1627.99,
            cpp7: 1306.41,
            cpp8: 1507.4,
            cpp9: 2914.31,
            cpp10: 3055,
            cpp11: 3055,
            cpp12: 2411.84
        },
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 763.97,
            cpp2: 1248.8,
            cpp3: 1616.09,
            cpp4: 1645.47,
            cpp5: 1366.33,
            cpp6: 1190.03,
            cpp7: 954.96,
            cpp8: 1101.88,
            cpp9: 2130.3,
            cpp10: 2233.14,
            cpp11: 2233.14,
            cpp12: 1763.01
        },
        {
            cityName: 'Ульяновск',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 700.93,
            cpp2: 1145.75,
            cpp3: 1482.74,
            cpp4: 1509.7,
            cpp5: 1253.59,
            cpp6: 1091.83,
            cpp7: 876.16,
            cpp8: 1010.96,
            cpp9: 1954.52,
            cpp10: 2048.87,
            cpp11: 2048.87,
            cpp12: 1617.53
        }
    ],
    Хабаровск: [
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 979.08,
            cpp2: 1600.42,
            cpp3: 2071.13,
            cpp4: 2108.78,
            cpp5: 1751.04,
            cpp6: 1525.1,
            cpp7: 1223.85,
            cpp8: 1412.13,
            cpp9: 2730.12,
            cpp10: 2861.92,
            cpp11: 2861.92,
            cpp12: 2259.41
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 590.27,
            cpp2: 964.86,
            cpp3: 1248.65,
            cpp4: 1271.35,
            cpp5: 1055.67,
            cpp6: 919.46,
            cpp7: 737.84,
            cpp8: 851.35,
            cpp9: 1645.94,
            cpp10: 1725.4,
            cpp11: 1725.4,
            cpp12: 1362.16
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 460.96,
            cpp2: 753.49,
            cpp3: 975.1,
            cpp4: 992.83,
            cpp5: 824.41,
            cpp6: 718.03,
            cpp7: 576.2,
            cpp8: 664.84,
            cpp9: 1285.36,
            cpp10: 1347.41,
            cpp11: 1347.41,
            cpp12: 1063.75
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 932.29,
            cpp2: 1523.94,
            cpp3: 1972.16,
            cpp4: 2008.02,
            cpp5: 1667.37,
            cpp6: 1452.23,
            cpp7: 1165.37,
            cpp8: 1344.66,
            cpp9: 2599.67,
            cpp10: 2725.17,
            cpp11: 2725.17,
            cpp12: 2151.45
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1230.5,
            cpp2: 2011.39,
            cpp3: 2602.97,
            cpp4: 2650.3,
            cpp5: 2200.7,
            cpp6: 1916.74,
            cpp7: 1538.12,
            cpp8: 1774.76,
            cpp9: 3431.19,
            cpp10: 3596.84,
            cpp11: 3596.84,
            cpp12: 2839.61
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 395.34,
            cpp2: 646.22,
            cpp3: 836.29,
            cpp4: 851.5,
            cpp5: 707.05,
            cpp6: 615.81,
            cpp7: 494.17,
            cpp8: 570.2,
            cpp9: 1102.38,
            cpp10: 1155.6,
            cpp11: 1155.6,
            cpp12: 912.32
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 764.45,
            cpp2: 1249.59,
            cpp3: 1617.11,
            cpp4: 1646.52,
            cpp5: 1367.2,
            cpp6: 1190.78,
            cpp7: 955.57,
            cpp8: 1102.58,
            cpp9: 2131.65,
            cpp10: 2234.56,
            cpp11: 2234.56,
            cpp12: 1764.12
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 340.14,
            cpp2: 555.99,
            cpp3: 719.52,
            cpp4: 732.6,
            cpp5: 608.32,
            cpp6: 529.83,
            cpp7: 425.17,
            cpp8: 490.58,
            cpp9: 948.46,
            cpp10: 994.25,
            cpp11: 994.25,
            cpp12: 784.93
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 699.6,
            cpp2: 1143.57,
            cpp3: 1479.92,
            cpp4: 1506.82,
            cpp5: 1251.2,
            cpp6: 1089.76,
            cpp7: 874.5,
            cpp8: 1009.03,
            cpp9: 1950.8,
            cpp10: 2044.97,
            cpp11: 2044.97,
            cpp12: 1614.45
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 752.96,
            cpp2: 1230.79,
            cpp3: 1592.79,
            cpp4: 1621.75,
            cpp5: 1346.63,
            cpp6: 1172.87,
            cpp7: 941.19,
            cpp8: 1085.99,
            cpp9: 2099.59,
            cpp10: 2200.95,
            cpp11: 2200.95,
            cpp12: 1737.59
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 258.14,
            cpp2: 421.96,
            cpp3: 546.07,
            cpp4: 555.99,
            cpp5: 461.67,
            cpp6: 402.1,
            cpp7: 322.67,
            cpp8: 372.32,
            cpp9: 719.81,
            cpp10: 754.56,
            cpp11: 754.56,
            cpp12: 595.71
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 206.68,
            cpp2: 337.85,
            cpp3: 437.21,
            cpp4: 445.16,
            cpp5: 369.64,
            cpp6: 321.95,
            cpp7: 258.35,
            cpp8: 298.1,
            cpp9: 576.33,
            cpp10: 604.15,
            cpp11: 604.15,
            cpp12: 476.96
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 752.79,
            cpp2: 1230.53,
            cpp3: 1592.45,
            cpp4: 1621.4,
            cpp5: 1346.34,
            cpp6: 1172.62,
            cpp7: 940.99,
            cpp8: 1085.76,
            cpp9: 2099.13,
            cpp10: 2200.47,
            cpp11: 2200.47,
            cpp12: 1737.21
        },
        {
            cityName: 'Хабаровск',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 371.09,
            cpp2: 606.58,
            cpp3: 784.99,
            cpp4: 799.26,
            cpp5: 663.67,
            cpp6: 578.04,
            cpp7: 463.86,
            cpp8: 535.22,
            cpp9: 1034.76,
            cpp10: 1084.71,
            cpp11: 1084.71,
            cpp12: 856.35
        },
        {
            cityName: 'Хабаровск',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 286.62,
            cpp2: 468.51,
            cpp3: 606.31,
            cpp4: 617.33,
            cpp5: 512.61,
            cpp6: 446.47,
            cpp7: 358.27,
            cpp8: 413.39,
            cpp9: 799.23,
            cpp10: 837.81,
            cpp11: 837.81,
            cpp12: 661.43
        }
    ],
    Челябинск: [
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 999.03,
            cpp2: 1633.03,
            cpp3: 2113.33,
            cpp4: 2151.75,
            cpp5: 1786.72,
            cpp6: 1556.18,
            cpp7: 1248.79,
            cpp8: 1440.91,
            cpp9: 2785.75,
            cpp10: 2920.24,
            cpp11: 2920.24,
            cpp12: 2305.45
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 788.66,
            cpp2: 1289.16,
            cpp3: 1668.32,
            cpp4: 1698.66,
            cpp5: 1410.49,
            cpp6: 1228.49,
            cpp7: 985.83,
            cpp8: 1137.49,
            cpp9: 2199.16,
            cpp10: 2305.32,
            cpp11: 2305.32,
            cpp12: 1819.99
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 685.32,
            cpp2: 1120.24,
            cpp3: 1449.72,
            cpp4: 1476.08,
            cpp5: 1225.67,
            cpp6: 1067.52,
            cpp7: 856.65,
            cpp8: 988.44,
            cpp9: 1910.99,
            cpp10: 2003.25,
            cpp11: 2003.25,
            cpp12: 1581.51
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 1500.24,
            cpp2: 2452.32,
            cpp3: 3173.59,
            cpp4: 3231.29,
            cpp5: 2683.12,
            cpp6: 2336.91,
            cpp7: 1875.3,
            cpp8: 2163.81,
            cpp9: 4183.36,
            cpp10: 4385.32,
            cpp11: 4385.32,
            cpp12: 3462.09
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 1552.27,
            cpp2: 2537.36,
            cpp3: 3283.64,
            cpp4: 3343.34,
            cpp5: 2776.17,
            cpp6: 2417.95,
            cpp7: 1940.33,
            cpp8: 2238.85,
            cpp9: 4328.43,
            cpp10: 4537.39,
            cpp11: 4537.39,
            cpp12: 3582.15
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 557.97,
            cpp2: 912.07,
            cpp3: 1180.33,
            cpp4: 1201.79,
            cpp5: 997.92,
            cpp6: 869.15,
            cpp7: 697.47,
            cpp8: 804.77,
            cpp9: 1555.89,
            cpp10: 1631,
            cpp11: 1631,
            cpp12: 1287.63
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 705.4,
            cpp2: 1153.05,
            cpp3: 1492.18,
            cpp4: 1519.31,
            cpp5: 1261.57,
            cpp6: 1098.79,
            cpp7: 881.74,
            cpp8: 1017.4,
            cpp9: 1966.97,
            cpp10: 2061.93,
            cpp11: 2061.93,
            cpp12: 1627.84
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 520.41,
            cpp2: 850.68,
            cpp3: 1100.88,
            cpp4: 1120.89,
            cpp5: 930.74,
            cpp6: 810.65,
            cpp7: 650.52,
            cpp8: 750.6,
            cpp9: 1451.16,
            cpp10: 1521.21,
            cpp11: 1521.21,
            cpp12: 1200.96
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 773.42,
            cpp2: 1264.24,
            cpp3: 1636.08,
            cpp4: 1665.82,
            cpp5: 1383.23,
            cpp6: 1204.75,
            cpp7: 966.77,
            cpp8: 1115.51,
            cpp9: 2156.65,
            cpp10: 2260.76,
            cpp11: 2260.76,
            cpp12: 1784.81
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 1030,
            cpp2: 1683.65,
            cpp3: 2178.84,
            cpp4: 2218.45,
            cpp5: 1842.11,
            cpp6: 1604.42,
            cpp7: 1287.5,
            cpp8: 1485.57,
            cpp9: 2872.11,
            cpp10: 3010.76,
            cpp11: 3010.76,
            cpp12: 2376.91
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 344.5,
            cpp2: 563.13,
            cpp3: 728.76,
            cpp4: 742.01,
            cpp5: 616.13,
            cpp6: 536.63,
            cpp7: 430.63,
            cpp8: 496.88,
            cpp9: 960.64,
            cpp10: 1007.01,
            cpp11: 1007.01,
            cpp12: 795.01
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 576.3,
            cpp2: 942.03,
            cpp3: 1219.1,
            cpp4: 1241.27,
            cpp5: 1030.7,
            cpp6: 897.7,
            cpp7: 720.38,
            cpp8: 831.21,
            cpp9: 1607,
            cpp10: 1684.58,
            cpp11: 1684.58,
            cpp12: 1329.93
        },
        {
            cityName: 'Челябинск',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 784.6,
            cpp2: 1282.52,
            cpp3: 1659.73,
            cpp4: 1689.91,
            cpp5: 1403.23,
            cpp6: 1222.17,
            cpp7: 980.75,
            cpp8: 1131.64,
            cpp9: 2187.83,
            cpp10: 2293.45,
            cpp11: 2293.45,
            cpp12: 1810.62
        },
        {
            cityName: 'Челябинск',
            priceType: 'min',
            channelName: 'МИР',
            cpp1: 271.02,
            cpp2: 443.01,
            cpp3: 573.31,
            cpp4: 583.73,
            cpp5: 484.71,
            cpp6: 422.16,
            cpp7: 338.77,
            cpp8: 390.89,
            cpp9: 755.73,
            cpp10: 792.21,
            cpp11: 792.21,
            cpp12: 625.43
        }
    ],
    Ярославль: [
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'Первый',
            cpp1: 556.95,
            cpp2: 910.4,
            cpp3: 1178.17,
            cpp4: 1199.59,
            cpp5: 996.09,
            cpp6: 867.56,
            cpp7: 696.19,
            cpp8: 803.3,
            cpp9: 1553.04,
            cpp10: 1628.01,
            cpp11: 1628.01,
            cpp12: 1285.27
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'Россия 1',
            cpp1: 561.8,
            cpp2: 918.33,
            cpp3: 1188.43,
            cpp4: 1210.03,
            cpp5: 1004.76,
            cpp6: 875.11,
            cpp7: 702.25,
            cpp8: 810.29,
            cpp9: 1566.56,
            cpp10: 1642.19,
            cpp11: 1642.19,
            cpp12: 1296.47
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'НТВ',
            cpp1: 497.25,
            cpp2: 812.81,
            cpp3: 1051.87,
            cpp4: 1071,
            cpp5: 889.31,
            cpp6: 774.56,
            cpp7: 621.56,
            cpp8: 717.19,
            cpp9: 1386.56,
            cpp10: 1453.5,
            cpp11: 1453.5,
            cpp12: 1147.5
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'ТНТ',
            cpp1: 838.42,
            cpp2: 1370.49,
            cpp3: 1773.57,
            cpp4: 1805.82,
            cpp5: 1499.47,
            cpp6: 1305.99,
            cpp7: 1048.02,
            cpp8: 1209.25,
            cpp9: 2337.89,
            cpp10: 2450.75,
            cpp11: 2450.75,
            cpp12: 1934.8
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'СТС',
            cpp1: 826.5,
            cpp2: 1351.02,
            cpp3: 1748.37,
            cpp4: 1780.16,
            cpp5: 1478.17,
            cpp6: 1287.44,
            cpp7: 1033.13,
            cpp8: 1192.07,
            cpp9: 2304.68,
            cpp10: 2415.94,
            cpp11: 2415.94,
            cpp12: 1907.32
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'Пятый канал',
            cpp1: 309.2,
            cpp2: 505.42,
            cpp3: 654.07,
            cpp4: 665.96,
            cpp5: 552.99,
            cpp6: 481.63,
            cpp7: 386.5,
            cpp8: 445.96,
            cpp9: 862.18,
            cpp10: 903.81,
            cpp11: 903.81,
            cpp12: 713.53
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'РЕН ТВ',
            cpp1: 258.51,
            cpp2: 422.57,
            cpp3: 546.85,
            cpp4: 556.79,
            cpp5: 462.34,
            cpp6: 402.68,
            cpp7: 323.14,
            cpp8: 372.85,
            cpp9: 720.85,
            cpp10: 755.65,
            cpp11: 755.65,
            cpp12: 596.56
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'Домашний',
            cpp1: 253.25,
            cpp2: 413.96,
            cpp3: 535.72,
            cpp4: 545.46,
            cpp5: 452.92,
            cpp6: 394.48,
            cpp7: 316.56,
            cpp8: 365.26,
            cpp9: 706.17,
            cpp10: 740.26,
            cpp11: 740.26,
            cpp12: 584.42
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'ТВ-3',
            cpp1: 612.75,
            cpp2: 1001.6,
            cpp3: 1296.19,
            cpp4: 1319.76,
            cpp5: 1095.87,
            cpp6: 954.47,
            cpp7: 765.93,
            cpp8: 883.77,
            cpp9: 1708.62,
            cpp10: 1791.1,
            cpp11: 1791.1,
            cpp12: 1414.03
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'Пятница',
            cpp1: 749.91,
            cpp2: 1225.81,
            cpp3: 1586.34,
            cpp4: 1615.18,
            cpp5: 1341.18,
            cpp6: 1168.12,
            cpp7: 937.38,
            cpp8: 1081.59,
            cpp9: 2091.08,
            cpp10: 2192.03,
            cpp11: 2192.03,
            cpp12: 1730.55
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'ТВЦ',
            cpp1: 120.34,
            cpp2: 196.71,
            cpp3: 254.56,
            cpp4: 259.19,
            cpp5: 215.22,
            cpp6: 187.45,
            cpp7: 150.42,
            cpp8: 173.56,
            cpp9: 335.56,
            cpp10: 351.76,
            cpp11: 351.76,
            cpp12: 277.7
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'Звезда',
            cpp1: 185.95,
            cpp2: 303.95,
            cpp3: 393.35,
            cpp4: 400.5,
            cpp5: 332.56,
            cpp6: 289.65,
            cpp7: 232.43,
            cpp8: 268.19,
            cpp9: 518.5,
            cpp10: 543.53,
            cpp11: 543.53,
            cpp12: 429.1
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'Россия 24',
            cpp1: 336.06,
            cpp2: 549.33,
            cpp3: 710.9,
            cpp4: 723.83,
            cpp5: 601.03,
            cpp6: 523.48,
            cpp7: 420.08,
            cpp8: 484.71,
            cpp9: 937.1,
            cpp10: 982.34,
            cpp11: 982.34,
            cpp12: 775.53
        },
        {
            cityName: 'Ярославль',
            priceType: 'grp',
            channelName: 'Матч ТВ',
            cpp1: 341.43,
            cpp2: 558.11,
            cpp3: 722.26,
            cpp4: 735.39,
            cpp5: 610.64,
            cpp6: 531.85,
            cpp7: 426.79,
            cpp8: 492.45,
            cpp9: 952.07,
            cpp10: 998.03,
            cpp11: 998.03,
            cpp12: 787.92
        },
        {
            cityName: 'Ярославль',
            priceType: 'min',
            channelName: 'Первый Ярославский НТМ',
            cpp1: 421.46,
            cpp2: 688.92,
            cpp3: 891.54,
            cpp4: 907.75,
            cpp5: 753.76,
            cpp6: 656.5,
            cpp7: 526.82,
            cpp8: 607.87,
            cpp9: 1175.21,
            cpp10: 1231.95,
            cpp11: 1231.95,
            cpp12: 972.59
        }
    ],
    Абакан: [
        {
            cityName: 'Абакан',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3772.99,
            cpp2: 6167.38,
            cpp3: 7981.32,
            cpp4: 8126.43,
            cpp5: 6747.84,
            cpp6: 5877.15,
            cpp7: 4716.23,
            cpp8: 5441.81,
            cpp9: 10520.83,
            cpp10: 11028.73,
            cpp11: 11028.73,
            cpp12: 8706.89
        },
        {
            cityName: 'Абакан',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2895.82,
            cpp2: 4733.55,
            cpp3: 6125.77,
            cpp4: 6237.14,
            cpp5: 5179.06,
            cpp6: 4510.79,
            cpp7: 3619.77,
            cpp8: 4176.66,
            cpp9: 8074.88,
            cpp10: 8464.7,
            cpp11: 8464.7,
            cpp12: 6682.66
        },
        {
            cityName: 'Абакан',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 3727.89,
            cpp2: 6093.67,
            cpp3: 7885.93,
            cpp4: 8029.31,
            cpp5: 6667.19,
            cpp6: 5806.91,
            cpp7: 4659.87,
            cpp8: 5376.77,
            cpp9: 10395.08,
            cpp10: 10896.92,
            cpp11: 10896.92,
            cpp12: 8602.83
        },
        {
            cityName: 'Абакан',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3073.7,
            cpp2: 5024.32,
            cpp3: 6502.07,
            cpp4: 6620.29,
            cpp5: 5497.2,
            cpp6: 4787.89,
            cpp7: 3842.13,
            cpp8: 4433.23,
            cpp9: 8570.91,
            cpp10: 8984.67,
            cpp11: 8984.67,
            cpp12: 7093.16
        },
        {
            cityName: 'Абакан',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2597.86,
            cpp2: 4246.5,
            cpp3: 5495.47,
            cpp4: 5595.38,
            cpp5: 4646.17,
            cpp6: 4046.66,
            cpp7: 3247.32,
            cpp8: 3746.91,
            cpp9: 7244.02,
            cpp10: 7593.74,
            cpp11: 7593.74,
            cpp12: 5995.05
        }
    ],
    Альметьевск: [
        {
            cityName: 'Альметьевск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1114.73,
            cpp2: 1822.16,
            cpp3: 2358.09,
            cpp4: 2400.96,
            cpp5: 1993.66,
            cpp6: 1736.41,
            cpp7: 1393.42,
            cpp8: 1607.79,
            cpp9: 3108.39,
            cpp10: 3258.45,
            cpp11: 3258.45,
            cpp12: 2572.46
        },
        {
            cityName: 'Альметьевск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 903.04,
            cpp2: 1476.13,
            cpp3: 1910.28,
            cpp4: 1945.01,
            cpp5: 1615.06,
            cpp6: 1406.66,
            cpp7: 1128.8,
            cpp8: 1302.46,
            cpp9: 2518.1,
            cpp10: 2639.66,
            cpp11: 2639.66,
            cpp12: 2083.94
        },
        {
            cityName: 'Альметьевск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 981.72,
            cpp2: 1604.73,
            cpp3: 2076.71,
            cpp4: 2114.47,
            cpp5: 1755.76,
            cpp6: 1529.21,
            cpp7: 1227.15,
            cpp8: 1415.94,
            cpp9: 2737.48,
            cpp10: 2869.64,
            cpp11: 2869.64,
            cpp12: 2265.5
        }
    ],
    Арзамас: [
        {
            cityName: 'Арзамас',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 887.3,
            cpp2: 1450.39,
            cpp3: 1876.97,
            cpp4: 1911.1,
            cpp5: 1586.89,
            cpp6: 1382.13,
            cpp7: 1109.12,
            cpp8: 1279.75,
            cpp9: 2474.19,
            cpp10: 2593.63,
            cpp11: 2593.63,
            cpp12: 2047.6
        },
        {
            cityName: 'Арзамас',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 533.9,
            cpp2: 872.71,
            cpp3: 1129.39,
            cpp4: 1149.93,
            cpp5: 954.85,
            cpp6: 831.65,
            cpp7: 667.37,
            cpp8: 770.04,
            cpp9: 1488.75,
            cpp10: 1560.62,
            cpp11: 1560.62,
            cpp12: 1232.07
        }
    ],
    Архангельск: [
        {
            cityName: 'Архангельск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3579.91,
            cpp2: 5851.77,
            cpp3: 7572.88,
            cpp4: 7710.57,
            cpp5: 6402.53,
            cpp6: 5576.4,
            cpp7: 4474.89,
            cpp8: 5163.33,
            cpp9: 9982.44,
            cpp10: 10464.35,
            cpp11: 10464.35,
            cpp12: 8261.33
        },
        {
            cityName: 'Архангельск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2729.78,
            cpp2: 4462.14,
            cpp3: 5774.53,
            cpp4: 5879.52,
            cpp5: 4882.1,
            cpp6: 4252.15,
            cpp7: 3412.22,
            cpp8: 3937.18,
            cpp9: 7611.88,
            cpp10: 7979.35,
            cpp11: 7979.35,
            cpp12: 6299.49
        },
        {
            cityName: 'Архангельск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 3186.71,
            cpp2: 5209.05,
            cpp3: 6741.12,
            cpp4: 6863.68,
            cpp5: 5699.31,
            cpp6: 4963.91,
            cpp7: 3983.39,
            cpp8: 4596.22,
            cpp9: 8886.02,
            cpp10: 9315,
            cpp11: 9315,
            cpp12: 7353.95
        },
        {
            cityName: 'Архангельск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3383.16,
            cpp2: 5530.17,
            cpp3: 7156.69,
            cpp4: 7286.81,
            cpp5: 6050.66,
            cpp6: 5269.93,
            cpp7: 4228.95,
            cpp8: 4879.56,
            cpp9: 9433.82,
            cpp10: 9889.25,
            cpp11: 9889.25,
            cpp12: 7807.3
        },
        {
            cityName: 'Архангельск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 3213.41,
            cpp2: 5252.68,
            cpp3: 6797.59,
            cpp4: 6921.18,
            cpp5: 5747.05,
            cpp6: 5005.5,
            cpp7: 4016.76,
            cpp8: 4634.72,
            cpp9: 8960.46,
            cpp10: 9393.03,
            cpp11: 9393.03,
            cpp12: 7415.55
        },
        {
            cityName: 'Архангельск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2445.11,
            cpp2: 3996.82,
            cpp3: 5172.35,
            cpp4: 5266.4,
            cpp5: 4372.99,
            cpp6: 3808.73,
            cpp7: 3056.39,
            cpp8: 3526.6,
            cpp9: 6818.1,
            cpp10: 7147.25,
            cpp11: 7147.25,
            cpp12: 5642.57
        },
        {
            cityName: 'Архангельск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1742.06,
            cpp2: 2847.59,
            cpp3: 3685.12,
            cpp4: 3752.12,
            cpp5: 3115.6,
            cpp6: 2713.59,
            cpp7: 2177.57,
            cpp8: 2512.58,
            cpp9: 4857.66,
            cpp10: 5092.17,
            cpp11: 5092.17,
            cpp12: 4020.13
        },
        {
            cityName: 'Архангельск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1650.52,
            cpp2: 2697.96,
            cpp3: 3491.48,
            cpp4: 3554.96,
            cpp5: 2951.89,
            cpp6: 2571,
            cpp7: 2063.15,
            cpp8: 2380.55,
            cpp9: 4602.4,
            cpp10: 4824.59,
            cpp11: 4824.59,
            cpp12: 3808.88
        }
    ],
    Астрахань: [
        {
            cityName: 'Астрахань',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4755.53,
            cpp2: 7773.46,
            cpp3: 10059.77,
            cpp4: 10242.68,
            cpp5: 8505.08,
            cpp6: 7407.65,
            cpp7: 5944.41,
            cpp8: 6858.93,
            cpp9: 13260.61,
            cpp10: 13900.77,
            cpp11: 13900.77,
            cpp12: 10974.3
        },
        {
            cityName: 'Астрахань',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 3344.49,
            cpp2: 5466.95,
            cpp3: 7074.88,
            cpp4: 7203.52,
            cpp5: 5981.49,
            cpp6: 5209.69,
            cpp7: 4180.61,
            cpp8: 4823.78,
            cpp9: 9325.98,
            cpp10: 9776.2,
            cpp11: 9776.2,
            cpp12: 7718.05
        },
        {
            cityName: 'Астрахань',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 3896.86,
            cpp2: 6369.87,
            cpp3: 8243.36,
            cpp4: 8393.23,
            cpp5: 6969.38,
            cpp6: 6070.11,
            cpp7: 4871.07,
            cpp8: 5620.47,
            cpp9: 10866.24,
            cpp10: 11390.82,
            cpp11: 11390.82,
            cpp12: 8992.75
        },
        {
            cityName: 'Астрахань',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3303.23,
            cpp2: 5399.51,
            cpp3: 6987.61,
            cpp4: 7114.66,
            cpp5: 5907.7,
            cpp6: 5145.42,
            cpp7: 4129.04,
            cpp8: 4764.28,
            cpp9: 9210.94,
            cpp10: 9655.6,
            cpp11: 9655.6,
            cpp12: 7622.84
        },
        {
            cityName: 'Астрахань',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 3138.21,
            cpp2: 5129.77,
            cpp3: 6638.52,
            cpp4: 6759.22,
            cpp5: 5612.57,
            cpp6: 4888.37,
            cpp7: 3922.76,
            cpp8: 4526.27,
            cpp9: 8750.78,
            cpp10: 9173.23,
            cpp11: 9173.23,
            cpp12: 7242.03
        },
        {
            cityName: 'Астрахань',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 2459.85,
            cpp2: 4020.91,
            cpp3: 5203.53,
            cpp4: 5298.14,
            cpp5: 4399.35,
            cpp6: 3831.69,
            cpp7: 3074.81,
            cpp8: 3547.86,
            cpp9: 6859.19,
            cpp10: 7190.33,
            cpp11: 7190.33,
            cpp12: 5676.57
        },
        {
            cityName: 'Астрахань',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2031.47,
            cpp2: 3320.66,
            cpp3: 4297.33,
            cpp4: 4375.46,
            cpp5: 3633.2,
            cpp6: 3164.4,
            cpp7: 2539.33,
            cpp8: 2930,
            cpp9: 5664.66,
            cpp10: 5938.13,
            cpp11: 5938.13,
            cpp12: 4688
        },
        {
            cityName: 'Астрахань',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1913.86,
            cpp2: 3128.42,
            cpp3: 4048.55,
            cpp4: 4122.16,
            cpp5: 3422.86,
            cpp6: 2981.2,
            cpp7: 2392.32,
            cpp8: 2760.37,
            cpp9: 5336.72,
            cpp10: 5594.36,
            cpp11: 5594.36,
            cpp12: 4416.6
        },
        {
            cityName: 'Астрахань',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2746.68,
            cpp2: 4489.76,
            cpp3: 5810.28,
            cpp4: 5915.92,
            cpp5: 4912.33,
            cpp6: 4278.48,
            cpp7: 3433.35,
            cpp8: 3961.56,
            cpp9: 7659.01,
            cpp10: 8028.75,
            cpp11: 8028.75,
            cpp12: 6338.49
        },
        {
            cityName: 'Астрахань',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1401.31,
            cpp2: 2290.6,
            cpp3: 2964.3,
            cpp4: 3018.2,
            cpp5: 2506.18,
            cpp6: 2182.8,
            cpp7: 1751.63,
            cpp8: 2021.11,
            cpp9: 3907.49,
            cpp10: 4096.12,
            cpp11: 4096.12,
            cpp12: 3233.78
        },
        {
            cityName: 'Астрахань',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1395.25,
            cpp2: 2280.7,
            cpp3: 2951.49,
            cpp4: 3005.15,
            cpp5: 2495.35,
            cpp6: 2173.37,
            cpp7: 1744.06,
            cpp8: 2012.38,
            cpp9: 3890.6,
            cpp10: 4078.42,
            cpp11: 4078.42,
            cpp12: 3219.81
        }
    ],
    Ачинск: [
        {
            cityName: 'Ачинск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1833.41,
            cpp2: 2996.92,
            cpp3: 3878.37,
            cpp4: 3948.89,
            cpp5: 3278.99,
            cpp6: 2855.89,
            cpp7: 2291.77,
            cpp8: 2644.35,
            cpp9: 5112.4,
            cpp10: 5359.21,
            cpp11: 5359.21,
            cpp12: 4230.95
        },
        {
            cityName: 'Ачинск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 1844.42,
            cpp2: 3014.92,
            cpp3: 3901.66,
            cpp4: 3972.6,
            cpp5: 3298.67,
            cpp6: 2873.04,
            cpp7: 2305.52,
            cpp8: 2660.22,
            cpp9: 5143.09,
            cpp10: 5391.38,
            cpp11: 5391.38,
            cpp12: 4256.35
        }
    ],
    Балаково: [
        {
            cityName: 'Балаково',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1712.79,
            cpp2: 2799.75,
            cpp3: 3623.21,
            cpp4: 3689.08,
            cpp5: 3063.26,
            cpp6: 2668,
            cpp7: 2140.99,
            cpp8: 2470.37,
            cpp9: 4776.05,
            cpp10: 5006.61,
            cpp11: 5006.61,
            cpp12: 3952.59
        },
        {
            cityName: 'Балаково',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1240.11,
            cpp2: 2027.1,
            cpp3: 2623.3,
            cpp4: 2671,
            cpp5: 2217.88,
            cpp6: 1931.7,
            cpp7: 1550.13,
            cpp8: 1788.61,
            cpp9: 3457.99,
            cpp10: 3624.93,
            cpp11: 3624.93,
            cpp12: 2861.78
        },
        {
            cityName: 'Балаково',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 1273.31,
            cpp2: 2081.38,
            cpp3: 2693.55,
            cpp4: 2742.52,
            cpp5: 2277.27,
            cpp6: 1983.43,
            cpp7: 1591.64,
            cpp8: 1836.51,
            cpp9: 3550.59,
            cpp10: 3722,
            cpp11: 3722,
            cpp12: 2938.42
        },
        {
            cityName: 'Балаково',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 652.68,
            cpp2: 1066.88,
            cpp3: 1380.67,
            cpp4: 1405.77,
            cpp5: 1167.29,
            cpp6: 1016.67,
            cpp7: 815.85,
            cpp8: 941.36,
            cpp9: 1819.97,
            cpp10: 1907.83,
            cpp11: 1907.83,
            cpp12: 1506.18
        }
    ],
    Белгород: [
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4326.47,
            cpp2: 7072.12,
            cpp3: 9152.16,
            cpp4: 9318.56,
            cpp5: 7737.73,
            cpp6: 6739.32,
            cpp7: 5408.09,
            cpp8: 6240.11,
            cpp9: 12064.21,
            cpp10: 12646.62,
            cpp11: 12646.62,
            cpp12: 9984.17
        },
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 4232.89,
            cpp2: 6919.15,
            cpp3: 8954.19,
            cpp4: 9117,
            cpp5: 7570.36,
            cpp6: 6593.54,
            cpp7: 5291.12,
            cpp8: 6105.13,
            cpp9: 11803.26,
            cpp10: 12373.07,
            cpp11: 12373.07,
            cpp12: 9768.21
        },
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 4135.4,
            cpp2: 6759.79,
            cpp3: 8747.96,
            cpp4: 8907.01,
            cpp5: 7396,
            cpp6: 6441.68,
            cpp7: 5169.25,
            cpp8: 5964.52,
            cpp9: 11531.4,
            cpp10: 12088.09,
            cpp11: 12088.09,
            cpp12: 9543.23
        },
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 5700.93,
            cpp2: 9318.84,
            cpp3: 12059.67,
            cpp4: 12278.94,
            cpp5: 10195.9,
            cpp6: 8880.3,
            cpp7: 7126.17,
            cpp8: 8222.5,
            cpp9: 15896.84,
            cpp10: 16664.27,
            cpp11: 16664.27,
            cpp12: 13156
        },
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 6320.6,
            cpp2: 10331.76,
            cpp3: 13370.51,
            cpp4: 13613.61,
            cpp5: 11304.16,
            cpp6: 9845.56,
            cpp7: 7900.75,
            cpp8: 9116.25,
            cpp9: 17624.76,
            cpp10: 18475.61,
            cpp11: 18475.61,
            cpp12: 14586.01
        },
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 3060.13,
            cpp2: 5002.14,
            cpp3: 6473.36,
            cpp4: 6591.06,
            cpp5: 5472.93,
            cpp6: 4766.75,
            cpp7: 3825.17,
            cpp8: 4413.65,
            cpp9: 8533.06,
            cpp10: 8945,
            cpp11: 8945,
            cpp12: 7061.85
        },
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 4598.31,
            cpp2: 7516.46,
            cpp3: 9727.19,
            cpp4: 9904.04,
            cpp5: 8223.89,
            cpp6: 7162.75,
            cpp7: 5747.88,
            cpp8: 6632.17,
            cpp9: 12822.2,
            cpp10: 13441.2,
            cpp11: 13441.2,
            cpp12: 10611.48
        },
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1351.32,
            cpp2: 2208.89,
            cpp3: 2858.57,
            cpp4: 2910.54,
            cpp5: 2416.79,
            cpp6: 2104.95,
            cpp7: 1689.15,
            cpp8: 1949.02,
            cpp9: 3768.11,
            cpp10: 3950.02,
            cpp11: 3950.02,
            cpp12: 3118.44
        },
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 3744.43,
            cpp2: 6120.7,
            cpp3: 7920.9,
            cpp4: 8064.92,
            cpp5: 6696.76,
            cpp6: 5832.67,
            cpp7: 4680.53,
            cpp8: 5400.62,
            cpp9: 10441.19,
            cpp10: 10945.25,
            cpp11: 10945.25,
            cpp12: 8640.99
        },
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2840.16,
            cpp2: 4642.58,
            cpp3: 6008.04,
            cpp4: 6117.28,
            cpp5: 5079.52,
            cpp6: 4424.1,
            cpp7: 3550.21,
            cpp8: 4096.39,
            cpp9: 7919.69,
            cpp10: 8302.02,
            cpp11: 8302.02,
            cpp12: 6554.23
        },
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1372.61,
            cpp2: 2243.69,
            cpp3: 2903.59,
            cpp4: 2956.39,
            cpp5: 2454.86,
            cpp6: 2138.1,
            cpp7: 1715.76,
            cpp8: 1979.72,
            cpp9: 3827.46,
            cpp10: 4012.24,
            cpp11: 4012.24,
            cpp12: 3167.56
        },
        {
            cityName: 'Белгород',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1361.84,
            cpp2: 2226.09,
            cpp3: 2880.82,
            cpp4: 2933.2,
            cpp5: 2435.61,
            cpp6: 2121.33,
            cpp7: 1702.31,
            cpp8: 1964.2,
            cpp9: 3797.45,
            cpp10: 3980.78,
            cpp11: 3980.78,
            cpp12: 3142.72
        }
    ],
    Березники: [
        {
            cityName: 'Березники',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1501.17,
            cpp2: 2453.84,
            cpp3: 3175.56,
            cpp4: 3233.29,
            cpp5: 2684.79,
            cpp6: 2338.36,
            cpp7: 1876.47,
            cpp8: 2165.15,
            cpp9: 4185.96,
            cpp10: 4388.04,
            cpp11: 4388.04,
            cpp12: 3464.24
        },
        {
            cityName: 'Березники',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 983.59,
            cpp2: 1607.8,
            cpp3: 2080.68,
            cpp4: 2118.51,
            cpp5: 1759.12,
            cpp6: 1532.13,
            cpp7: 1229.49,
            cpp8: 1418.64,
            cpp9: 2742.71,
            cpp10: 2875.12,
            cpp11: 2875.12,
            cpp12: 2269.83
        },
        {
            cityName: 'Березники',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 1875.1,
            cpp2: 3065.07,
            cpp3: 3966.56,
            cpp4: 4038.68,
            cpp5: 3353.55,
            cpp6: 2920.83,
            cpp7: 2343.88,
            cpp8: 2704.47,
            cpp9: 5228.65,
            cpp10: 5481.07,
            cpp11: 5481.07,
            cpp12: 4327.16
        },
        {
            cityName: 'Березники',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 0,
            cpp2: 0,
            cpp3: 1275.74,
            cpp4: 1298.94,
            cpp5: 1078.58,
            cpp6: 939.41,
            cpp7: 753.85,
            cpp8: 869.83,
            cpp9: 1681.66,
            cpp10: 1762.85,
            cpp11: 1762.85,
            cpp12: 1391.72
        },
        {
            cityName: 'Березники',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 504.96,
            cpp2: 825.41,
            cpp3: 1068.18,
            cpp4: 1087.6,
            cpp5: 903.1,
            cpp6: 786.57,
            cpp7: 631.2,
            cpp8: 728.31,
            cpp9: 1408.06,
            cpp10: 1476.03,
            cpp11: 1476.03,
            cpp12: 1165.29
        },
        {
            cityName: 'Березники',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 0,
            cpp2: 0,
            cpp3: 977.75,
            cpp4: 995.53,
            cpp5: 826.64,
            cpp6: 719.98,
            cpp7: 577.76,
            cpp8: 666.65,
            cpp9: 1288.85,
            cpp10: 1351.07,
            cpp11: 1351.07,
            cpp12: 1066.64
        }
    ],
    Биробиджан: [
        {
            cityName: 'Биробиджан',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2445.6,
            cpp2: 3997.61,
            cpp3: 5173.38,
            cpp4: 5267.44,
            cpp5: 4373.86,
            cpp6: 3809.49,
            cpp7: 3057,
            cpp8: 3527.31,
            cpp9: 6819.46,
            cpp10: 7148.67,
            cpp11: 7148.67,
            cpp12: 5643.69
        },
        {
            cityName: 'Биробиджан',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 1663.17,
            cpp2: 2718.64,
            cpp3: 3518.24,
            cpp4: 3582.21,
            cpp5: 2974.52,
            cpp6: 2590.71,
            cpp7: 2078.96,
            cpp8: 2398.8,
            cpp9: 4637.68,
            cpp10: 4861.57,
            cpp11: 4861.57,
            cpp12: 3838.08
        },
        {
            cityName: 'Биробиджан',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1387.13,
            cpp2: 2267.42,
            cpp3: 2934.3,
            cpp4: 2987.66,
            cpp5: 2480.82,
            cpp6: 2160.72,
            cpp7: 1733.91,
            cpp8: 2000.66,
            cpp9: 3867.95,
            cpp10: 4054.68,
            cpp11: 4054.68,
            cpp12: 3201.06
        },
        {
            cityName: 'Биробиджан',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 1713.13,
            cpp2: 2800.3,
            cpp3: 3623.92,
            cpp4: 3689.81,
            cpp5: 3063.86,
            cpp6: 2668.52,
            cpp7: 2141.41,
            cpp8: 2470.86,
            cpp9: 4776.99,
            cpp10: 5007.6,
            cpp11: 5007.6,
            cpp12: 3953.37
        },
        {
            cityName: 'Биробиджан',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 683.09,
            cpp2: 1116.59,
            cpp3: 1445,
            cpp4: 1471.28,
            cpp5: 1221.69,
            cpp6: 1064.05,
            cpp7: 0,
            cpp8: 0,
            cpp9: 0,
            cpp10: 0,
            cpp11: 0,
            cpp12: 0
        }
    ],
    Благовещенск: [
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 12617.36,
            cpp2: 20624.53,
            cpp3: 26690.57,
            cpp4: 27175.85,
            cpp5: 22565.66,
            cpp6: 19653.96,
            cpp7: 15771.7,
            cpp8: 18198.12,
            cpp9: 35183.02,
            cpp10: 36881.51,
            cpp11: 36881.51,
            cpp12: 29116.98
        },
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 6000.02,
            cpp2: 9807.73,
            cpp3: 12692.36,
            cpp4: 12923.13,
            cpp5: 10730.81,
            cpp6: 9346.19,
            cpp7: 7500.03,
            cpp8: 8653.88,
            cpp9: 16730.83,
            cpp10: 17538.53,
            cpp11: 17538.53,
            cpp12: 13846.21
        },
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 9116.58,
            cpp2: 14902.1,
            cpp3: 19285.07,
            cpp4: 19635.71,
            cpp5: 16304.65,
            cpp6: 14200.82,
            cpp7: 11395.72,
            cpp8: 13148.91,
            cpp9: 25421.23,
            cpp10: 26648.46,
            cpp11: 26648.46,
            cpp12: 21038.26
        },
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 8128.01,
            cpp2: 13286.17,
            cpp3: 17193.87,
            cpp4: 17506.48,
            cpp5: 14536.63,
            cpp6: 12660.94,
            cpp7: 10160.01,
            cpp8: 11723.09,
            cpp9: 22664.64,
            cpp10: 23758.8,
            cpp11: 23758.8,
            cpp12: 18756.94
        },
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 7840.03,
            cpp2: 12815.44,
            cpp3: 16584.69,
            cpp4: 16886.23,
            cpp5: 14021.6,
            cpp6: 12212.36,
            cpp7: 9800.04,
            cpp8: 11307.74,
            cpp9: 21861.63,
            cpp10: 22917.02,
            cpp11: 22917.02,
            cpp12: 18092.39
        },
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 4616.41,
            cpp2: 7546.05,
            cpp3: 9765.47,
            cpp4: 9943.03,
            cpp5: 8256.26,
            cpp6: 7190.94,
            cpp7: 5770.51,
            cpp8: 6658.28,
            cpp9: 12872.67,
            cpp10: 13494.11,
            cpp11: 13494.11,
            cpp12: 10653.24
        },
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 6165.68,
            cpp2: 10078.51,
            cpp3: 13042.78,
            cpp4: 13279.92,
            cpp5: 11027.07,
            cpp6: 9604.23,
            cpp7: 7707.09,
            cpp8: 8892.8,
            cpp9: 17192.75,
            cpp10: 18022.74,
            cpp11: 18022.74,
            cpp12: 14228.48
        },
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 0,
            cpp2: 0,
            cpp3: 8640.48,
            cpp4: 8797.58,
            cpp5: 7305.13,
            cpp6: 6362.53,
            cpp7: 5105.74,
            cpp8: 5891.24,
            cpp9: 11389.72,
            cpp10: 11939.57,
            cpp11: 11939.57,
            cpp12: 9425.98
        },
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 5512.73,
            cpp2: 9011.2,
            cpp3: 11661.55,
            cpp4: 11873.58,
            cpp5: 9859.31,
            cpp6: 8587.14,
            cpp7: 6890.92,
            cpp8: 7951.06,
            cpp9: 15372.04,
            cpp10: 16114.14,
            cpp11: 16114.14,
            cpp12: 12721.69
        },
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 5619.78,
            cpp2: 9186.17,
            cpp3: 11887.99,
            cpp4: 12104.13,
            cpp5: 10050.75,
            cpp6: 8753.88,
            cpp7: 7024.72,
            cpp8: 8105.45,
            cpp9: 15670.53,
            cpp10: 16427.04,
            cpp11: 16427.04,
            cpp12: 12968.71
        },
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'Россия 24',
            cpp1: 5493.84,
            cpp2: 8980.31,
            cpp3: 11621.57,
            cpp4: 11832.88,
            cpp5: 9825.51,
            cpp6: 8557.71,
            cpp7: 6867.29,
            cpp8: 7923.8,
            cpp9: 15319.35,
            cpp10: 16058.9,
            cpp11: 16058.9,
            cpp12: 12678.08
        },
        {
            cityName: 'Благовещенск',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 2385.31,
            cpp2: 3899.06,
            cpp3: 5045.84,
            cpp4: 5137.58,
            cpp5: 4266.03,
            cpp6: 3715.57,
            cpp7: 0,
            cpp8: 0,
            cpp9: 0,
            cpp10: 0,
            cpp11: 0,
            cpp12: 0
        }
    ],
    Братск: [
        {
            cityName: 'Братск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2353.52,
            cpp2: 3847.11,
            cpp3: 4978.61,
            cpp4: 5069.13,
            cpp5: 4209.19,
            cpp6: 3666.07,
            cpp7: 2941.91,
            cpp8: 3394.51,
            cpp9: 6562.71,
            cpp10: 6879.53,
            cpp11: 6879.53,
            cpp12: 5431.21
        },
        {
            cityName: 'Братск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2456.57,
            cpp2: 4015.55,
            cpp3: 5196.6,
            cpp4: 5291.08,
            cpp5: 4393.49,
            cpp6: 3826.58,
            cpp7: 3070.72,
            cpp8: 3543.13,
            cpp9: 6850.06,
            cpp10: 7180.75,
            cpp11: 7180.75,
            cpp12: 5669.01
        },
        {
            cityName: 'Братск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1700.4,
            cpp2: 2779.49,
            cpp3: 3596.99,
            cpp4: 3662.39,
            cpp5: 3041.09,
            cpp6: 2648.69,
            cpp7: 0,
            cpp8: 0,
            cpp9: 0,
            cpp10: 0,
            cpp11: 0,
            cpp12: 0
        },
        {
            cityName: 'Братск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 0,
            cpp2: 0,
            cpp3: 3511.51,
            cpp4: 3575.36,
            cpp5: 2968.82,
            cpp6: 2585.75,
            cpp7: 2074.98,
            cpp8: 2394.21,
            cpp9: 4628.81,
            cpp10: 4852.27,
            cpp11: 4852.27,
            cpp12: 3830.74
        }
    ],
    Брянск: [
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3341.95,
            cpp2: 5462.8,
            cpp3: 7069.51,
            cpp4: 7198.04,
            cpp5: 5976.95,
            cpp6: 5205.73,
            cpp7: 4177.44,
            cpp8: 4820.12,
            cpp9: 9318.9,
            cpp10: 9768.77,
            cpp11: 9768.77,
            cpp12: 7712.19
        },
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2302.5,
            cpp2: 3763.71,
            cpp3: 4870.68,
            cpp4: 4959.24,
            cpp5: 4117.94,
            cpp6: 3586.59,
            cpp7: 2878.13,
            cpp8: 3320.92,
            cpp9: 6420.45,
            cpp10: 6730.4,
            cpp11: 6730.4,
            cpp12: 5313.47
        },
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2133.14,
            cpp2: 3486.86,
            cpp3: 4512.41,
            cpp4: 4594.45,
            cpp5: 3815.04,
            cpp6: 3322.77,
            cpp7: 2666.42,
            cpp8: 3076.64,
            cpp9: 5948.18,
            cpp10: 6235.33,
            cpp11: 6235.33,
            cpp12: 4922.63
        },
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2855.96,
            cpp2: 4668.4,
            cpp3: 6041.46,
            cpp4: 6151.31,
            cpp5: 5107.78,
            cpp6: 4448.71,
            cpp7: 3569.95,
            cpp8: 4119.18,
            cpp9: 7963.75,
            cpp10: 8348.2,
            cpp11: 8348.2,
            cpp12: 6590.69
        },
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2960.1,
            cpp2: 4838.62,
            cpp3: 6261.74,
            cpp4: 6375.59,
            cpp5: 5294.02,
            cpp6: 4610.92,
            cpp7: 3700.12,
            cpp8: 4269.37,
            cpp9: 8254.12,
            cpp10: 8652.59,
            cpp11: 8652.59,
            cpp12: 6830.99
        },
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1250.07,
            cpp2: 2043.39,
            cpp3: 2644.39,
            cpp4: 2692.47,
            cpp5: 2235.71,
            cpp6: 1947.23,
            cpp7: 1562.59,
            cpp8: 1802.99,
            cpp9: 3485.78,
            cpp10: 3654.06,
            cpp11: 3654.06,
            cpp12: 2884.79
        },
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2810.57,
            cpp2: 4594.2,
            cpp3: 5945.43,
            cpp4: 6053.53,
            cpp5: 5026.59,
            cpp6: 4378,
            cpp7: 3513.21,
            cpp8: 4053.7,
            cpp9: 7837.16,
            cpp10: 8215.51,
            cpp11: 8215.51,
            cpp12: 6485.93
        },
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 974.91,
            cpp2: 1593.6,
            cpp3: 2062.3,
            cpp4: 2099.8,
            cpp5: 1743.58,
            cpp6: 1518.6,
            cpp7: 1218.63,
            cpp8: 1406.11,
            cpp9: 2718.49,
            cpp10: 2849.72,
            cpp11: 2849.72,
            cpp12: 2249.78
        },
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1165.59,
            cpp2: 1905.29,
            cpp3: 2465.67,
            cpp4: 2510.5,
            cpp5: 2084.61,
            cpp6: 1815.63,
            cpp7: 1456.99,
            cpp8: 1681.14,
            cpp9: 3250.2,
            cpp10: 3407.11,
            cpp11: 3407.11,
            cpp12: 2689.82
        },
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 953.99,
            cpp2: 1559.41,
            cpp3: 2018.06,
            cpp4: 2054.75,
            cpp5: 1706.17,
            cpp6: 1486.02,
            cpp7: 1192.49,
            cpp8: 1375.95,
            cpp9: 2660.16,
            cpp10: 2788.59,
            cpp11: 2788.59,
            cpp12: 2201.52
        },
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 616.85,
            cpp2: 1008.31,
            cpp3: 1304.88,
            cpp4: 1328.6,
            cpp5: 1103.21,
            cpp6: 960.86,
            cpp7: 771.06,
            cpp8: 889.69,
            cpp9: 1720.06,
            cpp10: 1803.1,
            cpp11: 1803.1,
            cpp12: 1423.5
        },
        {
            cityName: 'Брянск',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 647.12,
            cpp2: 1057.78,
            cpp3: 1368.9,
            cpp4: 1393.79,
            cpp5: 1157.34,
            cpp6: 1008.01,
            cpp7: 808.89,
            cpp8: 933.34,
            cpp9: 1804.46,
            cpp10: 1891.57,
            cpp11: 1891.57,
            cpp12: 1493.34
        }
    ],
    'Великий Новгород': [
        {
            cityName: 'Великий Новгород',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2932.83,
            cpp2: 4794.05,
            cpp3: 6204.07,
            cpp4: 6316.87,
            cpp5: 5245.26,
            cpp6: 4568.45,
            cpp7: 3666.04,
            cpp8: 4230.04,
            cpp9: 8178.09,
            cpp10: 8572.89,
            cpp11: 8572.89,
            cpp12: 6768.07
        },
        {
            cityName: 'Великий Новгород',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 1968.41,
            cpp2: 3217.6,
            cpp3: 4163.95,
            cpp4: 4239.66,
            cpp5: 3520.43,
            cpp6: 3066.18,
            cpp7: 2460.52,
            cpp8: 2839.06,
            cpp9: 5488.84,
            cpp10: 5753.82,
            cpp11: 5753.82,
            cpp12: 4542.49
        },
        {
            cityName: 'Великий Новгород',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1867.73,
            cpp2: 3053.03,
            cpp3: 3950.97,
            cpp4: 4022.81,
            cpp5: 3340.37,
            cpp6: 2909.35,
            cpp7: 2334.67,
            cpp8: 2693.85,
            cpp9: 5208.1,
            cpp10: 5459.53,
            cpp11: 5459.53,
            cpp12: 4310.15
        },
        {
            cityName: 'Великий Новгород',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2442.02,
            cpp2: 3991.76,
            cpp3: 5165.81,
            cpp4: 5259.74,
            cpp5: 4367.46,
            cpp6: 3803.92,
            cpp7: 3052.53,
            cpp8: 3522.14,
            cpp9: 6809.48,
            cpp10: 7138.21,
            cpp11: 7138.21,
            cpp12: 5635.43
        },
        {
            cityName: 'Великий Новгород',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2472.08,
            cpp2: 4040.89,
            cpp3: 5229.39,
            cpp4: 5324.47,
            cpp5: 4421.21,
            cpp6: 3850.73,
            cpp7: 3090.09,
            cpp8: 3565.49,
            cpp9: 6893.29,
            cpp10: 7226.07,
            cpp11: 7226.07,
            cpp12: 5704.79
        },
        {
            cityName: 'Великий Новгород',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 616.78,
            cpp2: 1008.2,
            cpp3: 1304.73,
            cpp4: 1328.45,
            cpp5: 1103.09,
            cpp6: 960.76,
            cpp7: 770.98,
            cpp8: 889.59,
            cpp9: 1719.87,
            cpp10: 1802.9,
            cpp11: 1802.9,
            cpp12: 1423.34
        },
        {
            cityName: 'Великий Новгород',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1448.97,
            cpp2: 2368.51,
            cpp3: 3065.13,
            cpp4: 3120.86,
            cpp5: 2591.43,
            cpp6: 2257.05,
            cpp7: 1811.21,
            cpp8: 2089.86,
            cpp9: 4040.4,
            cpp10: 4235.45,
            cpp11: 4235.45,
            cpp12: 3343.78
        },
        {
            cityName: 'Великий Новгород',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1114.46,
            cpp2: 1821.71,
            cpp3: 2357.51,
            cpp4: 2400.37,
            cpp5: 1993.17,
            cpp6: 1735.98,
            cpp7: 1393.07,
            cpp8: 1607.39,
            cpp9: 3107.63,
            cpp10: 3257.65,
            cpp11: 3257.65,
            cpp12: 2571.83
        },
        {
            cityName: 'Великий Новгород',
            priceType: 'min',
            channelName: 'Россия 24',
            cpp1: 1422.59,
            cpp2: 2325.38,
            cpp3: 3009.32,
            cpp4: 3064.03,
            cpp5: 2544.24,
            cpp6: 2215.95,
            cpp7: 1778.23,
            cpp8: 2051.81,
            cpp9: 3966.83,
            cpp10: 4158.33,
            cpp11: 4158.33,
            cpp12: 3282.89
        }
    ],
    Владикавказ: [
        {
            cityName: 'Владикавказ',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3410.5,
            cpp2: 5574.85,
            cpp3: 7214.52,
            cpp4: 7345.69,
            cpp5: 6099.55,
            cpp6: 5312.51,
            cpp7: 4263.12,
            cpp8: 4918.99,
            cpp9: 9510.04,
            cpp10: 9969.15,
            cpp11: 9969.15,
            cpp12: 7870.38
        },
        {
            cityName: 'Владикавказ',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 1909.71,
            cpp2: 3121.64,
            cpp3: 4039.77,
            cpp4: 4113.22,
            cpp5: 3415.44,
            cpp6: 2974.74,
            cpp7: 2387.13,
            cpp8: 2754.39,
            cpp9: 5325.15,
            cpp10: 5582.22,
            cpp11: 5582.22,
            cpp12: 4407.02
        },
        {
            cityName: 'Владикавказ',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2459.36,
            cpp2: 4020.11,
            cpp3: 5202.49,
            cpp4: 5297.08,
            cpp5: 4398.47,
            cpp6: 3830.92,
            cpp7: 3074.2,
            cpp8: 3547.15,
            cpp9: 6857.83,
            cpp10: 7188.89,
            cpp11: 7188.89,
            cpp12: 5675.44
        },
        {
            cityName: 'Владикавказ',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1385.85,
            cpp2: 2265.32,
            cpp3: 2931.6,
            cpp4: 2984.9,
            cpp5: 2478.53,
            cpp6: 2158.72,
            cpp7: 1732.31,
            cpp8: 1998.82,
            cpp9: 3864.38,
            cpp10: 4050.93,
            cpp11: 4050.93,
            cpp12: 3198.1
        }
    ],
    Владимир: [
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 5457.94,
            cpp2: 8921.64,
            cpp3: 11545.65,
            cpp4: 11755.57,
            cpp5: 9761.32,
            cpp6: 8501.8,
            cpp7: 6822.43,
            cpp8: 7872.03,
            cpp9: 15219.26,
            cpp10: 15953.99,
            cpp11: 15953.99,
            cpp12: 12595.25
        },
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 4005.33,
            cpp2: 6547.18,
            cpp3: 8472.82,
            cpp4: 8626.87,
            cpp5: 7163.38,
            cpp6: 6239.08,
            cpp7: 5006.67,
            cpp8: 5776.92,
            cpp9: 11168.72,
            cpp10: 11707.9,
            cpp11: 11707.9,
            cpp12: 9243.08
        },
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 4471.02,
            cpp2: 7308.4,
            cpp3: 9457.93,
            cpp4: 9629.9,
            cpp5: 7996.25,
            cpp6: 6964.48,
            cpp7: 5588.78,
            cpp8: 6448.59,
            cpp9: 12467.28,
            cpp10: 13069.15,
            cpp11: 13069.15,
            cpp12: 10317.75
        },
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 5840.36,
            cpp2: 9546.74,
            cpp3: 12354.6,
            cpp4: 12579.23,
            cpp5: 10445.26,
            cpp6: 9097.48,
            cpp7: 7300.45,
            cpp8: 8423.59,
            cpp9: 16285.62,
            cpp10: 17071.82,
            cpp11: 17071.82,
            cpp12: 13477.75
        },
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 7241.19,
            cpp2: 11836.55,
            cpp3: 15317.89,
            cpp4: 15596.4,
            cpp5: 12950.58,
            cpp6: 11279.54,
            cpp7: 9051.48,
            cpp8: 10444.02,
            cpp9: 20191.77,
            cpp10: 21166.54,
            cpp11: 21166.54,
            cpp12: 16710.43
        },
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 2215.64,
            cpp2: 3621.72,
            cpp3: 4686.93,
            cpp4: 4772.14,
            cpp5: 3962.58,
            cpp6: 3451.28,
            cpp7: 2769.55,
            cpp8: 3195.63,
            cpp9: 6178.22,
            cpp10: 6476.48,
            cpp11: 6476.48,
            cpp12: 5113.01
        },
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2041.58,
            cpp2: 3337.19,
            cpp3: 4318.72,
            cpp4: 4397.24,
            cpp5: 3651.28,
            cpp6: 3180.15,
            cpp7: 2551.97,
            cpp8: 2944.58,
            cpp9: 5692.85,
            cpp10: 5967.68,
            cpp11: 5967.68,
            cpp12: 4711.33
        },
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1110.44,
            cpp2: 1815.14,
            cpp3: 2349,
            cpp4: 2391.71,
            cpp5: 1985.97,
            cpp6: 1729.72,
            cpp7: 1388.05,
            cpp8: 1601.59,
            cpp9: 3096.41,
            cpp10: 3245.89,
            cpp11: 3245.89,
            cpp12: 2562.55
        },
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2507.19,
            cpp2: 4098.29,
            cpp3: 5303.66,
            cpp4: 5400.09,
            cpp5: 4484.01,
            cpp6: 3905.43,
            cpp7: 3133.98,
            cpp8: 3616.13,
            cpp9: 6991.19,
            cpp10: 7328.7,
            cpp11: 7328.7,
            cpp12: 5785.82
        },
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 4456.05,
            cpp2: 7283.93,
            cpp3: 9426.26,
            cpp4: 9597.64,
            cpp5: 7969.47,
            cpp6: 6941.15,
            cpp7: 5570.06,
            cpp8: 6426.99,
            cpp9: 12425.52,
            cpp10: 13025.37,
            cpp11: 13025.37,
            cpp12: 10283.19
        },
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'Россия 24',
            cpp1: 3040.53,
            cpp2: 4970.1,
            cpp3: 6431.9,
            cpp4: 6548.84,
            cpp5: 5437.88,
            cpp6: 4736.22,
            cpp7: 3800.67,
            cpp8: 4385.39,
            cpp9: 8478.41,
            cpp10: 8887.72,
            cpp11: 8887.72,
            cpp12: 7016.62
        },
        {
            cityName: 'Владимир',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1684.26,
            cpp2: 2753.11,
            cpp3: 3562.85,
            cpp4: 3627.63,
            cpp5: 3012.23,
            cpp6: 2623.55,
            cpp7: 0,
            cpp8: 0,
            cpp9: 0,
            cpp10: 0,
            cpp11: 0,
            cpp12: 0
        }
    ],
    Волгодонск: [
        {
            cityName: 'Волгодонск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 746.25,
            cpp2: 1219.83,
            cpp3: 1578.6,
            cpp4: 1607.31,
            cpp5: 1334.64,
            cpp6: 1162.43,
            cpp7: 932.81,
            cpp8: 1076.32,
            cpp9: 2080.89,
            cpp10: 2181.34,
            cpp11: 2181.34,
            cpp12: 1722.11
        },
        {
            cityName: 'Волгодонск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 548.55,
            cpp2: 896.67,
            cpp3: 1160.39,
            cpp4: 1181.49,
            cpp5: 981.06,
            cpp6: 854.47,
            cpp7: 685.69,
            cpp8: 791.18,
            cpp9: 1529.61,
            cpp10: 1603.45,
            cpp11: 1603.45,
            cpp12: 1265.88
        }
    ],
    Вологда: [
        {
            cityName: 'Вологда',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4473.11,
            cpp2: 7311.81,
            cpp3: 9462.35,
            cpp4: 9634.39,
            cpp5: 7999.98,
            cpp6: 6967.73,
            cpp7: 5591.39,
            cpp8: 6451.6,
            cpp9: 12473.09,
            cpp10: 13075.24,
            cpp11: 13075.24,
            cpp12: 10322.56
        },
        {
            cityName: 'Вологда',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 3099.3,
            cpp2: 5066.16,
            cpp3: 6556.2,
            cpp4: 6675.41,
            cpp5: 5542.97,
            cpp6: 4827.75,
            cpp7: 3874.12,
            cpp8: 4470.14,
            cpp9: 8642.27,
            cpp10: 9059.48,
            cpp11: 9059.48,
            cpp12: 7152.22
        },
        {
            cityName: 'Вологда',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2448.54,
            cpp2: 4002.41,
            cpp3: 5179.6,
            cpp4: 5273.77,
            cpp5: 4379.11,
            cpp6: 3814.07,
            cpp7: 3060.67,
            cpp8: 3531.54,
            cpp9: 6827.65,
            cpp10: 7157.26,
            cpp11: 7157.26,
            cpp12: 5650.47
        },
        {
            cityName: 'Вологда',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2948.65,
            cpp2: 4819.9,
            cpp3: 6237.52,
            cpp4: 6350.93,
            cpp5: 5273.54,
            cpp6: 4593.08,
            cpp7: 3685.81,
            cpp8: 4252.86,
            cpp9: 8222.19,
            cpp10: 8619.12,
            cpp11: 8619.12,
            cpp12: 6804.57
        },
        {
            cityName: 'Вологда',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2991.56,
            cpp2: 4890.06,
            cpp3: 6328.31,
            cpp4: 6443.37,
            cpp5: 5350.3,
            cpp6: 4659.93,
            cpp7: 3739.45,
            cpp8: 4314.75,
            cpp9: 8341.86,
            cpp10: 8744.57,
            cpp11: 8744.57,
            cpp12: 6903.61
        },
        {
            cityName: 'Вологда',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1108.66,
            cpp2: 1812.23,
            cpp3: 2345.24,
            cpp4: 2387.88,
            cpp5: 1982.79,
            cpp6: 1726.95,
            cpp7: 1385.82,
            cpp8: 1599.03,
            cpp9: 3091.45,
            cpp10: 3240.69,
            cpp11: 3240.69,
            cpp12: 2558.44
        },
        {
            cityName: 'Вологда',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1668.74,
            cpp2: 2727.75,
            cpp3: 3530.03,
            cpp4: 3594.21,
            cpp5: 2984.48,
            cpp6: 2599.38,
            cpp7: 2085.92,
            cpp8: 2406.84,
            cpp9: 4653.22,
            cpp10: 4877.85,
            cpp11: 4877.85,
            cpp12: 3850.94
        },
        {
            cityName: 'Вологда',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1437.17,
            cpp2: 2349.22,
            cpp3: 3040.17,
            cpp4: 3095.45,
            cpp5: 2570.33,
            cpp6: 2238.67,
            cpp7: 1796.46,
            cpp8: 2072.84,
            cpp9: 4007.5,
            cpp10: 4200.96,
            cpp11: 4200.96,
            cpp12: 3316.55
        },
        {
            cityName: 'Вологда',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1866.82,
            cpp2: 3051.53,
            cpp3: 3949.04,
            cpp4: 4020.84,
            cpp5: 3338.73,
            cpp6: 2907.93,
            cpp7: 2333.52,
            cpp8: 2692.53,
            cpp9: 5205.55,
            cpp10: 5456.85,
            cpp11: 5456.85,
            cpp12: 4308.04
        },
        {
            cityName: 'Вологда',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1076.99,
            cpp2: 1760.47,
            cpp3: 2278.25,
            cpp4: 2319.67,
            cpp5: 1926.16,
            cpp6: 1677.62,
            cpp7: 1346.24,
            cpp8: 1553.35,
            cpp9: 3003.15,
            cpp10: 3148.13,
            cpp11: 3148.13,
            cpp12: 2485.37
        }
    ],
    Выборг: [
        {
            cityName: 'Выборг',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1705.25,
            cpp2: 2787.44,
            cpp3: 3607.27,
            cpp4: 3672.86,
            cpp5: 3049.78,
            cpp6: 2656.26,
            cpp7: 2131.57,
            cpp8: 2459.5,
            cpp9: 4755.04,
            cpp10: 4984.59,
            cpp11: 4984.59,
            cpp12: 3935.2
        }
    ],
    Дзержинск: [
        {
            cityName: 'Дзержинск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 4830.56,
            cpp2: 7896.1,
            cpp3: 10218.49,
            cpp4: 10404.28,
            cpp5: 8639.27,
            cpp6: 7524.52,
            cpp7: 6038.2,
            cpp8: 6967.15,
            cpp9: 13469.82,
            cpp10: 14120.09,
            cpp11: 14120.09,
            cpp12: 11147.44
        },
        {
            cityName: 'Дзержинск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 2703.19,
            cpp2: 4418.68,
            cpp3: 5718.29,
            cpp4: 5822.26,
            cpp5: 4834.55,
            cpp6: 4210.74,
            cpp7: 3378.99,
            cpp8: 3898.83,
            cpp9: 7537.74,
            cpp10: 7901.63,
            cpp11: 7901.63,
            cpp12: 6238.13
        }
    ],
    Златоуст: [
        {
            cityName: 'Златоуст',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1470.89,
            cpp2: 2404.34,
            cpp3: 3111.51,
            cpp4: 3168.08,
            cpp5: 2630.64,
            cpp6: 2291.2,
            cpp7: 1838.62,
            cpp8: 2121.48,
            cpp9: 4101.53,
            cpp10: 4299.53,
            cpp11: 4299.53,
            cpp12: 3394.37
        },
        {
            cityName: 'Златоуст',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1149.1,
            cpp2: 1878.34,
            cpp3: 2430.79,
            cpp4: 2474.99,
            cpp5: 2055.12,
            cpp6: 1789.94,
            cpp7: 1436.38,
            cpp8: 1657.36,
            cpp9: 3204.22,
            cpp10: 3358.91,
            cpp11: 3358.91,
            cpp12: 2651.77
        },
        {
            cityName: 'Златоуст',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 1354.9,
            cpp2: 2214.74,
            cpp3: 2866.14,
            cpp4: 2918.25,
            cpp5: 2423.19,
            cpp6: 2110.52,
            cpp7: 1693.63,
            cpp8: 1954.18,
            cpp9: 3778.09,
            cpp10: 3960.48,
            cpp11: 3960.48,
            cpp12: 3126.69
        }
    ],
    Иваново: [
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4909.2,
            cpp2: 8024.65,
            cpp3: 10384.84,
            cpp4: 10573.66,
            cpp5: 8779.91,
            cpp6: 7647.02,
            cpp7: 6136.5,
            cpp8: 7080.57,
            cpp9: 13689.11,
            cpp10: 14349.96,
            cpp11: 14349.96,
            cpp12: 11328.92
        },
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 3881.43,
            cpp2: 6344.65,
            cpp3: 8210.72,
            cpp4: 8360,
            cpp5: 6941.79,
            cpp6: 6046.07,
            cpp7: 4851.79,
            cpp8: 5598.22,
            cpp9: 10823.22,
            cpp10: 11345.72,
            cpp11: 11345.72,
            cpp12: 8957.15
        },
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 3983.71,
            cpp2: 6511.83,
            cpp3: 8427.08,
            cpp4: 8580.3,
            cpp5: 7124.71,
            cpp6: 6205.39,
            cpp7: 4979.64,
            cpp8: 5745.74,
            cpp9: 11108.42,
            cpp10: 11644.69,
            cpp11: 11644.69,
            cpp12: 9193.18
        },
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 5553.04,
            cpp2: 9077.09,
            cpp3: 11746.82,
            cpp4: 11960.4,
            cpp5: 9931.4,
            cpp6: 8649.93,
            cpp7: 6941.3,
            cpp8: 8009.2,
            cpp9: 15484.44,
            cpp10: 16231.97,
            cpp11: 16231.97,
            cpp12: 12814.71
        },
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 6443.77,
            cpp2: 10533.09,
            cpp3: 13631.06,
            cpp4: 13878.89,
            cpp5: 11524.44,
            cpp6: 10037.41,
            cpp7: 8054.71,
            cpp8: 9293.9,
            cpp9: 17968.21,
            cpp10: 18835.64,
            cpp11: 18835.64,
            cpp12: 14870.24
        },
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 3051.63,
            cpp2: 4988.24,
            cpp3: 6455.37,
            cpp4: 6572.74,
            cpp5: 5457.72,
            cpp6: 4753.5,
            cpp7: 3814.54,
            cpp8: 4401.39,
            cpp9: 8509.35,
            cpp10: 8920.15,
            cpp11: 8920.15,
            cpp12: 7042.22
        },
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2745.7,
            cpp2: 4488.16,
            cpp3: 5808.21,
            cpp4: 5913.82,
            cpp5: 4910.58,
            cpp6: 4276.96,
            cpp7: 3432.13,
            cpp8: 3960.15,
            cpp9: 7656.28,
            cpp10: 8025.89,
            cpp11: 8025.89,
            cpp12: 6336.23
        },
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 3871.74,
            cpp2: 6328.81,
            cpp3: 8190.22,
            cpp4: 8339.14,
            cpp5: 6924.46,
            cpp6: 6030.98,
            cpp7: 4839.68,
            cpp8: 5584.24,
            cpp9: 10796.2,
            cpp10: 11317.4,
            cpp11: 11317.4,
            cpp12: 8934.79
        },
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2185.87,
            cpp2: 3573.06,
            cpp3: 4623.96,
            cpp4: 4708.03,
            cpp5: 3909.35,
            cpp6: 3404.91,
            cpp7: 2732.34,
            cpp8: 3152.7,
            cpp9: 6095.22,
            cpp10: 6389.47,
            cpp11: 6389.47,
            cpp12: 5044.32
        },
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1834.12,
            cpp2: 2998.08,
            cpp3: 3879.87,
            cpp4: 3950.41,
            cpp5: 3280.25,
            cpp6: 2856.99,
            cpp7: 2292.65,
            cpp8: 2645.37,
            cpp9: 5114.37,
            cpp10: 5361.27,
            cpp11: 5361.27,
            cpp12: 4232.59
        },
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1427.3,
            cpp2: 2333.08,
            cpp3: 3019.28,
            cpp4: 3074.18,
            cpp5: 2552.67,
            cpp6: 2223.29,
            cpp7: 1784.12,
            cpp8: 2058.6,
            cpp9: 3979.97,
            cpp10: 4172.1,
            cpp11: 4172.1,
            cpp12: 3293.76
        },
        {
            cityName: 'Иваново',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1499.1,
            cpp2: 2450.45,
            cpp3: 3171.17,
            cpp4: 3228.83,
            cpp5: 2681.08,
            cpp6: 2335.13,
            cpp7: 1873.87,
            cpp8: 2162.16,
            cpp9: 4180.18,
            cpp10: 4381.98,
            cpp11: 4381.98,
            cpp12: 3459.46
        }
    ],
    'Йошкар-Ола': [
        {
            cityName: 'Йошкар-Ола',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3124.97,
            cpp2: 5108.13,
            cpp3: 6610.52,
            cpp4: 6730.71,
            cpp5: 5588.89,
            cpp6: 4867.75,
            cpp7: 3906.22,
            cpp8: 4507.17,
            cpp9: 8713.87,
            cpp10: 9134.54,
            cpp11: 9134.54,
            cpp12: 7211.48
        },
        {
            cityName: 'Йошкар-Ола',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2452.73,
            cpp2: 4009.26,
            cpp3: 5188.46,
            cpp4: 5282.79,
            cpp5: 4386.61,
            cpp6: 3820.59,
            cpp7: 3065.91,
            cpp8: 3537.59,
            cpp9: 6839.33,
            cpp10: 7169.51,
            cpp11: 7169.51,
            cpp12: 5660.14
        },
        {
            cityName: 'Йошкар-Ола',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2184.17,
            cpp2: 3570.27,
            cpp3: 4620.35,
            cpp4: 4704.36,
            cpp5: 3906.3,
            cpp6: 3402.26,
            cpp7: 2730.21,
            cpp8: 3150.24,
            cpp9: 6090.47,
            cpp10: 6384.49,
            cpp11: 6384.49,
            cpp12: 5040.39
        },
        {
            cityName: 'Йошкар-Ола',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3004.04,
            cpp2: 4910.45,
            cpp3: 6354.69,
            cpp4: 6470.23,
            cpp5: 5372.61,
            cpp6: 4679.37,
            cpp7: 3755.05,
            cpp8: 4332.75,
            cpp9: 8376.64,
            cpp10: 8781.03,
            cpp11: 8781.03,
            cpp12: 6932.39
        },
        {
            cityName: 'Йошкар-Ола',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 3076.58,
            cpp2: 5029.02,
            cpp3: 6508.14,
            cpp4: 6626.47,
            cpp5: 5502.34,
            cpp6: 4792.36,
            cpp7: 3845.72,
            cpp8: 4437.37,
            cpp9: 8578.91,
            cpp10: 8993.07,
            cpp11: 8993.07,
            cpp12: 7099.79
        },
        {
            cityName: 'Йошкар-Ола',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1399.44,
            cpp2: 2287.54,
            cpp3: 2960.35,
            cpp4: 3014.18,
            cpp5: 2502.84,
            cpp6: 2179.89,
            cpp7: 1749.3,
            cpp8: 2018.42,
            cpp9: 3902.28,
            cpp10: 4090.67,
            cpp11: 4090.67,
            cpp12: 3229.47
        },
        {
            cityName: 'Йошкар-Ола',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2625.18,
            cpp2: 4291.16,
            cpp3: 5553.26,
            cpp4: 5654.23,
            cpp5: 4695.03,
            cpp6: 4089.22,
            cpp7: 3281.47,
            cpp8: 3786.31,
            cpp9: 7320.21,
            cpp10: 7673.6,
            cpp11: 7673.6,
            cpp12: 6058.1
        },
        {
            cityName: 'Йошкар-Ола',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2162.99,
            cpp2: 3535.66,
            cpp3: 4575.56,
            cpp4: 4658.75,
            cpp5: 3868.43,
            cpp6: 3369.28,
            cpp7: 2703.74,
            cpp8: 3119.7,
            cpp9: 6031.42,
            cpp10: 6322.59,
            cpp11: 6322.59,
            cpp12: 4991.52
        },
        {
            cityName: 'Йошкар-Ола',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 388.62,
            cpp2: 635.25,
            cpp3: 822.09,
            cpp4: 837.03,
            cpp5: 695.04,
            cpp6: 605.35,
            cpp7: 485.78,
            cpp8: 560.51,
            cpp9: 1083.66,
            cpp10: 1135.97,
            cpp11: 1135.97,
            cpp12: 896.82
        }
    ],
    Калининград: [
        {
            cityName: 'Калининград',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 9409.99,
            cpp2: 15381.71,
            cpp3: 19905.74,
            cpp4: 20267.67,
            cpp5: 16829.4,
            cpp6: 14657.87,
            cpp7: 11762.48,
            cpp8: 13572.1,
            cpp9: 26239.39,
            cpp10: 27506.12,
            cpp11: 27506.12,
            cpp12: 21715.36
        },
        {
            cityName: 'Калининград',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 6808.07,
            cpp2: 11128.58,
            cpp3: 14401.69,
            cpp4: 14663.54,
            cpp5: 12175.97,
            cpp6: 10604.88,
            cpp7: 8510.09,
            cpp8: 9819.33,
            cpp9: 18984.05,
            cpp10: 19900.52,
            cpp11: 19900.52,
            cpp12: 15710.93
        },
        {
            cityName: 'Калининград',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 7164.31,
            cpp2: 11710.9,
            cpp3: 15155.28,
            cpp4: 15430.83,
            cpp5: 12813.1,
            cpp6: 11159.8,
            cpp7: 8955.39,
            cpp8: 10333.14,
            cpp9: 19977.41,
            cpp10: 20941.84,
            cpp11: 20941.84,
            cpp12: 16533.03
        },
        {
            cityName: 'Калининград',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 8403.42,
            cpp2: 13736.35,
            cpp3: 17776.45,
            cpp4: 18099.66,
            cpp5: 15029.18,
            cpp6: 13089.94,
            cpp7: 10504.27,
            cpp8: 12120.31,
            cpp9: 23432.6,
            cpp10: 24563.83,
            cpp11: 24563.83,
            cpp12: 19392.5
        },
        {
            cityName: 'Калининград',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 6982.76,
            cpp2: 11414.13,
            cpp3: 14771.23,
            cpp4: 15039.8,
            cpp5: 12488.4,
            cpp6: 10877,
            cpp7: 8728.45,
            cpp8: 10071.29,
            cpp9: 19471.16,
            cpp10: 20411.15,
            cpp11: 20411.15,
            cpp12: 16114.07
        },
        {
            cityName: 'Калининград',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 3413.24,
            cpp2: 5579.33,
            cpp3: 7220.31,
            cpp4: 7351.58,
            cpp5: 6104.44,
            cpp6: 5316.77,
            cpp7: 4266.54,
            cpp8: 4922.94,
            cpp9: 9517.68,
            cpp10: 9977.15,
            cpp11: 9977.15,
            cpp12: 7876.7
        },
        {
            cityName: 'Калининград',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 4780.8,
            cpp2: 7814.77,
            cpp3: 10113.23,
            cpp4: 10297.11,
            cpp5: 8550.27,
            cpp6: 7447.01,
            cpp7: 5976,
            cpp8: 6895.38,
            cpp9: 13331.07,
            cpp10: 13974.64,
            cpp11: 13974.64,
            cpp12: 11032.61
        },
        {
            cityName: 'Калининград',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 4884.95,
            cpp2: 7985.02,
            cpp3: 10333.55,
            cpp4: 10521.44,
            cpp5: 8736.55,
            cpp6: 7609.25,
            cpp7: 6106.19,
            cpp8: 7045.61,
            cpp9: 13621.5,
            cpp10: 14279.09,
            cpp11: 14279.09,
            cpp12: 11272.97
        },
        {
            cityName: 'Калининград',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 3580.42,
            cpp2: 5852.61,
            cpp3: 7573.96,
            cpp4: 7711.67,
            cpp5: 6403.44,
            cpp6: 5577.19,
            cpp7: 4475.52,
            cpp8: 5164.06,
            cpp9: 9983.86,
            cpp10: 10465.84,
            cpp11: 10465.84,
            cpp12: 8262.5
        },
        {
            cityName: 'Калининград',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 3915.01,
            cpp2: 6399.53,
            cpp3: 8281.74,
            cpp4: 8432.32,
            cpp5: 7001.84,
            cpp6: 6098.37,
            cpp7: 4893.76,
            cpp8: 5646.64,
            cpp9: 10916.84,
            cpp10: 11443.86,
            cpp11: 11443.86,
            cpp12: 9034.63
        },
        {
            cityName: 'Калининград',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 4085.43,
            cpp2: 6678.11,
            cpp3: 8642.26,
            cpp4: 8799.39,
            cpp5: 7306.64,
            cpp6: 6363.85,
            cpp7: 5106.79,
            cpp8: 5892.45,
            cpp9: 11392.07,
            cpp10: 11942.03,
            cpp11: 11942.03,
            cpp12: 9427.92
        }
    ],
    Калуга: [
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2687.64,
            cpp2: 4393.25,
            cpp3: 5685.38,
            cpp4: 5788.75,
            cpp5: 4806.73,
            cpp6: 4186.51,
            cpp7: 3359.54,
            cpp8: 3876.4,
            cpp9: 7494.37,
            cpp10: 7856.17,
            cpp11: 7856.17,
            cpp12: 6202.24
        },
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2362.77,
            cpp2: 3862.22,
            cpp3: 4998.17,
            cpp4: 5089.04,
            cpp5: 4225.72,
            cpp6: 3680.47,
            cpp7: 2953.46,
            cpp8: 3407.84,
            cpp9: 6588.49,
            cpp10: 6906.56,
            cpp11: 6906.56,
            cpp12: 5452.54
        },
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2876.27,
            cpp2: 4701.59,
            cpp3: 6084.41,
            cpp4: 6195.04,
            cpp5: 5144.1,
            cpp6: 4480.34,
            cpp7: 3595.34,
            cpp8: 4148.46,
            cpp9: 8020.36,
            cpp10: 8407.55,
            cpp11: 8407.55,
            cpp12: 6637.54
        },
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2317.6,
            cpp2: 3788.38,
            cpp3: 4902.61,
            cpp4: 4991.75,
            cpp5: 4144.93,
            cpp6: 3610.1,
            cpp7: 2897,
            cpp8: 3342.69,
            cpp9: 6462.53,
            cpp10: 6774.51,
            cpp11: 6774.51,
            cpp12: 5348.3
        },
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2819.23,
            cpp2: 4608.35,
            cpp3: 5963.75,
            cpp4: 6072.18,
            cpp5: 5042.08,
            cpp6: 4391.49,
            cpp7: 3524.03,
            cpp8: 4066.19,
            cpp9: 7861.31,
            cpp10: 8240.82,
            cpp11: 8240.82,
            cpp12: 6505.91
        },
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1412.68,
            cpp2: 2309.18,
            cpp3: 2988.35,
            cpp4: 3042.68,
            cpp5: 2526.52,
            cpp6: 2200.51,
            cpp7: 1765.84,
            cpp8: 2037.51,
            cpp9: 3939.19,
            cpp10: 4129.36,
            cpp11: 4129.36,
            cpp12: 3260.02
        },
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1055,
            cpp2: 1724.52,
            cpp3: 2231.73,
            cpp4: 2272.31,
            cpp5: 1886.83,
            cpp6: 1643.37,
            cpp7: 1318.75,
            cpp8: 1521.64,
            cpp9: 2941.83,
            cpp10: 3083.85,
            cpp11: 3083.85,
            cpp12: 2434.62
        },
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1386.81,
            cpp2: 2266.9,
            cpp3: 2933.63,
            cpp4: 2986.97,
            cpp5: 2480.25,
            cpp6: 2160.22,
            cpp7: 1733.51,
            cpp8: 2000.2,
            cpp9: 3867.06,
            cpp10: 4053.74,
            cpp11: 4053.74,
            cpp12: 3200.32
        },
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2010.46,
            cpp2: 3286.32,
            cpp3: 4252.89,
            cpp4: 4330.21,
            cpp5: 3595.62,
            cpp6: 3131.67,
            cpp7: 2513.07,
            cpp8: 2899.7,
            cpp9: 5606.08,
            cpp10: 5876.72,
            cpp11: 5876.72,
            cpp12: 4639.52
        },
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1870.35,
            cpp2: 3057.31,
            cpp3: 3956.52,
            cpp4: 4028.46,
            cpp5: 3345.06,
            cpp6: 2913.44,
            cpp7: 2337.94,
            cpp8: 2697.63,
            cpp9: 5215.41,
            cpp10: 5467.19,
            cpp11: 5467.19,
            cpp12: 4316.2
        },
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1142.54,
            cpp2: 1867.61,
            cpp3: 2416.91,
            cpp4: 2460.86,
            cpp5: 2043.39,
            cpp6: 1779.73,
            cpp7: 1428.18,
            cpp8: 1647.89,
            cpp9: 3185.93,
            cpp10: 3339.73,
            cpp11: 3339.73,
            cpp12: 2636.63
        },
        {
            cityName: 'Калуга',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1756.22,
            cpp2: 2870.75,
            cpp3: 3715.09,
            cpp4: 3782.64,
            cpp5: 3140.94,
            cpp6: 2735.66,
            cpp7: 2195.28,
            cpp8: 2533.02,
            cpp9: 4897.16,
            cpp10: 5133.58,
            cpp11: 5133.58,
            cpp12: 4052.82
        }
    ],
    'Каменск-Уральский': [
        {
            cityName: 'Каменск-Уральский',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1215.4,
            cpp2: 1986.71,
            cpp3: 2571.04,
            cpp4: 2617.78,
            cpp5: 2173.69,
            cpp6: 1893.22,
            cpp7: 1519.25,
            cpp8: 1752.98,
            cpp9: 3389.09,
            cpp10: 3552.7,
            cpp11: 3552.7,
            cpp12: 2804.77
        },
        {
            cityName: 'Каменск-Уральский',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1138.96,
            cpp2: 1861.76,
            cpp3: 2409.33,
            cpp4: 2453.14,
            cpp5: 2036.98,
            cpp6: 1774.14,
            cpp7: 1423.7,
            cpp8: 1642.73,
            cpp9: 3175.94,
            cpp10: 3329.26,
            cpp11: 3329.26,
            cpp12: 2628.36
        }
    ],
    Камышин: [
        {
            cityName: 'Камышин',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1654.29,
            cpp2: 2704.13,
            cpp3: 3499.46,
            cpp4: 3563.09,
            cpp5: 2958.63,
            cpp6: 2576.87,
            cpp7: 2067.86,
            cpp8: 2385.99,
            cpp9: 4612.92,
            cpp10: 4835.62,
            cpp11: 4835.62,
            cpp12: 3817.59
        },
        {
            cityName: 'Камышин',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 926.42,
            cpp2: 1514.34,
            cpp3: 1959.73,
            cpp4: 1995.36,
            cpp5: 1656.86,
            cpp6: 1443.07,
            cpp7: 1158.02,
            cpp8: 1336.18,
            cpp9: 2583.28,
            cpp10: 2707.99,
            cpp11: 2707.99,
            cpp12: 2137.89
        },
        {
            cityName: 'Камышин',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 509.7,
            cpp2: 833.17,
            cpp3: 1078.22,
            cpp4: 1097.83,
            cpp5: 911.59,
            cpp6: 793.96,
            cpp7: 637.13,
            cpp8: 735.15,
            cpp9: 1421.29,
            cpp10: 1489.91,
            cpp11: 1489.91,
            cpp12: 1176.24
        }
    ],
    Киров: [
        {
            cityName: 'Киров',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4606.08,
            cpp2: 7529.17,
            cpp3: 9743.64,
            cpp4: 9920.79,
            cpp5: 8237.8,
            cpp6: 7174.86,
            cpp7: 5757.6,
            cpp8: 6643.39,
            cpp9: 12843.88,
            cpp10: 13463.93,
            cpp11: 13463.93,
            cpp12: 10629.42
        },
        {
            cityName: 'Киров',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 3699.57,
            cpp2: 6047.37,
            cpp3: 7826.01,
            cpp4: 7968.3,
            cpp5: 6616.54,
            cpp6: 5762.79,
            cpp7: 4624.46,
            cpp8: 5335.92,
            cpp9: 10316.1,
            cpp10: 10814.12,
            cpp11: 10814.12,
            cpp12: 8537.47
        },
        {
            cityName: 'Киров',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 3915.32,
            cpp2: 6400.04,
            cpp3: 8282.4,
            cpp4: 8432.99,
            cpp5: 7002.39,
            cpp6: 6098.86,
            cpp7: 4894.15,
            cpp8: 5647.09,
            cpp9: 10917.71,
            cpp10: 11444.77,
            cpp11: 11444.77,
            cpp12: 9035.35
        },
        {
            cityName: 'Киров',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 4524.14,
            cpp2: 7395.23,
            cpp3: 9570.3,
            cpp4: 9744.31,
            cpp5: 8091.26,
            cpp6: 7047.22,
            cpp7: 5655.18,
            cpp8: 6525.21,
            cpp9: 12615.4,
            cpp10: 13224.42,
            cpp11: 13224.42,
            cpp12: 10440.33
        },
        {
            cityName: 'Киров',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 2075,
            cpp2: 3391.83,
            cpp3: 4389.42,
            cpp4: 4469.23,
            cpp5: 3711.06,
            cpp6: 3232.21,
            cpp7: 2593.75,
            cpp8: 2992.79,
            cpp9: 5786.06,
            cpp10: 6065.38,
            cpp11: 6065.38,
            cpp12: 4788.46
        },
        {
            cityName: 'Киров',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2283.55,
            cpp2: 3732.73,
            cpp3: 4830.59,
            cpp4: 4918.42,
            cpp5: 4084.04,
            cpp6: 3557.07,
            cpp7: 2854.44,
            cpp8: 3293.58,
            cpp9: 6367.6,
            cpp10: 6675,
            cpp11: 6675,
            cpp12: 5269.73
        },
        {
            cityName: 'Киров',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 2254.3,
            cpp2: 3684.91,
            cpp3: 4768.71,
            cpp4: 4855.42,
            cpp5: 4031.73,
            cpp6: 3511.51,
            cpp7: 2817.88,
            cpp8: 3251.4,
            cpp9: 6286.03,
            cpp10: 6589.49,
            cpp11: 6589.49,
            cpp12: 5202.23
        },
        {
            cityName: 'Киров',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 4209.12,
            cpp2: 6880.29,
            cpp3: 8903.91,
            cpp4: 9065.8,
            cpp5: 7527.85,
            cpp6: 6556.51,
            cpp7: 5261.4,
            cpp8: 6070.85,
            cpp9: 11736.97,
            cpp10: 12303.58,
            cpp11: 12303.58,
            cpp12: 9713.35
        },
        {
            cityName: 'Киров',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 0,
            cpp2: 0,
            cpp3: 5737.03,
            cpp4: 5841.34,
            cpp5: 4850.4,
            cpp6: 4224.54,
            cpp7: 3390.06,
            cpp8: 3911.61,
            cpp9: 7562.45,
            cpp10: 7927.53,
            cpp11: 7927.53,
            cpp12: 6258.58
        },
        {
            cityName: 'Киров',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 782.6,
            cpp2: 1279.25,
            cpp3: 1655.5,
            cpp4: 1685.6,
            cpp5: 1399.65,
            cpp6: 1219.05,
            cpp7: 978.25,
            cpp8: 1128.75,
            cpp9: 2182.25,
            cpp10: 2287.6,
            cpp11: 2287.6,
            cpp12: 1806
        },
        {
            cityName: 'Киров',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 2235.07,
            cpp2: 3653.48,
            cpp3: 4728.03,
            cpp4: 4814,
            cpp5: 3997.34,
            cpp6: 3481.55,
            cpp7: 2793.84,
            cpp8: 3223.66,
            cpp9: 6232.4,
            cpp10: 6533.28,
            cpp11: 6533.28,
            cpp12: 5157.85
        }
    ],
    'Комсомольск-на-Амуре': [
        {
            cityName: 'Комсомольск-на-Амуре',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 6778.43,
            cpp2: 11080.12,
            cpp3: 14338.98,
            cpp4: 14599.69,
            cpp5: 12122.96,
            cpp6: 10558.71,
            cpp7: 8473.04,
            cpp8: 9776.58,
            cpp9: 18901.39,
            cpp10: 19813.87,
            cpp11: 19813.87,
            cpp12: 15642.53
        },
        {
            cityName: 'Комсомольск-на-Амуре',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 4148.1,
            cpp2: 6780.54,
            cpp3: 8774.82,
            cpp4: 8934.36,
            cpp5: 7418.71,
            cpp6: 6461.46,
            cpp7: 5185.12,
            cpp8: 5982.83,
            cpp9: 11566.81,
            cpp10: 12125.21,
            cpp11: 12125.21,
            cpp12: 9572.53
        },
        {
            cityName: 'Комсомольск-на-Амуре',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 4631.29,
            cpp2: 7570.38,
            cpp3: 9796.96,
            cpp4: 9975.09,
            cpp5: 8282.89,
            cpp6: 7214.13,
            cpp7: 5789.12,
            cpp8: 6679.75,
            cpp9: 12914.18,
            cpp10: 13537.62,
            cpp11: 13537.62,
            cpp12: 10687.6
        },
        {
            cityName: 'Комсомольск-на-Амуре',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 8264.59,
            cpp2: 13509.42,
            cpp3: 17482.78,
            cpp4: 17800.64,
            cpp5: 14780.89,
            cpp6: 12873.68,
            cpp7: 10330.73,
            cpp8: 11920.07,
            cpp9: 23045.48,
            cpp10: 24158.02,
            cpp11: 24158.02,
            cpp12: 19072.12
        },
        {
            cityName: 'Комсомольск-на-Амуре',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 2152.99,
            cpp2: 3519.31,
            cpp3: 4554.4,
            cpp4: 4637.21,
            cpp5: 3850.54,
            cpp6: 3353.7,
            cpp7: 2691.24,
            cpp8: 3105.27,
            cpp9: 6003.53,
            cpp10: 6293.35,
            cpp11: 6293.35,
            cpp12: 4968.44
        }
    ],
    Кострома: [
        {
            cityName: 'Кострома',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3405.61,
            cpp2: 5566.87,
            cpp3: 7204.18,
            cpp4: 7335.17,
            cpp5: 6090.81,
            cpp6: 5304.9,
            cpp7: 4257.02,
            cpp8: 4911.94,
            cpp9: 9496.42,
            cpp10: 9954.87,
            cpp11: 9954.87,
            cpp12: 7859.11
        },
        {
            cityName: 'Кострома',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2379.35,
            cpp2: 3889.32,
            cpp3: 5033.24,
            cpp4: 5124.76,
            cpp5: 4255.38,
            cpp6: 3706.3,
            cpp7: 2974.19,
            cpp8: 3431.76,
            cpp9: 6634.73,
            cpp10: 6955.03,
            cpp11: 6955.03,
            cpp12: 5490.81
        },
        {
            cityName: 'Кострома',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2537.69,
            cpp2: 4148.15,
            cpp3: 5368.2,
            cpp4: 5465.8,
            cpp5: 4538.57,
            cpp6: 3952.95,
            cpp7: 3172.12,
            cpp8: 3660.14,
            cpp9: 7076.26,
            cpp10: 7417.87,
            cpp11: 7417.87,
            cpp12: 5856.22
        },
        {
            cityName: 'Кострома',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2935.59,
            cpp2: 4798.57,
            cpp3: 6209.91,
            cpp4: 6322.82,
            cpp5: 5250.2,
            cpp6: 4572.75,
            cpp7: 3669.49,
            cpp8: 4234.03,
            cpp9: 8185.79,
            cpp10: 8580.97,
            cpp11: 8580.97,
            cpp12: 6774.45
        },
        {
            cityName: 'Кострома',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1360.29,
            cpp2: 2223.55,
            cpp3: 2877.53,
            cpp4: 2929.85,
            cpp5: 2432.82,
            cpp6: 2118.91,
            cpp7: 1700.36,
            cpp8: 1961.95,
            cpp9: 3793.11,
            cpp10: 3976.22,
            cpp11: 3976.22,
            cpp12: 3139.12
        },
        {
            cityName: 'Кострома',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1512.13,
            cpp2: 2471.75,
            cpp3: 3198.74,
            cpp4: 3256.9,
            cpp5: 2704.39,
            cpp6: 2355.43,
            cpp7: 1890.16,
            cpp8: 2180.96,
            cpp9: 4216.52,
            cpp10: 4420.07,
            cpp11: 4420.07,
            cpp12: 3489.53
        },
        {
            cityName: 'Кострома',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1638.09,
            cpp2: 2677.64,
            cpp3: 3465.18,
            cpp4: 3528.18,
            cpp5: 2929.65,
            cpp6: 2551.63,
            cpp7: 2047.61,
            cpp8: 2362.62,
            cpp9: 4567.74,
            cpp10: 4788.25,
            cpp11: 4788.25,
            cpp12: 3780.2
        },
        {
            cityName: 'Кострома',
            priceType: 'min',
            channelName: 'Россия 24',
            cpp1: 1371.86,
            cpp2: 2242.46,
            cpp3: 2902.01,
            cpp4: 2954.77,
            cpp5: 2453.51,
            cpp6: 2136.93,
            cpp7: 1714.82,
            cpp8: 1978.64,
            cpp9: 3825.37,
            cpp10: 4010.04,
            cpp11: 4010.04,
            cpp12: 3165.82
        },
        {
            cityName: 'Кострома',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1060.79,
            cpp2: 1733.99,
            cpp3: 2243.98,
            cpp4: 2284.78,
            cpp5: 1897.18,
            cpp6: 1652.39,
            cpp7: 1325.99,
            cpp8: 1529.99,
            cpp9: 2957.98,
            cpp10: 3100.77,
            cpp11: 3100.77,
            cpp12: 2447.98
        }
    ],
    Курган: [
        {
            cityName: 'Курган',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 6969.95,
            cpp2: 11393.19,
            cpp3: 14744.13,
            cpp4: 15012.21,
            cpp5: 12465.49,
            cpp6: 10857.04,
            cpp7: 8712.44,
            cpp8: 10052.82,
            cpp9: 19435.44,
            cpp10: 20373.71,
            cpp11: 20373.71,
            cpp12: 16084.51
        },
        {
            cityName: 'Курган',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 5417.14,
            cpp2: 8854.93,
            cpp3: 11459.33,
            cpp4: 11667.68,
            cpp5: 9688.34,
            cpp6: 8438.23,
            cpp7: 6771.42,
            cpp8: 7813.18,
            cpp9: 15105.47,
            cpp10: 15834.7,
            cpp11: 15834.7,
            cpp12: 12501.08
        },
        {
            cityName: 'Курган',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 5667.69,
            cpp2: 9264.49,
            cpp3: 11989.35,
            cpp4: 12207.33,
            cpp5: 10136.45,
            cpp6: 8828.52,
            cpp7: 7084.61,
            cpp8: 8174.55,
            cpp9: 15804.14,
            cpp10: 16567.1,
            cpp11: 16567.1,
            cpp12: 13079.29
        },
        {
            cityName: 'Курган',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 6580.77,
            cpp2: 10757.03,
            cpp3: 13920.86,
            cpp4: 14173.96,
            cpp5: 11769.45,
            cpp6: 10250.81,
            cpp7: 8225.96,
            cpp8: 9491.49,
            cpp9: 18350.22,
            cpp10: 19236.09,
            cpp11: 19236.09,
            cpp12: 15186.39
        },
        {
            cityName: 'Курган',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 3098.52,
            cpp2: 5064.88,
            cpp3: 6554.55,
            cpp4: 6673.72,
            cpp5: 5541.58,
            cpp6: 4826.53,
            cpp7: 3873.14,
            cpp8: 4469.01,
            cpp9: 8640.09,
            cpp10: 9057.2,
            cpp11: 9057.2,
            cpp12: 7150.42
        },
        {
            cityName: 'Курган',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 3495.43,
            cpp2: 5713.69,
            cpp3: 7394.18,
            cpp4: 7528.62,
            cpp5: 6251.45,
            cpp6: 5444.81,
            cpp7: 4369.29,
            cpp8: 5041.49,
            cpp9: 9746.88,
            cpp10: 10217.42,
            cpp11: 10217.42,
            cpp12: 8066.38
        },
        {
            cityName: 'Курган',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 3731.3,
            cpp2: 6099.25,
            cpp3: 7893.14,
            cpp4: 8036.65,
            cpp5: 6673.29,
            cpp6: 5812.22,
            cpp7: 4664.13,
            cpp8: 5381.69,
            cpp9: 10404.6,
            cpp10: 10906.89,
            cpp11: 10906.89,
            cpp12: 8610.7
        }
    ],
    Курск: [
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2563.24,
            cpp2: 4189.91,
            cpp3: 5422.24,
            cpp4: 5520.83,
            cpp5: 4584.26,
            cpp6: 3992.74,
            cpp7: 3204.05,
            cpp8: 3696.98,
            cpp9: 7147.5,
            cpp10: 7492.55,
            cpp11: 7492.55,
            cpp12: 5915.17
        },
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2321.64,
            cpp2: 3794.98,
            cpp3: 4911.15,
            cpp4: 5000.45,
            cpp5: 4152.16,
            cpp6: 3616.39,
            cpp7: 2902.04,
            cpp8: 3348.51,
            cpp9: 6473.79,
            cpp10: 6786.32,
            cpp11: 6786.32,
            cpp12: 5357.62
        },
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2349.26,
            cpp2: 3840.14,
            cpp3: 4969.6,
            cpp4: 5059.95,
            cpp5: 4201.57,
            cpp6: 3659.43,
            cpp7: 2936.58,
            cpp8: 3388.36,
            cpp9: 6550.83,
            cpp10: 6867.08,
            cpp11: 6867.08,
            cpp12: 5421.38
        },
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2475.45,
            cpp2: 4046.4,
            cpp3: 5236.52,
            cpp4: 5331.73,
            cpp5: 4427.24,
            cpp6: 3855.98,
            cpp7: 3094.31,
            cpp8: 3570.35,
            cpp9: 6902.69,
            cpp10: 7235.92,
            cpp11: 7235.92,
            cpp12: 5712.57
        },
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2478.09,
            cpp2: 4050.73,
            cpp3: 5242.12,
            cpp4: 5337.43,
            cpp5: 4431.97,
            cpp6: 3860.1,
            cpp7: 3097.61,
            cpp8: 3574.17,
            cpp9: 6910.06,
            cpp10: 7243.65,
            cpp11: 7243.65,
            cpp12: 5718.67
        },
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1867.88,
            cpp2: 3053.27,
            cpp3: 3951.29,
            cpp4: 4023.13,
            cpp5: 3340.64,
            cpp6: 2909.59,
            cpp7: 2334.85,
            cpp8: 2694.06,
            cpp9: 5208.52,
            cpp10: 5459.97,
            cpp11: 5459.97,
            cpp12: 4310.5
        },
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1888.43,
            cpp2: 3086.86,
            cpp3: 3994.76,
            cpp4: 4067.39,
            cpp5: 3377.39,
            cpp6: 2941.6,
            cpp7: 2360.54,
            cpp8: 2723.7,
            cpp9: 5265.82,
            cpp10: 5520.03,
            cpp11: 5520.03,
            cpp12: 4357.92
        },
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1145.96,
            cpp2: 1873.2,
            cpp3: 2424.14,
            cpp4: 2468.21,
            cpp5: 2049.5,
            cpp6: 1785.05,
            cpp7: 1432.44,
            cpp8: 1652.82,
            cpp9: 3195.45,
            cpp10: 3349.72,
            cpp11: 3349.72,
            cpp12: 2644.51
        },
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1392.96,
            cpp2: 2276.96,
            cpp3: 2946.65,
            cpp4: 3000.23,
            cpp5: 2491.26,
            cpp6: 2169.81,
            cpp7: 1741.2,
            cpp8: 2009.08,
            cpp9: 3884.22,
            cpp10: 4071.74,
            cpp11: 4071.74,
            cpp12: 3214.53
        },
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1307.49,
            cpp2: 2137.25,
            cpp3: 2765.85,
            cpp4: 2816.14,
            cpp5: 2338.4,
            cpp6: 2036.67,
            cpp7: 1634.37,
            cpp8: 1885.81,
            cpp9: 3645.9,
            cpp10: 3821.9,
            cpp11: 3821.9,
            cpp12: 3017.29
        },
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 594.49,
            cpp2: 971.76,
            cpp3: 1257.58,
            cpp4: 1280.44,
            cpp5: 1063.22,
            cpp6: 926.03,
            cpp7: 743.11,
            cpp8: 857.44,
            cpp9: 1657.72,
            cpp10: 1737.74,
            cpp11: 1737.74,
            cpp12: 1371.9
        },
        {
            cityName: 'Курск',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 831.38,
            cpp2: 1358.99,
            cpp3: 1758.69,
            cpp4: 1790.67,
            cpp5: 1486.9,
            cpp6: 1295.04,
            cpp7: 1039.23,
            cpp8: 1199.11,
            cpp9: 2318.28,
            cpp10: 2430.19,
            cpp11: 2430.19,
            cpp12: 1918.57
        }
    ],
    Кызыл: [
        {
            cityName: 'Кызыл',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 774.55,
            cpp2: 1266.09,
            cpp3: 1638.47,
            cpp4: 1668.26,
            cpp5: 1385.25,
            cpp6: 1206.51,
            cpp7: 968.19,
            cpp8: 1117.14,
            cpp9: 2159.81,
            cpp10: 2264.07,
            cpp11: 2264.07,
            cpp12: 1787.43
        },
        {
            cityName: 'Кызыл',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 462.8,
            cpp2: 756.5,
            cpp3: 978.99,
            cpp4: 996.79,
            cpp5: 827.7,
            cpp6: 720.9,
            cpp7: 578.5,
            cpp8: 667.5,
            cpp9: 1290.49,
            cpp10: 1352.79,
            cpp11: 1352.79,
            cpp12: 1067.99
        },
        {
            cityName: 'Кызыл',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 585.63,
            cpp2: 957.27,
            cpp3: 1238.83,
            cpp4: 1261.35,
            cpp5: 1047.37,
            cpp6: 912.23,
            cpp7: 732.03,
            cpp8: 844.65,
            cpp9: 1633,
            cpp10: 1711.83,
            cpp11: 1711.83,
            cpp12: 1351.45
        }
    ],
    Липецк: [
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 6104.91,
            cpp2: 9979.18,
            cpp3: 12914.23,
            cpp4: 13149.03,
            cpp5: 10918.39,
            cpp6: 9509.57,
            cpp7: 7631.14,
            cpp8: 8805.16,
            cpp9: 17023.3,
            cpp10: 17845.12,
            cpp11: 17845.12,
            cpp12: 14088.25
        },
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 5393.8,
            cpp2: 8816.79,
            cpp3: 11409.96,
            cpp4: 11617.41,
            cpp5: 9646.6,
            cpp6: 8401.88,
            cpp7: 6742.25,
            cpp8: 7779.52,
            cpp9: 15040.4,
            cpp10: 15766.49,
            cpp11: 15766.49,
            cpp12: 12447.23
        },
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 4605.5,
            cpp2: 7528.22,
            cpp3: 9742.4,
            cpp4: 9919.53,
            cpp5: 8236.76,
            cpp6: 7173.95,
            cpp7: 5756.87,
            cpp8: 6642.55,
            cpp9: 12842.25,
            cpp10: 13462.22,
            cpp11: 13462.22,
            cpp12: 10628.07
        },
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3453.06,
            cpp2: 5644.42,
            cpp3: 7304.55,
            cpp4: 7437.36,
            cpp5: 6175.66,
            cpp6: 5378.8,
            cpp7: 4316.32,
            cpp8: 4980.37,
            cpp9: 9628.72,
            cpp10: 10093.55,
            cpp11: 10093.55,
            cpp12: 7968.59
        },
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 3210.42,
            cpp2: 5247.8,
            cpp3: 6791.27,
            cpp4: 6914.75,
            cpp5: 5741.71,
            cpp6: 5000.85,
            cpp7: 4013.02,
            cpp8: 4630.41,
            cpp9: 8952.13,
            cpp10: 9384.3,
            cpp11: 9384.3,
            cpp12: 7408.66
        },
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 2821.87,
            cpp2: 4612.68,
            cpp3: 5969.35,
            cpp4: 6077.88,
            cpp5: 5046.81,
            cpp6: 4395.61,
            cpp7: 3527.34,
            cpp8: 4070.01,
            cpp9: 7868.69,
            cpp10: 8248.56,
            cpp11: 8248.56,
            cpp12: 6512.02
        },
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2840.64,
            cpp2: 4643.36,
            cpp3: 6009.05,
            cpp4: 6118.31,
            cpp5: 5080.38,
            cpp6: 4424.85,
            cpp7: 3550.8,
            cpp8: 4097.08,
            cpp9: 7921.02,
            cpp10: 8303.42,
            cpp11: 8303.42,
            cpp12: 6555.33
        },
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1300.13,
            cpp2: 2125.21,
            cpp3: 2750.27,
            cpp4: 2800.28,
            cpp5: 2325.23,
            cpp6: 2025.2,
            cpp7: 1625.16,
            cpp8: 1875.18,
            cpp9: 3625.36,
            cpp10: 3800.37,
            cpp11: 3800.37,
            cpp12: 3000.3
        },
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2475.25,
            cpp2: 4046.08,
            cpp3: 5236.11,
            cpp4: 5331.31,
            cpp5: 4426.89,
            cpp6: 3855.68,
            cpp7: 3094.06,
            cpp8: 3570.07,
            cpp9: 6902.14,
            cpp10: 7235.35,
            cpp11: 7235.35,
            cpp12: 5712.12
        },
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2273.75,
            cpp2: 3716.7,
            cpp3: 4809.85,
            cpp4: 4897.3,
            cpp5: 4066.51,
            cpp6: 3541.8,
            cpp7: 2842.18,
            cpp8: 3279.44,
            cpp9: 6340.25,
            cpp10: 6646.33,
            cpp11: 6646.33,
            cpp12: 5247.1
        },
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1229.31,
            cpp2: 2009.46,
            cpp3: 2600.47,
            cpp4: 2647.75,
            cpp5: 2198.58,
            cpp6: 1914.89,
            cpp7: 1536.64,
            cpp8: 1773.05,
            cpp9: 3427.9,
            cpp10: 3593.38,
            cpp11: 3593.38,
            cpp12: 2836.88
        },
        {
            cityName: 'Липецк',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 2249.52,
            cpp2: 3677.1,
            cpp3: 4758.6,
            cpp4: 4845.12,
            cpp5: 4023.18,
            cpp6: 3504.06,
            cpp7: 2811.9,
            cpp8: 3244.5,
            cpp9: 6272.69,
            cpp10: 6575.51,
            cpp11: 6575.51,
            cpp12: 5191.2
        }
    ],
    Магадан: [
        {
            cityName: 'Магадан',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3911,
            cpp2: 6392.99,
            cpp3: 8273.28,
            cpp4: 8423.7,
            cpp5: 6994.68,
            cpp6: 6092.14,
            cpp7: 4888.75,
            cpp8: 5640.87,
            cpp9: 10905.68,
            cpp10: 11432.16,
            cpp11: 11432.16,
            cpp12: 9025.39
        },
        {
            cityName: 'Магадан',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2445.35,
            cpp2: 3997.2,
            cpp3: 5172.85,
            cpp4: 5266.9,
            cpp5: 4373.41,
            cpp6: 3809.1,
            cpp7: 3056.68,
            cpp8: 3526.94,
            cpp9: 6818.75,
            cpp10: 7147.94,
            cpp11: 7147.94,
            cpp12: 5643.11
        },
        {
            cityName: 'Магадан',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2960.84,
            cpp2: 4839.84,
            cpp3: 6263.32,
            cpp4: 6377.2,
            cpp5: 5295.35,
            cpp6: 4612.08,
            cpp7: 3701.05,
            cpp8: 4270.44,
            cpp9: 8256.19,
            cpp10: 8654.77,
            cpp11: 8654.77,
            cpp12: 6832.71
        },
        {
            cityName: 'Магадан',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3348.44,
            cpp2: 5473.41,
            cpp3: 7083.23,
            cpp4: 7212.02,
            cpp5: 5988.55,
            cpp6: 5215.83,
            cpp7: 4185.55,
            cpp8: 4829.48,
            cpp9: 9336.99,
            cpp10: 9787.74,
            cpp11: 9787.74,
            cpp12: 7727.16
        },
        {
            cityName: 'Магадан',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1258.41,
            cpp2: 2057.01,
            cpp3: 2662.01,
            cpp4: 2710.41,
            cpp5: 2250.61,
            cpp6: 1960.21,
            cpp7: 0,
            cpp8: 0,
            cpp9: 0,
            cpp10: 0,
            cpp11: 0,
            cpp12: 0
        },
        {
            cityName: 'Магадан',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1061.25,
            cpp2: 1734.73,
            cpp3: 2244.95,
            cpp4: 2285.76,
            cpp5: 1898,
            cpp6: 1653.1,
            cpp7: 1326.56,
            cpp8: 1530.64,
            cpp9: 2959.25,
            cpp10: 3102.11,
            cpp11: 3102.11,
            cpp12: 2449.03
        }
    ],
    Магнитогорск: [
        {
            cityName: 'Магнитогорск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4597.51,
            cpp2: 7515.17,
            cpp3: 9725.51,
            cpp4: 9902.34,
            cpp5: 8222.48,
            cpp6: 7161.51,
            cpp7: 5746.89,
            cpp8: 6631.03,
            cpp9: 12819.99,
            cpp10: 13438.89,
            cpp11: 13438.89,
            cpp12: 10609.65
        },
        {
            cityName: 'Магнитогорск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 3789.93,
            cpp2: 6195.07,
            cpp3: 8017.15,
            cpp4: 8162.92,
            cpp5: 6778.14,
            cpp6: 5903.54,
            cpp7: 4737.41,
            cpp8: 5466.24,
            cpp9: 10568.06,
            cpp10: 11078.24,
            cpp11: 11078.24,
            cpp12: 8745.98
        },
        {
            cityName: 'Магнитогорск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2962.42,
            cpp2: 4842.41,
            cpp3: 6266.65,
            cpp4: 6380.59,
            cpp5: 5298.17,
            cpp6: 4614.54,
            cpp7: 3703.02,
            cpp8: 4272.72,
            cpp9: 8260.59,
            cpp10: 8659.38,
            cpp11: 8659.38,
            cpp12: 6836.35
        },
        {
            cityName: 'Магнитогорск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 4529.68,
            cpp2: 7404.29,
            cpp3: 9582.02,
            cpp4: 9756.24,
            cpp5: 8101.16,
            cpp6: 7055.85,
            cpp7: 5662.1,
            cpp8: 6533.2,
            cpp9: 12630.85,
            cpp10: 13240.61,
            cpp11: 13240.61,
            cpp12: 10453.12
        },
        {
            cityName: 'Магнитогорск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1397.34,
            cpp2: 2284.12,
            cpp3: 2955.92,
            cpp4: 3009.66,
            cpp5: 2499.09,
            cpp6: 2176.63,
            cpp7: 1746.68,
            cpp8: 2015.4,
            cpp9: 3896.44,
            cpp10: 4084.54,
            cpp11: 4084.54,
            cpp12: 3224.64
        },
        {
            cityName: 'Магнитогорск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1321.64,
            cpp2: 2160.38,
            cpp3: 2795.78,
            cpp4: 2846.61,
            cpp5: 2363.71,
            cpp6: 2058.71,
            cpp7: 1652.05,
            cpp8: 1906.21,
            cpp9: 3685.35,
            cpp10: 3863.26,
            cpp11: 3863.26,
            cpp12: 3049.94
        },
        {
            cityName: 'Магнитогорск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1115.23,
            cpp2: 1822.97,
            cpp3: 2359.14,
            cpp4: 2402.03,
            cpp5: 1994.54,
            cpp6: 1737.18,
            cpp7: 1394.04,
            cpp8: 1608.5,
            cpp9: 3109.77,
            cpp10: 3259.9,
            cpp11: 3259.9,
            cpp12: 2573.6
        },
        {
            cityName: 'Магнитогорск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1983.37,
            cpp2: 3242.04,
            cpp3: 4195.59,
            cpp4: 4271.87,
            cpp5: 3547.18,
            cpp6: 3089.48,
            cpp7: 2479.21,
            cpp8: 2860.63,
            cpp9: 5530.54,
            cpp10: 5797.54,
            cpp11: 5797.54,
            cpp12: 4577
        },
        {
            cityName: 'Магнитогорск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2768.92,
            cpp2: 4526.13,
            cpp3: 5857.34,
            cpp4: 5963.84,
            cpp5: 4952.11,
            cpp6: 4313.13,
            cpp7: 3461.16,
            cpp8: 3993.64,
            cpp9: 7721.04,
            cpp10: 8093.78,
            cpp11: 8093.78,
            cpp12: 6389.82
        },
        {
            cityName: 'Магнитогорск',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 843.13,
            cpp2: 1378.19,
            cpp3: 1783.54,
            cpp4: 1815.97,
            cpp5: 1507.9,
            cpp6: 1313.33,
            cpp7: 1053.91,
            cpp8: 1216.05,
            cpp9: 2351.03,
            cpp10: 2464.53,
            cpp11: 2464.53,
            cpp12: 1945.68
        }
    ],
    Майкоп: [
        {
            cityName: 'Майкоп',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 423.28,
            cpp2: 691.91,
            cpp3: 895.41,
            cpp4: 911.69,
            cpp5: 757.03,
            cpp6: 659.35,
            cpp7: 529.1,
            cpp8: 610.51,
            cpp9: 1180.31,
            cpp10: 1237.29,
            cpp11: 1237.29,
            cpp12: 976.81
        }
    ],
    Махачкала: [
        {
            cityName: 'Махачкала',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 11385.95,
            cpp2: 18611.65,
            cpp3: 24085.67,
            cpp4: 24523.59,
            cpp5: 20363.34,
            cpp6: 17735.81,
            cpp7: 14232.44,
            cpp8: 16422.05,
            cpp9: 31749.29,
            cpp10: 33282.01,
            cpp11: 33282.01,
            cpp12: 26275.27
        },
        {
            cityName: 'Махачкала',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 13277.92,
            cpp2: 21704.29,
            cpp3: 28087.91,
            cpp4: 28598.6,
            cpp5: 23747.05,
            cpp6: 20682.91,
            cpp7: 16597.4,
            cpp8: 19150.84,
            cpp9: 37024.97,
            cpp10: 38812.38,
            cpp11: 38812.38,
            cpp12: 30641.35
        },
        {
            cityName: 'Махачкала',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 10692.47,
            cpp2: 17478.08,
            cpp3: 22618.7,
            cpp4: 23029.95,
            cpp5: 19123.08,
            cpp6: 16655.59,
            cpp7: 13365.59,
            cpp8: 15421.84,
            cpp9: 29815.55,
            cpp10: 31254.93,
            cpp11: 31254.93,
            cpp12: 24674.94
        },
        {
            cityName: 'Махачкала',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 8012.58,
            cpp2: 13097.48,
            cpp3: 16949.68,
            cpp4: 17257.86,
            cpp5: 14330.18,
            cpp6: 12481.13,
            cpp7: 10015.72,
            cpp8: 11556.6,
            cpp9: 22342.76,
            cpp10: 23421.38,
            cpp11: 23421.38,
            cpp12: 18490.56
        },
        {
            cityName: 'Махачкала',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 3811.62,
            cpp2: 6230.54,
            cpp3: 8063.05,
            cpp4: 8209.65,
            cpp5: 6816.94,
            cpp6: 5937.34,
            cpp7: 4764.53,
            cpp8: 5497.54,
            cpp9: 10628.57,
            cpp10: 11141.67,
            cpp11: 11141.67,
            cpp12: 8796.06
        }
    ],
    Миасс: [
        {
            cityName: 'Миасс',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2619.79,
            cpp2: 4282.35,
            cpp3: 5541.86,
            cpp4: 5642.62,
            cpp5: 4685.39,
            cpp6: 4080.82,
            cpp7: 3274.74,
            cpp8: 3778.54,
            cpp9: 7305.18,
            cpp10: 7657.84,
            cpp11: 7657.84,
            cpp12: 6045.67
        },
        {
            cityName: 'Миасс',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2166.5,
            cpp2: 3541.39,
            cpp3: 4582.97,
            cpp4: 4666.3,
            cpp5: 3874.69,
            cpp6: 3374.73,
            cpp7: 2708.12,
            cpp8: 3124.75,
            cpp9: 6041.19,
            cpp10: 6332.83,
            cpp11: 6332.83,
            cpp12: 4999.6
        },
        {
            cityName: 'Миасс',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 0,
            cpp2: 0,
            cpp3: 4730.48,
            cpp4: 4816.49,
            cpp5: 3999.41,
            cpp6: 3483.36,
            cpp7: 2795.29,
            cpp8: 3225.33,
            cpp9: 6235.64,
            cpp10: 6536.67,
            cpp11: 6536.67,
            cpp12: 5160.53
        },
        {
            cityName: 'Миасс',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1800.98,
            cpp2: 2943.92,
            cpp3: 3809.77,
            cpp4: 3879.04,
            cpp5: 3220.99,
            cpp6: 2805.38,
            cpp7: 2251.23,
            cpp8: 2597.57,
            cpp9: 5021.98,
            cpp10: 5264.42,
            cpp11: 5264.42,
            cpp12: 4156.12
        },
        {
            cityName: 'Миасс',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1734.28,
            cpp2: 2834.88,
            cpp3: 3668.67,
            cpp4: 3735.37,
            cpp5: 3101.7,
            cpp6: 2701.48,
            cpp7: 2167.85,
            cpp8: 2501.37,
            cpp9: 4835.98,
            cpp10: 5069.44,
            cpp11: 5069.44,
            cpp12: 4002.19
        }
    ],
    Мурманск: [
        {
            cityName: 'Мурманск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4322.17,
            cpp2: 7065.08,
            cpp3: 9143.05,
            cpp4: 9309.28,
            cpp5: 7730.03,
            cpp6: 6732.61,
            cpp7: 5402.71,
            cpp8: 6233.9,
            cpp9: 12052.2,
            cpp10: 12634.03,
            cpp11: 12634.03,
            cpp12: 9974.23
        },
        {
            cityName: 'Мурманск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 4284.99,
            cpp2: 7004.31,
            cpp3: 9064.4,
            cpp4: 9229.21,
            cpp5: 7663.54,
            cpp6: 6674.7,
            cpp7: 5356.24,
            cpp8: 6180.27,
            cpp9: 11948.53,
            cpp10: 12525.36,
            cpp11: 12525.36,
            cpp12: 9888.44
        },
        {
            cityName: 'Мурманск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 3771.16,
            cpp2: 6164.4,
            cpp3: 7977.46,
            cpp4: 8122.51,
            cpp5: 6744.58,
            cpp6: 5874.31,
            cpp7: 4713.95,
            cpp8: 5439.18,
            cpp9: 10515.74,
            cpp10: 11023.4,
            cpp11: 11023.4,
            cpp12: 8702.68
        },
        {
            cityName: 'Мурманск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 5753.11,
            cpp2: 9404.12,
            cpp3: 12170.04,
            cpp4: 12391.31,
            cpp5: 10289.21,
            cpp6: 8961.57,
            cpp7: 7191.39,
            cpp8: 8297.75,
            cpp9: 16042.32,
            cpp10: 16816.78,
            cpp11: 16816.78,
            cpp12: 13276.4
        },
        {
            cityName: 'Мурманск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 5672.3,
            cpp2: 9272.02,
            cpp3: 11999.09,
            cpp4: 12217.25,
            cpp5: 10144.68,
            cpp6: 8835.69,
            cpp7: 7090.37,
            cpp8: 8181.2,
            cpp9: 15816.98,
            cpp10: 16580.56,
            cpp11: 16580.56,
            cpp12: 13089.91
        },
        {
            cityName: 'Мурманск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1884.52,
            cpp2: 3080.47,
            cpp3: 3986.49,
            cpp4: 4058.97,
            cpp5: 3370.4,
            cpp6: 2935.51,
            cpp7: 2355.65,
            cpp8: 2718.06,
            cpp9: 5254.92,
            cpp10: 5508.6,
            cpp11: 5508.6,
            cpp12: 4348.9
        },
        {
            cityName: 'Мурманск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2173.5,
            cpp2: 3552.84,
            cpp3: 4597.79,
            cpp4: 4681.39,
            cpp5: 3887.22,
            cpp6: 3385.65,
            cpp7: 2716.88,
            cpp8: 3134.86,
            cpp9: 6060.73,
            cpp10: 6353.31,
            cpp11: 6353.31,
            cpp12: 5015.77
        },
        {
            cityName: 'Мурманск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1301.12,
            cpp2: 2126.83,
            cpp3: 2752.37,
            cpp4: 2802.41,
            cpp5: 2327,
            cpp6: 2026.75,
            cpp7: 1626.4,
            cpp8: 1876.62,
            cpp9: 3628.13,
            cpp10: 3803.28,
            cpp11: 3803.28,
            cpp12: 3002.59
        },
        {
            cityName: 'Мурманск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2128.73,
            cpp2: 3479.66,
            cpp3: 4503.09,
            cpp4: 4584.96,
            cpp5: 3807.16,
            cpp6: 3315.91,
            cpp7: 2660.92,
            cpp8: 3070.29,
            cpp9: 5935.89,
            cpp10: 6222.45,
            cpp11: 6222.45,
            cpp12: 4912.46
        },
        {
            cityName: 'Мурманск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2065.56,
            cpp2: 3376.4,
            cpp3: 4369.46,
            cpp4: 4448.9,
            cpp5: 3694.18,
            cpp6: 3217.51,
            cpp7: 2581.95,
            cpp8: 2979.18,
            cpp9: 5759.74,
            cpp10: 6037.8,
            cpp11: 6037.8,
            cpp12: 4766.68
        },
        {
            cityName: 'Мурманск',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 716.31,
            cpp2: 1170.89,
            cpp3: 1515.27,
            cpp4: 1542.82,
            cpp5: 1281.09,
            cpp6: 1115.79,
            cpp7: 895.39,
            cpp8: 1033.14,
            cpp9: 1997.4,
            cpp10: 2093.82,
            cpp11: 2093.82,
            cpp12: 1653.02
        }
    ],
    'Набережные Челны': [
        {
            cityName: 'Набережные Челны',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 5027.48,
            cpp2: 8217.99,
            cpp3: 10635.05,
            cpp4: 10828.41,
            cpp5: 8991.45,
            cpp6: 7831.26,
            cpp7: 6284.35,
            cpp8: 7251.17,
            cpp9: 14018.93,
            cpp10: 14695.7,
            cpp11: 14695.7,
            cpp12: 11601.87
        },
        {
            cityName: 'Набережные Челны',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 4494.32,
            cpp2: 7346.49,
            cpp3: 9507.22,
            cpp4: 9680.08,
            cpp5: 8037.93,
            cpp6: 7000.77,
            cpp7: 5617.9,
            cpp8: 6482.2,
            cpp9: 12532.25,
            cpp10: 13137.25,
            cpp11: 13137.25,
            cpp12: 10371.52
        },
        {
            cityName: 'Набережные Челны',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 4774.16,
            cpp2: 7803.92,
            cpp3: 10099.19,
            cpp4: 10282.81,
            cpp5: 8538.41,
            cpp6: 7436.68,
            cpp7: 5967.7,
            cpp8: 6885.81,
            cpp9: 13312.57,
            cpp10: 13955.25,
            cpp11: 13955.25,
            cpp12: 11017.3
        },
        {
            cityName: 'Набережные Челны',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 4836.48,
            cpp2: 7905.79,
            cpp3: 10231.02,
            cpp4: 10417.04,
            cpp5: 8649.86,
            cpp6: 7533.75,
            cpp7: 6045.6,
            cpp8: 6975.69,
            cpp9: 13486.34,
            cpp10: 14137.4,
            cpp11: 14137.4,
            cpp12: 11161.11
        },
        {
            cityName: 'Набережные Челны',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2293.71,
            cpp2: 3749.33,
            cpp3: 4852.08,
            cpp4: 4940.3,
            cpp5: 4102.21,
            cpp6: 3572.89,
            cpp7: 2867.14,
            cpp8: 3308.24,
            cpp9: 6395.92,
            cpp10: 6704.69,
            cpp11: 6704.69,
            cpp12: 5293.18
        },
        {
            cityName: 'Набережные Челны',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1736.38,
            cpp2: 2838.32,
            cpp3: 3673.12,
            cpp4: 3739.9,
            cpp5: 3105.45,
            cpp6: 2704.75,
            cpp7: 2170.48,
            cpp8: 2504.4,
            cpp9: 4841.83,
            cpp10: 5075.58,
            cpp11: 5075.58,
            cpp12: 4007.04
        },
        {
            cityName: 'Набережные Челны',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2739.41,
            cpp2: 4477.89,
            cpp3: 5794.91,
            cpp4: 5900.28,
            cpp5: 4899.34,
            cpp6: 4267.16,
            cpp7: 3424.27,
            cpp8: 3951.08,
            cpp9: 7638.75,
            cpp10: 8007.52,
            cpp11: 8007.52,
            cpp12: 6321.72
        },
        {
            cityName: 'Набережные Челны',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2678.25,
            cpp2: 4377.91,
            cpp3: 5665.53,
            cpp4: 5768.54,
            cpp5: 4789.95,
            cpp6: 4171.89,
            cpp7: 3347.81,
            cpp8: 3862.86,
            cpp9: 7468.19,
            cpp10: 7828.73,
            cpp11: 7828.73,
            cpp12: 6180.57
        },
        {
            cityName: 'Набережные Челны',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1820.39,
            cpp2: 2975.64,
            cpp3: 3850.83,
            cpp4: 3920.84,
            cpp5: 3255.7,
            cpp6: 2835.61,
            cpp7: 2275.49,
            cpp8: 2625.56,
            cpp9: 5076.09,
            cpp10: 5321.14,
            cpp11: 5321.14,
            cpp12: 4200.9
        },
        {
            cityName: 'Набережные Челны',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1603.8,
            cpp2: 2621.59,
            cpp3: 3392.65,
            cpp4: 3454.33,
            cpp5: 2868.33,
            cpp6: 2498.22,
            cpp7: 2004.75,
            cpp8: 2313.17,
            cpp9: 4472.13,
            cpp10: 4688.02,
            cpp11: 4688.02,
            cpp12: 3701.07
        }
    ],
    Нальчик: [
        {
            cityName: 'Нальчик',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2597.34,
            cpp2: 4245.65,
            cpp3: 5494.37,
            cpp4: 5594.27,
            cpp5: 4645.24,
            cpp6: 4045.85,
            cpp7: 3246.67,
            cpp8: 3746.16,
            cpp9: 7242.58,
            cpp10: 7592.22,
            cpp11: 7592.22,
            cpp12: 5993.86
        },
        {
            cityName: 'Нальчик',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 1947.93,
            cpp2: 3184.12,
            cpp3: 4120.63,
            cpp4: 4195.55,
            cpp5: 3483.8,
            cpp6: 3034.28,
            cpp7: 2434.92,
            cpp8: 2809.52,
            cpp9: 5431.74,
            cpp10: 5693.96,
            cpp11: 5693.96,
            cpp12: 4495.23
        }
    ],
    Находка: [
        {
            cityName: 'Находка',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 8611.16,
            cpp2: 14075.93,
            cpp3: 18215.91,
            cpp4: 18547.11,
            cpp5: 15400.73,
            cpp6: 13413.54,
            cpp7: 10763.95,
            cpp8: 12419.94,
            cpp9: 24011.88,
            cpp10: 25171.08,
            cpp11: 25171.08,
            cpp12: 19871.9
        }
    ],
    Нижневартовск: [
        {
            cityName: 'Нижневартовск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 7093.84,
            cpp2: 11595.7,
            cpp3: 15006.2,
            cpp4: 15279.04,
            cpp5: 12687.06,
            cpp6: 11050.02,
            cpp7: 8867.3,
            cpp8: 10231.5,
            cpp9: 19780.9,
            cpp10: 20735.84,
            cpp11: 20735.84,
            cpp12: 16370.4
        },
        {
            cityName: 'Нижневартовск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 6028.19,
            cpp2: 9853.77,
            cpp3: 12751.94,
            cpp4: 12983.79,
            cpp5: 10781.18,
            cpp6: 9390.06,
            cpp7: 7535.23,
            cpp8: 8694.5,
            cpp9: 16809.37,
            cpp10: 17620.86,
            cpp11: 17620.86,
            cpp12: 13911.2
        },
        {
            cityName: 'Нижневартовск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 4820.16,
            cpp2: 7879.11,
            cpp3: 10196.5,
            cpp4: 10381.89,
            cpp5: 8620.68,
            cpp6: 7508.33,
            cpp7: 6025.2,
            cpp8: 6952.16,
            cpp9: 13440.84,
            cpp10: 14089.71,
            cpp11: 14089.71,
            cpp12: 11123.45
        },
        {
            cityName: 'Нижневартовск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 4770.25,
            cpp2: 7797.52,
            cpp3: 10090.91,
            cpp4: 10274.39,
            cpp5: 8531.41,
            cpp6: 7430.58,
            cpp7: 5962.81,
            cpp8: 6880.17,
            cpp9: 13301.66,
            cpp10: 13943.81,
            cpp11: 13943.81,
            cpp12: 11008.27
        },
        {
            cityName: 'Нижневартовск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 0,
            cpp2: 0,
            cpp3: 5770.25,
            cpp4: 5875.17,
            cpp5: 4878.49,
            cpp6: 4249,
            cpp7: 3409.69,
            cpp8: 3934.26,
            cpp9: 7606.24,
            cpp10: 7973.44,
            cpp11: 7973.44,
            cpp12: 6294.82
        },
        {
            cityName: 'Нижневартовск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 0,
            cpp2: 0,
            cpp3: 4713.66,
            cpp4: 4799.36,
            cpp5: 3985.19,
            cpp6: 3470.97,
            cpp7: 2785.35,
            cpp8: 3213.86,
            cpp9: 6213.46,
            cpp10: 6513.42,
            cpp11: 6513.42,
            cpp12: 5142.18
        },
        {
            cityName: 'Нижневартовск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2435.32,
            cpp2: 3980.81,
            cpp3: 5151.64,
            cpp4: 5245.3,
            cpp5: 4355.48,
            cpp6: 3793.48,
            cpp7: 3044.15,
            cpp8: 3512.48,
            cpp9: 6790.8,
            cpp10: 7118.63,
            cpp11: 7118.63,
            cpp12: 5619.97
        },
        {
            cityName: 'Нижневартовск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2769.98,
            cpp2: 4527.85,
            cpp3: 5859.57,
            cpp4: 5966.11,
            cpp5: 4954,
            cpp6: 4314.77,
            cpp7: 3462.47,
            cpp8: 3995.16,
            cpp9: 7723.98,
            cpp10: 8096.86,
            cpp11: 8096.86,
            cpp12: 6392.26
        }
    ],
    Нижнекамск: [
        {
            cityName: 'Нижнекамск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2473.56,
            cpp2: 4043.32,
            cpp3: 5232.53,
            cpp4: 5327.67,
            cpp5: 4423.87,
            cpp6: 3853.05,
            cpp7: 3091.95,
            cpp8: 3567.64,
            cpp9: 6897.43,
            cpp10: 7230.41,
            cpp11: 7230.41,
            cpp12: 5708.22
        },
        {
            cityName: 'Нижнекамск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2363.76,
            cpp2: 3863.84,
            cpp3: 5000.26,
            cpp4: 5091.18,
            cpp5: 4227.49,
            cpp6: 3682.01,
            cpp7: 2954.7,
            cpp8: 3409.27,
            cpp9: 6591.25,
            cpp10: 6909.45,
            cpp11: 6909.45,
            cpp12: 5454.83
        },
        {
            cityName: 'Нижнекамск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 0,
            cpp2: 0,
            cpp3: 4764.56,
            cpp4: 4851.18,
            cpp5: 4028.22,
            cpp6: 3508.45,
            cpp7: 2815.42,
            cpp8: 3248.56,
            cpp9: 6280.55,
            cpp10: 6583.75,
            cpp11: 6583.75,
            cpp12: 5197.7
        },
        {
            cityName: 'Нижнекамск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 0,
            cpp2: 0,
            cpp3: 4773.24,
            cpp4: 4860.03,
            cpp5: 4035.56,
            cpp6: 3514.84,
            cpp7: 2820.55,
            cpp8: 3254.48,
            cpp9: 6292,
            cpp10: 6595.76,
            cpp11: 6595.76,
            cpp12: 5207.18
        },
        {
            cityName: 'Нижнекамск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1083.93,
            cpp2: 1771.81,
            cpp3: 2292.93,
            cpp4: 2334.62,
            cpp5: 1938.57,
            cpp6: 1688.43,
            cpp7: 1354.92,
            cpp8: 1563.36,
            cpp9: 3022.5,
            cpp10: 3168.42,
            cpp11: 3168.42,
            cpp12: 2501.38
        }
    ],
    'Нижний Тагил': [
        {
            cityName: 'Нижний Тагил',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2352.59,
            cpp2: 3845.58,
            cpp3: 4976.63,
            cpp4: 5067.12,
            cpp5: 4207.52,
            cpp6: 3664.61,
            cpp7: 2940.74,
            cpp8: 3393.16,
            cpp9: 6560.1,
            cpp10: 6876.8,
            cpp11: 6876.8,
            cpp12: 5429.05
        },
        {
            cityName: 'Нижний Тагил',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1824.82,
            cpp2: 2982.87,
            cpp3: 3860.19,
            cpp4: 3930.37,
            cpp5: 3263.61,
            cpp6: 2842.5,
            cpp7: 2281.02,
            cpp8: 2631.95,
            cpp9: 5088.43,
            cpp10: 5334.08,
            cpp11: 5334.08,
            cpp12: 4211.11
        },
        {
            cityName: 'Нижний Тагил',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2402.92,
            cpp2: 3927.85,
            cpp3: 5083.1,
            cpp4: 5175.52,
            cpp5: 4297.53,
            cpp6: 3743.01,
            cpp7: 3003.65,
            cpp8: 3465.75,
            cpp9: 6700.45,
            cpp10: 7023.92,
            cpp11: 7023.92,
            cpp12: 5545.2
        },
        {
            cityName: 'Нижний Тагил',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 623.06,
            cpp2: 1018.46,
            cpp3: 1318.01,
            cpp4: 1341.97,
            cpp5: 1114.32,
            cpp6: 970.53,
            cpp7: 778.82,
            cpp8: 898.64,
            cpp9: 1737.38,
            cpp10: 1821.25,
            cpp11: 1821.25,
            cpp12: 1437.83
        },
        {
            cityName: 'Нижний Тагил',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 958.32,
            cpp2: 1566.48,
            cpp3: 2027.21,
            cpp4: 2064.07,
            cpp5: 1713.91,
            cpp6: 1492.76,
            cpp7: 1197.9,
            cpp8: 1382.19,
            cpp9: 2672.23,
            cpp10: 2801.24,
            cpp11: 2801.24,
            cpp12: 2211.5
        }
    ],
    Новокузнецк: [
        {
            cityName: 'Новокузнецк',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4416.12,
            cpp2: 7218.65,
            cpp3: 9341.79,
            cpp4: 9511.64,
            cpp5: 7898.05,
            cpp6: 6878.95,
            cpp7: 5520.15,
            cpp8: 6369.4,
            cpp9: 12314.17,
            cpp10: 12908.65,
            cpp11: 12908.65,
            cpp12: 10191.04
        },
        {
            cityName: 'Новокузнецк',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 3896.01,
            cpp2: 6368.48,
            cpp3: 8241.57,
            cpp4: 8391.41,
            cpp5: 6967.87,
            cpp6: 6068.79,
            cpp7: 4870.02,
            cpp8: 5619.25,
            cpp9: 10863.88,
            cpp10: 11388.35,
            cpp11: 11388.35,
            cpp12: 8990.8
        },
        {
            cityName: 'Новокузнецк',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3627.05,
            cpp2: 5928.83,
            cpp3: 7672.61,
            cpp4: 7812.11,
            cpp5: 6486.84,
            cpp6: 5649.83,
            cpp7: 4533.81,
            cpp8: 5231.32,
            cpp9: 10113.89,
            cpp10: 10602.15,
            cpp11: 10602.15,
            cpp12: 8370.12
        },
        {
            cityName: 'Новокузнецк',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2738.6,
            cpp2: 4476.56,
            cpp3: 5793.19,
            cpp4: 5898.52,
            cpp5: 4897.88,
            cpp6: 4265.9,
            cpp7: 3423.25,
            cpp8: 3949.9,
            cpp9: 7636.48,
            cpp10: 8005.14,
            cpp11: 8005.14,
            cpp12: 6319.85
        },
        {
            cityName: 'Новокузнецк',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 2055.12,
            cpp2: 3359.33,
            cpp3: 4347.37,
            cpp4: 4426.41,
            cpp5: 3675.5,
            cpp6: 3201.24,
            cpp7: 2568.9,
            cpp8: 2964.12,
            cpp9: 5730.62,
            cpp10: 6007.27,
            cpp11: 6007.27,
            cpp12: 4742.59
        },
        {
            cityName: 'Новокузнецк',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2338.62,
            cpp2: 3822.75,
            cpp3: 4947.09,
            cpp4: 5037.03,
            cpp5: 4182.54,
            cpp6: 3642.85,
            cpp7: 2923.28,
            cpp8: 3373.01,
            cpp9: 6521.16,
            cpp10: 6835.97,
            cpp11: 6835.97,
            cpp12: 5396.82
        },
        {
            cityName: 'Новокузнецк',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 2141.64,
            cpp2: 3500.75,
            cpp3: 4530.38,
            cpp4: 4612.75,
            cpp5: 3830.23,
            cpp6: 3336.01,
            cpp7: 2677.04,
            cpp8: 3088.9,
            cpp9: 5971.87,
            cpp10: 6260.17,
            cpp11: 6260.17,
            cpp12: 4942.24
        },
        {
            cityName: 'Новокузнецк',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2346.87,
            cpp2: 3836.23,
            cpp3: 4964.54,
            cpp4: 5054.8,
            cpp5: 4197.29,
            cpp6: 3655.71,
            cpp7: 2933.59,
            cpp8: 3384.91,
            cpp9: 6544.16,
            cpp10: 6860.09,
            cpp11: 6860.09,
            cpp12: 5415.86
        },
        {
            cityName: 'Новокузнецк',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1673.88,
            cpp2: 2736.15,
            cpp3: 3540.9,
            cpp4: 3605.27,
            cpp5: 2993.67,
            cpp6: 2607.39,
            cpp7: 2092.35,
            cpp8: 2414.25,
            cpp9: 4667.54,
            cpp10: 4892.87,
            cpp11: 4892.87,
            cpp12: 3862.79
        }
    ],
    Новороссийск: [
        {
            cityName: 'Новороссийск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2948.37,
            cpp2: 4819.45,
            cpp3: 6236.93,
            cpp4: 6350.33,
            cpp5: 5273.04,
            cpp6: 4592.65,
            cpp7: 3685.46,
            cpp8: 4252.45,
            cpp9: 8221.41,
            cpp10: 8618.3,
            cpp11: 8618.3,
            cpp12: 6803.92
        },
        {
            cityName: 'Новороссийск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2080.14,
            cpp2: 3400.23,
            cpp3: 4400.29,
            cpp4: 4480.3,
            cpp5: 3720.25,
            cpp6: 3240.22,
            cpp7: 2600.17,
            cpp8: 3000.2,
            cpp9: 5800.39,
            cpp10: 6080.4,
            cpp11: 6080.4,
            cpp12: 4800.32
        },
        {
            cityName: 'Новороссийск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 630.12,
            cpp2: 1030,
            cpp3: 1332.94,
            cpp4: 1357.17,
            cpp5: 1126.94,
            cpp6: 981.53,
            cpp7: 787.65,
            cpp8: 908.82,
            cpp9: 1757.06,
            cpp10: 1841.88,
            cpp11: 1841.88,
            cpp12: 1454.11
        }
    ],
    Норильск: [
        {
            cityName: 'Норильск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2959.83,
            cpp2: 4838.18,
            cpp3: 6261.17,
            cpp4: 6375.01,
            cpp5: 5293.54,
            cpp6: 4610.5,
            cpp7: 3699.78,
            cpp8: 4268.98,
            cpp9: 8253.36,
            cpp10: 8651.8,
            cpp11: 8651.8,
            cpp12: 6830.37
        },
        {
            cityName: 'Норильск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2250.83,
            cpp2: 3679.23,
            cpp3: 4761.36,
            cpp4: 4847.93,
            cpp5: 4025.51,
            cpp6: 3506.09,
            cpp7: 2813.53,
            cpp8: 3246.38,
            cpp9: 6276.34,
            cpp10: 6579.34,
            cpp11: 6579.34,
            cpp12: 5194.21
        },
        {
            cityName: 'Норильск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1525,
            cpp2: 2492.79,
            cpp3: 3225.96,
            cpp4: 3284.61,
            cpp5: 2727.4,
            cpp6: 2375.48,
            cpp7: 1906.25,
            cpp8: 2199.52,
            cpp9: 4252.4,
            cpp10: 4457.69,
            cpp11: 4457.69,
            cpp12: 3519.23
        },
        {
            cityName: 'Норильск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2546.33,
            cpp2: 4162.27,
            cpp3: 5386.47,
            cpp4: 5484.41,
            cpp5: 4554.02,
            cpp6: 3966.4,
            cpp7: 3182.91,
            cpp8: 3672.59,
            cpp9: 7100.35,
            cpp10: 7443.12,
            cpp11: 7443.12,
            cpp12: 5876.15
        },
        {
            cityName: 'Норильск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 3540.99,
            cpp2: 5788.16,
            cpp3: 7490.56,
            cpp4: 7626.75,
            cpp5: 6332.93,
            cpp6: 5515.78,
            cpp7: 4426.24,
            cpp8: 5107.2,
            cpp9: 9873.92,
            cpp10: 10350.59,
            cpp11: 10350.59,
            cpp12: 8171.52
        },
        {
            cityName: 'Норильск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1643.03,
            cpp2: 2685.72,
            cpp3: 3475.63,
            cpp4: 3538.83,
            cpp5: 2938.49,
            cpp6: 2559.33,
            cpp7: 2053.78,
            cpp8: 2369.75,
            cpp9: 4581.51,
            cpp10: 4802.69,
            cpp11: 4802.69,
            cpp12: 3791.6
        },
        {
            cityName: 'Норильск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1717.51,
            cpp2: 2807.48,
            cpp3: 3633.2,
            cpp4: 3699.26,
            cpp5: 3071.71,
            cpp6: 2675.36,
            cpp7: 2146.89,
            cpp8: 2477.18,
            cpp9: 4789.22,
            cpp10: 5020.43,
            cpp11: 5020.43,
            cpp12: 3963.49
        },
        {
            cityName: 'Норильск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1209.35,
            cpp2: 1976.82,
            cpp3: 2558.24,
            cpp4: 2604.76,
            cpp5: 2162.88,
            cpp6: 1883.8,
            cpp7: 1511.69,
            cpp8: 1744.26,
            cpp9: 3372.23,
            cpp10: 3535.03,
            cpp11: 3535.03,
            cpp12: 2790.81
        },
        {
            cityName: 'Норильск',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 535.97,
            cpp2: 876.1,
            cpp3: 1133.78,
            cpp4: 1154.39,
            cpp5: 958.56,
            cpp6: 834.87,
            cpp7: 669.96,
            cpp8: 773.03,
            cpp9: 1494.52,
            cpp10: 1566.67,
            cpp11: 1566.67,
            cpp12: 1236.85
        }
    ],
    Ноябрьск: [
        {
            cityName: 'Ноябрьск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 858.33,
            cpp2: 1403.04,
            cpp3: 1815.7,
            cpp4: 1848.71,
            cpp5: 1535.09,
            cpp6: 1337.01,
            cpp7: 1072.91,
            cpp8: 1237.98,
            cpp9: 2393.42,
            cpp10: 2508.97,
            cpp11: 2508.97,
            cpp12: 1980.76
        },
        {
            cityName: 'Ноябрьск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 360.39,
            cpp2: 589.1,
            cpp3: 762.37,
            cpp4: 776.23,
            cpp5: 644.55,
            cpp6: 561.38,
            cpp7: 450.49,
            cpp8: 519.8,
            cpp9: 1004.94,
            cpp10: 1053.46,
            cpp11: 1053.46,
            cpp12: 831.68
        },
        {
            cityName: 'Ноябрьск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 754.24,
            cpp2: 1232.89,
            cpp3: 1595.51,
            cpp4: 1624.52,
            cpp5: 1348.93,
            cpp6: 1174.87,
            cpp7: 942.8,
            cpp8: 1087.85,
            cpp9: 2103.17,
            cpp10: 2204.7,
            cpp11: 2204.7,
            cpp12: 1740.55
        },
        {
            cityName: 'Ноябрьск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 617.66,
            cpp2: 1009.64,
            cpp3: 1306.59,
            cpp4: 1330.34,
            cpp5: 1104.66,
            cpp6: 962.12,
            cpp7: 772.07,
            cpp8: 890.86,
            cpp9: 1722.32,
            cpp10: 1805.47,
            cpp11: 1805.47,
            cpp12: 1425.37
        }
    ],
    Обнинск: [
        {
            cityName: 'Обнинск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1612.67,
            cpp2: 2636.1,
            cpp3: 3411.43,
            cpp4: 3473.45,
            cpp5: 2884.21,
            cpp6: 2512.05,
            cpp7: 2015.84,
            cpp8: 2325.97,
            cpp9: 4496.88,
            cpp10: 4713.97,
            cpp11: 4713.97,
            cpp12: 3721.56
        },
        {
            cityName: 'Обнинск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1124.68,
            cpp2: 1838.41,
            cpp3: 2379.12,
            cpp4: 2422.38,
            cpp5: 2011.44,
            cpp6: 1751.9,
            cpp7: 1405.85,
            cpp8: 1622.13,
            cpp9: 3136.12,
            cpp10: 3287.52,
            cpp11: 3287.52,
            cpp12: 2595.41
        },
        {
            cityName: 'Обнинск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2214.33,
            cpp2: 3619.58,
            cpp3: 4684.16,
            cpp4: 4769.32,
            cpp5: 3960.24,
            cpp6: 3449.24,
            cpp7: 2767.91,
            cpp8: 3193.74,
            cpp9: 6174.57,
            cpp10: 6472.65,
            cpp11: 6472.65,
            cpp12: 5109.99
        },
        {
            cityName: 'Обнинск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 760.76,
            cpp2: 1243.55,
            cpp3: 1609.3,
            cpp4: 1638.56,
            cpp5: 1360.59,
            cpp6: 1185.03,
            cpp7: 950.95,
            cpp8: 1097.25,
            cpp9: 2121.34,
            cpp10: 2223.75,
            cpp11: 2223.75,
            cpp12: 1755.59
        },
        {
            cityName: 'Обнинск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1293.78,
            cpp2: 2114.83,
            cpp3: 2736.85,
            cpp4: 2786.61,
            cpp5: 2313.88,
            cpp6: 2015.31,
            cpp7: 1617.23,
            cpp8: 1866.03,
            cpp9: 3607.66,
            cpp10: 3781.82,
            cpp11: 3781.82,
            cpp12: 2985.65
        },
        {
            cityName: 'Обнинск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1063.4,
            cpp2: 1738.24,
            cpp3: 2249.49,
            cpp4: 2290.39,
            cpp5: 1901.84,
            cpp6: 1656.44,
            cpp7: 1329.24,
            cpp8: 1533.74,
            cpp9: 2965.24,
            cpp10: 3108.39,
            cpp11: 3108.39,
            cpp12: 2453.99
        }
    ],
    Орел: [
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3016.94,
            cpp2: 4931.54,
            cpp3: 6382,
            cpp4: 6498.03,
            cpp5: 5395.69,
            cpp6: 4699.47,
            cpp7: 3771.18,
            cpp8: 4351.36,
            cpp9: 8412.63,
            cpp10: 8818.76,
            cpp11: 8818.76,
            cpp12: 6962.18
        },
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2614.97,
            cpp2: 4274.47,
            cpp3: 5531.67,
            cpp4: 5632.25,
            cpp5: 4676.78,
            cpp6: 4073.32,
            cpp7: 3268.72,
            cpp8: 3771.59,
            cpp9: 7291.75,
            cpp10: 7643.77,
            cpp11: 7643.77,
            cpp12: 6034.55
        },
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2030.25,
            cpp2: 3318.67,
            cpp3: 4294.75,
            cpp4: 4372.84,
            cpp5: 3631.02,
            cpp6: 3162.5,
            cpp7: 2537.81,
            cpp8: 2928.24,
            cpp9: 5661.26,
            cpp10: 5934.56,
            cpp11: 5934.56,
            cpp12: 4685.18
        },
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2284.56,
            cpp2: 3734.37,
            cpp3: 4832.72,
            cpp4: 4920.58,
            cpp5: 4085.84,
            cpp6: 3558.64,
            cpp7: 2855.7,
            cpp8: 3295.03,
            cpp9: 6370.4,
            cpp10: 6677.93,
            cpp11: 6677.93,
            cpp12: 5272.05
        },
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2894.86,
            cpp2: 4731.99,
            cpp3: 6123.75,
            cpp4: 6235.09,
            cpp5: 5177.35,
            cpp6: 4509.31,
            cpp7: 3618.58,
            cpp8: 4175.28,
            cpp9: 8072.21,
            cpp10: 8461.91,
            cpp11: 8461.91,
            cpp12: 6680.45
        },
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 947.29,
            cpp2: 1548.46,
            cpp3: 2003.88,
            cpp4: 2040.32,
            cpp5: 1694.19,
            cpp6: 1475.59,
            cpp7: 1184.11,
            cpp8: 1366.29,
            cpp9: 2641.48,
            cpp10: 2769,
            cpp11: 2769,
            cpp12: 2186.06
        },
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 927.86,
            cpp2: 1516.7,
            cpp3: 1962.79,
            cpp4: 1998.47,
            cpp5: 1659.45,
            cpp6: 1445.33,
            cpp7: 1159.83,
            cpp8: 1338.26,
            cpp9: 2587.31,
            cpp10: 2712.22,
            cpp11: 2712.22,
            cpp12: 2141.22
        },
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 830.39,
            cpp2: 1357.37,
            cpp3: 1756.6,
            cpp4: 1788.54,
            cpp5: 1485.12,
            cpp6: 1293.49,
            cpp7: 1037.99,
            cpp8: 1197.68,
            cpp9: 2315.52,
            cpp10: 2427.3,
            cpp11: 2427.3,
            cpp12: 1916.29
        },
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 865,
            cpp2: 1413.94,
            cpp3: 1829.81,
            cpp4: 1863.08,
            cpp5: 1547.02,
            cpp6: 1347.4,
            cpp7: 1081.25,
            cpp8: 1247.6,
            cpp9: 2412.02,
            cpp10: 2528.46,
            cpp11: 2528.46,
            cpp12: 1996.16
        },
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 942.76,
            cpp2: 1541.05,
            cpp3: 1994.31,
            cpp4: 2030.57,
            cpp5: 1686.1,
            cpp6: 1468.53,
            cpp7: 1178.45,
            cpp8: 1359.75,
            cpp9: 2628.86,
            cpp10: 2755.77,
            cpp11: 2755.77,
            cpp12: 2175.61
        },
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'Россия 24',
            cpp1: 544.22,
            cpp2: 889.59,
            cpp3: 1151.24,
            cpp4: 1172.17,
            cpp5: 973.32,
            cpp6: 847.73,
            cpp7: 680.28,
            cpp8: 784.93,
            cpp9: 1517.54,
            cpp10: 1590.8,
            cpp11: 1590.8,
            cpp12: 1255.89
        },
        {
            cityName: 'Орел',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 469.55,
            cpp2: 767.53,
            cpp3: 993.27,
            cpp4: 1011.33,
            cpp5: 839.77,
            cpp6: 731.41,
            cpp7: 586.93,
            cpp8: 677.23,
            cpp9: 1309.32,
            cpp10: 1372.52,
            cpp11: 1372.52,
            cpp12: 1083.57
        }
    ],
    Оренбург: [
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 5173.11,
            cpp2: 8456.04,
            cpp3: 10943.11,
            cpp4: 11142.07,
            cpp5: 9251.9,
            cpp6: 8058.11,
            cpp7: 6466.38,
            cpp8: 7461.21,
            cpp9: 14425.01,
            cpp10: 15121.39,
            cpp11: 15121.39,
            cpp12: 11937.94
        },
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 4527.7,
            cpp2: 7401.05,
            cpp3: 9577.83,
            cpp4: 9751.97,
            cpp5: 8097.62,
            cpp6: 7052.77,
            cpp7: 5659.63,
            cpp8: 6530.34,
            cpp9: 12625.32,
            cpp10: 13234.82,
            cpp11: 13234.82,
            cpp12: 10448.54
        },
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 3493.11,
            cpp2: 5709.89,
            cpp3: 7389.27,
            cpp4: 7523.62,
            cpp5: 6247.29,
            cpp6: 5441.19,
            cpp7: 4366.39,
            cpp8: 5038.14,
            cpp9: 9740.4,
            cpp10: 10210.62,
            cpp11: 10210.62,
            cpp12: 8061.02
        },
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 6349.51,
            cpp2: 10379,
            cpp3: 13431.65,
            cpp4: 13675.86,
            cpp5: 11355.85,
            cpp6: 9890.58,
            cpp7: 7936.88,
            cpp8: 9157.94,
            cpp9: 17705.35,
            cpp10: 18560.09,
            cpp11: 18560.09,
            cpp12: 14652.71
        },
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 6560.83,
            cpp2: 10724.44,
            cpp3: 13878.69,
            cpp4: 14131.03,
            cpp5: 11733.8,
            cpp6: 10219.76,
            cpp7: 8201.04,
            cpp8: 9462.74,
            cpp9: 18294.63,
            cpp10: 19177.82,
            cpp11: 19177.82,
            cpp12: 15140.39
        },
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1573.7,
            cpp2: 2572.4,
            cpp3: 3328.98,
            cpp4: 3389.51,
            cpp5: 2814.5,
            cpp6: 2451.34,
            cpp7: 1967.13,
            cpp8: 2269.76,
            cpp9: 4388.2,
            cpp10: 4600.05,
            cpp11: 4600.05,
            cpp12: 3631.62
        },
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1821.92,
            cpp2: 2978.13,
            cpp3: 3854.05,
            cpp4: 3924.13,
            cpp5: 3258.43,
            cpp6: 2837.99,
            cpp7: 2277.4,
            cpp8: 2627.76,
            cpp9: 5080.34,
            cpp10: 5325.6,
            cpp11: 5325.6,
            cpp12: 4204.42
        },
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2147.33,
            cpp2: 3510.06,
            cpp3: 4542.43,
            cpp4: 4625.02,
            cpp5: 3840.42,
            cpp6: 3344.88,
            cpp7: 2684.16,
            cpp8: 3097.11,
            cpp9: 5987.75,
            cpp10: 6276.81,
            cpp11: 6276.81,
            cpp12: 4955.38
        },
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 815.18,
            cpp2: 1332.5,
            cpp3: 1724.41,
            cpp4: 1755.76,
            cpp5: 1457.91,
            cpp6: 1269.79,
            cpp7: 1018.97,
            cpp8: 1175.73,
            cpp9: 2273.09,
            cpp10: 2382.82,
            cpp11: 2382.82,
            cpp12: 1881.17
        },
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'Звезда',
            cpp1: 950.63,
            cpp2: 1553.92,
            cpp3: 2010.96,
            cpp4: 2047.52,
            cpp5: 1700.17,
            cpp6: 1480.8,
            cpp7: 1188.29,
            cpp8: 1371.11,
            cpp9: 2650.81,
            cpp10: 2778.78,
            cpp11: 2778.78,
            cpp12: 2193.77
        },
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'Россия 24',
            cpp1: 812.18,
            cpp2: 1327.6,
            cpp3: 1718.08,
            cpp4: 1749.31,
            cpp5: 1452.55,
            cpp6: 1265.13,
            cpp7: 1015.23,
            cpp8: 1171.42,
            cpp9: 2264.74,
            cpp10: 2374.07,
            cpp11: 2374.07,
            cpp12: 1874.26
        },
        {
            cityName: 'Оренбург',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 940.09,
            cpp2: 1536.68,
            cpp3: 1988.64,
            cpp4: 2024.8,
            cpp5: 1681.31,
            cpp6: 1464.36,
            cpp7: 1175.11,
            cpp8: 1355.89,
            cpp9: 2621.39,
            cpp10: 2747.94,
            cpp11: 2747.94,
            cpp12: 2169.43
        }
    ],
    Орск: [
        {
            cityName: 'Орск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3472.83,
            cpp2: 5676.74,
            cpp3: 7346.37,
            cpp4: 7479.94,
            cpp5: 6211.02,
            cpp6: 5409.6,
            cpp7: 4341.04,
            cpp8: 5008.89,
            cpp9: 9683.86,
            cpp10: 10151.35,
            cpp11: 10151.35,
            cpp12: 8014.23
        },
        {
            cityName: 'Орск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2285.69,
            cpp2: 3736.22,
            cpp3: 4835.11,
            cpp4: 4923.02,
            cpp5: 4087.87,
            cpp6: 3560.4,
            cpp7: 2857.11,
            cpp8: 3296.67,
            cpp9: 6373.56,
            cpp10: 6681.24,
            cpp11: 6681.24,
            cpp12: 5274.67
        },
        {
            cityName: 'Орск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2305.53,
            cpp2: 3768.65,
            cpp3: 4877.07,
            cpp4: 4965.75,
            cpp5: 4123.34,
            cpp6: 3591.3,
            cpp7: 2881.91,
            cpp8: 3325.28,
            cpp9: 6428.87,
            cpp10: 6739.23,
            cpp11: 6739.23,
            cpp12: 5320.44
        },
        {
            cityName: 'Орск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2712.49,
            cpp2: 4433.89,
            cpp3: 5737.97,
            cpp4: 5842.3,
            cpp5: 4851.19,
            cpp6: 4225.23,
            cpp7: 3390.62,
            cpp8: 3912.25,
            cpp9: 7563.69,
            cpp10: 7928.83,
            cpp11: 7928.83,
            cpp12: 6259.6
        },
        {
            cityName: 'Орск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1467.46,
            cpp2: 2398.73,
            cpp3: 3104.24,
            cpp4: 3160.68,
            cpp5: 2624.49,
            cpp6: 2285.85,
            cpp7: 1834.32,
            cpp8: 2116.53,
            cpp9: 4091.95,
            cpp10: 4289.49,
            cpp11: 4289.49,
            cpp12: 3386.44
        },
        {
            cityName: 'Орск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1137.57,
            cpp2: 1859.49,
            cpp3: 2406.39,
            cpp4: 2450.15,
            cpp5: 2034.5,
            cpp6: 1771.98,
            cpp7: 1421.96,
            cpp8: 1640.72,
            cpp9: 3172.06,
            cpp10: 3325.2,
            cpp11: 3325.2,
            cpp12: 2625.16
        },
        {
            cityName: 'Орск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1059.99,
            cpp2: 1732.68,
            cpp3: 2242.3,
            cpp4: 2283.06,
            cpp5: 1895.76,
            cpp6: 1651.15,
            cpp7: 1324.99,
            cpp8: 1528.84,
            cpp9: 2955.75,
            cpp10: 3098.44,
            cpp11: 3098.44,
            cpp12: 2446.14
        },
        {
            cityName: 'Орск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1276.59,
            cpp2: 2086.73,
            cpp3: 2700.47,
            cpp4: 2749.57,
            cpp5: 2283.13,
            cpp6: 1988.53,
            cpp7: 1595.73,
            cpp8: 1841.23,
            cpp9: 3559.71,
            cpp10: 3731.56,
            cpp11: 3731.56,
            cpp12: 2945.97
        }
    ],
    Пенза: [
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4919.09,
            cpp2: 8040.82,
            cpp3: 10405.76,
            cpp4: 10594.96,
            cpp5: 8797.6,
            cpp6: 7662.42,
            cpp7: 6148.86,
            cpp8: 7094.84,
            cpp9: 13716.69,
            cpp10: 14378.87,
            cpp11: 14378.87,
            cpp12: 11351.74
        },
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 4209.63,
            cpp2: 6881.13,
            cpp3: 8905,
            cpp4: 9066.91,
            cpp5: 7528.77,
            cpp6: 6557.32,
            cpp7: 5262.04,
            cpp8: 6071.59,
            cpp9: 11738.4,
            cpp10: 12305.09,
            cpp11: 12305.09,
            cpp12: 9714.54
        },
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 4968.01,
            cpp2: 8120.79,
            cpp3: 10509.26,
            cpp4: 10700.34,
            cpp5: 8885.1,
            cpp6: 7738.64,
            cpp7: 6210.02,
            cpp8: 7165.4,
            cpp9: 13853.11,
            cpp10: 14521.88,
            cpp11: 14521.88,
            cpp12: 11464.64
        },
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 6714.62,
            cpp2: 10975.82,
            cpp3: 14204,
            cpp4: 14462.25,
            cpp5: 12008.83,
            cpp6: 10459.31,
            cpp7: 8393.27,
            cpp8: 9684.54,
            cpp9: 18723.45,
            cpp10: 19627.34,
            cpp11: 19627.34,
            cpp12: 15495.27
        },
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 5944.85,
            cpp2: 9717.55,
            cpp3: 12575.65,
            cpp4: 12804.3,
            cpp5: 10632.14,
            cpp6: 9260.25,
            cpp7: 7431.07,
            cpp8: 8574.31,
            cpp9: 16577,
            cpp10: 17377.26,
            cpp11: 17377.26,
            cpp12: 13718.89
        },
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1825.08,
            cpp2: 2983.31,
            cpp3: 3860.75,
            cpp4: 3930.95,
            cpp5: 3264.09,
            cpp6: 2842.92,
            cpp7: 2281.35,
            cpp8: 2632.33,
            cpp9: 5089.17,
            cpp10: 5334.86,
            cpp11: 5334.86,
            cpp12: 4211.73
        },
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2194.5,
            cpp2: 3587.16,
            cpp3: 4642.21,
            cpp4: 4726.61,
            cpp5: 3924.78,
            cpp6: 3418.35,
            cpp7: 2743.12,
            cpp8: 3165.14,
            cpp9: 6119.28,
            cpp10: 6414.69,
            cpp11: 6414.69,
            cpp12: 5064.23
        },
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1462.09,
            cpp2: 2389.96,
            cpp3: 3092.89,
            cpp4: 3149.12,
            cpp5: 2614.9,
            cpp6: 2277.49,
            cpp7: 1827.61,
            cpp8: 2108.79,
            cpp9: 4076.99,
            cpp10: 4273.81,
            cpp11: 4273.81,
            cpp12: 3374.06
        },
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2873.59,
            cpp2: 4697.21,
            cpp3: 6078.74,
            cpp4: 6189.27,
            cpp5: 5139.3,
            cpp6: 4476.17,
            cpp7: 3591.98,
            cpp8: 4144.6,
            cpp9: 8012.89,
            cpp10: 8399.72,
            cpp11: 8399.72,
            cpp12: 6631.36
        },
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2724.45,
            cpp2: 4453.43,
            cpp3: 5763.26,
            cpp4: 5868.05,
            cpp5: 4872.58,
            cpp6: 4243.86,
            cpp7: 3405.57,
            cpp8: 3929.5,
            cpp9: 7597.03,
            cpp10: 7963.78,
            cpp11: 7963.78,
            cpp12: 6287.2
        },
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1247.78,
            cpp2: 2039.65,
            cpp3: 2639.54,
            cpp4: 2687.53,
            cpp5: 2231.61,
            cpp6: 1943.66,
            cpp7: 1559.73,
            cpp8: 1799.69,
            cpp9: 3479.4,
            cpp10: 3647.37,
            cpp11: 3647.37,
            cpp12: 2879.5
        },
        {
            cityName: 'Пенза',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1662.24,
            cpp2: 2717.13,
            cpp3: 3516.28,
            cpp4: 3580.21,
            cpp5: 2972.85,
            cpp6: 2589.26,
            cpp7: 2077.8,
            cpp8: 2397.46,
            cpp9: 4635.1,
            cpp10: 4858.86,
            cpp11: 4858.86,
            cpp12: 3835.94
        }
    ],
    Петрозаводск: [
        {
            cityName: 'Петрозаводск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3596.25,
            cpp2: 5878.48,
            cpp3: 7607.44,
            cpp4: 7745.76,
            cpp5: 6431.75,
            cpp6: 5601.84,
            cpp7: 4495.31,
            cpp8: 5186.89,
            cpp9: 10027.99,
            cpp10: 10512.1,
            cpp11: 10512.1,
            cpp12: 8299.03
        },
        {
            cityName: 'Петрозаводск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 3762.17,
            cpp2: 6149.7,
            cpp3: 7958.43,
            cpp4: 8103.13,
            cpp5: 6728.49,
            cpp6: 5860.3,
            cpp7: 4702.71,
            cpp8: 5426.2,
            cpp9: 10490.66,
            cpp10: 10997.11,
            cpp11: 10997.11,
            cpp12: 8681.93
        },
        {
            cityName: 'Петрозаводск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 3696.4,
            cpp2: 6042.19,
            cpp3: 7819.3,
            cpp4: 7961.47,
            cpp5: 6610.86,
            cpp6: 5757.85,
            cpp7: 4620.5,
            cpp8: 5331.34,
            cpp9: 10307.26,
            cpp10: 10804.85,
            cpp11: 10804.85,
            cpp12: 8530.15
        }
    ],
    'Петропавловск-Камчатский': [
        {
            cityName: 'Петропавловск-Камчатский',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 7106.4,
            cpp2: 11616.23,
            cpp3: 15032.77,
            cpp4: 15306.09,
            cpp5: 12709.52,
            cpp6: 11069.59,
            cpp7: 8883,
            cpp8: 10249.62,
            cpp9: 19815.93,
            cpp10: 20772.56,
            cpp11: 20772.56,
            cpp12: 16399.39
        },
        {
            cityName: 'Петропавловск-Камчатский',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 6277.78,
            cpp2: 10261.76,
            cpp3: 13279.92,
            cpp4: 13521.37,
            cpp5: 11227.57,
            cpp6: 9778.85,
            cpp7: 7847.22,
            cpp8: 9054.49,
            cpp9: 17505.35,
            cpp10: 18350.43,
            cpp11: 18350.43,
            cpp12: 14487.18
        },
        {
            cityName: 'Петропавловск-Камчатский',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 7958.27,
            cpp2: 13008.7,
            cpp3: 16834.79,
            cpp4: 17140.88,
            cpp5: 14233.05,
            cpp6: 12396.53,
            cpp7: 9947.83,
            cpp8: 11478.27,
            cpp9: 22191.32,
            cpp10: 23262.62,
            cpp11: 23262.62,
            cpp12: 18365.23
        },
        {
            cityName: 'Петропавловск-Камчатский',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 2519.64,
            cpp2: 4118.65,
            cpp3: 5330.01,
            cpp4: 5426.92,
            cpp5: 4506.28,
            cpp6: 3924.83,
            cpp7: 0,
            cpp8: 0,
            cpp9: 0,
            cpp10: 0,
            cpp11: 0,
            cpp12: 0
        },
        {
            cityName: 'Петропавловск-Камчатский',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 2343.13,
            cpp2: 3830.12,
            cpp3: 4956.63,
            cpp4: 5046.75,
            cpp5: 4190.6,
            cpp6: 3649.88,
            cpp7: 2928.92,
            cpp8: 3379.52,
            cpp9: 6533.74,
            cpp10: 6849.16,
            cpp11: 6849.16,
            cpp12: 5407.23
        }
    ],
    Псков: [
        {
            cityName: 'Псков',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3488.83,
            cpp2: 5702.9,
            cpp3: 7380.22,
            cpp4: 7514.41,
            cpp5: 6239.64,
            cpp6: 5434.53,
            cpp7: 4361.04,
            cpp8: 5031.97,
            cpp9: 9728.47,
            cpp10: 10198.12,
            cpp11: 10198.12,
            cpp12: 8051.15
        },
        {
            cityName: 'Псков',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2756.88,
            cpp2: 4506.44,
            cpp3: 5831.86,
            cpp4: 5937.89,
            cpp5: 4930.57,
            cpp6: 4294.37,
            cpp7: 3446.1,
            cpp8: 3976.27,
            cpp9: 7687.45,
            cpp10: 8058.57,
            cpp11: 8058.57,
            cpp12: 6362.03
        },
        {
            cityName: 'Псков',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2964.58,
            cpp2: 4845.95,
            cpp3: 6271.23,
            cpp4: 6385.26,
            cpp5: 5302.04,
            cpp6: 4617.91,
            cpp7: 3705.73,
            cpp8: 4275.84,
            cpp9: 8266.63,
            cpp10: 8665.71,
            cpp11: 8665.71,
            cpp12: 6841.35
        },
        {
            cityName: 'Псков',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 5435.47,
            cpp2: 8884.9,
            cpp3: 11498.11,
            cpp4: 11707.16,
            cpp5: 9721.13,
            cpp6: 8466.79,
            cpp7: 6794.34,
            cpp8: 7839.62,
            cpp9: 15156.6,
            cpp10: 15888.3,
            cpp11: 15888.3,
            cpp12: 12543.39
        },
        {
            cityName: 'Псков',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 4963.56,
            cpp2: 8113.52,
            cpp3: 10499.85,
            cpp4: 10690.75,
            cpp5: 8877.14,
            cpp6: 7731.71,
            cpp7: 6204.46,
            cpp8: 7158.99,
            cpp9: 13840.71,
            cpp10: 14508.88,
            cpp11: 14508.88,
            cpp12: 11454.38
        },
        {
            cityName: 'Псков',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1290.29,
            cpp2: 2109.13,
            cpp3: 2729.47,
            cpp4: 2779.09,
            cpp5: 2307.64,
            cpp6: 2009.88,
            cpp7: 1612.87,
            cpp8: 1861,
            cpp9: 3597.93,
            cpp10: 3771.63,
            cpp11: 3771.63,
            cpp12: 2977.6
        },
        {
            cityName: 'Псков',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1500.54,
            cpp2: 2452.81,
            cpp3: 3174.23,
            cpp4: 3231.94,
            cpp5: 2683.66,
            cpp6: 2337.38,
            cpp7: 1875.68,
            cpp8: 2164.24,
            cpp9: 4184.21,
            cpp10: 4386.2,
            cpp11: 4386.2,
            cpp12: 3462.79
        },
        {
            cityName: 'Псков',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2312.73,
            cpp2: 3780.42,
            cpp3: 4892.31,
            cpp4: 4981.26,
            cpp5: 4136.22,
            cpp6: 3602.52,
            cpp7: 2890.91,
            cpp8: 3335.66,
            cpp9: 6448.95,
            cpp10: 6760.28,
            cpp11: 6760.28,
            cpp12: 5337.06
        },
        {
            cityName: 'Псков',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1392.19,
            cpp2: 2275.7,
            cpp3: 2945.02,
            cpp4: 2998.57,
            cpp5: 2489.88,
            cpp6: 2168.61,
            cpp7: 1740.24,
            cpp8: 2007.97,
            cpp9: 3882.07,
            cpp10: 4069.48,
            cpp11: 4069.48,
            cpp12: 3212.75
        }
    ],
    Пятигорск: [
        {
            cityName: 'Пятигорск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1012.4,
            cpp2: 1654.88,
            cpp3: 2141.61,
            cpp4: 2180.55,
            cpp5: 1810.64,
            cpp6: 1577.01,
            cpp7: 1265.5,
            cpp8: 1460.19,
            cpp9: 2823.04,
            cpp10: 2959.32,
            cpp11: 2959.32,
            cpp12: 2336.31
        },
        {
            cityName: 'Пятигорск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1047.35,
            cpp2: 1712.01,
            cpp3: 2215.55,
            cpp4: 2255.83,
            cpp5: 1873.14,
            cpp6: 1631.45,
            cpp7: 1309.19,
            cpp8: 1510.6,
            cpp9: 2920.49,
            cpp10: 3061.48,
            cpp11: 3061.48,
            cpp12: 2416.96
        }
    ],
    Рыбинск: [
        {
            cityName: 'Рыбинск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1164.64,
            cpp2: 1903.74,
            cpp3: 2463.66,
            cpp4: 2508.46,
            cpp5: 2082.92,
            cpp6: 1814.15,
            cpp7: 1455.8,
            cpp8: 1679.77,
            cpp9: 3247.56,
            cpp10: 3404.34,
            cpp11: 3404.34,
            cpp12: 2687.63
        },
        {
            cityName: 'Рыбинск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 863.33,
            cpp2: 1411.22,
            cpp3: 1826.28,
            cpp4: 1859.49,
            cpp5: 1544.04,
            cpp6: 1344.81,
            cpp7: 1079.17,
            cpp8: 1245.19,
            cpp9: 2407.37,
            cpp10: 2523.59,
            cpp11: 2523.59,
            cpp12: 1992.31
        },
        {
            cityName: 'Рыбинск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 1005.84,
            cpp2: 1644.17,
            cpp3: 2127.74,
            cpp4: 2166.43,
            cpp5: 1798.91,
            cpp6: 1566.79,
            cpp7: 1257.3,
            cpp8: 1450.74,
            cpp9: 2804.75,
            cpp10: 2940.16,
            cpp11: 2940.16,
            cpp12: 2321.18
        },
        {
            cityName: 'Рыбинск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 356.94,
            cpp2: 583.46,
            cpp3: 755.07,
            cpp4: 768.8,
            cpp5: 638.38,
            cpp6: 556.01,
            cpp7: 446.18,
            cpp8: 514.82,
            cpp9: 995.32,
            cpp10: 1043.37,
            cpp11: 1043.37,
            cpp12: 823.71
        },
        {
            cityName: 'Рыбинск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 415.58,
            cpp2: 679.32,
            cpp3: 879.12,
            cpp4: 895.11,
            cpp5: 743.26,
            cpp6: 647.35,
            cpp7: 519.48,
            cpp8: 599.4,
            cpp9: 1158.84,
            cpp10: 1214.79,
            cpp11: 1214.79,
            cpp12: 959.04
        },
        {
            cityName: 'Рыбинск',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 472.02,
            cpp2: 771.58,
            cpp3: 998.51,
            cpp4: 1016.66,
            cpp5: 844.19,
            cpp6: 735.27,
            cpp7: 590.03,
            cpp8: 680.8,
            cpp9: 1316.22,
            cpp10: 1379.76,
            cpp11: 1379.76,
            cpp12: 1089.28
        }
    ],
    Рязань: [
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4600.69,
            cpp2: 7520.36,
            cpp3: 9732.23,
            cpp4: 9909.18,
            cpp5: 8228.16,
            cpp6: 7166.46,
            cpp7: 5750.86,
            cpp8: 6635.61,
            cpp9: 12828.85,
            cpp10: 13448.17,
            cpp11: 13448.17,
            cpp12: 10616.98
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 4331.33,
            cpp2: 7080.06,
            cpp3: 9162.43,
            cpp4: 9329.02,
            cpp5: 7746.42,
            cpp6: 6746.88,
            cpp7: 5414.16,
            cpp8: 6247.11,
            cpp9: 12077.75,
            cpp10: 12660.81,
            cpp11: 12660.81,
            cpp12: 9995.38
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 4302.72,
            cpp2: 7033.29,
            cpp3: 9101.91,
            cpp4: 9267.4,
            cpp5: 7695.25,
            cpp6: 6702.31,
            cpp7: 5378.4,
            cpp8: 6205.85,
            cpp9: 11997.97,
            cpp10: 12577.18,
            cpp11: 12577.18,
            cpp12: 9929.35
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 5827.15,
            cpp2: 9525.15,
            cpp3: 12326.67,
            cpp4: 12550.79,
            cpp5: 10421.64,
            cpp6: 9076.91,
            cpp7: 7283.94,
            cpp8: 8404.55,
            cpp9: 16248.79,
            cpp10: 17033.22,
            cpp11: 17033.22,
            cpp12: 13447.28
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 5883.86,
            cpp2: 9617.86,
            cpp3: 12446.64,
            cpp4: 12672.94,
            cpp5: 10523.07,
            cpp6: 9165.25,
            cpp7: 7354.83,
            cpp8: 8486.34,
            cpp9: 16406.93,
            cpp10: 17198.99,
            cpp11: 17198.99,
            cpp12: 13578.15
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 3122.81,
            cpp2: 5104.6,
            cpp3: 6605.95,
            cpp4: 6726.06,
            cpp5: 5585.03,
            cpp6: 4864.38,
            cpp7: 3903.52,
            cpp8: 4504.06,
            cpp9: 8707.85,
            cpp10: 9128.22,
            cpp11: 9128.22,
            cpp12: 7206.49
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 3416.12,
            cpp2: 5584.04,
            cpp3: 7226.4,
            cpp4: 7357.79,
            cpp5: 6109.59,
            cpp6: 5321.26,
            cpp7: 4270.15,
            cpp8: 4927.09,
            cpp9: 9525.71,
            cpp10: 9985.57,
            cpp11: 9985.57,
            cpp12: 7883.35
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1914.31,
            cpp2: 3129.17,
            cpp3: 4049.51,
            cpp4: 4123.14,
            cpp5: 3423.68,
            cpp6: 2981.91,
            cpp7: 2392.89,
            cpp8: 2761.03,
            cpp9: 5337.99,
            cpp10: 5595.69,
            cpp11: 5595.69,
            cpp12: 4417.65
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2310.01,
            cpp2: 3775.98,
            cpp3: 4886.56,
            cpp4: 4975.41,
            cpp5: 4131.36,
            cpp6: 3598.29,
            cpp7: 2887.51,
            cpp8: 3331.75,
            cpp9: 6441.37,
            cpp10: 6752.34,
            cpp11: 6752.34,
            cpp12: 5330.79
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 2400.88,
            cpp2: 3924.52,
            cpp3: 5078.79,
            cpp4: 5171.14,
            cpp5: 4293.89,
            cpp6: 3739.84,
            cpp7: 3001.11,
            cpp8: 3462.81,
            cpp9: 6694.77,
            cpp10: 7017.97,
            cpp11: 7017.97,
            cpp12: 5540.5
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'Звезда',
            cpp1: 1259.78,
            cpp2: 2059.26,
            cpp3: 2664.92,
            cpp4: 2713.37,
            cpp5: 2253.07,
            cpp6: 1962.35,
            cpp7: 1574.73,
            cpp8: 0,
            cpp9: 0,
            cpp10: 0,
            cpp11: 0,
            cpp12: 0
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'Россия 24',
            cpp1: 3606.66,
            cpp2: 5895.5,
            cpp3: 7629.47,
            cpp4: 7768.18,
            cpp5: 6450.37,
            cpp6: 5618.06,
            cpp7: 4508.32,
            cpp8: 5201.91,
            cpp9: 10057.03,
            cpp10: 10542.54,
            cpp11: 10542.54,
            cpp12: 8323.06
        },
        {
            cityName: 'Рязань',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1493.07,
            cpp2: 2440.59,
            cpp3: 3158.41,
            cpp4: 3215.84,
            cpp5: 2670.29,
            cpp6: 2325.74,
            cpp7: 1866.33,
            cpp8: 2153.46,
            cpp9: 4163.36,
            cpp10: 4364.35,
            cpp11: 4364.35,
            cpp12: 3445.54
        }
    ],
    Саранск: [
        {
            cityName: 'Саранск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2552.34,
            cpp2: 4172.09,
            cpp3: 5399.18,
            cpp4: 5497.34,
            cpp5: 4564.76,
            cpp6: 3975.76,
            cpp7: 3190.42,
            cpp8: 3681.26,
            cpp9: 7117.1,
            cpp10: 7460.68,
            cpp11: 7460.68,
            cpp12: 5890.01
        },
        {
            cityName: 'Саранск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2115.87,
            cpp2: 3458.64,
            cpp3: 4475.88,
            cpp4: 4557.26,
            cpp5: 3784.15,
            cpp6: 3295.88,
            cpp7: 2644.84,
            cpp8: 3051.74,
            cpp9: 5900.03,
            cpp10: 6184.85,
            cpp11: 6184.85,
            cpp12: 4882.78
        },
        {
            cityName: 'Саранск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1916.55,
            cpp2: 3132.82,
            cpp3: 4054.24,
            cpp4: 4127.95,
            cpp5: 3427.68,
            cpp6: 2985.39,
            cpp7: 2395.69,
            cpp8: 2764.25,
            cpp9: 5344.23,
            cpp10: 5602.22,
            cpp11: 5602.22,
            cpp12: 4422.81
        },
        {
            cityName: 'Саранск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2071.54,
            cpp2: 3386.17,
            cpp3: 4382.1,
            cpp4: 4461.77,
            cpp5: 3704.86,
            cpp6: 3226.82,
            cpp7: 2589.42,
            cpp8: 2987.79,
            cpp9: 5776.4,
            cpp10: 6055.26,
            cpp11: 6055.26,
            cpp12: 4780.47
        },
        {
            cityName: 'Саранск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2172.34,
            cpp2: 3550.94,
            cpp3: 4595.34,
            cpp4: 4678.89,
            cpp5: 3885.15,
            cpp6: 3383.84,
            cpp7: 2715.43,
            cpp8: 3133.18,
            cpp9: 6057.49,
            cpp10: 6349.92,
            cpp11: 6349.92,
            cpp12: 5013.1
        },
        {
            cityName: 'Саранск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1382.03,
            cpp2: 2259.08,
            cpp3: 2923.52,
            cpp4: 2976.68,
            cpp5: 2471.7,
            cpp6: 2152.78,
            cpp7: 1727.54,
            cpp8: 1993.31,
            cpp9: 3853.73,
            cpp10: 4039.78,
            cpp11: 4039.78,
            cpp12: 3189.3
        },
        {
            cityName: 'Саранск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1364.95,
            cpp2: 2231.17,
            cpp3: 2887.4,
            cpp4: 2939.89,
            cpp5: 2441.16,
            cpp6: 2126.17,
            cpp7: 1706.19,
            cpp8: 1968.68,
            cpp9: 3806.11,
            cpp10: 3989.86,
            cpp11: 3989.86,
            cpp12: 3149.89
        },
        {
            cityName: 'Саранск',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1295.56,
            cpp2: 2117.74,
            cpp3: 2740.61,
            cpp4: 2790.43,
            cpp5: 2317.06,
            cpp6: 2018.08,
            cpp7: 1619.45,
            cpp8: 1868.59,
            cpp9: 3612.62,
            cpp10: 3787.02,
            cpp11: 3787.02,
            cpp12: 2989.75
        }
    ],
    Севастополь: [
        {
            cityName: 'Севастополь',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1967.98,
            cpp2: 3216.89,
            cpp3: 4163.04,
            cpp4: 4238.73,
            cpp5: 3519.66,
            cpp6: 3065.51,
            cpp7: 2459.98,
            cpp8: 2838.43,
            cpp9: 5487.64,
            cpp10: 5752.56,
            cpp11: 5752.56,
            cpp12: 4541.49
        },
        {
            cityName: 'Севастополь',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1792.76,
            cpp2: 2930.48,
            cpp3: 3792.39,
            cpp4: 3861.34,
            cpp5: 3206.29,
            cpp6: 2792.58,
            cpp7: 2240.96,
            cpp8: 2585.72,
            cpp9: 4999.06,
            cpp10: 5240.39,
            cpp11: 5240.39,
            cpp12: 4137.15
        },
        {
            cityName: 'Севастополь',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 973.78,
            cpp2: 1591.76,
            cpp3: 2059.92,
            cpp4: 2097.38,
            cpp5: 1741.57,
            cpp6: 1516.85,
            cpp7: 1217.23,
            cpp8: 1404.49,
            cpp9: 2715.35,
            cpp10: 2846.44,
            cpp11: 2846.44,
            cpp12: 2247.19
        }
    ],
    Серов: [
        {
            cityName: 'Серов',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 659.52,
            cpp2: 1078.06,
            cpp3: 1395.14,
            cpp4: 1420.5,
            cpp5: 1179.53,
            cpp6: 1027.33,
            cpp7: 824.4,
            cpp8: 951.23,
            cpp9: 1839.05,
            cpp10: 1927.83,
            cpp11: 1927.83,
            cpp12: 1521.97
        },
        {
            cityName: 'Серов',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 601.65,
            cpp2: 983.47,
            cpp3: 1272.73,
            cpp4: 1295.87,
            cpp5: 1076.03,
            cpp6: 937.19,
            cpp7: 752.06,
            cpp8: 867.77,
            cpp9: 1677.68,
            cpp10: 1758.67,
            cpp11: 1758.67,
            cpp12: 1388.43
        }
    ],
    Симферополь: [
        {
            cityName: 'Симферополь',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2258.28,
            cpp2: 3691.43,
            cpp3: 4777.14,
            cpp4: 4864,
            cpp5: 4038.86,
            cpp6: 3517.71,
            cpp7: 2822.86,
            cpp8: 3257.14,
            cpp9: 6297.14,
            cpp10: 6601.14,
            cpp11: 6601.14,
            cpp12: 5211.43
        },
        {
            cityName: 'Симферополь',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 1885.1,
            cpp2: 3081.41,
            cpp3: 3987.71,
            cpp4: 4060.21,
            cpp5: 3371.43,
            cpp6: 2936.4,
            cpp7: 2356.37,
            cpp8: 2718.89,
            cpp9: 5256.52,
            cpp10: 5510.29,
            cpp11: 5510.29,
            cpp12: 4350.23
        },
        {
            cityName: 'Симферополь',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2421.5,
            cpp2: 3958.22,
            cpp3: 5122.41,
            cpp4: 5215.54,
            cpp5: 4330.76,
            cpp6: 3771.95,
            cpp7: 3026.88,
            cpp8: 3492.55,
            cpp9: 6752.26,
            cpp10: 7078.24,
            cpp11: 7078.24,
            cpp12: 5588.08
        },
        {
            cityName: 'Симферополь',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 685.45,
            cpp2: 1120.45,
            cpp3: 1450,
            cpp4: 1476.36,
            cpp5: 1225.91,
            cpp6: 1067.73,
            cpp7: 856.82,
            cpp8: 988.63,
            cpp9: 1911.36,
            cpp10: 2003.63,
            cpp11: 2003.63,
            cpp12: 1581.82
        },
        {
            cityName: 'Симферополь',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 644.94,
            cpp2: 1054.23,
            cpp3: 1364.3,
            cpp4: 1389.11,
            cpp5: 1153.45,
            cpp6: 1004.62,
            cpp7: 806.18,
            cpp8: 930.21,
            cpp9: 1798.4,
            cpp10: 1885.22,
            cpp11: 1885.22,
            cpp12: 1488.33
        }
    ],
    Смоленск: [
        {
            cityName: 'Смоленск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2552.45,
            cpp2: 4172.28,
            cpp3: 5399.42,
            cpp4: 5497.59,
            cpp5: 4564.96,
            cpp6: 3975.94,
            cpp7: 3190.57,
            cpp8: 3681.42,
            cpp9: 7117.41,
            cpp10: 7461.01,
            cpp11: 7461.01,
            cpp12: 5890.27
        },
        {
            cityName: 'Смоленск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2223.29,
            cpp2: 3634.23,
            cpp3: 4703.12,
            cpp4: 4788.63,
            cpp5: 3976.27,
            cpp6: 3463.2,
            cpp7: 2779.11,
            cpp8: 3206.67,
            cpp9: 6199.56,
            cpp10: 6498.85,
            cpp11: 6498.85,
            cpp12: 5130.67
        },
        {
            cityName: 'Смоленск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2073.53,
            cpp2: 3389.42,
            cpp3: 4386.31,
            cpp4: 4466.07,
            cpp5: 3708.43,
            cpp6: 3229.92,
            cpp7: 2591.91,
            cpp8: 2990.67,
            cpp9: 5781.96,
            cpp10: 6061.09,
            cpp11: 6061.09,
            cpp12: 4785.07
        },
        {
            cityName: 'Смоленск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2711.23,
            cpp2: 4431.82,
            cpp3: 5735.3,
            cpp4: 5839.58,
            cpp5: 4848.94,
            cpp6: 4223.27,
            cpp7: 3389.04,
            cpp8: 3910.43,
            cpp9: 7560.17,
            cpp10: 7925.15,
            cpp11: 7925.15,
            cpp12: 6256.69
        },
        {
            cityName: 'Смоленск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2461.02,
            cpp2: 4022.82,
            cpp3: 5206,
            cpp4: 5300.65,
            cpp5: 4401.43,
            cpp6: 3833.51,
            cpp7: 3076.27,
            cpp8: 3549.54,
            cpp9: 6862.45,
            cpp10: 7193.74,
            cpp11: 7193.74,
            cpp12: 5679.27
        },
        {
            cityName: 'Смоленск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1172.72,
            cpp2: 1916.95,
            cpp3: 2480.76,
            cpp4: 2525.86,
            cpp5: 2097.37,
            cpp6: 1826.74,
            cpp7: 1465.9,
            cpp8: 1691.42,
            cpp9: 3270.09,
            cpp10: 3427.95,
            cpp11: 3427.95,
            cpp12: 2706.28
        },
        {
            cityName: 'Смоленск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1737.05,
            cpp2: 2839.4,
            cpp3: 3674.52,
            cpp4: 3741.33,
            cpp5: 3106.64,
            cpp6: 2705.79,
            cpp7: 2171.31,
            cpp8: 2505.36,
            cpp9: 4843.69,
            cpp10: 5077.52,
            cpp11: 5077.52,
            cpp12: 4008.57
        },
        {
            cityName: 'Смоленск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1345.82,
            cpp2: 2199.9,
            cpp3: 2846.93,
            cpp4: 2898.69,
            cpp5: 2406.95,
            cpp6: 2096.38,
            cpp7: 1682.28,
            cpp8: 1941.09,
            cpp9: 3752.77,
            cpp10: 3933.94,
            cpp11: 3933.94,
            cpp12: 3105.74
        },
        {
            cityName: 'Смоленск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1558.87,
            cpp2: 2548.16,
            cpp3: 3297.61,
            cpp4: 3357.57,
            cpp5: 2787.98,
            cpp6: 2428.24,
            cpp7: 1948.59,
            cpp8: 2248.37,
            cpp9: 4346.85,
            cpp10: 4556.7,
            cpp11: 4556.7,
            cpp12: 3597.4
        },
        {
            cityName: 'Смоленск',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1143.31,
            cpp2: 1868.87,
            cpp3: 2418.53,
            cpp4: 2462.51,
            cpp5: 2044.76,
            cpp6: 1780.92,
            cpp7: 1429.13,
            cpp8: 1649,
            cpp9: 3188.07,
            cpp10: 3341.97,
            cpp11: 3341.97,
            cpp12: 2638.4
        },
        {
            cityName: 'Смоленск',
            priceType: 'min',
            channelName: 'Россия 24',
            cpp1: 1132.66,
            cpp2: 1851.47,
            cpp3: 2396.01,
            cpp4: 2439.58,
            cpp5: 2025.72,
            cpp6: 1764.34,
            cpp7: 1415.83,
            cpp8: 1633.65,
            cpp9: 3158.38,
            cpp10: 3310.86,
            cpp11: 3310.86,
            cpp12: 2613.83
        }
    ],
    Сочи: [
        {
            cityName: 'Сочи',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3822.57,
            cpp2: 6248.43,
            cpp3: 8086.21,
            cpp4: 8233.23,
            cpp5: 6836.52,
            cpp6: 5954.39,
            cpp7: 4778.21,
            cpp8: 5513.32,
            cpp9: 10659.09,
            cpp10: 11173.67,
            cpp11: 11173.67,
            cpp12: 8821.31
        },
        {
            cityName: 'Сочи',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 3387.02,
            cpp2: 5536.48,
            cpp3: 7164.86,
            cpp4: 7295.13,
            cpp5: 6057.56,
            cpp6: 5275.94,
            cpp7: 4233.78,
            cpp8: 4885.13,
            cpp9: 9444.59,
            cpp10: 9900.53,
            cpp11: 9900.53,
            cpp12: 7816.21
        },
        {
            cityName: 'Сочи',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2938.12,
            cpp2: 4802.7,
            cpp3: 6215.26,
            cpp4: 6328.26,
            cpp5: 5254.72,
            cpp6: 4576.69,
            cpp7: 3672.65,
            cpp8: 4237.68,
            cpp9: 8192.84,
            cpp10: 8588.36,
            cpp11: 8588.36,
            cpp12: 6780.28
        },
        {
            cityName: 'Сочи',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3012.71,
            cpp2: 4924.63,
            cpp3: 6373.04,
            cpp4: 6488.92,
            cpp5: 5388.12,
            cpp6: 4692.88,
            cpp7: 3765.89,
            cpp8: 4345.26,
            cpp9: 8400.83,
            cpp10: 8806.39,
            cpp11: 8806.39,
            cpp12: 6952.41
        },
        {
            cityName: 'Сочи',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 2907.79,
            cpp2: 4753.12,
            cpp3: 6151.1,
            cpp4: 6262.94,
            cpp5: 5200.47,
            cpp6: 4529.44,
            cpp7: 3634.74,
            cpp8: 4193.93,
            cpp9: 8108.27,
            cpp10: 8499.7,
            cpp11: 8499.7,
            cpp12: 6710.29
        },
        {
            cityName: 'Сочи',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1337.1,
            cpp2: 2185.64,
            cpp3: 2828.48,
            cpp4: 2879.91,
            cpp5: 2391.35,
            cpp6: 2082.79,
            cpp7: 1671.37,
            cpp8: 1928.51,
            cpp9: 3728.45,
            cpp10: 3908.44,
            cpp11: 3908.44,
            cpp12: 3085.61
        },
        {
            cityName: 'Сочи',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1467.85,
            cpp2: 2399.38,
            cpp3: 3105.07,
            cpp4: 3161.53,
            cpp5: 2625.2,
            cpp6: 2286.46,
            cpp7: 1834.82,
            cpp8: 2117.1,
            cpp9: 4093.05,
            cpp10: 4290.65,
            cpp11: 4290.65,
            cpp12: 3387.35
        },
        {
            cityName: 'Сочи',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1183.14,
            cpp2: 1933.97,
            cpp3: 2502.79,
            cpp4: 2548.29,
            cpp5: 2115.99,
            cpp6: 1842.96,
            cpp7: 1478.92,
            cpp8: 1706.44,
            cpp9: 3299.13,
            cpp10: 3458.39,
            cpp11: 3458.39,
            cpp12: 2730.31
        },
        {
            cityName: 'Сочи',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1622.61,
            cpp2: 2652.34,
            cpp3: 3432.44,
            cpp4: 3494.85,
            cpp5: 2901.97,
            cpp6: 2527.52,
            cpp7: 2028.26,
            cpp8: 2340.3,
            cpp9: 4524.58,
            cpp10: 4743.01,
            cpp11: 4743.01,
            cpp12: 3744.48
        },
        {
            cityName: 'Сочи',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2429.45,
            cpp2: 3971.21,
            cpp3: 5139.21,
            cpp4: 5232.65,
            cpp5: 4344.97,
            cpp6: 3784.33,
            cpp7: 3036.81,
            cpp8: 3504.01,
            cpp9: 6774.42,
            cpp10: 7101.46,
            cpp11: 7101.46,
            cpp12: 5606.42
        },
        {
            cityName: 'Сочи',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1061.94,
            cpp2: 1735.87,
            cpp3: 2246.42,
            cpp4: 2287.27,
            cpp5: 1899.25,
            cpp6: 1654.18,
            cpp7: 1327.43,
            cpp8: 1531.65,
            cpp9: 2961.19,
            cpp10: 3104.15,
            cpp11: 3104.15,
            cpp12: 2450.64
        }
    ],
    'Старый Оскол': [
        {
            cityName: 'Старый Оскол',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1149.44,
            cpp2: 1878.9,
            cpp3: 2431.51,
            cpp4: 2475.72,
            cpp5: 2055.73,
            cpp6: 1790.48,
            cpp7: 1436.8,
            cpp8: 1657.85,
            cpp9: 3205.18,
            cpp10: 3359.91,
            cpp11: 3359.91,
            cpp12: 2652.56
        },
        {
            cityName: 'Старый Оскол',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 697.76,
            cpp2: 1140.56,
            cpp3: 1476.02,
            cpp4: 1502.86,
            cpp5: 1247.91,
            cpp6: 1086.89,
            cpp7: 872.19,
            cpp8: 1006.38,
            cpp9: 1945.66,
            cpp10: 2039.59,
            cpp11: 2039.59,
            cpp12: 1610.2
        }
    ],
    Стерлитамак: [
        {
            cityName: 'Стерлитамак',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2254.5,
            cpp2: 3685.24,
            cpp3: 4769.14,
            cpp4: 4855.85,
            cpp5: 4032.09,
            cpp6: 3511.82,
            cpp7: 2818.13,
            cpp8: 3251.69,
            cpp9: 6286.59,
            cpp10: 6590.08,
            cpp11: 6590.08,
            cpp12: 5202.7
        },
        {
            cityName: 'Стерлитамак',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1761.58,
            cpp2: 2879.51,
            cpp3: 3726.43,
            cpp4: 3794.18,
            cpp5: 3150.52,
            cpp6: 2744,
            cpp7: 2201.98,
            cpp8: 2540.74,
            cpp9: 4912.11,
            cpp10: 5149.24,
            cpp11: 5149.24,
            cpp12: 4065.19
        },
        {
            cityName: 'Стерлитамак',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2194.72,
            cpp2: 3587.52,
            cpp3: 4642.68,
            cpp4: 4727.09,
            cpp5: 3925.17,
            cpp6: 3418.7,
            cpp7: 2743.4,
            cpp8: 3165.46,
            cpp9: 6119.89,
            cpp10: 6415.34,
            cpp11: 6415.34,
            cpp12: 5064.74
        }
    ],
    Сургут: [
        {
            cityName: 'Сургут',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4495.4,
            cpp2: 7348.24,
            cpp3: 9509.49,
            cpp4: 9682.39,
            cpp5: 8039.84,
            cpp6: 7002.44,
            cpp7: 5619.25,
            cpp8: 6483.74,
            cpp9: 12535.24,
            cpp10: 13140.39,
            cpp11: 13140.39,
            cpp12: 10373.99
        },
        {
            cityName: 'Сургут',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3234.89,
            cpp2: 5287.8,
            cpp3: 6843.04,
            cpp4: 6967.46,
            cpp5: 5785.48,
            cpp6: 5038.96,
            cpp7: 4043.61,
            cpp8: 4665.71,
            cpp9: 9020.37,
            cpp10: 9455.83,
            cpp11: 9455.83,
            cpp12: 7465.13
        },
        {
            cityName: 'Сургут',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 3517.61,
            cpp2: 5749.94,
            cpp3: 7441.1,
            cpp4: 7576.39,
            cpp5: 6291.11,
            cpp6: 5479.36,
            cpp7: 4397.01,
            cpp8: 5073.48,
            cpp9: 9808.73,
            cpp10: 10282.25,
            cpp11: 10282.25,
            cpp12: 8117.57
        },
        {
            cityName: 'Сургут',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 684.68,
            cpp2: 1119.19,
            cpp3: 1448.37,
            cpp4: 1474.7,
            cpp5: 1224.53,
            cpp6: 1066.52,
            cpp7: 855.85,
            cpp8: 987.52,
            cpp9: 1909.21,
            cpp10: 2001.38,
            cpp11: 2001.38,
            cpp12: 1580.04
        },
        {
            cityName: 'Сургут',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2127.85,
            cpp2: 3478.21,
            cpp3: 4501.21,
            cpp4: 4583.05,
            cpp5: 3805.57,
            cpp6: 3314.53,
            cpp7: 2659.81,
            cpp8: 3069.01,
            cpp9: 5933.42,
            cpp10: 6219.86,
            cpp11: 6219.86,
            cpp12: 4910.41
        },
        {
            cityName: 'Сургут',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 1019.93,
            cpp2: 1667.19,
            cpp3: 2157.54,
            cpp4: 2196.76,
            cpp5: 1824.1,
            cpp6: 1588.73,
            cpp7: 1274.91,
            cpp8: 1471.05,
            cpp9: 2844.02,
            cpp10: 2981.32,
            cpp11: 2981.32,
            cpp12: 2353.67
        },
        {
            cityName: 'Сургут',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1065.89,
            cpp2: 1742.32,
            cpp3: 2254.77,
            cpp4: 2295.77,
            cpp5: 1906.31,
            cpp6: 1660.33,
            cpp7: 1332.36,
            cpp8: 1537.34,
            cpp9: 2972.2,
            cpp10: 3115.68,
            cpp11: 3115.68,
            cpp12: 2459.75
        }
    ],
    Сызрань: [
        {
            cityName: 'Сызрань',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 2495.25,
            cpp2: 4078.77,
            cpp3: 5278.41,
            cpp4: 5374.38,
            cpp5: 4462.66,
            cpp6: 3886.83,
            cpp7: 3119.06,
            cpp8: 3598.92,
            cpp9: 6957.91,
            cpp10: 7293.81,
            cpp11: 7293.81,
            cpp12: 5758.27
        }
    ],
    Сыктывкар: [
        {
            cityName: 'Сыктывкар',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3052.42,
            cpp2: 4989.53,
            cpp3: 6457.04,
            cpp4: 6574.44,
            cpp5: 5459.14,
            cpp6: 4754.73,
            cpp7: 3815.52,
            cpp8: 4402.53,
            cpp9: 8511.55,
            cpp10: 8922.46,
            cpp11: 8922.46,
            cpp12: 7044.05
        },
        {
            cityName: 'Сыктывкар',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2252.59,
            cpp2: 3682.12,
            cpp3: 4765.1,
            cpp4: 4851.74,
            cpp5: 4028.68,
            cpp6: 3508.85,
            cpp7: 2815.74,
            cpp8: 3248.93,
            cpp9: 6281.27,
            cpp10: 6584.51,
            cpp11: 6584.51,
            cpp12: 5198.29
        },
        {
            cityName: 'Сыктывкар',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2479.01,
            cpp2: 4052.24,
            cpp3: 5244.07,
            cpp4: 5339.42,
            cpp5: 4433.62,
            cpp6: 3861.54,
            cpp7: 3098.77,
            cpp8: 3575.5,
            cpp9: 6912.64,
            cpp10: 7246.35,
            cpp11: 7246.35,
            cpp12: 5720.8
        },
        {
            cityName: 'Сыктывкар',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 2555.38,
            cpp2: 4177.06,
            cpp3: 5405.61,
            cpp4: 5503.89,
            cpp5: 4570.19,
            cpp6: 3980.49,
            cpp7: 3194.22,
            cpp8: 3685.64,
            cpp9: 7125.57,
            cpp10: 7469.56,
            cpp11: 7469.56,
            cpp12: 5897.02
        },
        {
            cityName: 'Сыктывкар',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1446.26,
            cpp2: 2364.09,
            cpp3: 3059.4,
            cpp4: 3115.03,
            cpp5: 2586.59,
            cpp6: 2252.83,
            cpp7: 1807.83,
            cpp8: 2085.96,
            cpp9: 4032.85,
            cpp10: 4227.54,
            cpp11: 4227.54,
            cpp12: 3337.53
        },
        {
            cityName: 'Сыктывкар',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 1676.11,
            cpp2: 2739.8,
            cpp3: 3545.62,
            cpp4: 3610.09,
            cpp5: 2997.66,
            cpp6: 2610.87,
            cpp7: 2095.14,
            cpp8: 2417.47,
            cpp9: 4673.78,
            cpp10: 4899.41,
            cpp11: 4899.41,
            cpp12: 3867.95
        },
        {
            cityName: 'Сыктывкар',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2133.52,
            cpp2: 3487.49,
            cpp3: 4513.22,
            cpp4: 4595.27,
            cpp5: 3815.72,
            cpp6: 3323.37,
            cpp7: 2666.9,
            cpp8: 3077.19,
            cpp9: 5949.24,
            cpp10: 6236.44,
            cpp11: 6236.44,
            cpp12: 4923.51
        },
        {
            cityName: 'Сыктывкар',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1368.69,
            cpp2: 2237.28,
            cpp3: 2895.31,
            cpp4: 2947.95,
            cpp5: 2447.85,
            cpp6: 2132,
            cpp7: 1710.86,
            cpp8: 1974.07,
            cpp9: 3816.54,
            cpp10: 4000.79,
            cpp11: 4000.79,
            cpp12: 3158.52
        }
    ],
    Тамбов: [
        {
            cityName: 'Тамбов',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3536.33,
            cpp2: 5780.54,
            cpp3: 7480.69,
            cpp4: 7616.71,
            cpp5: 6324.59,
            cpp6: 5508.51,
            cpp7: 4420.41,
            cpp8: 5100.47,
            cpp9: 9860.91,
            cpp10: 10336.96,
            cpp11: 10336.96,
            cpp12: 8160.76
        },
        {
            cityName: 'Тамбов',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 3218.69,
            cpp2: 5261.32,
            cpp3: 6808.77,
            cpp4: 6932.57,
            cpp5: 5756.51,
            cpp6: 5013.73,
            cpp7: 4023.36,
            cpp8: 4642.34,
            cpp9: 8975.2,
            cpp10: 9408.48,
            cpp11: 9408.48,
            cpp12: 7427.75
        },
        {
            cityName: 'Тамбов',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2948.75,
            cpp2: 4820.07,
            cpp3: 6237.74,
            cpp4: 6351.15,
            cpp5: 5273.72,
            cpp6: 4593.24,
            cpp7: 3685.94,
            cpp8: 4253,
            cpp9: 8222.47,
            cpp10: 8619.42,
            cpp11: 8619.42,
            cpp12: 6804.81
        },
        {
            cityName: 'Тамбов',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3887.76,
            cpp2: 6354.99,
            cpp3: 8224.1,
            cpp4: 8373.63,
            cpp5: 6953.11,
            cpp6: 6055.93,
            cpp7: 4859.7,
            cpp8: 5607.34,
            cpp9: 10840.86,
            cpp10: 11364.21,
            cpp11: 11364.21,
            cpp12: 8971.75
        },
        {
            cityName: 'Тамбов',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1399.61,
            cpp2: 2287.83,
            cpp3: 2960.72,
            cpp4: 3014.55,
            cpp5: 2503.16,
            cpp6: 2180.17,
            cpp7: 1749.52,
            cpp8: 2018.67,
            cpp9: 3902.77,
            cpp10: 4091.18,
            cpp11: 4091.18,
            cpp12: 3229.88
        },
        {
            cityName: 'Тамбов',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1844.49,
            cpp2: 3015.03,
            cpp3: 3901.8,
            cpp4: 3972.74,
            cpp5: 3298.8,
            cpp6: 2873.14,
            cpp7: 2305.61,
            cpp8: 2660.32,
            cpp9: 5143.28,
            cpp10: 5391.58,
            cpp11: 5391.58,
            cpp12: 4256.51
        },
        {
            cityName: 'Тамбов',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1133.91,
            cpp2: 1853.51,
            cpp3: 2398.66,
            cpp4: 2442.27,
            cpp5: 2027.96,
            cpp6: 1766.28,
            cpp7: 1417.39,
            cpp8: 1635.45,
            cpp9: 3161.87,
            cpp10: 3314.51,
            cpp11: 3314.51,
            cpp12: 2616.72
        }
    ],
    Тобольск: [
        {
            cityName: 'Тобольск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1497.8,
            cpp2: 2448.33,
            cpp3: 3168.42,
            cpp4: 3226.03,
            cpp5: 2678.76,
            cpp6: 2333.11,
            cpp7: 1872.25,
            cpp8: 2160.29,
            cpp9: 4176.56,
            cpp10: 4378.18,
            cpp11: 4378.18,
            cpp12: 3456.46
        },
        {
            cityName: 'Тобольск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 1153.57,
            cpp2: 1885.65,
            cpp3: 2440.25,
            cpp4: 2484.62,
            cpp5: 2063.12,
            cpp6: 1796.91,
            cpp7: 1441.97,
            cpp8: 1663.81,
            cpp9: 3216.7,
            cpp10: 3371.98,
            cpp11: 3371.98,
            cpp12: 2662.09
        }
    ],
    Тольятти: [
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 6499.09,
            cpp2: 10623.52,
            cpp3: 13748.08,
            cpp4: 13998.05,
            cpp5: 11623.38,
            cpp6: 10123.59,
            cpp7: 8123.87,
            cpp8: 9373.69,
            cpp9: 18122.47,
            cpp10: 18997.35,
            cpp11: 18997.35,
            cpp12: 14997.91
        },
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 7244.5,
            cpp2: 11841.98,
            cpp3: 15324.91,
            cpp4: 15603.55,
            cpp5: 12956.52,
            cpp6: 11284.71,
            cpp7: 9055.63,
            cpp8: 10448.8,
            cpp9: 20201.02,
            cpp10: 21176.24,
            cpp11: 21176.24,
            cpp12: 16718.09
        },
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 5898.93,
            cpp2: 9642.49,
            cpp3: 12478.51,
            cpp4: 12705.4,
            cpp5: 10550.02,
            cpp6: 9188.72,
            cpp7: 7373.67,
            cpp8: 8508.08,
            cpp9: 16448.95,
            cpp10: 17243.04,
            cpp11: 17243.04,
            cpp12: 13612.92
        },
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 7549.15,
            cpp2: 12339.95,
            cpp3: 15969.35,
            cpp4: 16259.7,
            cpp5: 13501.36,
            cpp6: 11759.25,
            cpp7: 9436.43,
            cpp8: 10888.19,
            cpp9: 21050.5,
            cpp10: 22066.73,
            cpp11: 22066.73,
            cpp12: 17421.11
        },
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 7337.3,
            cpp2: 11993.66,
            cpp3: 15521.2,
            cpp4: 15803.41,
            cpp5: 13122.47,
            cpp6: 11429.25,
            cpp7: 9171.62,
            cpp8: 10582.64,
            cpp9: 20459.77,
            cpp10: 21447.48,
            cpp11: 21447.48,
            cpp12: 16932.22
        },
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 2554.19,
            cpp2: 4175.12,
            cpp3: 5403.1,
            cpp4: 5501.34,
            cpp5: 4568.08,
            cpp6: 3978.65,
            cpp7: 3192.74,
            cpp8: 3683.93,
            cpp9: 7122.27,
            cpp10: 7466.1,
            cpp11: 7466.1,
            cpp12: 5894.29
        },
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 5310.47,
            cpp2: 8680.58,
            cpp3: 11233.69,
            cpp4: 11437.94,
            cpp5: 9497.57,
            cpp6: 8272.08,
            cpp7: 6638.09,
            cpp8: 7659.33,
            cpp9: 14808.05,
            cpp10: 15522.92,
            cpp11: 15522.92,
            cpp12: 12254.93
        },
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 4398.68,
            cpp2: 7190.14,
            cpp3: 9304.89,
            cpp4: 9474.07,
            cpp5: 7866.86,
            cpp6: 6851.78,
            cpp7: 5498.35,
            cpp8: 6344.24,
            cpp9: 12265.54,
            cpp10: 12857.67,
            cpp11: 12857.67,
            cpp12: 10150.79
        },
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 5147.87,
            cpp2: 8414.79,
            cpp3: 10889.73,
            cpp4: 11087.73,
            cpp5: 9206.77,
            cpp6: 8018.8,
            cpp7: 6434.84,
            cpp8: 7424.82,
            cpp9: 14354.64,
            cpp10: 15047.63,
            cpp11: 15047.63,
            cpp12: 11879.71
        },
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 5033.54,
            cpp2: 8227.9,
            cpp3: 10647.87,
            cpp4: 10841.47,
            cpp5: 9002.29,
            cpp6: 7840.71,
            cpp7: 6291.93,
            cpp8: 7259.91,
            cpp9: 14035.83,
            cpp10: 14713.42,
            cpp11: 14713.42,
            cpp12: 11615.86
        },
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1280.59,
            cpp2: 2093.28,
            cpp3: 2708.94,
            cpp4: 2758.2,
            cpp5: 2290.29,
            cpp6: 1994.77,
            cpp7: 1600.74,
            cpp8: 1847.01,
            cpp9: 3570.88,
            cpp10: 3743.27,
            cpp11: 3743.27,
            cpp12: 2955.21
        },
        {
            cityName: 'Тольятти',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1160.13,
            cpp2: 1896.37,
            cpp3: 2454.13,
            cpp4: 2498.75,
            cpp5: 2074.85,
            cpp6: 1807.13,
            cpp7: 1450.17,
            cpp8: 1673.27,
            cpp9: 3234.99,
            cpp10: 3391.16,
            cpp11: 3391.16,
            cpp12: 2677.23
        }
    ],
    'Улан-Удэ': [
        {
            cityName: 'Улан-Удэ',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 6494.81,
            cpp2: 10616.51,
            cpp3: 13739.01,
            cpp4: 13988.81,
            cpp5: 11615.71,
            cpp6: 10116.91,
            cpp7: 8118.51,
            cpp8: 9367.51,
            cpp9: 18110.52,
            cpp10: 18984.82,
            cpp11: 18984.82,
            cpp12: 14988.02
        },
        {
            cityName: 'Улан-Удэ',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 4371.08,
            cpp2: 7145.03,
            cpp3: 9246.51,
            cpp4: 9414.63,
            cpp5: 7817.5,
            cpp6: 6808.79,
            cpp7: 5463.84,
            cpp8: 6304.44,
            cpp9: 12188.58,
            cpp10: 12776.99,
            cpp11: 12776.99,
            cpp12: 10087.1
        },
        {
            cityName: 'Улан-Удэ',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 5261.19,
            cpp2: 8600.02,
            cpp3: 11129.44,
            cpp4: 11331.79,
            cpp5: 9409.43,
            cpp6: 8195.31,
            cpp7: 6576.49,
            cpp8: 7588.25,
            cpp9: 14670.62,
            cpp10: 15378.86,
            cpp11: 15378.86,
            cpp12: 12141.21
        },
        {
            cityName: 'Улан-Удэ',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 4157.1,
            cpp2: 6795.27,
            cpp3: 8793.87,
            cpp4: 8953.76,
            cpp5: 7434.82,
            cpp6: 6475.49,
            cpp7: 5196.38,
            cpp8: 5995.82,
            cpp9: 11591.92,
            cpp10: 12151.53,
            cpp11: 12151.53,
            cpp12: 9593.32
        },
        {
            cityName: 'Улан-Удэ',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 2430.25,
            cpp2: 3972.53,
            cpp3: 5140.92,
            cpp4: 5234.39,
            cpp5: 4346.42,
            cpp6: 3785.59,
            cpp7: 3037.82,
            cpp8: 3505.18,
            cpp9: 6776.67,
            cpp10: 7103.82,
            cpp11: 7103.82,
            cpp12: 5608.28
        },
        {
            cityName: 'Улан-Удэ',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 3253.74,
            cpp2: 5318.61,
            cpp3: 6882.91,
            cpp4: 7008.05,
            cpp5: 5819.19,
            cpp6: 5068.32,
            cpp7: 4067.17,
            cpp8: 4692.89,
            cpp9: 9072.92,
            cpp10: 9510.93,
            cpp11: 9510.93,
            cpp12: 7508.63
        },
        {
            cityName: 'Улан-Удэ',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 3018.75,
            cpp2: 4934.5,
            cpp3: 6385.83,
            cpp4: 6501.93,
            cpp5: 5398.93,
            cpp6: 4702.29,
            cpp7: 3773.44,
            cpp8: 4353.97,
            cpp9: 8417.68,
            cpp10: 8824.05,
            cpp11: 8824.05,
            cpp12: 6966.36
        },
        {
            cityName: 'Улан-Удэ',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1431.46,
            cpp2: 2339.88,
            cpp3: 3028.09,
            cpp4: 3083.14,
            cpp5: 2560.11,
            cpp6: 2229.77,
            cpp7: 1789.32,
            cpp8: 2064.6,
            cpp9: 3991.57,
            cpp10: 4184.26,
            cpp11: 4184.26,
            cpp12: 3303.37
        }
    ],
    Уссурийск: [
        {
            cityName: 'Уссурийск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 5387.69,
            cpp2: 8806.8,
            cpp3: 11397.03,
            cpp4: 11604.25,
            cpp5: 9635.67,
            cpp6: 8392.36,
            cpp7: 6734.61,
            cpp8: 7770.7,
            cpp9: 15023.36,
            cpp10: 15748.63,
            cpp11: 15748.63,
            cpp12: 12433.13
        }
    ],
    Ухта: [
        {
            cityName: 'Ухта',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 1102.86,
            cpp2: 1802.75,
            cpp3: 2332.97,
            cpp4: 2375.39,
            cpp5: 1972.42,
            cpp6: 1717.91,
            cpp7: 1378.57,
            cpp8: 1590.66,
            cpp9: 3075.28,
            cpp10: 3223.74,
            cpp11: 3223.74,
            cpp12: 2545.06
        }
    ],
    Чебоксары: [
        {
            cityName: 'Чебоксары',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 5784.47,
            cpp2: 9455.38,
            cpp3: 12236.37,
            cpp4: 12458.85,
            cpp5: 10345.29,
            cpp6: 9010.42,
            cpp7: 7230.58,
            cpp8: 8342.98,
            cpp9: 16129.76,
            cpp10: 16908.44,
            cpp11: 16908.44,
            cpp12: 13348.77
        },
        {
            cityName: 'Чебоксары',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 5241,
            cpp2: 8567.03,
            cpp3: 11086.74,
            cpp4: 11288.32,
            cpp5: 9373.33,
            cpp6: 8163.87,
            cpp7: 6551.25,
            cpp8: 7559.14,
            cpp9: 14614.34,
            cpp10: 15319.86,
            cpp11: 15319.86,
            cpp12: 12094.62
        },
        {
            cityName: 'Чебоксары',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 5198.55,
            cpp2: 8497.62,
            cpp3: 10996.92,
            cpp4: 11196.87,
            cpp5: 9297.4,
            cpp6: 8097.73,
            cpp7: 6498.18,
            cpp8: 7497.9,
            cpp9: 14495.95,
            cpp10: 15195.75,
            cpp11: 15195.75,
            cpp12: 11996.64
        },
        {
            cityName: 'Чебоксары',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 7211.78,
            cpp2: 11788.49,
            cpp3: 15255.69,
            cpp4: 15533.06,
            cpp5: 12897.99,
            cpp6: 11233.73,
            cpp7: 9014.72,
            cpp8: 10401.61,
            cpp9: 20109.77,
            cpp10: 21080.59,
            cpp11: 21080.59,
            cpp12: 16642.57
        },
        {
            cityName: 'Чебоксары',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 8498.95,
            cpp2: 13892.52,
            cpp3: 17978.56,
            cpp4: 18305.44,
            cpp5: 15200.05,
            cpp6: 13238.75,
            cpp7: 10623.69,
            cpp8: 12258.11,
            cpp9: 23699.01,
            cpp10: 24843.09,
            cpp11: 24843.09,
            cpp12: 19612.97
        },
        {
            cityName: 'Чебоксары',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 1737.85,
            cpp2: 2840.72,
            cpp3: 3676.23,
            cpp4: 3743.07,
            cpp5: 3108.08,
            cpp6: 2707.04,
            cpp7: 2172.32,
            cpp8: 2506.52,
            cpp9: 4845.93,
            cpp10: 5079.88,
            cpp11: 5079.88,
            cpp12: 4010.43
        },
        {
            cityName: 'Чебоксары',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 2854.78,
            cpp2: 4666.46,
            cpp3: 6038.95,
            cpp4: 6148.75,
            cpp5: 5105.66,
            cpp6: 4446.87,
            cpp7: 3568.47,
            cpp8: 4117.47,
            cpp9: 7960.44,
            cpp10: 8344.74,
            cpp11: 8344.74,
            cpp12: 6587.95
        },
        {
            cityName: 'Чебоксары',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 1398.5,
            cpp2: 2286.02,
            cpp3: 2958.38,
            cpp4: 3012.16,
            cpp5: 2501.17,
            cpp6: 2178.44,
            cpp7: 1748.13,
            cpp8: 2017.07,
            cpp9: 3899.68,
            cpp10: 4087.94,
            cpp11: 4087.94,
            cpp12: 3227.32
        },
        {
            cityName: 'Чебоксары',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2247.08,
            cpp2: 3673.11,
            cpp3: 4753.44,
            cpp4: 4839.87,
            cpp5: 4018.82,
            cpp6: 3500.26,
            cpp7: 2808.85,
            cpp8: 3240.98,
            cpp9: 6265.9,
            cpp10: 6568.39,
            cpp11: 6568.39,
            cpp12: 5185.57
        },
        {
            cityName: 'Чебоксары',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 798.1,
            cpp2: 1304.59,
            cpp3: 1688.3,
            cpp4: 1718.99,
            cpp5: 1427.38,
            cpp6: 1243.2,
            cpp7: 997.63,
            cpp8: 1151.11,
            cpp9: 2225.48,
            cpp10: 2332.92,
            cpp11: 2332.92,
            cpp12: 1841.78
        },
        {
            cityName: 'Чебоксары',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1958.66,
            cpp2: 3201.66,
            cpp3: 4143.32,
            cpp4: 4218.66,
            cpp5: 3502.99,
            cpp6: 3050.99,
            cpp7: 2448.33,
            cpp8: 2824.99,
            cpp9: 5461.65,
            cpp10: 5725.32,
            cpp11: 5725.32,
            cpp12: 4519.99
        }
    ],
    Череповец: [
        {
            cityName: 'Череповец',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 4270.79,
            cpp2: 6981.1,
            cpp3: 9034.37,
            cpp4: 9198.63,
            cpp5: 7638.15,
            cpp6: 6652.58,
            cpp7: 5338.49,
            cpp8: 6159.8,
            cpp9: 11908.94,
            cpp10: 12483.85,
            cpp11: 12483.85,
            cpp12: 9855.67
        },
        {
            cityName: 'Череповец',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2627.18,
            cpp2: 4294.44,
            cpp3: 5557.5,
            cpp4: 5658.55,
            cpp5: 4698.62,
            cpp6: 4092.34,
            cpp7: 3283.98,
            cpp8: 3789.21,
            cpp9: 7325.8,
            cpp10: 7679.46,
            cpp11: 7679.46,
            cpp12: 6062.73
        },
        {
            cityName: 'Череповец',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3858.23,
            cpp2: 6306.72,
            cpp3: 8161.64,
            cpp4: 8310.03,
            cpp5: 6900.29,
            cpp6: 6009.93,
            cpp7: 4822.79,
            cpp8: 5564.75,
            cpp9: 10758.52,
            cpp10: 11277.9,
            cpp11: 11277.9,
            cpp12: 8903.61
        },
        {
            cityName: 'Череповец',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 3142.14,
            cpp2: 5136.19,
            cpp3: 6646.83,
            cpp4: 6767.69,
            cpp5: 5619.6,
            cpp6: 4894.49,
            cpp7: 3927.67,
            cpp8: 4531.93,
            cpp9: 8761.74,
            cpp10: 9184.72,
            cpp11: 9184.72,
            cpp12: 7251.09
        },
        {
            cityName: 'Череповец',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 702.42,
            cpp2: 1148.19,
            cpp3: 1485.89,
            cpp4: 1512.91,
            cpp5: 1256.26,
            cpp6: 1094.16,
            cpp7: 878.03,
            cpp8: 1013.11,
            cpp9: 1958.68,
            cpp10: 2053.23,
            cpp11: 2053.23,
            cpp12: 1620.97
        },
        {
            cityName: 'Череповец',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 1946.71,
            cpp2: 3182.12,
            cpp3: 4118.04,
            cpp4: 4192.91,
            cpp5: 3481.61,
            cpp6: 3032.37,
            cpp7: 2433.38,
            cpp8: 2807.75,
            cpp9: 5428.32,
            cpp10: 5690.38,
            cpp11: 5690.38,
            cpp12: 4492.4
        },
        {
            cityName: 'Череповец',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 2098.21,
            cpp2: 3429.77,
            cpp3: 4438.53,
            cpp4: 4519.23,
            cpp5: 3752.57,
            cpp6: 3268.37,
            cpp7: 2622.77,
            cpp8: 3026.27,
            cpp9: 5850.79,
            cpp10: 6133.24,
            cpp11: 6133.24,
            cpp12: 4842.03
        }
    ],
    Чита: [
        {
            cityName: 'Чита',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 8124.97,
            cpp2: 13281.19,
            cpp3: 17187.43,
            cpp4: 17499.93,
            cpp5: 14531.19,
            cpp6: 12656.2,
            cpp7: 10156.21,
            cpp8: 11718.7,
            cpp9: 22656.15,
            cpp10: 23749.9,
            cpp11: 23749.9,
            cpp12: 18749.92
        },
        {
            cityName: 'Чита',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 4767.53,
            cpp2: 7793.08,
            cpp3: 10085.16,
            cpp4: 10268.53,
            cpp5: 8526.55,
            cpp6: 7426.35,
            cpp7: 5959.42,
            cpp8: 6876.25,
            cpp9: 13294.08,
            cpp10: 13935.86,
            cpp11: 13935.86,
            cpp12: 11002
        },
        {
            cityName: 'Чита',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 7852.87,
            cpp2: 12836.43,
            cpp3: 16611.85,
            cpp4: 16913.88,
            cpp5: 14044.56,
            cpp6: 12232.36,
            cpp7: 9816.09,
            cpp8: 11326.26,
            cpp9: 21897.44,
            cpp10: 22954.55,
            cpp11: 22954.55,
            cpp12: 18122.02
        },
        {
            cityName: 'Чита',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 6603.04,
            cpp2: 10793.44,
            cpp3: 13967.98,
            cpp4: 14221.94,
            cpp5: 11809.29,
            cpp6: 10285.51,
            cpp7: 8253.81,
            cpp8: 9523.62,
            cpp9: 18412.34,
            cpp10: 19301.21,
            cpp11: 19301.21,
            cpp12: 15237.79
        },
        {
            cityName: 'Чита',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 4223.81,
            cpp2: 6904.31,
            cpp3: 8934.99,
            cpp4: 9097.44,
            cpp5: 7554.12,
            cpp6: 6579.4,
            cpp7: 5279.76,
            cpp8: 6092.04,
            cpp9: 11777.94,
            cpp10: 12346.53,
            cpp11: 12346.53,
            cpp12: 9747.26
        },
        {
            cityName: 'Чита',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 4291.46,
            cpp2: 7014.89,
            cpp3: 9078.09,
            cpp4: 9243.15,
            cpp5: 7675.11,
            cpp6: 6684.78,
            cpp7: 5364.33,
            cpp8: 6189.61,
            cpp9: 11966.57,
            cpp10: 12544.27,
            cpp11: 12544.27,
            cpp12: 9903.37
        },
        {
            cityName: 'Чита',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 3614.4,
            cpp2: 5908.16,
            cpp3: 7645.85,
            cpp4: 7784.86,
            cpp5: 6464.22,
            cpp6: 5630.12,
            cpp7: 4518,
            cpp8: 5213.08,
            cpp9: 10078.62,
            cpp10: 10565.17,
            cpp11: 10565.17,
            cpp12: 8340.93
        },
        {
            cityName: 'Чита',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 3396.97,
            cpp2: 5552.75,
            cpp3: 7185.91,
            cpp4: 7316.56,
            cpp5: 6075.36,
            cpp6: 5291.44,
            cpp7: 4246.22,
            cpp8: 4899.48,
            cpp9: 9472.33,
            cpp10: 9929.62,
            cpp11: 9929.62,
            cpp12: 7839.17
        },
        {
            cityName: 'Чита',
            priceType: 'min',
            channelName: 'Матч ТВ',
            cpp1: 1250.17,
            cpp2: 2043.54,
            cpp3: 2644.58,
            cpp4: 2692.67,
            cpp5: 2235.88,
            cpp6: 1947.38,
            cpp7: 1562.71,
            cpp8: 1803.13,
            cpp9: 3486.04,
            cpp10: 3654.33,
            cpp11: 3654.33,
            cpp12: 2885
        }
    ],
    'Южно-Сахалинск': [
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 10192.82,
            cpp2: 16661.34,
            cpp3: 21561.74,
            cpp4: 21953.77,
            cpp5: 18229.47,
            cpp6: 15877.28,
            cpp7: 12741.03,
            cpp8: 14701.19,
            cpp9: 28422.29,
            cpp10: 29794.41,
            cpp11: 29794.41,
            cpp12: 23521.9
        },
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 7905.87,
            cpp2: 12923.06,
            cpp3: 16723.96,
            cpp4: 17028.03,
            cpp5: 14139.35,
            cpp6: 12314.91,
            cpp7: 9882.34,
            cpp8: 11402.7,
            cpp9: 22045.22,
            cpp10: 23109.47,
            cpp11: 23109.47,
            cpp12: 18244.32
        },
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 10549.3,
            cpp2: 17244.04,
            cpp3: 22315.82,
            cpp4: 22721.56,
            cpp5: 18867.01,
            cpp6: 16432.56,
            cpp7: 13186.62,
            cpp8: 15215.33,
            cpp9: 29416.31,
            cpp10: 30836.4,
            cpp11: 30836.4,
            cpp12: 24344.53
        },
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 11779.92,
            cpp2: 19255.63,
            cpp3: 24919.06,
            cpp4: 25372.13,
            cpp5: 21067.93,
            cpp6: 18349.49,
            cpp7: 14724.9,
            cpp8: 16990.27,
            cpp9: 32847.85,
            cpp10: 34433.61,
            cpp11: 34433.61,
            cpp12: 27184.43
        },
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 11852.57,
            cpp2: 19374.39,
            cpp3: 25072.74,
            cpp4: 25528.61,
            cpp5: 21197.86,
            cpp6: 18462.66,
            cpp7: 14815.71,
            cpp8: 17095.05,
            cpp9: 33050.43,
            cpp10: 34645.97,
            cpp11: 34645.97,
            cpp12: 27352.08
        },
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'Пятый канал',
            cpp1: 3847.42,
            cpp2: 6289.05,
            cpp3: 8138.77,
            cpp4: 8286.75,
            cpp5: 6880.96,
            cpp6: 5993.09,
            cpp7: 4809.27,
            cpp8: 5549.16,
            cpp9: 10728.38,
            cpp10: 11246.3,
            cpp11: 11246.3,
            cpp12: 8878.66
        },
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'РЕН ТВ',
            cpp1: 3946.71,
            cpp2: 6451.35,
            cpp3: 8348.81,
            cpp4: 8500.6,
            cpp5: 7058.54,
            cpp6: 6147.76,
            cpp7: 4933.39,
            cpp8: 5692.37,
            cpp9: 11005.24,
            cpp10: 11536.53,
            cpp11: 11536.53,
            cpp12: 9107.79
        },
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 3160.82,
            cpp2: 5166.73,
            cpp3: 6686.35,
            cpp4: 6807.92,
            cpp5: 5653.01,
            cpp6: 4923.59,
            cpp7: 3951.03,
            cpp8: 4558.88,
            cpp9: 8813.83,
            cpp10: 9239.32,
            cpp11: 9239.32,
            cpp12: 7294.2
        },
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 6370.54,
            cpp2: 10413.38,
            cpp3: 13476.14,
            cpp4: 13721.16,
            cpp5: 11393.46,
            cpp6: 9923.34,
            cpp7: 7963.17,
            cpp8: 9188.28,
            cpp9: 17764,
            cpp10: 18621.57,
            cpp11: 18621.57,
            cpp12: 14701.24
        },
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 6047.85,
            cpp2: 9885.91,
            cpp3: 12793.53,
            cpp4: 13026.14,
            cpp5: 10816.35,
            cpp6: 9420.69,
            cpp7: 7559.81,
            cpp8: 8722.86,
            cpp9: 16864.2,
            cpp10: 17678.33,
            cpp11: 17678.33,
            cpp12: 13956.58
        },
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 2753.36,
            cpp2: 4500.68,
            cpp3: 5824.41,
            cpp4: 5930.31,
            cpp5: 4924.28,
            cpp6: 4288.89,
            cpp7: 3441.7,
            cpp8: 3971.19,
            cpp9: 7677.64,
            cpp10: 8048.28,
            cpp11: 8048.28,
            cpp12: 6353.91
        },
        {
            cityName: 'Южно-Сахалинск',
            priceType: 'min',
            channelName: 'Россия 24',
            cpp1: 8144.37,
            cpp2: 13312.92,
            cpp3: 17228.48,
            cpp4: 17541.72,
            cpp5: 14565.9,
            cpp6: 12686.43,
            cpp7: 10180.47,
            cpp8: 11746.69,
            cpp9: 22710.27,
            cpp10: 23806.63,
            cpp11: 23806.63,
            cpp12: 18794.71
        }
    ],
    Якутск: [
        {
            cityName: 'Якутск',
            priceType: 'min',
            channelName: 'Первый',
            cpp1: 3661.89,
            cpp2: 5985.79,
            cpp3: 7746.31,
            cpp4: 7887.15,
            cpp5: 6549.16,
            cpp6: 5704.1,
            cpp7: 4577.37,
            cpp8: 5281.58,
            cpp9: 10211.05,
            cpp10: 10704,
            cpp11: 10704,
            cpp12: 8450.52
        },
        {
            cityName: 'Якутск',
            priceType: 'min',
            channelName: 'Россия 1',
            cpp1: 2304.44,
            cpp2: 3766.88,
            cpp3: 4874.79,
            cpp4: 4963.42,
            cpp5: 4121.41,
            cpp6: 3589.62,
            cpp7: 2880.56,
            cpp8: 3323.72,
            cpp9: 6425.85,
            cpp10: 6736.07,
            cpp11: 6736.07,
            cpp12: 5317.95
        },
        {
            cityName: 'Якутск',
            priceType: 'min',
            channelName: 'НТВ',
            cpp1: 2990.24,
            cpp2: 4887.89,
            cpp3: 6325.5,
            cpp4: 6440.51,
            cpp5: 5347.93,
            cpp6: 4657.87,
            cpp7: 3737.8,
            cpp8: 4312.84,
            cpp9: 8338.16,
            cpp10: 8740.7,
            cpp11: 8740.7,
            cpp12: 6900.55
        },
        {
            cityName: 'Якутск',
            priceType: 'min',
            channelName: 'ТНТ',
            cpp1: 3466.65,
            cpp2: 5666.64,
            cpp3: 7333.3,
            cpp4: 7466.63,
            cpp5: 6199.97,
            cpp6: 5399.98,
            cpp7: 4333.31,
            cpp8: 4999.98,
            cpp9: 9666.63,
            cpp10: 10133.29,
            cpp11: 10133.29,
            cpp12: 7999.97
        },
        {
            cityName: 'Якутск',
            priceType: 'min',
            channelName: 'СТС',
            cpp1: 8194.61,
            cpp2: 13395.03,
            cpp3: 17334.75,
            cpp4: 17649.92,
            cpp5: 14655.74,
            cpp6: 12764.68,
            cpp7: 10243.26,
            cpp8: 11819.14,
            cpp9: 22850.35,
            cpp10: 23953.47,
            cpp11: 23953.47,
            cpp12: 18910.63
        },
        {
            cityName: 'Якутск',
            priceType: 'min',
            channelName: 'Домашний',
            cpp1: 2475.24,
            cpp2: 4046.07,
            cpp3: 5236.09,
            cpp4: 5331.29,
            cpp5: 4426.87,
            cpp6: 3855.67,
            cpp7: 3094.05,
            cpp8: 3570.06,
            cpp9: 6902.12,
            cpp10: 7235.32,
            cpp11: 7235.32,
            cpp12: 5712.1
        },
        {
            cityName: 'Якутск',
            priceType: 'min',
            channelName: 'ТВ-3',
            cpp1: 2055.01,
            cpp2: 3359.15,
            cpp3: 4347.14,
            cpp4: 4426.18,
            cpp5: 3675.31,
            cpp6: 3201.08,
            cpp7: 2568.76,
            cpp8: 2963.96,
            cpp9: 5730.32,
            cpp10: 6006.96,
            cpp11: 6006.96,
            cpp12: 4742.33
        },
        {
            cityName: 'Якутск',
            priceType: 'min',
            channelName: 'Пятница',
            cpp1: 3618.38,
            cpp2: 5914.65,
            cpp3: 7654.26,
            cpp4: 7793.43,
            cpp5: 6471.33,
            cpp6: 5636.32,
            cpp7: 4522.97,
            cpp8: 5218.81,
            cpp9: 10089.7,
            cpp10: 10576.79,
            cpp11: 10576.79,
            cpp12: 8350.1
        },
        {
            cityName: 'Якутск',
            priceType: 'min',
            channelName: 'ТВЦ',
            cpp1: 1756.44,
            cpp2: 2871.11,
            cpp3: 3715.55,
            cpp4: 3783.11,
            cpp5: 3141.33,
            cpp6: 2736,
            cpp7: 2195.55,
            cpp8: 2533.33,
            cpp9: 4897.77,
            cpp10: 5134.22,
            cpp11: 5134.22,
            cpp12: 4053.33
        }
    ]
};
