"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalErrorContainer = void 0;
const styled_components_1 = require("styled-components");
exports.GlobalErrorContainer = styled_components_1.default.div.withConfig({ displayName: "GlobalErrorContainer", componentId: "-16pjyx3" }) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    max-height: 600px;
    max-width: 700px;
    width: 100%;
  }
`;
