import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body, #root {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif, Helvetica, Arial;
    scroll-behavior: smooth;
  }

  #root {
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
  }
`;
