"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThanksStep = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const Thanks = styled_components_1.default.div.withConfig({ displayName: "Thanks", componentId: "-15zaoch" }) `
  font-size: 30px;
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
function ThanksStep() {
    return (0, jsx_runtime_1.jsx)(Thanks, { children: "\u0421\u043F\u0430\u0441\u0438\u0431\u043E, \u0432\u0430\u0448\u0430 \u0437\u0430\u044F\u0432\u043A\u0430 \u043F\u0440\u0438\u043D\u044F\u0442\u0430" });
}
exports.ThanksStep = ThanksStep;
