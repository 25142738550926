"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Step = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = require("styled-components");
const mediaQueries_1 = require("@common/mediaQueries");
const utils_1 = require("./utils");
const StepStyled = styled_components_1.default.div.withConfig({ displayName: "StepStyled", componentId: "-1cx5ib" }) `
  @media ${mediaQueries_1.devices.mobileS} {
    display: flex;
    flex-direction: column;
    min-height: ${utils_1.ONE_STEP_HEIGHT - 10}px;
    gap: 20px;
    margin-bottom: 45px;
  }

  @media ${mediaQueries_1.devices.mobileXL} {
    flex-direction: ${({ isReversePosition }) => `${isReversePosition ? 'row' : 'row-reverse'}`};
    justify-content: space-between;
    margin-bottom: 0px;
  }
`;
const Image = styled_components_1.default.img.withConfig({ displayName: "Image", componentId: "-1s50pjl" }) `
  @media ${mediaQueries_1.devices.mobileS} {
    height: 260px;
    background-color: #f8f8f8;
    border-radius: 40px;
    width: 100%;
  }

  @media ${mediaQueries_1.devices.mobileXL} {
    width: 50%;
  }

  @media ${mediaQueries_1.devices.tablet} {
    width: 400px;
  }
`;
const Text = styled_components_1.default.div.withConfig({ displayName: "Text", componentId: "-d0m0hh" }) `
  @media ${mediaQueries_1.devices.mobileL} {
    flex-basis: 400px;
  }
`;
const Timeline = styled_components_1.default.span.withConfig({ displayName: "Timeline", componentId: "-1ssorqa" }) `
  @media ${mediaQueries_1.devices.laptop} {
    display: flex;
    justify-content: center;
    width: 2px;
    margin: 0 auto;
    position: relative;
    margin-top: 10px;
    background: ${({ fillPercent, theme }) => `linear-gradient(${theme.palette.primary.main} ${fillPercent}%, #e3e6e9 ${fillPercent}%)`};

    &:before {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: ${({ fillPercent, theme }) => `${fillPercent > 0 ? theme.palette.primary.main : '#bdbdbd'}`};
      content: ${({ index }) => `'${index}'`};
      margin: 0 auto;
      position: absolute;
      border-radius: 50%;
      color: #fff;
      font-weight: 700;
    }
  }
`;
const PointsList = styled_components_1.default.ul.withConfig({ displayName: "PointsList", componentId: "-1fvgny1" }) `
  border-top: 1px solid #e8e8e8;
  padding-top: 16px;
  padding-left: 0px;
  list-style: none;
`;
const Point = styled_components_1.default.li.withConfig({ displayName: "Point", componentId: "-6qq9fm" }) `
  line-height: 175%;
  margin-bottom: 4px;
  position: relative;
  padding-left: 30px;

  &:before {
    height: 13.75px;
    width: 5px;
    border-right: ${({ theme }) => `3px solid ${theme.palette.primary.main}`};
    border-top: ${({ theme }) => `3px solid ${theme.palette.primary.main}`};
    content: '';
    left: 5px;
    top: 4px;
    position: absolute;
    transform: scaleX(-1) rotate(135deg);
  }
`;
function Step({ step, scrollPosition }) {
    const { index, title, titleDescription, points, img } = step;
    const fillPercent = (0, utils_1.calculateFillPercent)(scrollPosition, index);
    const isReversePosition = index % 2 === 1;
    return ((0, jsx_runtime_1.jsxs)(StepStyled, Object.assign({ isReversePosition: isReversePosition }, { children: [(0, jsx_runtime_1.jsx)(Image, { src: img }), (0, jsx_runtime_1.jsx)(Timeline, { fillPercent: fillPercent, index: index }), (0, jsx_runtime_1.jsxs)(Text, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "h2" }, { children: title })), titleDescription && (0, jsx_runtime_1.jsx)("p", { children: titleDescription }), (0, jsx_runtime_1.jsx)(PointsList, { children: points.map(point => ((0, jsx_runtime_1.jsx)(Point, { children: point }, point))) })] })] })));
}
exports.Step = Step;
