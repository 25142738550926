"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Formatter = void 0;
const CURRENCY_REGEXP = /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g;
class Formatter {
    static formatCurrency(number) {
        const value = number.toString();
        return value.replace(CURRENCY_REGEXP, '$1 ');
    }
}
exports.Formatter = Formatter;
