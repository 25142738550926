"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styles_1 = require("@mui/material/styles");
const AdapterDateFns_1 = require("@mui/x-date-pickers/AdapterDateFns");
const LocalizationProvider_1 = require("@mui/x-date-pickers/LocalizationProvider");
const locale_1 = require("date-fns/locale");
const globalStyles_1 = require("@common/globalStyles");
const theme_1 = require("@common/theme");
const ErrorBoundary_1 = require("@components/ErrorBoundary");
const main_1 = require("@pages/main");
function App() {
    return ((0, jsx_runtime_1.jsx)(styles_1.ThemeProvider, Object.assign({ theme: theme_1.theme }, { children: (0, jsx_runtime_1.jsxs)(LocalizationProvider_1.LocalizationProvider, Object.assign({ dateAdapter: AdapterDateFns_1.AdapterDateFns, locale: locale_1.ru }, { children: [(0, jsx_runtime_1.jsx)(globalStyles_1.GlobalStyles, {}), (0, jsx_runtime_1.jsx)(ErrorBoundary_1.ErrorBoundary, { children: (0, jsx_runtime_1.jsx)(main_1.Main, {}) })] })) })));
}
exports.App = App;
