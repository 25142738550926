"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkSteps = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const styled_components_1 = require("styled-components");
const mediaQueries_1 = require("@common/mediaQueries");
const useIntersectionScroll_1 = require("@hooks/useIntersectionScroll");
const Step_1 = require("./Step");
const utils_1 = require("./utils");
const StepsWrapper = styled_components_1.default.div.withConfig({ displayName: "StepsWrapper", componentId: "-n3ercm" }) `
  margin-top: 40px;

  @media ${mediaQueries_1.devices.tablet} {
    margin-top: 80px;
  }
`;
function WorkSteps() {
    const ref = (0, react_1.useRef)(null);
    const scrollPosition = (0, useIntersectionScroll_1.useIntersectionScroll)(ref);
    return ((0, jsx_runtime_1.jsxs)(material_1.Container, Object.assign({ ref: ref }, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ align: "center", variant: "h2" }, { children: "\u041A\u0430\u043A \u044D\u0442\u043E \u0440\u0430\u0431\u043E\u0442\u0430\u0435\u0442?" })), (0, jsx_runtime_1.jsx)(StepsWrapper, { children: utils_1.steps.map(step => ((0, jsx_runtime_1.jsx)(Step_1.Step, { step: step, scrollPosition: scrollPosition }, step.index))) })] })));
}
exports.WorkSteps = WorkSteps;
