"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AVG_TVR_BA = void 0;
exports.AVG_TVR_BA = [
    { cityName: 'Москва', channelName: 'Первый', value: 0.7082 },
    { cityName: 'Москва', channelName: 'Россия 1', value: 1.9638 },
    { cityName: 'Москва', channelName: 'НТВ', value: 1.2877 },
    { cityName: 'Москва', channelName: 'ТНТ', value: 0.3987 },
    { cityName: 'Москва', channelName: 'СТС', value: 0.3892 },
    { cityName: 'Москва', channelName: 'Пятый канал', value: 0.655 },
    { cityName: 'Москва', channelName: 'РЕН ТВ', value: 0.4765 },
    { cityName: 'Москва', channelName: 'Домашний', value: 0.363 },
    { cityName: 'Москва', channelName: 'ТВ-3', value: 0.2765 },
    { cityName: 'Москва', channelName: 'Пятница', value: 0.3246 },
    { cityName: 'Москва', channelName: 'Че', value: 0.0569 },
    { cityName: 'Москва', channelName: 'Ю', value: 0.0993 },
    { cityName: 'Москва', channelName: 'ТВЦ', value: 0.6172 },
    { cityName: 'Москва', channelName: 'Звезда', value: 0.2595 },
    { cityName: 'Москва', channelName: 'Россия 24', value: 0.3092 },
    { cityName: 'Москва', channelName: 'Матч ТВ', value: 0.2299 },
    { cityName: 'Москва', channelName: 'Disney', value: 0.2141 },
    { cityName: 'Москва', channelName: 'Москва 24', value: 0.1414 },
    { cityName: 'Москва', channelName: 'Карусель', value: 0.5408 },
    { cityName: 'Санкт-Петербург', channelName: 'Первый', value: 0.4922 },
    { cityName: 'Санкт-Петербург', channelName: 'Россия 1', value: 1.8667 },
    { cityName: 'Санкт-Петербург', channelName: 'НТВ', value: 1.2198 },
    { cityName: 'Санкт-Петербург', channelName: 'ТНТ', value: 0.2663 },
    { cityName: 'Санкт-Петербург', channelName: 'СТС', value: 0.3575 },
    { cityName: 'Санкт-Петербург', channelName: 'Пятый канал', value: 1.0364 },
    { cityName: 'Санкт-Петербург', channelName: 'РЕН ТВ', value: 0.3409 },
    { cityName: 'Санкт-Петербург', channelName: 'Домашний', value: 0.2069 },
    { cityName: 'Санкт-Петербург', channelName: 'ТВ-3', value: 0.1766 },
    { cityName: 'Санкт-Петербург', channelName: 'Пятница', value: 0.2356 },
    { cityName: 'Санкт-Петербург', channelName: 'Че', value: 0.031 },
    { cityName: 'Санкт-Петербург', channelName: 'ТВЦ', value: 0.4536 },
    { cityName: 'Санкт-Петербург', channelName: 'Звезда', value: 0.1208 },
    { cityName: 'Санкт-Петербург', channelName: 'Россия 24', value: 0.286 },
    { cityName: 'Санкт-Петербург', channelName: 'Матч ТВ', value: 0.2188 },
    { cityName: 'Санкт-Петербург', channelName: 'Телеканал 78', value: 0.1559 },
    { cityName: 'Санкт-Петербург', channelName: 'ТК Санкт-Петербург', value: 0.0934 },
    { cityName: 'Барнаул', channelName: 'Первый', value: 1.2019 },
    { cityName: 'Барнаул', channelName: 'Россия 1', value: 2.5289 },
    { cityName: 'Барнаул', channelName: 'НТВ', value: 1.581 },
    { cityName: 'Барнаул', channelName: 'ТНТ', value: 0.3894 },
    { cityName: 'Барнаул', channelName: 'СТС', value: 0.3291 },
    { cityName: 'Барнаул', channelName: 'Пятый канал', value: 1.1014 },
    { cityName: 'Барнаул', channelName: 'РЕН ТВ', value: 0.6299 },
    { cityName: 'Барнаул', channelName: 'Домашний', value: 0.8284 },
    { cityName: 'Барнаул', channelName: 'ТВ-3', value: 0.3707 },
    { cityName: 'Барнаул', channelName: 'Пятница', value: 0.175 },
    { cityName: 'Барнаул', channelName: 'ТВЦ', value: 0.3501 },
    { cityName: 'Барнаул', channelName: 'Матч ТВ', value: 0.1037 },
    { cityName: 'Владивосток', channelName: 'Первый', value: 0.8636 },
    { cityName: 'Владивосток', channelName: 'Россия 1', value: 1.8935 },
    { cityName: 'Владивосток', channelName: 'НТВ', value: 1.4884 },
    { cityName: 'Владивосток', channelName: 'ТНТ', value: 0.4193 },
    { cityName: 'Владивосток', channelName: 'СТС', value: 0.4967 },
    { cityName: 'Владивосток', channelName: 'Пятый канал', value: 0.8459 },
    { cityName: 'Владивосток', channelName: 'РЕН ТВ', value: 0.6371 },
    { cityName: 'Владивосток', channelName: 'Домашний', value: 0.441 },
    { cityName: 'Владивосток', channelName: 'ТВ-3', value: 0.3558 },
    { cityName: 'Владивосток', channelName: 'Пятница', value: 0.5275 },
    { cityName: 'Владивосток', channelName: 'ТВЦ', value: 0.5126 },
    { cityName: 'Владивосток', channelName: 'Россия 24', value: 0.6889 },
    { cityName: 'Владивосток', channelName: 'Матч ТВ', value: 0.0931 },
    { cityName: 'Волгоград', channelName: 'Первый', value: 0.9743 },
    { cityName: 'Волгоград', channelName: 'Россия 1', value: 1.7825 },
    { cityName: 'Волгоград', channelName: 'НТВ', value: 1.9479 },
    { cityName: 'Волгоград', channelName: 'ТНТ', value: 0.6403 },
    { cityName: 'Волгоград', channelName: 'СТС', value: 0.8472 },
    { cityName: 'Волгоград', channelName: 'Пятый канал', value: 1.862 },
    { cityName: 'Волгоград', channelName: 'РЕН ТВ', value: 0.8547 },
    { cityName: 'Волгоград', channelName: 'Домашний', value: 0.6758 },
    { cityName: 'Волгоград', channelName: 'ТВ-3', value: 0.4726 },
    { cityName: 'Волгоград', channelName: 'Пятница', value: 0.5568 },
    { cityName: 'Волгоград', channelName: 'ТВЦ', value: 0.7582 },
    { cityName: 'Волгоград', channelName: 'Звезда', value: 0.5466 },
    { cityName: 'Волгоград', channelName: 'Матч ТВ', value: 0.1378 },
    { cityName: 'Воронеж', channelName: 'Первый', value: 1.4753 },
    { cityName: 'Воронеж', channelName: 'Россия 1', value: 2.36 },
    { cityName: 'Воронеж', channelName: 'НТВ', value: 1.36 },
    { cityName: 'Воронеж', channelName: 'ТНТ', value: 0.5422 },
    { cityName: 'Воронеж', channelName: 'СТС', value: 0.5509 },
    { cityName: 'Воронеж', channelName: 'Пятый канал', value: 0.9497 },
    { cityName: 'Воронеж', channelName: 'РЕН ТВ', value: 0.6011 },
    { cityName: 'Воронеж', channelName: 'Домашний', value: 0.5015 },
    { cityName: 'Воронеж', channelName: 'ТВ-3', value: 0.2021 },
    { cityName: 'Воронеж', channelName: 'Пятница', value: 0.1945 },
    { cityName: 'Воронеж', channelName: 'ТВЦ', value: 0.2276 },
    { cityName: 'Воронеж', channelName: 'Звезда', value: 0.3162 },
    { cityName: 'Воронеж', channelName: 'Матч ТВ', value: 0.1072 },
    { cityName: 'Екатеринбург', channelName: 'Первый', value: 1.0271 },
    { cityName: 'Екатеринбург', channelName: 'Россия 1', value: 1.6565 },
    { cityName: 'Екатеринбург', channelName: 'НТВ', value: 1.6226 },
    { cityName: 'Екатеринбург', channelName: 'ТНТ', value: 0.4994 },
    { cityName: 'Екатеринбург', channelName: 'СТС', value: 0.5081 },
    { cityName: 'Екатеринбург', channelName: 'Пятый канал', value: 1.0601 },
    { cityName: 'Екатеринбург', channelName: 'РЕН ТВ', value: 0.6814 },
    { cityName: 'Екатеринбург', channelName: 'Домашний', value: 0.267 },
    { cityName: 'Екатеринбург', channelName: 'ТВ-3', value: 0.1887 },
    { cityName: 'Екатеринбург', channelName: 'Пятница', value: 0.3326 },
    { cityName: 'Екатеринбург', channelName: 'ТВЦ', value: 0.3708 },
    { cityName: 'Екатеринбург', channelName: 'Звезда', value: 0.195 },
    { cityName: 'Екатеринбург', channelName: 'Матч ТВ', value: 0.2135 },
    { cityName: 'Иркутск', channelName: 'Первый', value: 0.6129 },
    { cityName: 'Иркутск', channelName: 'Россия 1', value: 1.707 },
    { cityName: 'Иркутск', channelName: 'НТВ', value: 0.9228 },
    { cityName: 'Иркутск', channelName: 'ТНТ', value: 0.5045 },
    { cityName: 'Иркутск', channelName: 'СТС', value: 0.6756 },
    { cityName: 'Иркутск', channelName: 'Пятый канал', value: 0.6176 },
    { cityName: 'Иркутск', channelName: 'РЕН ТВ', value: 1.1832 },
    { cityName: 'Иркутск', channelName: 'Домашний', value: 0.6625 },
    { cityName: 'Иркутск', channelName: 'ТВ-3', value: 0.3869 },
    { cityName: 'Иркутск', channelName: 'Пятница', value: 0.3238 },
    { cityName: 'Иркутск', channelName: 'ТВЦ', value: 0.6426 },
    { cityName: 'Иркутск', channelName: 'Россия 24', value: 0.3914 },
    { cityName: 'Иркутск', channelName: 'Матч ТВ', value: 0.0867 },
    { cityName: 'Казань', channelName: 'Первый', value: 1.5273 },
    { cityName: 'Казань', channelName: 'Россия 1', value: 1.0225 },
    { cityName: 'Казань', channelName: 'НТВ', value: 1.0244 },
    { cityName: 'Казань', channelName: 'ТНТ', value: 0.4542 },
    { cityName: 'Казань', channelName: 'СТС', value: 0.6346 },
    { cityName: 'Казань', channelName: 'Пятый канал', value: 0.5836 },
    { cityName: 'Казань', channelName: 'РЕН ТВ', value: 0.3962 },
    { cityName: 'Казань', channelName: 'Домашний', value: 0.7476 },
    { cityName: 'Казань', channelName: 'ТВ-3', value: 0.2739 },
    { cityName: 'Казань', channelName: 'Пятница', value: 0.2077 },
    { cityName: 'Казань', channelName: 'ТВЦ', value: 0.4602 },
    { cityName: 'Казань', channelName: 'Звезда', value: 0.2639 },
    { cityName: 'Казань', channelName: 'Россия 24', value: 0.2206 },
    { cityName: 'Казань', channelName: 'Матч ТВ', value: 0.0488 },
    { cityName: 'Кемерово', channelName: 'Первый', value: 1.0533 },
    { cityName: 'Кемерово', channelName: 'Россия 1', value: 2.5476 },
    { cityName: 'Кемерово', channelName: 'НТВ', value: 1.2783 },
    { cityName: 'Кемерово', channelName: 'ТНТ', value: 1.0593 },
    { cityName: 'Кемерово', channelName: 'СТС', value: 0.4968 },
    { cityName: 'Кемерово', channelName: 'Пятый канал', value: 1.0692 },
    { cityName: 'Кемерово', channelName: 'РЕН ТВ', value: 0.7656 },
    { cityName: 'Кемерово', channelName: 'Домашний', value: 0.5507 },
    { cityName: 'Кемерово', channelName: 'ТВ-3', value: 0.3567 },
    { cityName: 'Кемерово', channelName: 'Пятница', value: 0.5613 },
    { cityName: 'Кемерово', channelName: 'ТВЦ', value: 0.2926 },
    { cityName: 'Кемерово', channelName: 'Матч ТВ', value: 0.0593 },
    { cityName: 'Краснодар', channelName: 'Первый', value: 1.2694 },
    { cityName: 'Краснодар', channelName: 'Россия 1', value: 2.1573 },
    { cityName: 'Краснодар', channelName: 'НТВ', value: 1.1151 },
    { cityName: 'Краснодар', channelName: 'ТНТ', value: 0.6411 },
    { cityName: 'Краснодар', channelName: 'СТС', value: 0.5597 },
    { cityName: 'Краснодар', channelName: 'Пятый канал', value: 1.28 },
    { cityName: 'Краснодар', channelName: 'РЕН ТВ', value: 0.599 },
    { cityName: 'Краснодар', channelName: 'Домашний', value: 0.6194 },
    { cityName: 'Краснодар', channelName: 'ТВ-3', value: 0.5426 },
    { cityName: 'Краснодар', channelName: 'Пятница', value: 0.5087 },
    { cityName: 'Краснодар', channelName: 'ТВЦ', value: 0.3747 },
    { cityName: 'Краснодар', channelName: 'Звезда', value: 0.2894 },
    { cityName: 'Краснодар', channelName: 'Матч ТВ', value: 0.1482 },
    { cityName: 'Красноярск', channelName: 'Первый', value: 0.9702 },
    { cityName: 'Красноярск', channelName: 'Россия 1', value: 1.2405 },
    { cityName: 'Красноярск', channelName: 'НТВ', value: 1.0849 },
    { cityName: 'Красноярск', channelName: 'ТНТ', value: 0.505 },
    { cityName: 'Красноярск', channelName: 'СТС', value: 0.5594 },
    { cityName: 'Красноярск', channelName: 'Пятый канал', value: 1.251 },
    { cityName: 'Красноярск', channelName: 'РЕН ТВ', value: 0.5068 },
    { cityName: 'Красноярск', channelName: 'Домашний', value: 0.5541 },
    { cityName: 'Красноярск', channelName: 'ТВ-3', value: 0.2661 },
    { cityName: 'Красноярск', channelName: 'Пятница', value: 0.2917 },
    { cityName: 'Красноярск', channelName: 'ТВЦ', value: 0.5571 },
    { cityName: 'Красноярск', channelName: 'Звезда', value: 0.2457 },
    { cityName: 'Красноярск', channelName: 'Матч ТВ', value: 0.0795 },
    { cityName: 'Нижний Новгород', channelName: 'Первый', value: 1.5066 },
    { cityName: 'Нижний Новгород', channelName: 'Россия 1', value: 2.0357 },
    { cityName: 'Нижний Новгород', channelName: 'НТВ', value: 1.4673 },
    { cityName: 'Нижний Новгород', channelName: 'ТНТ', value: 0.6389 },
    { cityName: 'Нижний Новгород', channelName: 'СТС', value: 0.591 },
    { cityName: 'Нижний Новгород', channelName: 'Пятый канал', value: 1.1393 },
    { cityName: 'Нижний Новгород', channelName: 'РЕН ТВ', value: 0.8451 },
    { cityName: 'Нижний Новгород', channelName: 'Домашний', value: 0.5258 },
    { cityName: 'Нижний Новгород', channelName: 'ТВ-3', value: 0.3195 },
    { cityName: 'Нижний Новгород', channelName: 'Пятница', value: 0.5203 },
    { cityName: 'Нижний Новгород', channelName: 'ТВЦ', value: 0.6029 },
    { cityName: 'Нижний Новгород', channelName: 'Звезда', value: 0.3533 },
    { cityName: 'Нижний Новгород', channelName: 'Россия 24', value: 0.3202 },
    { cityName: 'Нижний Новгород', channelName: 'Матч ТВ', value: 0.3384 },
    { cityName: 'Новосибирск', channelName: 'Первый', value: 1.2848 },
    { cityName: 'Новосибирск', channelName: 'Россия 1', value: 2.5324 },
    { cityName: 'Новосибирск', channelName: 'НТВ', value: 1.1768 },
    { cityName: 'Новосибирск', channelName: 'ТНТ', value: 0.6098 },
    { cityName: 'Новосибирск', channelName: 'СТС', value: 0.5365 },
    { cityName: 'Новосибирск', channelName: 'Пятый канал', value: 0.8093 },
    { cityName: 'Новосибирск', channelName: 'РЕН ТВ', value: 0.6282 },
    { cityName: 'Новосибирск', channelName: 'Домашний', value: 0.2542 },
    { cityName: 'Новосибирск', channelName: 'ТВ-3', value: 0.2801 },
    { cityName: 'Новосибирск', channelName: 'Пятница', value: 0.4351 },
    { cityName: 'Новосибирск', channelName: 'ТВЦ', value: 0.4469 },
    { cityName: 'Новосибирск', channelName: 'Звезда', value: 0.3123 },
    { cityName: 'Новосибирск', channelName: 'Россия 24', value: 0.8145 },
    { cityName: 'Новосибирск', channelName: 'Матч ТВ', value: 0.2021 },
    { cityName: 'Омск', channelName: 'Первый', value: 1.3177 },
    { cityName: 'Омск', channelName: 'Россия 1', value: 1.9901 },
    { cityName: 'Омск', channelName: 'НТВ', value: 1.7102 },
    { cityName: 'Омск', channelName: 'ТНТ', value: 0.6873 },
    { cityName: 'Омск', channelName: 'СТС', value: 0.8487 },
    { cityName: 'Омск', channelName: 'Пятый канал', value: 0.9878 },
    { cityName: 'Омск', channelName: 'РЕН ТВ', value: 1.05 },
    { cityName: 'Омск', channelName: 'Домашний', value: 1.3483 },
    { cityName: 'Омск', channelName: 'ТВ-3', value: 0.4281 },
    { cityName: 'Омск', channelName: 'ТВЦ', value: 0.7557 },
    { cityName: 'Омск', channelName: 'Матч ТВ', value: 0.2403 },
    { cityName: 'Пермь', channelName: 'Первый', value: 0.8338 },
    { cityName: 'Пермь', channelName: 'Россия 1', value: 1.9872 },
    { cityName: 'Пермь', channelName: 'НТВ', value: 1.342 },
    { cityName: 'Пермь', channelName: 'ТНТ', value: 0.9587 },
    { cityName: 'Пермь', channelName: 'СТС', value: 0.4722 },
    { cityName: 'Пермь', channelName: 'Пятый канал', value: 1.2962 },
    { cityName: 'Пермь', channelName: 'РЕН ТВ', value: 0.78 },
    { cityName: 'Пермь', channelName: 'Домашний', value: 0.7257 },
    { cityName: 'Пермь', channelName: 'ТВ-3', value: 0.3989 },
    { cityName: 'Пермь', channelName: 'Пятница', value: 0.5392 },
    { cityName: 'Пермь', channelName: 'ТВЦ', value: 0.5168 },
    { cityName: 'Пермь', channelName: 'Звезда', value: 0.2758 },
    { cityName: 'Пермь', channelName: 'Матч ТВ', value: 0.1135 },
    { cityName: 'Ростов-на-Дону', channelName: 'Первый', value: 1.4467 },
    { cityName: 'Ростов-на-Дону', channelName: 'Россия 1', value: 1.8105 },
    { cityName: 'Ростов-на-Дону', channelName: 'НТВ', value: 0.8651 },
    { cityName: 'Ростов-на-Дону', channelName: 'ТНТ', value: 0.6502 },
    { cityName: 'Ростов-на-Дону', channelName: 'СТС', value: 0.5921 },
    { cityName: 'Ростов-на-Дону', channelName: 'Пятый канал', value: 0.5341 },
    { cityName: 'Ростов-на-Дону', channelName: 'РЕН ТВ', value: 0.9915 },
    { cityName: 'Ростов-на-Дону', channelName: 'Домашний', value: 0.6306 },
    { cityName: 'Ростов-на-Дону', channelName: 'ТВ-3', value: 0.252 },
    { cityName: 'Ростов-на-Дону', channelName: 'Пятница', value: 0.2997 },
    { cityName: 'Ростов-на-Дону', channelName: 'ТВЦ', value: 0.4206 },
    { cityName: 'Ростов-на-Дону', channelName: 'Звезда', value: 0.3619 },
    { cityName: 'Ростов-на-Дону', channelName: 'Россия 24', value: 0.37 },
    { cityName: 'Ростов-на-Дону', channelName: 'Матч ТВ', value: 0.0874 },
    { cityName: 'Самара', channelName: 'Первый', value: 1.0212 },
    { cityName: 'Самара', channelName: 'Россия 1', value: 2.1538 },
    { cityName: 'Самара', channelName: 'НТВ', value: 1.4925 },
    { cityName: 'Самара', channelName: 'ТНТ', value: 0.5781 },
    { cityName: 'Самара', channelName: 'СТС', value: 0.6141 },
    { cityName: 'Самара', channelName: 'Пятый канал', value: 0.6847 },
    { cityName: 'Самара', channelName: 'РЕН ТВ', value: 0.3988 },
    { cityName: 'Самара', channelName: 'Домашний', value: 0.528 },
    { cityName: 'Самара', channelName: 'ТВ-3', value: 0.2974 },
    { cityName: 'Самара', channelName: 'ТВЦ', value: 0.6118 },
    { cityName: 'Самара', channelName: 'Матч ТВ', value: 0.063 },
    { cityName: 'Саратов', channelName: 'Первый', value: 1.3615 },
    { cityName: 'Саратов', channelName: 'Россия 1', value: 2.0352 },
    { cityName: 'Саратов', channelName: 'НТВ', value: 1.2839 },
    { cityName: 'Саратов', channelName: 'ТНТ', value: 0.5371 },
    { cityName: 'Саратов', channelName: 'СТС', value: 0.3785 },
    { cityName: 'Саратов', channelName: 'Пятый канал', value: 0.6543 },
    { cityName: 'Саратов', channelName: 'РЕН ТВ', value: 0.4999 },
    { cityName: 'Саратов', channelName: 'Домашний', value: 0.7002 },
    { cityName: 'Саратов', channelName: 'ТВ-3', value: 0.2504 },
    { cityName: 'Саратов', channelName: 'Пятница', value: 0.3133 },
    { cityName: 'Саратов', channelName: 'ТВЦ', value: 0.4837 },
    { cityName: 'Саратов', channelName: 'Россия 24', value: 0.3052 },
    { cityName: 'Саратов', channelName: 'Матч ТВ', value: 0.0818 },
    { cityName: 'Ставрополь', channelName: 'Первый', value: 0.8161 },
    { cityName: 'Ставрополь', channelName: 'Россия 1', value: 1.6489 },
    { cityName: 'Ставрополь', channelName: 'НТВ', value: 0.5847 },
    { cityName: 'Ставрополь', channelName: 'ТНТ', value: 0.6494 },
    { cityName: 'Ставрополь', channelName: 'СТС', value: 0.6307 },
    { cityName: 'Ставрополь', channelName: 'Пятый канал', value: 1.0677 },
    { cityName: 'Ставрополь', channelName: 'РЕН ТВ', value: 0.4376 },
    { cityName: 'Ставрополь', channelName: 'Домашний', value: 0.3377 },
    { cityName: 'Ставрополь', channelName: 'ТВ-3', value: 0.9307 },
    { cityName: 'Ставрополь', channelName: 'Пятница', value: 0.1593 },
    { cityName: 'Ставрополь', channelName: 'Россия 24', value: 0.1352 },
    { cityName: 'Ставрополь', channelName: 'Матч ТВ', value: 0.1076 },
    { cityName: 'Тверь', channelName: 'Первый', value: 1.13 },
    { cityName: 'Тверь', channelName: 'Россия 1', value: 1.7666 },
    { cityName: 'Тверь', channelName: 'НТВ', value: 0.9398 },
    { cityName: 'Тверь', channelName: 'ТНТ', value: 0.5188 },
    { cityName: 'Тверь', channelName: 'СТС', value: 0.4913 },
    { cityName: 'Тверь', channelName: 'Пятый канал', value: 0.5935 },
    { cityName: 'Тверь', channelName: 'РЕН ТВ', value: 0.4394 },
    { cityName: 'Тверь', channelName: 'Домашний', value: 0.2711 },
    { cityName: 'Тверь', channelName: 'ТВ-3', value: 0.3862 },
    { cityName: 'Тверь', channelName: 'Пятница', value: 0.8177 },
    { cityName: 'Тверь', channelName: 'ТВЦ', value: 0.1782 },
    { cityName: 'Тверь', channelName: 'Матч ТВ', value: 0.1143 },
    { cityName: 'Томск', channelName: 'Первый', value: 0.839 },
    { cityName: 'Томск', channelName: 'Россия 1', value: 1.7403 },
    { cityName: 'Томск', channelName: 'НТВ', value: 0.9653 },
    { cityName: 'Томск', channelName: 'ТНТ', value: 0.5445 },
    { cityName: 'Томск', channelName: 'СТС', value: 0.4932 },
    { cityName: 'Томск', channelName: 'Пятый канал', value: 0.8388 },
    { cityName: 'Томск', channelName: 'РЕН ТВ', value: 0.5533 },
    { cityName: 'Томск', channelName: 'Домашний', value: 0.6374 },
    { cityName: 'Томск', channelName: 'ТВ-3', value: 0.5628 },
    { cityName: 'Томск', channelName: 'Пятница', value: 0.3043 },
    { cityName: 'Томск', channelName: 'ТВЦ', value: 0.3138 },
    { cityName: 'Томск', channelName: 'Матч ТВ', value: 0.0907 },
    { cityName: 'Тюмень', channelName: 'Первый', value: 1.3851 },
    { cityName: 'Тюмень', channelName: 'Россия 1', value: 2.0407 },
    { cityName: 'Тюмень', channelName: 'НТВ', value: 0.7949 },
    { cityName: 'Тюмень', channelName: 'ТНТ', value: 0.6425 },
    { cityName: 'Тюмень', channelName: 'СТС', value: 0.9192 },
    { cityName: 'Тюмень', channelName: 'Пятый канал', value: 0.7438 },
    { cityName: 'Тюмень', channelName: 'РЕН ТВ', value: 0.5956 },
    { cityName: 'Тюмень', channelName: 'Домашний', value: 0.7464 },
    { cityName: 'Тюмень', channelName: 'ТВ-3', value: 0.8023 },
    { cityName: 'Тюмень', channelName: 'Пятница', value: 0.7056 },
    { cityName: 'Тюмень', channelName: 'ТВЦ', value: 0.3343 },
    { cityName: 'Тюмень', channelName: 'Звезда', value: 0.217 },
    { cityName: 'Тюмень', channelName: 'Матч ТВ', value: 0.0673 },
    { cityName: 'Уфа', channelName: 'Первый', value: 0.6565 },
    { cityName: 'Уфа', channelName: 'Россия 1', value: 1.5913 },
    { cityName: 'Уфа', channelName: 'НТВ', value: 1.0408 },
    { cityName: 'Уфа', channelName: 'ТНТ', value: 1.0627 },
    { cityName: 'Уфа', channelName: 'СТС', value: 1.2787 },
    { cityName: 'Уфа', channelName: 'Пятый канал', value: 0.8517 },
    { cityName: 'Уфа', channelName: 'РЕН ТВ', value: 0.4444 },
    { cityName: 'Уфа', channelName: 'Домашний', value: 0.5796 },
    { cityName: 'Уфа', channelName: 'ТВ-3', value: 0.2489 },
    { cityName: 'Уфа', channelName: 'Пятница', value: 0.2544 },
    { cityName: 'Уфа', channelName: 'ТВЦ', value: 0.6748 },
    { cityName: 'Уфа', channelName: 'Матч ТВ', value: 0.0863 },
    { cityName: 'Уфа', channelName: 'БСТ', value: 0.0587 },
    { cityName: 'Хабаровск', channelName: 'Первый', value: 1.121 },
    { cityName: 'Хабаровск', channelName: 'Россия 1', value: 1.8454 },
    { cityName: 'Хабаровск', channelName: 'НТВ', value: 1.088 },
    { cityName: 'Хабаровск', channelName: 'ТНТ', value: 0.3788 },
    { cityName: 'Хабаровск', channelName: 'СТС', value: 1.0138 },
    { cityName: 'Хабаровск', channelName: 'Пятый канал', value: 1.5457 },
    { cityName: 'Хабаровск', channelName: 'РЕН ТВ', value: 0.6558 },
    { cityName: 'Хабаровск', channelName: 'Домашний', value: 0.7216 },
    { cityName: 'Хабаровск', channelName: 'ТВ-3', value: 0.731 },
    { cityName: 'Хабаровск', channelName: 'Пятница', value: 0.5336 },
    { cityName: 'Хабаровск', channelName: 'ТВЦ', value: 0.7823 },
    { cityName: 'Хабаровск', channelName: 'Звезда', value: 0.3457 },
    { cityName: 'Хабаровск', channelName: 'Россия 24', value: 0.7139 },
    { cityName: 'Хабаровск', channelName: 'Матч ТВ', value: 0.0958 },
    { cityName: 'Челябинск', channelName: 'Первый', value: 1.525 },
    { cityName: 'Челябинск', channelName: 'Россия 1', value: 2.7972 },
    { cityName: 'Челябинск', channelName: 'НТВ', value: 1.5769 },
    { cityName: 'Челябинск', channelName: 'ТНТ', value: 1.0255 },
    { cityName: 'Челябинск', channelName: 'СТС', value: 1.078 },
    { cityName: 'Челябинск', channelName: 'Пятый канал', value: 0.588 },
    { cityName: 'Челябинск', channelName: 'РЕН ТВ', value: 0.6666 },
    { cityName: 'Челябинск', channelName: 'Домашний', value: 0.5093 },
    { cityName: 'Челябинск', channelName: 'ТВ-3', value: 0.4338 },
    { cityName: 'Челябинск', channelName: 'Пятница', value: 0.6733 },
    { cityName: 'Челябинск', channelName: 'ТВЦ', value: 0.3838 },
    { cityName: 'Челябинск', channelName: 'Звезда', value: 0.2876 },
    { cityName: 'Челябинск', channelName: 'Матч ТВ', value: 0.0808 },
    { cityName: 'Ярославль', channelName: 'Первый', value: 1.5992 },
    { cityName: 'Ярославль', channelName: 'Россия 1', value: 1.6269 },
    { cityName: 'Ярославль', channelName: 'НТВ', value: 0.6842 },
    { cityName: 'Ярославль', channelName: 'ТНТ', value: 0.6652 },
    { cityName: 'Ярославль', channelName: 'СТС', value: 0.7167 },
    { cityName: 'Ярославль', channelName: 'Пятый канал', value: 0.3798 },
    { cityName: 'Ярославль', channelName: 'РЕН ТВ', value: 0.7151 },
    { cityName: 'Ярославль', channelName: 'Домашний', value: 0.3733 },
    { cityName: 'Ярославль', channelName: 'ТВ-3', value: 0.2508 },
    { cityName: 'Ярославль', channelName: 'Пятница', value: 0.2649 },
    { cityName: 'Ярославль', channelName: 'ТВЦ', value: 0.3913 },
    { cityName: 'Ярославль', channelName: 'Звезда', value: 0.1103 },
    { cityName: 'Ярославль', channelName: 'Россия 24', value: 0.1352 },
    { cityName: 'Ярославль', channelName: 'Матч ТВ', value: 0.1032 },
    { cityName: 'Национальные каналы', channelName: 'Первый', value: 0.7188 },
    { cityName: 'Национальные каналы', channelName: 'Россия 1', value: 2.1015 },
    { cityName: 'Национальные каналы', channelName: 'НТВ', value: 1.7881 },
    { cityName: 'Национальные каналы', channelName: 'ТНТ', value: 0.7555 },
    { cityName: 'Национальные каналы', channelName: 'СТС', value: 0.932 },
    { cityName: 'Национальные каналы', channelName: 'Пятый канал', value: 1.1279 },
    { cityName: 'Национальные каналы', channelName: 'РЕН ТВ', value: 0.9923 },
    { cityName: 'Национальные каналы', channelName: 'Домашний', value: 1.0951 },
    { cityName: 'Национальные каналы', channelName: 'ТВ-3', value: 0.4872 },
    { cityName: 'Национальные каналы', channelName: 'Пятница', value: 0.4989 },
    { cityName: 'Национальные каналы', channelName: 'Че', value: 0.0958 },
    { cityName: 'Национальные каналы', channelName: 'Ю', value: 0.1517 },
    { cityName: 'Национальные каналы', channelName: 'ТВЦ', value: 0.6413 },
    { cityName: 'Национальные каналы', channelName: 'Карусель', value: 0.875 },
    { cityName: 'Национальные каналы', channelName: 'Звезда', value: 0.3986 },
    { cityName: 'Национальные каналы', channelName: 'Россия 24', value: 0.4665 },
    { cityName: 'Национальные каналы', channelName: 'Матч ТВ', value: 0.1481 },
    { cityName: 'Национальные каналы', channelName: 'Disney', value: 0.2226 },
    { cityName: 'Национальные каналы', channelName: 'СТС Love', value: 0.0955 },
    { cityName: 'Национальные каналы', channelName: 'ТНТ 4', value: 0.0787 },
    { cityName: 'Национальные каналы', channelName: 'Муз-ТВ', value: 0.1623 },
    { cityName: 'Национальные каналы', channelName: '2х2', value: 0.0686 },
    { cityName: 'Национальные каналы', channelName: 'Суббота', value: 0.171 },
    { cityName: 'Национальные каналы', channelName: 'МИР', value: 0.2593 },
    { cityName: 'Национальные каналы', channelName: 'СПАС', value: 0.0789 },
    { cityName: 'Национальные каналы', channelName: 'Кабельно-спутниковые каналы (пакет "Единый")', value: 1.5252 },
    { cityName: 'Национальные каналы', channelName: 'Кабельно-спутниковые каналы (пакет "Женский")', value: 1.8652 },
    { cityName: 'Национальные каналы', channelName: 'Кабельно-спутниковые каналы (пакет "Мужской")', value: 1.4069 },
    { cityName: 'Национальные каналы', channelName: 'Кабельно-спутниковые каналы (пакет "Детский")', value: 0.1422 },
    { cityName: 'Национальные каналы', channelName: 'Дом кино', value: 0.184 }
];
