"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TargetAudienceFirstStep = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const FormContext_1 = require("../FormContext");
const Navigation_1 = require("../Navigation");
const styles_1 = require("../styles");
const ageMarks = [
    { value: 4, label: 'от 4 лет' },
    { value: 99, label: 'до 99 лет' }
];
const INCOMES = [
    'Не хватает на еду',
    'Хватает на еду, но не хватает на одежду',
    'Хватает на еду и одежду, но не могут покупать дорогие вещи',
    'Хватает на всё'
];
const GENDERS = ['Любой', 'Мужской', 'Женский'];
function TargetAudienceFirstStep() {
    const { gender, changeGenderHandler, age, changeAgeHandler, incomes, toggleSelectedIncomes } = (0, react_1.useContext)(FormContext_1.FormContext);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(styles_1.Field, { children: [(0, jsx_runtime_1.jsx)("div", { children: "\u041F\u043E\u043B" }), (0, jsx_runtime_1.jsx)(material_1.RadioGroup, Object.assign({ row: true, value: gender, onChange: changeGenderHandler }, { children: GENDERS.map(_gender => ((0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { value: _gender, control: (0, jsx_runtime_1.jsx)(material_1.Radio, {}), label: _gender }, _gender))) }))] }), (0, jsx_runtime_1.jsxs)(styles_1.Field, { children: [(0, jsx_runtime_1.jsx)("div", { children: "\u0412\u043E\u0437\u0440\u0430\u0441\u0442" }), (0, jsx_runtime_1.jsx)(styles_1.SliderWrapper, { children: (0, jsx_runtime_1.jsx)(material_1.Slider, { min: 4, max: 99, step: 1, value: age, marks: ageMarks, valueLabelDisplay: "on", onChange: changeAgeHandler }) })] }), (0, jsx_runtime_1.jsxs)(styles_1.Field, { children: [(0, jsx_runtime_1.jsx)("div", { children: "\u0413\u0440\u0443\u043F\u043F\u0430 \u0434\u043E\u0445\u043E\u0434\u0430" }), INCOMES.map(income => ((0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(material_1.Checkbox, { onChange: toggleSelectedIncomes(income), checked: incomes.includes(income) }), label: income }, income)))] }), (0, jsx_runtime_1.jsx)(Navigation_1.Navigation, { stepData: { gender, age, incomes }, isValid: true })] }));
}
exports.TargetAudienceFirstStep = TargetAudienceFirstStep;
