"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIntersectionScroll = void 0;
const react_1 = require("react");
function useIntersectionScroll(ref, offset = 900) {
    const [scrollPosition, setScrollPosition] = (0, react_1.useState)(0);
    (0, react_1.useEffect)(() => {
        const scroll = () => {
            if (ref.current) {
                const height = window.scrollY - offset;
                setScrollPosition(height);
            }
        };
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                window.addEventListener('scroll', scroll);
            }
            else {
                window.removeEventListener('scroll', scroll);
            }
        }, {});
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref]);
    return scrollPosition;
}
exports.useIntersectionScroll = useIntersectionScroll;
